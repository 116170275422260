import React, { useState, useEffect, useRef } from "react";
import {
  getSimulations,
  getSimulation,
  exportSimulations,
  getStatusSimulation,
} from "../services/Simulations";
import Input from "../components/input/Input";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import DatePicker from "react-datepicker";

//plugins
import swal from "sweetalert";
import moment from "moment";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import { listPartners } from "../services/Api";

function Simulations() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [listParceiros, setListParceiros] = useState([]);

  //Dados do filtro:
  const [nome, setNome] = useState(null);
  const [nomeModal, setNomeModal] = useState(null);
  const [status, setStatus] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const [simulation, setSimulation] = useState(null);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  //Dados do formulário:
  const [fullLoading, setFullLoading] = useState(false);

  const selectStatus = useRef();
  const selectParc = useRef();

  useEffect(() => {
    getData(null, null, null, null, null, null, 1);
    getParceiros();
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Data",
          field: "criado_em",
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Cliente",
          field: "cliente",
        },
        {
          label: "Celular",
          field: "celular",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            cliente: x.cliente,
            status: x.status,
            criado_em: x.criado_em,
            celular: x.celular,
            cpf: x.cpf,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => details(x.simulacao_id, x.cliente)}
                  >
                    <i className="fas fa-eye"></i>
                    Detalhes
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
    onClear();
  };

  const getData = async (
    status,
    nome,
    cpf,
    parceiro,
    dataInicio,
    dataFim,
    pg = 1
  ) => {
    try {
      setData();
      setStatus(status);
      setNome(nome);
      setCpf(cpf);
      setParceiro(parceiro);
      setPage(pg);
      setData(
        await getSimulations(
          status,
          nome,
          cpf,
          parceiro,
          dataInicio,
          dataFim,
          page
        )
      );
      setList(await getStatusSimulation());
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  const getParceiros = async () => {
    try {
      setListParceiros(await listPartners());
    } catch (err) {}
  };

  // const onPageChanged = async (pg) => getData(status, nome, cpf, pg);

  const onFilter = () => {
    if ((dataInicio && !dataFim) || (!dataInicio && dataFim)) {
      toast.info("Favor preencher as datas corretamente");
    } else {
      window.$("#modal-filter").modal("hide");
      getData(status, nome, cpf, parceiro, dataInicio, dataFim, 1);
    }
  };

  const onClear = () => {
    setStatus(null);
    setNome(null);
    setCpf(null);
    setParceiro(null);
    selectStatus.current.select.clearValue();
    selectParc.current.select.clearValue();
    setStatus(null);
    setDataInicio(null);
    setDataFim(null);
    window.$("#nome").val(null);
    window.$("#cpf").val(null);
    window.$("#parceiro").val(null);
    window.$("#status").val(null);
    window.$("#dataInicio").val(null);
    window.$("#dataFim").val(null);
  };

  async function details(id, cliente) {
    setFullLoading(true);
    setNomeModal("Detalhes Simulação - " + cliente);
    try {
      const response = await getSimulation(id);
      setSimulation(response);
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFullLoading(false);
    }
  }

  const handleExport = async () => {
    swal({
      title: "Exportar leads da simulação?",
      text: "Será exportado os leads das simulações",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await exportSimulations(
          nome,
          cpf,
          status,
          dataInicio,
          dataFim
        );
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV +=
            `Leads de Simulações Pendentes - ${moment().format("MMMM YYYY")}` +
            "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar as simulações", {
              icon: "error",
            });
          }

          let nomeArquivo = `leads-simulacoes-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFullLoading(false);
          swal("leads da simulação exportados com sucesso.", {
            icon: "success",
          });
        } else {
          setFullLoading(false);
          swal("Ocorreu um erro ao exportar os leads", {
            icon: "error",
          });
        }
      } else {
        swal("Os leads não foram exportados.", {
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {fullLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Exportar CSV",
              icone: (
                <i
                  className="fas fa-file-csv"
                  style={{ marginLeft: "5px" }}
                ></i>
              ),
              onClick: handleExport,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                // onPageChanged={onPageChanged}
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-3">
              <div className="card-header">
                <h3 className="mb-0">Simulação</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group">
                    <Select
                      className="form-control-select"
                      placeholder="Status..."
                      options={list}
                      ref={selectStatus}
                      onChange={(e) => {
                        if (e) {
                          setStatus(e.value || null);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group">
                    <Select
                      id="parceiro_id"
                      ref={selectParc}
                      className="form-control-select"
                      placeholder="Parceiros..."
                      onChange={(e) => {
                        if (e && e.value) {
                          setParceiro(e.value);
                        }
                      }}
                      options={listParceiros}
                      backspaceRemovesValue
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group mx-4">
                    <Input
                      id="nome"
                      className="form-control"
                      placeholder="Nome do Cliente..."
                      type="text"
                      value={nome}
                      onChange={(e) => {
                        setNome(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group mx-4">
                    <Input
                      id="cpf"
                      className="form-control"
                      placeholder="cpf..."
                      type="cpf"
                      value={cpf}
                      onChange={(e) => {
                        setCpf(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row mx-4">
                  <div className="form-group mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend ">
                        <span className="input-group-text px-3">
                          <i className="ni ni-calendar-grid-58"></i>
                        </span>
                      </div>
                      <DatePicker
                        id="dataInicio"
                        dateFormat="P"
                        locale="pt"
                        className="form-control"
                        placeholderText="Data inicial..."
                        selected={dataInicio}
                        onChange={(date) => setDataInicio(date)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text px-3">
                          <i className="ni ni-calendar-grid-58"></i>
                        </span>
                      </div>
                      <DatePicker
                        id="dataFim"
                        dateFormat="P"
                        locale="pt"
                        className="form-control"
                        placeholderText="Data final..."
                        selected={dataFim}
                        onChange={(date) => setDataFim(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClear}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal Adicionar / Editar */}
      <Modal
        id={"modal-data"}
        title={nomeModal}
        size={"lg"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClear}
              >
                x
              </span>
            </button>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados da Simulação</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {simulation &&
                    simulation.simulacao.blocos.map((x, index) => (
                      <div className="col-md-6 form-group" key={index}>
                        <label>{x.nome}</label>
                        <Input
                          id={x.id}
                          type="text"
                          className="form-control"
                          value={x.valor}
                          disabled={true}
                        />
                      </div>
                    ))}
                </div>

                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-block my-4"
                      data-dismiss="modal"
                      arial-label="close"
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default Simulations;
