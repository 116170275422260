import { Store } from "../redux/Store";
import instance from "./index";

export const getAgents = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `agentes/administrativo/listar`;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getIntentions = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `agentes/administrativo/listar-intencoes`;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getIntentionsByAgent = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `agentes/administrativo/buscar-intencoes?id=${id}`;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const saveIntentionsAgent = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/salvar-intencoes-emprestimo-agente";
      const response = await instance.post(
        url,
        { agente: id, intencoes: data },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getTermsAgents = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `administradores/buscar-termos`;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const gravarDadosTermos = async (dados) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "administradores/gravar-dados-termos";
      const response = await instance.post(
        url,
        dados,
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const importarAgentes = async (base64) => {
   return new Promise(async (resolve, reject) => {
     try {
       const token = Store.getState().user.token
 
       const url = "agentes/administrativo/importar-agentes"
       const response = await instance.post(
         url,
         { arquivo: base64 },
         { headers: { token } }
       )
 
       if (response.status === 200) {
         resolve(response)
       } else {
         reject(response)
       }
     } catch (err) {
       reject(err)
     }
   })
 }
