import React from "react";
import { useState, useEffect } from "react";
import { getReportProdGeral } from "../../../services/Api";

//charts
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import funcoes from "../../../utils/helpers/funcoes";

function AnualPropCharts({ userId }) {
  const [prop, setProp] = useState([]);

  useEffect(() => {
     const getDados = async () => {
       try {
         setProp(await getReportProdGeral(userId));
       } catch (err) {}
     };
    getDados();
  }, [userId]);

  const options = {
    chart: {
      style: {
        fontFamily: 'arial',
      },
      type: "line",
      // backgroundColor: 'transparent',
    },
    title: {
      text: "Relatório Anual de Produção",
    },

    subtitle: {
      text: '<a href="http://seucreditonegocios.com.br" target="_blank">seucreditonegocios.com.br</a>',
    },

    yAxis: {
      labels: {
        formatter: function () {
          return "R$ " + funcoes.formataMoeda(this.value);
        },
      },
      title: {
        text: "Produção",
      },
    },
    xAxis: {
      categories: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    },
    credits: {
      enabled: false,
    },
    legend:false,
    series: prop,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
            maxHeight: 800,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "bottom",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
export default AnualPropCharts;
