import * as yup from "yup";

const schema = yup.object().shape({
    tipoContratoAgent: yup.string('Selecione o tipo do contrato').required('Campo Obrigatório'),
    taxaAgent: yup.string('Selecione a taxa').required('Campo Obrigatório'),
    parcelaAgent: yup.string('Selecione a parcela').required('Campo Obrigatório'),
    tipoContratoClient: yup.string('Selecione o tipo do contrato').required('Campo Obrigatório'),
    taxaClient: yup.string('Selecione a taxa').required('Campo Obrigatório'),
    parcelaClient: yup.string('Selecione a parcela').required('Campo Obrigatório'),
});

export default schema;