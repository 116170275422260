import React, { useState, useEffect, useMemo, useRef } from "react";

import {
  getAgentData,
  getAgents,
  getAgentsStatus,
  getAgentsMaster,
  approveAgent,
  disableAgent,
  updateStatusAgent,
  updateDocuments,
  sendPasswordAgent,
  listPartners,
  listEstados,
  listBanks,
  listTypeOfAccounts,
  listByEstado,
  getAddressByCep,
  validateCpfAgent,
  hiddenComissionsAgent,
  exportAgents,
  sendAgent,
} from "../../services/Api";

import { importarAgentes, getIntentions } from "../../services/Agents";
import { unlockUser } from "../../services/Users";

import Footer from "../../components/footer/Footer";
import Input from "../../components/input/Input";
import Header from "../../components/header/Header";
import Menu from "../../components/sidenav/Menu";
import TopMenu from "../../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/modal/Modal";
import Loading from "../../components/loading/Loading";
import { Store } from "../../redux/Store";
import FormErrors from "../../components/formErrors/FormErrors";
import swal from "sweetalert";
import funcoes from "../../utils/helpers/funcoes";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import styles from "./style.module.css";
import registerDefault from "./ResourceAgents";

function Agents() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [listParceiros, setListParceiros] = useState([]);
  const [page, setPage] = useState(1);

  // lists
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [tipoContas, setTipoContas] = useState([]);

  //Dados do filtro:
  const [name, setName] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [status, setStatus] = useState(null);
  const [parceiro, setParceiro] = useState(null);

  //Agente selecionado:
  const [agent, setAgent] = useState(null);
  const [agentStatus, setAgentStatus] = useState(null);

  //Dados do formulário:
  const [viewMode, setViewMode] = useState("");
  const [modalTitle, setModalTitle] = useState(null);
  const [modalText, setModalText] = useState(null);
  const [modalOnConfirm, setModalOnConfirm] = useState(null);
  const extensoesPermitidas = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  const [inputErrors, setInputErrors] = useState({});
  const [files, setFiles] = useState({});
  const [disableSubmitUpload, setDisableSubmitUpload] = useState(true);
  const [ticagem, setTicagem] = useState({});
  const [agentesMaster, setAgentesMater] = useState(null);
  const [parceiros, setParceiros] = useState(null);
  const [intencoes, setIntencoes] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [fullLoading, setFullLoading] = useState(false);
  const [disableCampos, setDisableCampos] = useState(true);
  const [pendenciaImportacao, setPendenciaImportacao] = useState([]);

  const [register, setRegister] = useState({});
  const [errors, setErrors] = useState({});

  const user = Store.getState().user;
  const selectSexo = useRef();
  const selectMaster = useRef();
  const selectAgentMaster = useRef();
  const selectParceiros = useRef();
  const selectEstados = useRef();
  const selectCidades = useRef();
  const selectBancos = useRef();
  const selectTipoContas = useRef();
  const selectParc = useRef();

  useEffect(() => {
    if (user.role === 4) {
      getData(1, null, null, 1, null);
      setStatus(1);
    } else if (user.role === 5) {
      getData(1, null, null, 6, null);
      setStatus(6);
    } else {
      getData(1, null, null, null, null);
    }

    getStatus();
    getParceiros();
    window.$("#modal-image").on("hidden.bs.modal", function () {
      window.$("#modal-image").modal("hide");
      window.$("#modal-data").modal("show");
      window
        .$("#modal-data")
        .animate({ scrollTop: window.$("#modal-data").height() });
    });

    window.$("#modal-data").on("hidden.bs.modal", function () {
      setAgent(null);
      setRegister({});
      setErrors({});
      setTicagem({});
      selectSexo.current.select.clearValue();
      selectMaster.current.select.clearValue();
      selectAgentMaster.current.select.clearValue();
      selectParceiros.current.select.clearValue();
      selectEstados.current.select.clearValue();
      selectCidades.current.select.clearValue();
      selectBancos.current.select.clearValue();
      selectTipoContas.current.select.clearValue();
      selectParc.current.select.clearValue();
      window.$("#agencia").val("");
      window.$("#conta").val("");
      window.$("#pix").val("");
      window.$("#celular").val("");
      window.$("#email").val("");
      window.$("#observacao_auditoria").val("");
      window.$("#observacao_operacional").val("");
    });
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Id",
          field: "id",
        },
        {
          label: "Nome",
          field: "nome",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Nome",
          },
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Criado em",
          field: "criado_em",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],

      rows:
        data &&
        data.dados.map((x) => {
          return {
            nome: x.nome,
            id: x.id,
            cpf: x.cpf,
            status: (
              <span className="badge badge-dot mr-4">
                <i
                  className={`bg-${
                    [1, 7].includes(x.status_id)
                      ? "warning"
                      : [4, 5, 8, 9].includes(x.status_id)
                      ? "danger"
                      : [6].includes(x.status_id)
                      ? "info"
                      : "success"
                  }`}
                ></i>
                <span className="status">{x.status}</span>
              </span>
            ),
            criado_em: x.criado_em,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  {parseInt(user.role) === 1 || parseInt(user.role) === 6 ? (
                    <button
                      type="button"
                      onClick={() => onEditClick(x.id)}
                      className="dropdown-item"
                    >
                      <i className="fas fa-pencil-alt fa-lg"></i>
                      Editar
                    </button>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={() => onViewClick(x.id)}
                        className="dropdown-item"
                      >
                        <div className="divDropdown">
                          <i className="fas fa-eye fa-lg"></i>
                          Visualizar
                        </div>
                      </button>
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => openUpload(x.id)}
                    className="dropdown-item"
                  >
                    <div className="divDropdown">
                      <i className="fas fa-file-upload fa-lg"></i>
                      Upload
                    </div>
                  </button>
                  {[2, 3].includes(x.status_id) && (
                    <button
                      type="button"
                      onClick={() => generateNewPassword(x.id, x.nome)}
                      className="dropdown-item"
                    >
                      <div className="divDropdown">
                        <i className="fas fa-key"></i>
                        Enviar Senha
                      </div>
                    </button>
                  )}
                  {[8].includes(x.status_id) && (
                    <button
                      type="button"
                      onClick={() => handleUnlockUser(x)}
                      className="dropdown-item"
                    >
                      <div className="divDropdown">
                        <i className="fas fa-unlock-alt"></i>
                        Desbloquear
                      </div>
                    </button>
                  )}
                  {[6].includes(user.role) && (
                    <button
                      type="button"
                      onClick={() =>
                        hiddenComissions(x.id, x.nome, x.mostrar_comissao)
                      }
                      className="dropdown-item"
                    >
                      <i
                        className={
                          x.mostrar_comissao
                            ? "fas fa-eye-slash fa-lg"
                            : "fas fa-eye fa-lg"
                        }
                      ></i>
                      {x.mostrar_comissao
                        ? "Ocultar Comissões"
                        : "Mostrar Comissões"}
                    </button>
                  )}
                  {[4].includes(user.role) && (
                    <button
                      type="button"
                      onClick={() => onDisableClick(x.id, x.nome)}
                      className="dropdown-item"
                    >
                      <i className="fas fa-trash fa-lg"></i>
                      Desativar
                    </button>
                  )}
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  useEffect(() => {
    if (user.role === 6 || user.role === 1) {
      setDisableCampos(false);
    }
  }, [user]);

  useEffect(() => {
    getCidades();
  }, [register.estado_id]);

  // const allowEdit = (status) => {
  //   if (user.role === 4) {
  //     return [1].includes(status);
  //   } else if (user.role == 5) {
  //     return [6].includes(status);
  //   } else {
  //     return false;
  //   }
  // };

  const getParceiros = async () => {
   try {
     setListParceiros(await listPartners());
   } catch (err) {}
 };

  const getStatus = async () => {
    try {
      const response = await getAgentsStatus();
      const listAgentsStatus = response.map((item) => ({
        ...item,
        value: item.id,
        label: item.nome,
      }));
      setList(listAgentsStatus);
    } catch (err) {}
  };

  const getData = async (page, name, cpf, status, parceiro) => {
    try {
      setData();
      setPage(page);
      setName(name);
      setCpf(cpf);
      setStatus(status);
      setParceiro(parceiro);
      setData(await getAgents(name, cpf, status, parceiro, page));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  const setInputData = async (data) => {
    data.master = data.master === "NÃO" || !data.master ? false : true;
    data.complemento = data.complemento || "N/A";
    data.master_opcao = data.master ? "Sim" : "Não";
    data.estado_nome = data.estado_nome + " - " + data.estado;
    data.cidade_nome = data.cidade;

    window.$("#master_id").val("");
    window.$("#observacao_auditoria").val(null);
    window.$("#observacao_operacional").val(null);
    window.$("#nome_origem").val(null);
    window.$("#origem").val(null);
    window.$("#inputAudio").val(null);

    setErrors({});
    setRegister(data);
    setAgentStatus(data.status_id);
    setAgentesMater(await getAgentsMaster());
    setParceiros(await listPartners());
    await getEstados();
    await getBancos();
    await getTipoContas();

    if (data.ticagem) {
      let aux = {};
      let ticagem = data.ticagem;

      for (let i = 0; i < ticagem.length; i++) {
        const item = ticagem[i];
        aux[item.id] = item.valor;
      }

      setTicagem(aux);
    }
  };

  const onEditClick = async (id) => {
    setViewMode("Editar");
    setFullLoading(true);
    setAgent(id);
    window.$("#modal-data").modal("show");
    try {
      const data = await getAgentData(id);
      setInputData(data);
      setFullLoading(false);
    } catch (err) {
      toast.error("Falha ao carregar dados do agente!");
      window.$("#modal-data").modal("hide");
      setAgent(null);
      setFullLoading(true);
    }
  };

  const onViewClick = async (id) => {
    setViewMode("Detalhes");
    setFullLoading(true);
    setAgent(id);
    window.$("#modal-data").modal("show");
    try {
      const data = await getAgentData(id);

      setInputData(data);

      setFullLoading(false);
    } catch (err) {
      toast.error("Falha ao carregar dados do agente!");
      window.$("#modal-data").modal("hide");
      setAgent(null);
      setFullLoading(true);
    }
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(1, name, cpf, status ? status.value : null,parceiro);
  };

  const onClear = () => {
    window.$("#name").val(null);
    window.$("#cpf_filtro").val(null);
    window.$("#status").val(null);
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
    selectParc.current.select.clearValue();
    setName(null);
    setCpf(null);
    setStatus(null);
  };

  const openUpload = (id) => {
    setAgent(id);
    window.$("#modal-upload").modal("show");
  };

  const openFile = (file) => {
    if (
      file === null ||
      file === "null" ||
      file === undefined ||
      file === "undefined"
    ) {
      toast.info("Arquivo não encontrado, faça upload para visualizar!");
    } else {
      window.open(file, "_blank");
    }
  };

  const generateNewPassword = (id, nome) => {
    swal({
      title: "Enviar senha?",
      text: `Será enviado uma nova senha para o e-mail do agente ${nome}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        try {
          setFullLoading(true);
          const retorno = await sendPasswordAgent(id);
          if (retorno) {
            swal("Senha enviada com sucesso.", {
              icon: "success",
            });
          }
        } catch (error) {
          swal("Ocorreu um ao enviar a senha, tente novamente.", {
            icon: "error",
          });
        } finally {
          setFullLoading(false);
        }
      } else {
        swal("A senha não foi enviada para o e-mail do agente.", {
          icon: "info",
        });
      }
    });
  };

  const hiddenComissions = (id, nome, mostrarComissao) => {
    swal({
      title: `${mostrarComissao ? "Ocultar" : "Exibir"} as comissões?`,
      text: `Será ${
        mostrarComissao ? "ocultado" : "exibido"
      } as comissões do agente ${nome}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        try {
          setFullLoading(true);
          const retorno = await hiddenComissionsAgent(id, !mostrarComissao);
          if (retorno) {
            swal(
              `Comissões ${
                mostrarComissao ? "ocultadas" : "exibidas"
              } com sucesso.`,
              {
                icon: "success",
              }
            );

            getData(1, name, cpf, status, parceiro);
          }
        } catch (error) {
          swal(
            `Ocorreu um ao ${
              mostrarComissao ? "ocultar" : "exibir"
            } as comissões, tente novamente.`,
            {
              icon: "error",
            }
          );
        } finally {
          setFullLoading(false);
        }
      } else {
        swal(
          `As comissões do agente não foram ${
            mostrarComissao ? "ocultadas" : "exibidas"
          }.`,
          {
            icon: "info",
          }
        );
      }
    });
  };

  const onDisableClick = (id, name) => {
    setModalTitle("Desativar Agente");
    setModalText(`Deseja mesmo desativar o agente ${name}?`);

    const f = async () => {
      try {
        setFullLoading(true);
        await disableAgent(id);
        toast.success("Agente foi desativado com sucesso!");
        getData(1, name, cpf, status,parceiro);
        setFullLoading(false);
      } catch (err) {
        console.warn(err);
        setFullLoading(false);
        toast.error("Falha ao desativar agente!");
      }
      window.$("#modal-confirm").modal("hide");
    };
    setModalOnConfirm(() => f);

    window.$("#modal-confirm").modal("show");
  };

  const handleChange = async (event) => {
    const { id, value } = event.target;

    switch (id) {
      case "mostrar_comissao":
      case "exibe_crm":
      case "exibe_confirme":
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
        setRegister({ ...register, [id]: !register[id] });
        break;
      default:
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: value ? null : "Campo Obrigatório",
        }));
        setRegister({ ...register, [id]: value });
        break;
    }
  };

  const handleBlur = async (event) => {
    const { id, value } = event.target;

    if (id === "cep") {
      try {
        setFullLoading(true);
        const dataCep = await getAddressByCep(value);
        if (dataCep.status === 200) {
          const { id, uf, localidade, logradouro, ...restDataCep } =
            dataCep.data;
          const estadoSelected = estados.find((estado) => estado.uf === uf);

          if (estadoSelected && id) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              estado_id: null,
              cidade: null,
              endereco: null,
              bairro: null,
            }));
          }

          window.$("#numero_endereco").focus();

          return setRegister((prevRegister) => ({
            ...prevRegister,
            ...restDataCep,
            cidade: id,
            endereco: logradouro,
            estado_id: estadoSelected ? estadoSelected.id : "",
            estado_nome: estadoSelected ? estadoSelected.label : "",
            cidade_nome: localidade ? localidade : "",
          }));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        setFullLoading(false);
      }
    } else if (id === "cpf") {
      try {
        const retorno = await validateCpfAgent(agent, register.cpf);
        if (retorno && retorno.length > 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "CPF já esta sendo utilizado",
          }));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        setFullLoading(false);
      }
    }

    if (id === "observacao_auditoria" || "observacao_operacional") {
      return;
    }

    if (!value || value === "R$ 0,00") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Campo Obrigatório",
      }));
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleChangeFile = async (event) => {
    let { id } = event.target;
    let file = event.target.files[0];
    let filtro = extensoesPermitidas.filter((val) => {
      return val === file.type;
    });
    const tamanhoMaximo = file.size > 2000000;

    if (!filtro[0] && !tamanhoMaximo) {
      setDisableSubmitUpload(true);
      setInputErrors({
        ...inputErrors,
        [id]: "Extensão do arquivo não suportada! Extensões suportadas: (JPG, JPEG, PNG e PDF).",
      });
      return;
    } else if (filtro[0] && tamanhoMaximo) {
      setDisableSubmitUpload(true);
      setInputErrors({
        ...inputErrors,
        [id]: "Tamanho do arquivo não suportado! Tamanho maximo: 2mb.",
      });
      return;
    } else if (filtro[0] && !tamanhoMaximo) {
      if (inputErrors) {
        setDisableSubmitUpload(false);
        setInputErrors({ ...inputErrors, [id]: "" });
      }

      const base64 = await fileToBase64(file);

      setFiles({
        ...files,
        [id]: {
          data: base64,
          name: file.name,
          type: file.type,
          isFile: true,
        },
      });
    }
  };

  const handleChangeAudio = async (event) => {
    let audio = event.target.files[0];
    const base64 = await fileToBase64(audio);
    setRegister({
      ...register,
      audio_auditoria: {
        data: base64,
        name: audio.name,
        type: audio.type,
        isFile: true,
      },
    });
  };

  async function getEstados() {
    const data = await listEstados();
    setEstados(data);
  }

  async function getBancos() {
    const data = await listBanks();
    setBancos(data);
  }

  async function getTipoContas() {
    const data = await listTypeOfAccounts();
    setTipoContas(data);
  }

  async function getCidades() {
    const data = await listByEstado(register.estado_id);
    setCidades(data);
  }

  const LimparCamposUpload = () => {
    document.getElementById("selfie").value = null;
    document.getElementById("rg_cnh").value = null;
    document.getElementById("cpvt_endereco").value = null;
    document.getElementById("curriculo").value = null;
    setInputErrors({});
    setFiles({});
    setAgent(null);
  };

  const handleAdd = async () => {
    try {
      setAgent(null);
      setFullLoading(true);
      setErrors(registerDefault);
      setAgentesMater(await getAgentsMaster());
      setParceiros(await listPartners());
      setIntencoes(await getIntentions());

      await getEstados();
      await getBancos();
      await getTipoContas();
      setViewMode("Adicionar");
      window.$("#modal-data").modal("show");
      setFullLoading(false);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onSubmitAgent = async (event) => {
    event.preventDefault();
    setFullLoading(true);

    try {
      const response = await sendAgent(register, ticagem);
      if (response.id) {
        await getData(page, name, cpf, status, parceiro);
        setFullLoading(false);
        toast.success("Agente cadastrado com sucesso!");
        window.$("#modal-data").modal("hide");
      } else {
        toast.warn("Falha ao cadastrar o agente, tente novamente!");
        window.$("#modal-data").modal("hide");
      }
    } catch (err) {
      console.warn(err);
      toast.error("Ocorreu um erro, tente novamente!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFullLoading(false);
      window.$("#modal-data").modal("hide");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFullLoading(true);
    if (files) {
      try {
        const response = await updateDocuments(files, agent);
        if (response.id) {
          LimparCamposUpload();
          setFullLoading(false);
          toast.success("Arquivo enviado com sucesso!");
          window.$("#modal-upload").modal("hide");
        } else {
          LimparCamposUpload();
          toast.error("Falha ao enviar arquivo!");
          window.$("#modal-upload").modal("hide");
        }
      } catch (err) {
        console.warn(err);
        toast.error("Falha ao enviar arquivo!");
        window.$("#modal-upload").modal("hide");
      } finally {
        setFullLoading(false);
      }
    }
  };

  const updateStatus = async (event) => {
    event.preventDefault();

    if (register.observacao_auditoria === "") {
      register.observacao_auditoria = null;
    }
    if (register.observacao_operacional === "") {
      register.observacao_operacional = null;
    }

    try {
      setFullLoading(true);
      if (parseInt(register.status_id) === 3 && agentStatus !== 3) {
        await approveAgent(agent, register);
      } else {
        await updateStatusAgent(agent, ticagem, register);
      }

      toast.success(
        `${register.nome.split(" ")[0]} foi atualizado com sucesso!`
      );
      window.$("#modal-data").modal("hide");
      await getData(page, name, cpf, status,parceiro);
    } catch (err) {
      toast.error("Falha ao atualizar o agente!");
      window.$("#modal-data").modal("hide");
      setAgent(null);
    } finally {
      setFullLoading(false);
    }

    // aprove - 3
    // reject - 5
    // auditoria - 6
    // pendencia - 7
  };

  const updateTic = async (id) => {
    setTicagem({ ...ticagem, [id]: !ticagem[id] });
  };

  const handleUnlockUser = async (user) => {
    try {
      setFullLoading(true);
      await sendPasswordAgent(user.id);
      const result = await unlockUser(user.cpf);
      if (result && result.return === true) {
        toast.success(result.message);
      } else {
        toast.error(
          result.message ||
            "Ocorreu um erro ao desbloquear o usuario, tente novamente."
        );
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao desbloquear o usuario, tente novamente.");
    } finally {
      setFullLoading(false);
    }
  };

  const handleExport = async () => {
    swal({
      title: "Exportar agentes?",
      text: "Será exportado os agentes cadastrados no sistema",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await exportAgents(name, cpf, status);
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV +=
            `Relatório de Agentes - ${moment().format("MMMM YYYY")}` + "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar os agentes", {
              icon: "error",
            });
          }

          let nomeArquivo = `agentes-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFullLoading(false);
          swal("Agentes exportados com sucesso.", {
            icon: "success",
          });
        } else {
          setFullLoading(false);
          swal("Ocorreu um erro ao exportar os agentes", {
            icon: "error",
          });
        }
      } else {
        swal("Os agentes não foram exportados.", {
          icon: "info",
        });
      }
    });
  };

  const disabledBtnSubmit = useMemo(() => {
    return Object.keys(errors).filter((key) => errors[key]).length > 0;
  }, [errors]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  function sendFile() {
    try {
      var file = document.querySelector("#inputArquivo").files[0];
      if (file) {
        setFullLoading(true);
        getBase64(file).then(async (data) => {
          try {
            const result = await importarAgentes(data);
            if (result.data.length > 0) {
              toast.info(
                `${result.data.length} registros não foram importados!`,
                { autoClose: 10000 }
              );
              setPendenciaImportacao(result.data);
            } else {
              toast.success("Arquivo enviado com sucesso.");
              setPendenciaImportacao([]);
              window.$("#modal-importar-agente").modal("hide");
              window.$("#inputArquivo").val(null);
            }
            setFullLoading(false);
          } catch (err) {
            console.warn(err);
            setFullLoading(false);
            toast.error(err.erro);
          }
        });
      } else {
        toast.info("Selecione algum arquivo!");
      }
    } catch (err) {
      console.warn(err);
      setFullLoading(false);
      toast.error("Falha ao enviar arquivo!");
    }
  }

  return (
    <div>
      {fullLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: (
                <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: handleAdd,
            },
            {
              name: "Exportar CSV",
              icone: (
                <i
                  className="fas fa-file-csv"
                  style={{ marginLeft: "5px" }}
                ></i>
              ),
              onClick: handleExport,
            },
            {
              name: "Modelo Importação",
              icone: (
                <i
                  className="fas fa-download"
                  style={{ marginLeft: "5px" }}
                ></i>
              ),
              onClick: () =>
                window.open(
                  "https://storage.googleapis.com/seu-credito/Templates%20de%20Planilhas/modelo_importacao_agentes.xlsx"
                ),
            },
            {
              name: "Importar",
              icone: (
                <i className="fas fa-upload" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-importar-agente").modal("show"),
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card ">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                dark={false}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}

      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClear}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Agente</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group">
                    <Input
                      id="name"
                      className="form-control"
                      placeholder="Nome..."
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group ">
                    <Input
                      id="cpf_filtro"
                      className="form-control"
                      placeholder="Cpf..."
                      type="cpfCnpj"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group ">
                    <Select
                      id="status"
                      className={`${styles.padding} form-control-select`}
                      placeholder="Status..."
                      onChange={(e) => setStatus(e || null)}
                      value={status}
                      options={list}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group">
                    <Select
                      id="parceiro_id"
                      ref={selectParc}
                      className={`${styles.padding} form-control-select`}
                      placeholder="Parceiros..."
                      onChange={(e) => {
                        if (e && e.value) {
                          setParceiro(e.value);
                        }
                      }}
                      options={listParceiros}
                      backspaceRemovesValue
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClear}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal de Exibição dos dados */}
      <Modal
        id={"modal-data"}
        title={viewMode}
        size={"lg"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClear}
              >
                x
              </span>
            </button>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados Pessoais</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nome*</label>
                      <div className="input-group input-group-merge">
                        <Input
                          type="text"
                          className="form-control"
                          id="nome"
                          placeholder="Nome..."
                          value={register.nome || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          help={errors.nome || ""}
                          disabled={disableCampos}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nascimento*</label>
                      <div className="input-group input-group-merge">
                        <Input
                          type="birthday"
                          className="form-control"
                          id="nascimento"
                          placeholder="Nascimento..."
                          value={register.nascimento || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          help={errors.nascimento || ""}
                          disabled={disableCampos}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>CPF*</label>
                      <Input
                        type="cpfCnpj"
                        className="form-control"
                        id="cpf"
                        value={register.cpf || ""}
                        placeholder="CPF..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.cpf || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Celular*</label>
                      <Input
                        type="phone"
                        className="form-control"
                        id="celular"
                        value={register.celular}
                        placeholder="Celular..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.celular || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div
                    className={`col-md-${
                      viewMode !== "Adicionar" ? "6" : "12"
                    }`}
                  >
                    <div className="form-group">
                      <label>E-mail*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        value={register.email}
                        placeholder="E-mail..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.email || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  {viewMode !== "Adicionar" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Status*</label>
                        <select
                          id="status_id"
                          className="form-control"
                          placeholder="Status..."
                          value={register.status_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          help={errors.status_id || ""}
                        >
                          <option value="">Status...</option>
                          {list.map((x) => (
                            <option
                              key={x.id}
                              value={x.id}
                              style={{
                                display:
                                  (user.role === 4 && x.id === 6) ||
                                  x.id === 5 ||
                                  x.id === 7
                                    ? "block"
                                    : (user.role === 5 && x.id === 3) ||
                                      x.id === 5 ||
                                      x.id === 7 ||
                                      x.id === 4
                                    ? "block"
                                    : user.role === 1 ||
                                      (user.role === 6 && x.id === 3) ||
                                      x.id === 4 ||
                                      x.id === 5 ||
                                      x.id === 6 ||
                                      x.id === 7 ||
                                      x.id === 8 ||
                                      x.id === 9
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {x.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Master*</label>
                      <Select
                        id="master"
                        ref={selectMaster}
                        className={`${styles.padding} form-control-select`}
                        placeholder="Master..."
                        value={{
                          label: register.master_opcao
                            ? register.master_opcao
                            : "Selecione a opção",
                        }}
                        onChange={(e) => {
                          if (e) {
                            setRegister({
                              ...register,
                              master: e.value,
                              master_opcao: e.label,
                            });
                            setErrors({
                              ...errors,
                              master: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              master: "Campo Obrigatório",
                            });
                          }
                        }}
                        options={[
                          {
                            id: false,
                            nome: "Não",
                            label: "Não",
                            value: false,
                          },
                          {
                            id: true,
                            nome: "Sim",
                            label: "Sim",
                            value: true,
                          },
                        ]}
                        backspaceRemovesValue
                      />
                      {/* <option value="false">Não</option>
                                    <option value="true">Sim</option>
                                 </select> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nome Master*</label>
                      <Select
                        id="master_id"
                        ref={selectAgentMaster}
                        className={`${styles.padding} form-control-select`}
                        placeholder="Nome master..."
                        value={{
                          label: register.master_nome
                            ? register.master_nome
                            : "Selecione o agente master",
                        }}
                        onChange={(e) => {
                          if (e && e.value) {
                            setRegister({
                              ...register,
                              master_id: e.value,
                              master_nome: e.label,
                            });
                            setErrors({
                              ...errors,
                              master_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              master_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        disabled={disableCampos}
                        options={agentesMaster}
                        backspaceRemovesValue
                      />
                      {/* <option value="">Selecione...</option>
                                    {agentesMaster &&
                                       agentesMaster.map((x) => (
                                          <option value={x.usuario_id}>
                                             {x.nome}
                                          </option>
                                       ))}
                                 </select> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parceiros</label>
                      <Select
                        id="parceiro_id"
                        ref={selectParceiros}
                        className={`${styles.padding} form-control-select`}
                        placeholder="Nome master..."
                        value={{
                          label: register.parceiro_nome
                            ? register.parceiro_nome
                            : "Selecione o parceiro do agente",
                        }}
                        onChange={(e) => {
                          if (e && e.value) {
                            setRegister({
                              ...register,
                              parceiro_id: e.value,
                              parceiro_nome: e.label,
                            });
                            setErrors({
                              ...errors,
                              parceiro_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              parceiro_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        disabled={disableCampos}
                        options={parceiros}
                        backspaceRemovesValue
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sexo*</label>
                      <Select
                        id="sexo_id"
                        ref={selectSexo}
                        className={`${styles.padding} form-control-select`}
                        placeholder="Master..."
                        value={{
                          label: register.sexo
                            ? register.sexo
                            : "Selecione o sexo",
                        }}
                        onChange={(e) => {
                          if (e && e.value) {
                            setRegister({
                              ...register,
                              sexo_id: e.value,
                              sexo: e.label,
                            });
                            setErrors({
                              ...errors,
                              sexo_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              sexo_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        options={[
                          {
                            id: 1,
                            nome: "Masculino",
                            label: "Masculino",
                            value: 1,
                          },
                          {
                            id: 2,
                            nome: "Feminino",
                            label: "Feminino",
                            value: 2,
                          },
                        ]}
                        backspaceRemovesValue
                      />
                      {/* <option value="false">Não</option>
                                    <option value="true">Sim</option>
                                 </select> */}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Exibir Comissão*</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="mostrar_comissao"
                          onChange={handleChange}
                          checked={register.mostrar_comissao}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="mostrar_comissao"
                        >
                          Sim
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Exibe CRM*</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="exibe_crm"
                          onChange={handleChange}
                          checked={register.exibe_crm}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="exibe_crm"
                        >
                          Sim
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Exibe Confirme*</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="exibe_confirme"
                          onChange={handleChange}
                          checked={register.exibe_confirme}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="exibe_confirme"
                        >
                          Sim
                        </label>
                      </div>
                    </div>
                  </div>

                  {viewMode !== "Adicionar" && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Origem Selecionada</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="nome_origem"
                            value={register.nome_origem}
                            placeholder="Origem selecionada..."
                            help={errors.nome_origem || ""}
                            onChange={handleChange}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Origem Digitada</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="origem"
                            value={register.origem}
                            placeholder="Origem digitada..."
                            help={errors.origem || ""}
                            onChange={handleChange}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Endereço</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>CEP*</label>
                      <Input
                        type="cep"
                        className="form-control"
                        id="cep"
                        value={register.cep || ""}
                        placeholder="CEP..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.cep || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Estado*</label>
                      <Select
                        className={`${styles.padding} form-control-select`}
                        ref={selectEstados}
                        id="estado_id"
                        placeholder="Estado..."
                        value={{
                          label: register.estado_nome
                            ? register.estado_nome
                            : "Selecione o estado...",
                        }}
                        onChange={(e) => {
                          if (e && e.value) {
                            setRegister({
                              ...register,
                              estado_id: e.value,
                              estado_nome: e.label,
                            });
                            setErrors({
                              ...errors,
                              estado_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              estado_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        disabled={disableCampos}
                        options={estados}
                        backspaceRemovesValue
                      />
                      {/* {estados &&
                                       estados.map((x) => (
                                          <option value={x.value}>
                                             {x.label}
                                          </option>
                                       ))}
                                 </select> */}
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="form-group">
                      <label>Cidade*</label>
                      <Select
                        className={`${styles.padding} form-control-select`}
                        id="cidade"
                        ref={selectCidades}
                        placeholder="Cidade..."
                        value={{
                          label: register.cidade_nome
                            ? register.cidade_nome
                            : "Selecione o estado...",
                        }}
                        onChange={(e) => {
                          if (e && e.value) {
                            setRegister({
                              ...register,
                              cidade: e.value,
                              cidade_nome: e.label,
                            });
                            setErrors({
                              ...errors,
                              cidade: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              cidade: "Campo Obrigatório",
                            });
                          }
                        }}
                        options={cidades}
                        disabled={disableCampos}
                        backspaceRemovesValue
                      />
                      {/* {cidades &&
                                       cidades.map((x) => (
                                          <option value={x.value}>
                                             {x.label}
                                          </option>
                                       ))}
                                 </select> */}
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group">
                      <label>Logradouro*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="endereco"
                        value={register.endereco || ""}
                        placeholder="Logradouro..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.endereco || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Número*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="numero_endereco"
                        value={register.numero_endereco || ""}
                        placeholder="Número..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.numero_endereco || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Bairro*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="bairro"
                        value={register.bairro || ""}
                        placeholder="Bairro..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.bairro || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 ct-example">
                    <div className="form-group">
                      <label>Complemento*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="complemento"
                        value={register.complemento || ""}
                        placeholder="Complemento..."
                        onChange={handleChange}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Certificação*</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="certificacao"
                        value={register.certificacao || ""}
                        placeholder="Certificação..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.certificacao || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados Bancários</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Banco</label>
                      <Select
                        type="text"
                        className={`${styles.padding} form-control-select`}
                        id="banco_id"
                        ref={selectBancos}
                        value={{
                          label: register.banco
                            ? register.banco
                            : "Selecione o banco...",
                        }}
                        placeholder="Banco..."
                        onChange={(e) => {
                          if (e) {
                            setRegister({
                              ...register,
                              banco_id: e.value,
                              banco: e.label,
                            });
                            setErrors({
                              ...errors,
                              banco_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              banco_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        disabled={disableCampos}
                        options={bancos}
                        backspaceRemovesValue
                      />
                      {/* {bancos &&
                                       bancos.map((x) => (
                                          <option value={x.value}>
                                             {x.label}
                                          </option>
                                       ))} 
                                 </select>*/}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tipo Conta</label>
                      <Select
                        type="text"
                        className={`${styles.padding} form-control-select`}
                        id="tipo_conta_id"
                        ref={selectTipoContas}
                        value={{
                          label: register.tipo_conta
                            ? register.tipo_conta
                            : "Selecione o tipo da conta...",
                        }}
                        placeholder="Tipo de conta..."
                        disabled={disableCampos}
                        options={tipoContas}
                        onChange={(e) => {
                          if (e) {
                            setRegister({
                              ...register,
                              tipo_conta_id: e.value,
                              tipo_conta: e.label,
                            });
                            setErrors({
                              ...errors,
                              tipo_conta_id: null,
                            });
                          } else {
                            setErrors({
                              ...errors,
                              tipo_conta_id: "Campo Obrigatório",
                            });
                          }
                        }}
                        backspaceRemovesValue
                      />
                      {/* {tipoContas &&
                                       tipoContas.map((x) => (
                                          <option value={x.value}>
                                             {x.label}
                                          </option>
                                       ))}
                                 </select> */}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Agência</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="agencia"
                        value={register.agencia}
                        placeholder="Agência..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.agencia || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Conta</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="conta"
                        value={register.conta}
                        placeholder="Conta..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.conta || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pix</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="pix"
                        value={register.pix}
                        placeholder="Pix..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        help={errors.pix || ""}
                        disabled={disableCampos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Informações Adicionais</h3>
              </div>
              <div className="card-body">
                {register.status_id === 7 && (
                  <div className="form-group">
                    <h3>Pendência de documentos</h3>
                    <div className="input-group">
                      <textarea
                        id="observacao"
                        name="observacao"
                        className="form-control"
                        aria-label="With textarea"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.observacao || ""}
                      ></textarea>
                    </div>
                  </div>
                )}
                {[1, 5, 6].includes(user.role) && (
                  <>
                    <div className="form-group">
                      <h3>Observação Auditória</h3>
                      <div className="input-group">
                        <textarea
                          id="observacao_auditoria"
                          name="observacao_auditoria"
                          className="form-control"
                          aria-label="With textarea"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={register.observacao_auditoria == null ? '' : register.observacao_auditoria}
                          disabled={user.role == 1 ? true : false}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group">
                      <h3>Observação Operacional</h3>
                      <div className="input-group">
                        <textarea
                          id="observacao_operacional"
                          name="observacao_operacional"
                          className="form-control"
                          aria-label="With textarea"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={register.observacao_operacional == null ? '' : register.observacao_operacional}
                          disabled={user.role === 5}
                        ></textarea>
                      </div>
                    </div>

                    {viewMode !== "Adicionar" && (
                      <>
                        <h3 className="mb-2">Áudio</h3>
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="inputAudio"
                              lang="pt"
                              accept=".mp3"
                              onChange={handleChangeAudio}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputAudio"
                            >
                              Selecionar
                            </label>
                          </div>
                        </div>

                        <h3 className="mb-2">Auditoria</h3>
                        <div className="row">
                          {register.ticagem &&
                            register.ticagem.map((x, index) => (
                              <div key={index} className="col-md-4">
                                <div className="form-group">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      id={`checkboxTicagem${x.id}`}
                                      name={`checkboxTicagem${x.id}`}
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={ticagem[x.id]}
                                      onChange={() => updateTic(x.id)}
                                      disabled={
                                        user.role === 1 ||
                                        user.role === 5 ||
                                        user.role === 6
                                          ? false
                                          : true
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`checkboxTicagem${x.id}`}
                                    >
                                      {x.nome} - {x.id}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {viewMode === "Adicionar" && (
              <div className="card mb-4">
                <div className="card-header">
                  <h3 className="mb-0">Produtos Permitidos</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    {intencoes &&
                      intencoes.map((x) => (
                        <div className="col-md-4" key={x.id}>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={x.id}
                                checked={ticagem[x.id]}
                                onChange={() => updateTic(x.id)}
                                disabled={
                                  user.role === 1 ||
                                  user.role === 5 ||
                                  user.role === 6
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={x.id}
                              >
                                {x.nome}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            {viewMode !== "Adicionar" && (
              <div className="card mb-4">
                <div className="card-header">
                  <h3 className="mb-0">Documentos e Histórico</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() => openFile(register.selfie)}
                      >
                        Selfie
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() => openFile(register.rg_cnh_doc)}
                      >
                        RG/CNH
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() => openFile(register.rg_cnh_doc_verso)}
                      >
                        RG/CNH VERSO
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() =>
                          openFile(register.comprovante_endereco_doc)
                        }
                      >
                        Cpvt. Endereço
                      </button>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3 text-center">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() => openFile(register.curriculo_doc)}
                      >
                        Currículo
                      </button>
                    </div>
                    <div className="col-md-3 text-center">
                      <button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() => openFile(register.audio_auditoria)}
                      >
                        Audio
                      </button>
                    </div>
                  </div>

                  {register.historico && (
                    <>
                      <h3 className="mb-2 mt-2">Histórico</h3>
                      <div className="table-responsive">
                        <div>
                          <table className="table align-items-center">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Atualizado em</th>
                                <th scope="col">Atualizado Por</th>
                                <th scope="col">Titulo</th>
                                <th scope="col">Descrição</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody className="list">
                              {register.historico.length > 0 &&
                                register.historico.map((x, index) => (
                                  <tr key={index}>
                                    <td className="budget">
                                      {x.atualizado_em}
                                    </td>
                                    <td className="budget">
                                      {x.login ? x.login : "-"}
                                    </td>
                                    <td className="budget">{x.titulo}</td>
                                    <td className="budget" title={x.descricao}>
                                      {x.descricao
                                        ? funcoes.limitarTexto(x.descricao, 30)
                                        : ""}
                                    </td>
                                    <td className="budget">
                                      <span className="badge badge-info">
                                        {x.nome}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="row divBtnInfoAgente">
              <div className="col-md-6 text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-block"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
              </div>
              <div className="col-md-6 text-center">
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  onClick={
                    viewMode === "Adicionar" ? onSubmitAgent : updateStatus
                  }
                  disabled={disabledBtnSubmit}
                >
                  <i className="far fa-save fa-lg iconDownload"></i>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* Modal de Importação de Agente */}
      <Modal
        id={"modal-importar-agente"}
        title={"Importar"}
        size={"lg"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span arial-hidden="true" className="text-white">
                x
              </span>
            </button>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Arquivo XLSX*</label>
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputArquivo"
                    lang="pt-Br"
                  />
                </div>
              </div>
            </div>
            {pendenciaImportacao.length > 0 && (
              <div className="row">
                <div className="col">
                  <div className="card">
                    {/* <!-- Card header --> */}
                    <div className="card-header border-0">
                      <h3 className="mb-0">Pendências</h3>
                    </div>
                    {/* <!-- Light table --> */}
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="sort" data-sort="name">
                              Linha
                            </th>
                            <th scope="col" className="sort" data-sort="budget">
                              Pendência
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {pendenciaImportacao.map((pendencia, i) => (
                            <tr key={i}>
                              <th scope="row">
                                <div className="media align-items-center">
                                  <div className="media-body">
                                    <span className="name mb-0 text-sm">
                                      {pendencia.index}
                                    </span>
                                  </div>
                                </div>
                              </th>
                              <td className="budget">
                                {String(pendencia.pendencia)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12 text-center">
              <button
                type="button"
                onClick={() => sendFile()}
                className="btn btn-primary btn-block"
              >
                Adicionar
              </button>
            </div>
          </div>
        }
      />

      <Modal
        id={"modal-confirm"}
        size={"sm"}
        title={modalTitle}
        form={
          <div>
            <p>{modalText}</p>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={modalOnConfirm}
                className="btn btn-primary"
              >
                Confirmar
              </button>
            </div>
          </div>
        }
      />

      <Modal
        id={"modal-upload"}
        size=""
        title="Carregar Arquivos"
        onSubmit={handleSubmit}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClear}
              >
                x
              </span>
            </button>
            <label>Selfie</label>
            <div className="input-group mb-3">
              <input
                type="file"
                className={
                  inputErrors.selfie
                    ? "form-control border-error"
                    : "form-control"
                }
                accept="image/x-png,application/pdf,image/jpeg"
                id="selfie"
                name="selfie"
                data-unique="true"
                onChange={handleChangeFile}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="button">
                  <i className="fas fa-file-upload fa-lg"></i>
                </button>
              </div>
              {inputErrors.selfie ? (
                <FormErrors message={inputErrors.selfie} />
              ) : null}
            </div>
            <label>RG / CNH</label>
            <div className="input-group mb-3">
              <input
                type="file"
                className={
                  inputErrors.rg_cnh
                    ? "form-control border-error"
                    : "form-control"
                }
                accept="image/x-png,application/pdf,image/jpeg"
                id="rg_cnh"
                data-unique="true"
                tabIndex="0"
                onChange={handleChangeFile}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="button">
                  <i className="fas fa-file-upload fa-lg"></i>
                </button>
              </div>
              {inputErrors.rg_cnh ? (
                <FormErrors message={inputErrors.rg_cnh} />
              ) : null}
            </div>
            <label>RG / CNH VERSO</label>
            <div className="input-group mb-3">
              <input
                type="file"
                className={
                  inputErrors.rg_cnh_verson
                    ? "form-control border-error"
                    : "form-control"
                }
                accept="image/x-png,application/pdf,image/jpeg"
                id="rg_cnh_verso"
                data-unique="true"
                tabIndex="0"
                onChange={handleChangeFile}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="button">
                  <i className="fas fa-file-upload fa-lg"></i>
                </button>
              </div>
              {inputErrors.rg_cnh_verso ? (
                <FormErrors message={inputErrors.rg_cnh_verso} />
              ) : null}
            </div>
            <label>Cpvt. Endereço</label>
            <div className="input-group mb-3">
              <input
                type="file"
                className={
                  inputErrors.cpvt_endereco
                    ? "form-control border-error"
                    : "form-control"
                }
                accept="image/x-png,application/pdf,image/jpeg"
                id="cpvt_endereco"
                data-unique="true"
                tabIndex="0"
                onChange={handleChangeFile}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="button">
                  <i className="fas fa-file-upload fa-lg"></i>
                </button>
              </div>
              {inputErrors.cpvt_endereco ? (
                <FormErrors message={inputErrors.cpvt_endereco} />
              ) : null}
            </div>
            <label>Currículo</label>
            <div className="input-group mb-3">
              <input
                type="file"
                className={
                  inputErrors.curriculo
                    ? "form-control border-error"
                    : "form-control"
                }
                accept="image/x-png,application/pdf,image/jpeg"
                id="curriculo"
                data-unique="true"
                tabIndex="0"
                onChange={handleChangeFile}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="button">
                  <i className="fas fa-file-upload fa-lg"></i>
                </button>
              </div>
              {inputErrors.curriculo ? (
                <FormErrors message={inputErrors.curriculo} />
              ) : null}
            </div>
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={disableSubmitUpload}
                >
                  <i className="far fa-save fa-lg iconDownload"></i>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        }
      />

      <ToastContainer />
    </div>
  );
}

export default Agents;
