import React, { Component } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { Store } from "../redux/Store";
import AnualCharts from "../components/charts/anualCharts/AnualSimulationCharts";
import RelProposta from "../components/relatorio/relProposta";
import AnualPropCharts from "../components/charts/anualCharts/AnualPropCharts";
import AnualGeralCharts from "../components/charts/geralCharts/geralCharts";
import RelProjecao from "../components/charts/RelProjecao";
import RelProducaoProduto from "../components/charts/RelProducaoProduto";
import BankCharts from "../components/charts/geralCharts/banksCharts";
import BestAgents from "../components/bestAgents/BestAgents";
import { getMelhoresAgentes } from "../services/Api";

export default class Home extends Component {
  state = {
    AnualCharts: true,
  };

  render() {
    const dadosStore = Store.getState().user;
    
    return (
      <div>
        {/* Sidenav */}
        <Menu />
        {/* Main content */}
        <div className="main-content" id="panel">
          {/* Topnav */}
          <TopMenu />
          {/* Header */}
          <Header funcao={dadosStore.role} />
          {/* Page content */}
          <div className="container-fluid mt--6 ">
            <div className="row">
              <div className="col-xl-12">
                <div className="card bg-default">
                  <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-light text-uppercase ls-1 mb-1">
                          Geral
                        </h6>
                        <h5 className="h2 text-white mb-0">Relatórios</h5>
                      </div>
                      <div className="col">
                        <ul className="nav nav-pills justify-content-end ">
                          <li className="nav-item mr-2">
                            <a
                              className="nav-link py-2 px-3 active bg-green"
                              href="/relatorios"
                            >
                              <span className="d-none d-md-block text-white">
                                Individual
                              </span>
                              <span className="d-md-none text-white">
                                <i className="fas fa-chart-bar"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body col-xl-12 row">
                <div className=" col-md-9">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">GERAL</h6>
                      <h5 className="h3 mb-0 text-white">Produção total</h5>
                    </div>
                    <div className="card-body ">
                      <AnualGeralCharts />
                    </div>
                  </div>
                </div>
                <div className="card col-md-3 bg-default">
                  <div className="card-header bg-default">
                    <div className="row align-items-center ">
                      <div className="col">
                        <h6 className="surtitle">GERAL</h6>
                        <h5 className="h3 mb-0 text-white">
                          Relatório Propostas
                        </h5>
                      </div>
                    </div>
                  </div>
                  <RelProposta />
                </div>
              </div>

              <div className="row col-xl-12 ">
                <div className=" col-sm-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">GERAL</h6>
                      <h5 className="h3 mb-0 text-white">
                        Produção por Produto
                      </h5>
                    </div>
                    <div className="card-body ">
                      <RelProducaoProduto />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">Geral</h6>
                      <h5 className="h3 mb-0 text-white">Projeção</h5>
                    </div>
                    <div className="card-body">
                      <RelProjecao />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  col-xl-12">
                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">Geral</h6>
                      <h5 className="h3 mb-0 text-white">Simulações</h5>
                    </div>
                    <div className="card-body">
                      <AnualCharts />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">Geral</h6>
                      <h5 className="h3 mb-0 text-white">
                        Propostas Aprovadas
                      </h5>
                    </div>
                    <div className="card-body">
                      <AnualPropCharts />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">Geral</h6>
                      <h5 className="h3 mb-0 text-white">
                        Produção por bancos
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="chart">
                        <BankCharts />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header bg-default">
                      <h6 className="surtitle">TOP 10</h6>
                      <h5 className="h3 mb-0 text-white">Melhores Agentes </h5>
                    </div>
                     <BestAgents/>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
