import React from "react";

function FormErrors(props) {
  const { type = "error", message } = props;

  return (
    <span className={type === "error" ? "text-danger" : "text-success"}>{message}</span>
  );
}

export default FormErrors;
