import React, { Component } from "react";
import { firstAccess, logout } from "../services/Api";
import logo from "../logo.png";

//helpers
import funcoes from "../utils/helpers/funcoes";

export default class RecoveryAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorText: "",
      loading: false,
      newPassword: null,
      newPassWordConfirmation: null,
      password: null,
    };
  }

  handleOnNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value.trim() });
  };

  handleOnNewPasswordConfirmationChange = (event) => {
    this.setState({ newPassWordConfirmation: event.target.value.trim() });
  };

  handleOnPasswordChange = (event) => {
    this.setState({ password: event.target.value.trim() });
  };

  handleOnLogout = async () => {
    await logout();
  };

  handleOnSubmit = async (e) => {
    e.preventDefault();

    const { newPassword, password, newPassWordConfirmation } = this.state;
    
    try {
      if (password !== newPassword) {
        if(newPassword === newPassWordConfirmation) {
          const validaSenha = funcoes.validaSenha(newPassword);
          this.setState({ loading: true });
          if(!validaSenha.retorno) {
            this.setState({
              loading: false,
              error: true,
              errorText: validaSenha.erro,
            });
          }else{
            await firstAccess(password.trim(), newPassword.trim());
          }
          this.setState({ loading: false });
        }else{
          this.setState({
            loading: false,
            error: true,
            errorText: "As senhas informadas não coincidem!",
          });
        }
      } else {
        this.setState({
          loading: false,
          error: true,
          errorText: "A senha informada, não pode ser igual a anterior!",
        });
      }
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        error: true,
        errorText: "Falha ao realizar primeiro acesso!",
      });
    }
  };

  render() {
    const { error, errorText, newPassword, password, newPassWordConfirmation } =
      this.state;

    return (
      <div className="bg-default">
        <div>
          {/* Navbar */}
          <nav
            id="navbar-main"
            className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light"
          >
            <div className="container">
              <a className="navbar-brand" href="dashboard.html">
                <img src={logo} alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-collapse"
                aria-controls="navbar-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="navbar-collapse navbar-custom-collapse collapse"
                id="navbar-collapse"
              >
                <div className="navbar-collapse-header">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <a href="dashboard.html">
                        <img src="../logo.png" alt="" />
                      </a>
                    </div>
                    <div className="col-6 collapse-close">
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-controls="navbar-collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span />
                        <span />
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="d-lg-none" />
                <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="https://www.facebook.com/pg/seucreditonegocios"
                      target="_blank"
                      data-toggle="tooltip"
                      data-original-title="Like us on Facebook"
                    >
                      <i className="fab fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none">
                        Facebook
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="https://www.instagram.com/seucreditonegocios/"
                      target="_blank"
                      data-toggle="tooltip"
                      data-original-title="Follow us on Instagram"
                    >
                      <i className="fab fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none">
                        Instagram
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Main content */}
          <div className="main-content">
            {/* Header */}
            <div className="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
              <div className="container">
                <div className="header-body text-center mb-7">
                  <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                      <h1 className="text-white">Sua senha foi expirada</h1>
                      <p className="text-lead text-white">
                        <span className="h3 text-white">
                          Realize o procedimento abaixo para configurar sua
                          nova senha de acesso:
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  x={0}
                  y={0}
                  viewBox="0 0 2560 100"
                  preserveAspectRatio="none"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon
                    className="fill-default"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </div>
            {/* Page content */}
            <div className="container mt--8 pb-5">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div className="card bg-secondary border-0 mb-0">
                    <div className="card-body px-lg-5 py-lg-5">
                      <form role="form" onSubmit={this.handleOnSubmit}>
                      <div className="form-group mb-3">
                        <div className="input-group input-group-merge input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="ni ni-lock-circle-open" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              placeholder="Senha atual"
                              type="password"
                              required
                              onChange={this.handleOnPasswordChange}
                              value={password}
                            />
                          </div>
                        </div>
                        <div className="text-center text-muted mb-4">
                          <small>Entre com sua nova credencial.</small>
                        </div>
                        <div className="form-group mb-3">
                          <div className="input-group input-group-merge input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="ni ni-lock-circle-open" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              placeholder="Nova senha"
                              type="password"
                              required
                              onChange={this.handleOnNewPasswordChange}
                              value={newPassword}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-merge input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="ni ni-lock-circle-open" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              placeholder="Confirme sua nova senha"
                              type="password"
                              required
                              onChange={
                                this.handleOnNewPasswordConfirmationChange
                              }
                              value={newPassWordConfirmation}
                            />
                          </div>
                        </div>
                        {error && (
                          <div className="alert alert-warning" role="alert">
                            <strong>Ops!</strong>
                            {` ${errorText}`}
                          </div>
                        )}
                        <div className="text-center">
                          <button
                            type="button"
                            onClick={this.handleOnLogout}
                            className="btn btn-default my-4"
                          >
                            Voltar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary my-4"
                          >
                            Avançar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <footer className="py-5" id="footer-main">
            <div className="container">
              <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-6">
                  <div className="copyright text-center text-xl-left text-muted">
                    © 2020{" "}
                    <a
                      href="https://seucreditonegocios.com.br/"
                      className="font-weight-bold ml-1"
                      target="_blank"
                    >
                      Seu Crédito
                    </a>
                  </div>
                </div>
                <div className="col-xl-6">
                  <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                    <li className="nav-item">
                      <a
                        href="https://seucreditonegocios.com.br/"
                        className="nav-link"
                        target="_blank"
                      >
                        Seu Crédito
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
