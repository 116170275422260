import React, { Component, useState } from "react";
import moment from "moment";
import { login, updatePassword } from "../services/Api";
import logo from "../logo.png";
import Loading from "../components/loading/Loading";
import Input from "..//components/input/Input";
import swal from "sweetalert";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorText: "",
      loading: false,
      username: null,
      password: null,
      cpf: null,
      email: null,
    };
  }

  handleOnUsernameChange = (event) => {
    this.setState({ username: event.target.value.trim() });
  };

  handleOnPasswordChange = (event) => {
    this.setState({ password: event.target.value.trim() });
  };

  handleOnCpfChange = (event) => {
    this.setState({ cpf: event.target.value.trim() });
  };

  handleOnEmailChange = (event) => {
    this.setState({ email: event.target.value.trim() });
  };

  handlePasswordBox = () => {
    document.getElementById("passwordSection").style.visibility = "visible";
    document.getElementById("sectionLogin").style.visibility = "hidden";
  };
  handleBack = () => {
    document.getElementById("passwordSection").style.visibility = "hidden";
    document.getElementById("sectionLogin").style.visibility = "visible";
  };

  handleOnSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    try {
      this.setState({ loading: true });
      let response = await login(username.trim(), password.trim());
      if (response.type) {
        this.setState({
          loading: false,
          error: true,
          errorText: response.mensagem,
        });
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorText: err.erro ? err.erro : "Usuário ou senha inválida!",
      });
    }
  };

  handleOnPasswordSubmit = async (e) => {
    e.preventDefault();
    const { username, cpf, email } = this.state;
    try {
      this.setState({ loading: true });
      let response = await updatePassword(
        username.trim(),
        cpf.trim(),
        email.trim()
      );
      if (response.type) {
        this.setState({
          loading: false,
          error: true,
          errorText: response.mensagem,
        });
      }
      if (response.return) {
        // setFullLoading(false);
        swal("Senha enviada com sucesso.", {
          icon: "success",
        });
      } else {
        // setFullLoading(false);
        swal(response.message, {
          icon: "error",
        });
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorText: err.erro ? err.erro : "Dados inválidos!",
      });
    }
  };

  render() {
    const { error, errorText, loading, username, password, cpf, email } =
      this.state;

    return (
      <div className="bg-secondary">
        <div>
          {/* Navbar */}
          <nav
            id="navbar-main"
            className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light"
          >
            <div className="container">
              <a className="navbar-brand" href="">
                <img src={logo} alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-collapse"
                aria-controls="navbar-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="navbar-collapse navbar-custom-collapse collapse "
                id="navbar-collapse"
              >
                <div className="">
                  {" "}
                  {/*navbar-collapse-header */}
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      {/* <a href="">
                        <img src={logo} alt="seucredito" />
                      </a> */}
                    </div>
                    <div className="col-6 collapse-close">
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-controls="navbar-collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span />
                        <span />
                      </button>
                    </div>
                  </div>
                </div>
                {/* <hr className="d-lg-none" /> */}
                <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="https://www.facebook.com/pg/seucreditonegocios"
                      target="_blank"
                      data-toggle="tooltip"
                      data-original-title="Like us on Facebook"
                    >
                      <i className="fab fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none">
                        Facebook
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="https://www.instagram.com/seucreditonegocios/"
                      target="_blank"
                      data-toggle="tooltip"
                      data-original-title="Follow us on Instagram"
                    >
                      <i className="fab fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none">
                        Instagram
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="https://www.seucreditonegocios.com.br"
                      target="_blank"
                      data-toggle="tooltip"
                    >
                      <i className="fas fa-desktop" />
                      <span className="nav-link-inner--text d-lg-none">
                        Seu crédito
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Main content */}
          <div className="main-content">
            {/* Header */}
            <div className="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
              <div className="container">
                <div className="header-body text-center mb-7">
                  <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                      <h1 className="text-white">Bem-vindo!</h1>
                      <p className="text-lead text-white">
                        Esse é o sistema administrativo da Seu Crédito. Aqui
                        você encontra agilidade com solidez e segurança.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  x={0}
                  y={0}
                  viewBox="0 0 2560 100"
                  preserveAspectRatio="none"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon
                    className="fill-success"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </div>
            {/* Page content */}
            <div className="container mt--8 pb-5 ">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div
                    className="card bg-secondary border-0 shadow-2xl  mb-0"
                    style={{
                      visibility: "visible",
                      boxShadow:
                        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                    }}
                    id="sectionLogin"
                  >
                    <div className="card-body px-lg-5 py-lg-5">
                      <div className="text-center text-black mb-4">
                        <small>Entre com sua credencial.</small>
                      </div>
                      {loading ? (
                        <Loading  />
                      ) : (
                        <form role="form" onSubmit={this.handleOnSubmit}>
                          <div className="form-group mb-3">
                            <div
                              className="input-group input-group-merge input-group-alternative"
                              style={{
                                boxShadow:
                                  "0 20px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                              }}
                            >
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-single-02 text-primary" />
                                </span>
                              </div>
                              <Input
                                className="form-control "
                                placeholder="Usuário"
                                maxLength={50}
                                type="text"
                                required
                                onChange={this.handleOnUsernameChange}
                                value={username}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div
                              className="input-group input-group-merge input-group-alternative"
                              style={{
                                boxShadow:
                                  "0 20px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                              }}
                            >
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-lock-circle-open  text-primary" />
                                </span>
                              </div>
                              <Input
                                className="form-control"
                                placeholder="Senha"
                                htmlType="password"
                                required
                                onChange={this.handleOnPasswordChange}
                                value={password}
                              />
                            </div>
                          </div>
                          {error && (
                            <div className="alert alert-warning" role="alert">
                              <strong>Ops!</strong>
                              {` ${errorText}`}
                            </div>
                          )}
                          {error && (
                            <div className="alert alert-warning" role="alert">
                              <strong>Ops!</strong>
                              {` ${errorText}`}
                            </div>
                          )}

                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                            >
                              Entrar
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6">
                      {/* <button
                        className="bg-transparent border-0"
                        onClick={this.handlePasswordBox}
                      > */}
                      <a
                        type="button"
                        onClick={this.handlePasswordBox}
                        className="text-default pointer"
                      >
                        <small>Esqueceu sua Senha?</small>
                      </a>
                      {/* </button> */}
                    </div>
                  </div>

                  <div
                    className="card bg-secondary border-0 shadow-2xl mb-0"
                    style={{
                      visibility: "hidden",
                      marginTop: "-80%",
                      boxShadow:
                        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                    }}
                    id="passwordSection"
                  >
                    <div className="card-body px-lg-5 py-lg-5">
                      <div className="text-center text-black mb-4">
                        <small>
                          Preencha os campos para redefinir sua senha.
                        </small>
                      </div>
                      {loading ? (
                        <Loading />
                      ) : (
                        <form
                          role="form"
                          onSubmit={this.handleOnPasswordSubmit}
                        >
                          <div className="form-group mb-3">
                            <div className="input-group input-group-merge input-group-alternative"    style={{ boxShadow: '0 20px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)'}}>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-email-83 text-primary" />
                                </span>
                              </div>
                              <input
                                className="form-control "
                                placeholder="Email"
                                maxLength={30}
                                type="email"
                                required
                                onChange={this.handleOnEmailChange}
                                value={email}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <div className="input-group input-group-merge input-group-alternative"   style={{ boxShadow: '0 20px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)'}}>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-single-02 text-primary" />
                                </span>
                              </div>
                              <input
                                className="form-control "
                                placeholder="Usuário"
                                maxLength={30}
                                type="text"
                                required
                                onChange={this.handleOnUsernameChange}
                                value={username}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group input-group-merge input-group-alternative"   style={{ boxShadow: '0 20px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)'}}>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-badge text-primary" />
                                </span>
                              </div>
                              <input
                                className="form-control"
                                placeholder="CPF"
                                id="cpf"
                                type="cpf"
                                required
                                onChange={this.handleOnCpfChange}
                                value={cpf}
                              />
                            </div>
                          </div>
                          {error && (
                            <div className="alert alert-warning" role="alert">
                              <strong>Ops!</strong>
                              {` ${errorText}`}
                            </div>
                          )}
                          {error && (
                            <div className="alert alert-warning" role="alert">
                              <strong>Ops!</strong>
                              {` ${errorText}`}
                            </div>
                          )}

                          <div className="text-center">
                            <button
                              type="button"
                              onClick={this.handleBack}
                              className="btn btn-danger mt-2"
                            >
                              Voltar
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                            >
                              Confirmar
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <footer className="py-2" id="footer-main">
            <div className="container">
              <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-6">
                  <div className="copyright text-center text-xl-left text-muted">
                    © {moment().format("YYYY")}
                    <a
                      href="https://seucreditonegocios.com.br/"
                      className="font-weight-bold ml-1"
                      target="_blank"
                    >
                      Seu Crédito
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
