import React from "react";
import { useState, useEffect } from "react";
import { getReportProdBank } from "../../../services/Api";

//charts
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import funcoes from "../../../utils/helpers/funcoes";

function BanksCharts({ userId,periodo }) {
  const [dados, setDados] = useState([]);
  useEffect(() => {
    const getDados = async () => {
      try {
        setDados(await getReportProdBank(userId, periodo));
      } catch (err) {}
    };
    getDados();
  }, [userId, periodo]);

  const options = {
    chart: {
      style: {
        fontFamily: 'arial',
         },
      type: "column",
      spacingBottom:30,
    },
    title: {
      text: "Produção total por banco ",
    },
    plotOptions: {
      column: {
        pointWidth: 8,
        spacingRight: 15,
        borderRadius: 5
      },
    },
    subtitle: {
      text: '<a href="http://seucreditonegocios.com.br" target="_blank">seucreditonegocios.com.br</a>',
    },
    yAxis: {
      labels: {
        formatter: function () {
          return "R$ " + funcoes.formatarMoeda(this.value);
        },
      },
      title: {
        text: "Produção",
      },
      
    },
    xAxis: {
      categories: ["Bancos"],

    },
    credits: {
      enabled: false,
    },
    legend: false,
    series: dados,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "bottom",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
export default BanksCharts;
