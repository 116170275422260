import React, { useState, useEffect, useMemo, useRef } from "react";

import { listar, gravar, desativar, buscarComissoes, buscarAtributos, gravarRegraComissao, exportarParceiros } from "../services/Parceiros";
import { getAgentsMaster } from "../services/Api";

import Footer from "../components/footer/Footer";
import QRCode from "qrcode.react";
import Input from "../components/input/Input";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import { Store } from "../redux/Store";
import Select from "react-select";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import swal from "sweetalert";
import { listByEstado, listEstados, listBanks, listTypeOfAccounts, getAddressByCep } from "../services/Api";

//helpers
import funcoes from "../utils/helpers/funcoes";

const camposParceiro = [
   'nome', 'apelido', 'data_nascimento', 'documento', 'email', 'celular', 'urlamigavel',
   'responsavel', 'responsavel_id', 'cep', 'logradouro', 'numero', 'bairro', 'cidade', 'estado', 
]

function Parceiros() {
   const [datatable, setDataTable] = useState({});
   const [dataComissoes, setDataComissoes] = useState({});
   const [fullLoading, setFullLoading] = useState(false);
   const [parceiros, setParceiros] = useState([]);
   const [comissoes, setComissoes] = useState([]);
   const [parceiro, setParceiro] = useState({});
   const [errors, setErrors] = useState({});
   const [cidades, setCidades] = useState(null);
   const [estados, setEstados] = useState(null);
   const [bancos, setBancos] = useState(null);
   const [tipoContas, setTipoContas] = useState([]);
   const [inputNome, setInputNome] = useState('Nome');
   const [agentesMaster, setAgentesMater] = useState(null);
   const [inputNascimento, setInputNascimento] = useState('Data Nascimento');
   const [inputApelido, setInputApelido] = useState('Apelido');
   const [inputDocumento, setInputDocumento] = useState('CPF');
   const [inputDocumento2, setInputDocumento2] = useState('RG / CNH');

   const [register, setRegister] = useState({
      banco: null,
      porcentagem: null,
      atributo_id: null,
      descricao: null,
    });

   const [tituloModal, setTituloModal] = useState(null);
   const [tituloDataModal, setTituloDataModal] = useState(null);
   const [atributos, setAtributos] = useState(null);
   
   const user = Store.getState().user;
   const selectTipoParceiro = useRef();
   const selectEstados = useRef();
   const selectCidades = useRef();
   const selectBancos = useRef();
   const selectTipoContas = useRef();
   const selectAgentMaster = useRef();

    //dados filtro
  const [nome, setNome] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [responsavel, setResponsavel] = useState(null);

  useEffect(() => {
   getData(null, null, null);
 }, []);


   async function getCidades(estadoId) {
      const data = await listByEstado(estadoId);
      setCidades(data);
      return data;
   }

   // async function listarParceiros(){
   //    try {
   //       const result = await listar();
   //       setParceiros(result);
   //    } catch (error) {
   //       toast.error("Falha ao carregar os parceiros.")
   //    }
   // }

   const getData = async (documento, responsavel, nome) => {
      try {
        setParceiros();
        setNome(nome);
        setDocumento(documento);
        setResponsavel(responsavel);
        const response = await listar(documento, responsavel, nome);
        setParceiros(response);
        setAgentesMater(await getAgentsMaster());
      } catch (err) {
        toast.error("Falha ao carregar dados!");
      }
   };

   const onFilter = async () => {
      try{
         setFullLoading(true);
         setParceiros(await listar(documento, responsavel, nome));
         setFullLoading(false);
         window.$("#modal-filter").modal("hide");
      } catch (err) {
         toast.error("Falha ao carregar dados!");
         setFullLoading(false);
      } finally {
         setFullLoading(false);
         window.$("#modal-filter").modal("hide");
      }
   }

   async function getEstados() {
      const data = await listEstados();
      setEstados(data);
   }

   async function getBancos() {
      const data = await listBanks();
      setBancos(data);
   }

   async function getTipoContas() {
      const data = await listTypeOfAccounts();
      setTipoContas(data);
   }

   function downloadQrCode(produto){
      let qrCode = document.getElementById(`qrCodeElement-${produto}`);
      const pngUrl = qrCode
         .toDataURL("image/png")
         .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `produto-${produto}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
   }
   
   useEffect(() => {
      getBancos();
      getTipoContas();
      getEstados();
      getData();

      window.$("#modal-data").on("hidden.bs.modal", function () {
         setParceiro({});
         setErrors({});
         setInputNome('Nome');
         setInputApelido('Apelido');
         setInputDocumento('CPF');
         setInputDocumento2('RG / CNH');
         setInputNascimento('Data Nascimento');

         selectTipoParceiro.current.select.clearValue();
         selectEstados.current.select.clearValue();
         selectCidades.current.select.clearValue();
         selectBancos.current.select.clearValue();
         selectTipoContas.current.select.clearValue();
         selectAgentMaster.current.select.clearValue();

         window.$("#urlamigavel").val("");
         window.$("#celular").val("");
         window.$("#responsavel").val("");
      });
   }, []);

   useEffect(() => {
      if (parceiro && parceiro.estado){
         try {
            setFullLoading(true);
            getCidades(parceiro.estado);
         } catch (error) {
            toast.error('Falha ao carregar as cidades 😔');
         }finally{
            setFullLoading(false);
         }
      }
   }, [parceiro.estado]);

   useEffect(() => {
      if(parceiro.tipo_parceiro === 1 || parceiro.tipo_parceiro !== 0){
         setInputNome('Nome');
         setInputApelido('Apelido');
         setInputDocumento('CPF');
         setInputDocumento2('RG / CNH');
         setInputNascimento('Data Nascimento');
      }else{
         setInputNome('Razão Social');
         setInputApelido('Nome Fantasia');
         setInputDocumento('CNPJ');
         setInputDocumento2('Inscrição Estadual');
         setInputNascimento('Data Fundação');
      }
   }, [parceiro.tipo_parceiro])

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "Id",
               field: "id",
            },
            {
               label: "Nome",
               field: "nome",
               attributes: {
                  "aria-controls": "DataTable",
                  "aria-label": "Nome",
               },
            },
            {
               label: "Documento",
               field: "documento",
            },
            {
               label: "Responsavel",
               field: "responsavel",
            },
            {
               label: "Criado em",
               field: "criado_em",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            parceiros &&
            parceiros.map((x) => {
               return {
                  id: x.parceiro_id,
                  nome: x.nome,
                  documento: x.documento,
                  responsavel: x.responsavel,
                  criado_em: x.data_cadastro,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           {parseInt(user.role) === 1 ||
                           parseInt(user.role) === 6 ? (
                              <>
                                 <button
                                    type="button"
                                    onClick={() => onEditClick(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-pencil-alt fa-lg"></i>
                                    Editar
                                 </button>
                                 <button
                                    type="button"
                                    onClick={() => disabledParceiro(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-trash"></i>
                                    Desativar
                                 </button>
                                 <button
                                    type="button"
                                    onClick={() => {
                                       let auxParceiro = parceiros.filter(parceiro => parceiro.parceiro_id === x.parceiro_id)[0];
                                       setParceiro(auxParceiro)
                                       window.$("#modal-qrcode").modal("show")
                                    }}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-qrcode"></i>
                                    QRCODE
                                 </button>
                                 <button
                                    type="button"
                                    onClick={() => onClickComissoes(x.parceiro_id, x.nome)}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-eye fa-lg"></i>
                                    Regras Comissão
                                 </button>
                              </>
                           ) : (
                              <div>
                                 <button
                                    type="button"
                                    onClick={() => onEditClick(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <div className="divDropdown">
                                       <i className="fas fa-eye fa-lg"></i>
                                       Editar
                                    </div>
                                 </button>
                              </div>
                           )}
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [parceiros]);

   useEffect(() => {
      setDataComissoes({
         columns: [
            {
               label: "Id",
               field: "regra_id",
            },
            {
               label: "Banco",
               field: "banco",
               attributes: {
                  "aria-controls": "DataTable",
                  "aria-label": "Banco",
               },
            },
            {
               label: "Atributo",
               field: "atributo",
            },
            {
               label: "Descrição Atributo",
               field: "tipo_descricao",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            parceiros &&
            parceiros.map((x) => {
               return {
                  id: x.parceiro_id,
                  nome: x.nome,
                  documento: x.documento,
                  responsavel: x.responsavel,
                  criado_em: x.data_cadastro,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           {parseInt(user.role) === 1 ||
                           parseInt(user.role) === 6 ? (
                              <>
                                 <button
                                    type="button"
                                    onClick={() => onEditClick(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-pencil-alt fa-lg"></i>
                                    Editar
                                 </button>
                                 <button
                                    type="button"
                                    onClick={() => disabledParceiro(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <i className="fas fa-trash"></i>
                                    Desativar
                                 </button>
                              </>
                           ) : (
                              <div>
                                 <button
                                    type="button"
                                    onClick={() => onEditClick(x.parceiro_id)}
                                    className="dropdown-item"
                                 >
                                    <div className="divDropdown">
                                       <i className="fas fa-eye fa-lg"></i>
                                       Editar
                                    </div>
                                 </button>
                              </div>
                           )}
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [comissoes]);

   const onEditClick = async (id) => {
      setFullLoading(true);
      try {
         const retorno = parceiros.filter(x => x.parceiro_id === id)[0];
         setParceiro(retorno)
         setFullLoading(false);
         window.$("#modal-data").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do parceiro!");
         window.$("#modal-data").modal("hide");
         setFullLoading(false);
      }
   };

   const onEditCommision = async (dados) => {
      setTituloDataModal(`Editar - ${dados.banco}`);
      const resultAtributos = await buscarAtributos();
      if(resultAtributos){
         setAtributos(resultAtributos);
      }
      setErrors({});
      setRegister({
        ...register,
        regra_id: dados.regra_id,
        banco: dados.banco,
        banco_id: dados.banco_id,
        porcentagem: dados.porcentagem,
        atributo_id: dados.atributo_id,
        descricao: dados.descricao
      });
  
      window.$("#modal-data-comissao").modal("show");
      window.$("#modal-data-comissao").css("z-index", 9999);
    };

   const onClickComissoes = async (id, nome) => {
      setFullLoading(true);
      try {
         setTituloModal(nome);
         const response = await buscarComissoes(id);
         setComissoes(response);
         window.$("#modal-detalhes").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar os valores da comissão!");
         window.$("#modal-detalhes").modal("hide");
      }finally{
         setFullLoading(false);
      }
   };

   const gravarPercentual = async () =>{
      setFullLoading(true);
      try {
         const { banco_id, atributo_id, porcentagem, regra_id } = register;
         const result = await gravarRegraComissao(banco_id, atributo_id, porcentagem, regra_id);
         if(result && result.regra_id){
            toast.success(`Regra ${result.regra_id} salva com sucesso 😁`);
         }else{
            throw Error();
         }
      } catch (error) {
         toast.error('Ocorreu um erro ao salvar o percentual, tente novamente. 😥')
      }finally{
         setFullLoading(false);
         onClear();
      }
   }

   const handleExport = async () => {
      swal({
         title: "Exportar parceiros?",
         text: "Será exportado os parceiros cadastrados no sistema",
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFullLoading(true);
            const retorno = await exportarParceiros(nome, documento, responsavel);
            if (retorno) {
               let dados =
                  typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
               let CSV = "sep=," + "\r\n";
               let row = "";
               //Setar o nome do relatorio na primeira linha
               CSV +=
                  `Relatório de Parceiros - ${moment().format("MMMM YYYY")}` +
                  "\r\n";
               //Este loop irá extrair o rótulo do primeiro índice da matriz
               for (let index in dados[0]) {
                  //Agora converta cada valor para string e separado por vírgulas
                  row += index + ",";
               }
               row = row.slice(0, -1);
               //anexar linha de rótulo com quebra de linha
               CSV += row + "\r\n";
               //O primeiro loop é extrair cada linha
               for (let i = 0; i < dados.length; i++) {
                  let row = "";
                  //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
                  for (let index in dados[i]) {
                     row += '"' + dados[i][index] + '",';
                  }
                  row.slice(0, row.length - 1);
                  //adicione uma quebra de linha após cada linha
                  CSV += row + "\r\n";
               }

               if (CSV === "") {
                  swal("Ocorreu um erro ao exportar os parceiros", {
                     icon: "error",
                  });
               }

               let nomeArquivo = `parceiros-${moment().format("X")}`;
               let uri = "data:text/csv;charset=utf-8," + escape(CSV);
               let link = document.createElement("a");
               link.href = uri;
               link.style = "visibility:hidden";
               link.download = nomeArquivo + ".csv";
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
               setFullLoading(false);
               swal("Parceiros exportados com sucesso.", {
                  icon: "success",
               });
            } else {
               setFullLoading(false);
               swal("Ocorreu um erro ao exportar os parceiros", {
                  icon: "error",
               });
            }
         } else {
            swal("Os parceiros não foram exportados.", {
               icon: "info",
            });
         }
      });
   };

   const onClear = () => {
      window.$("#modal-data-comissao").modal("hide");
      window.$("#modal-detalhes").modal("hide");
      window.$("#modal-data").modal("hide");
      setParceiro({});
   };

   const onClearFiltro = () => {
      setNome(null);
      setDocumento(null);
      setResponsavel(null);
      window.$("#nome_filtro").val("");
      window.$("#documento_filtro").val("");
      window.$("#responsavel_filtro").val("");
   };

   const disabledBtnSubmit = useMemo(() => {
      let result = false;
      camposParceiro.map(campo => {
         if(!Object.keys(parceiro).includes(campo) || !parceiro[campo]){
            result = true;
            return result;
         }
      })

      return result;
   }, [parceiro]);

   const dataTableDetalhes = {
      columns: [
        {
          label: "Banco",
          field: "banco",
        },
        {
          label: "Percentual %",
          field: "percentual",
        },
        {
          label: "Atributo",
          field: "atributo",
        },
        {
          label: "Tipo",
          field: "tipo",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        comissoes &&
        comissoes.map((x) => {
          return {
            banco: funcoes.limitarTexto(x.banco, 18),
            percentual: x.porcentagem,
            atributo: x.descricao,
            tipo: x.tipo_descricao,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    onClick={() => onEditCommision(x)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-edit fa-lg"></i>
                    Editar
                  </button>
                </div>
              </div>
            ),
          };
        }),
    };

   function handleChange(e){
      const { id, value } = e.target;

      switch (id) {
         case 'nome':
            setErrors((prevErrors) => ({ ...prevErrors, [id]: value ? null : "Campo obrigatório" }));
            setParceiro({ ...parceiro, [id]: value, urlamigavel: value.replaceAll(' ', '-').toLowerCase() })
         break
         default:
            setErrors((prevErrors) => ({ ...prevErrors, [id]: value ? null : "Campo obrigatório" }));
            setParceiro({ ...parceiro, [id]: value })
         break;
      }

   }

   async function handleSave(event){
      event.preventDefault();
      try {
         setFullLoading(true);
         let result = await gravar(parceiro);
         if(result && result.parceiro_id){
            toast.success(`Parceiro salvo com sucesso 😁`);
            onClear();
         }else{
            throw Error();
         }
      } catch (error) {
         toast.error('Falha ao salvar o parceiro 😔')
         onClear();
      }finally{
         setFullLoading(false);
         onClear();
         // listarParceiros();
         getData();
      }
   }

   async function handleBlur(event){
      const { id, value } = event.target;

      if(id === 'cep'){
         try {
            setFullLoading(true);
            const dataCep = await getAddressByCep(value);
            const { status, data } = dataCep;
            if(status === 200){
               // Buscar o estado para o select
               const dadosEstado = estados.filter(e => e.uf === data.uf)[0];
               
               // Buscar a cidade para o select
               const dadosCidade = await getCidades(dadosEstado.id);
               const cidade = dadosCidade.filter(c => c.nome === data.localidade)[0];
               
               window.$("#numero").focus();

               setParceiro({
                  ...parceiro,
                  bairro: data.bairro,
                  complemento: data.complemento,
                  cidade: cidade.id,
                  cidade_nome: cidade.nome,
                  logradouro: data.logradouro,
                  estado: dadosEstado.id,
                  estado_nome: dadosEstado.nome
               })
            }else{
               toast.warn('Não localizamos o cep informado, tente novamente. 😔');
            }
         } catch (error) {
            toast.error('Falha ao carregar endereço do CEP 😔');
         }finally{
            setFullLoading(false);
         }
      }
   }

   async function disabledParceiro(id){
      swal({
         title: "Deseja desativar esse parceiro ? 👀",
         text: `O parceiro #${id} será desativado, essa ação não pode ser desfeita. 😲`,
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFullLoading(true);
            const retorno = await desativar(id);
            if (retorno) {
               setFullLoading(false);
               swal("O parceiro desativado com sucesso.", {
                  icon: "success",
               });
               // await listarParceiros();
               await getData();
            } else {
               setFullLoading(false);
               swal(retorno.message, {
                  icon: "error",
               });
            }
         } else {
            swal("O parceiro não foi desativado.", {
               icon: "error",
            });

            setFullLoading(false);
         }
      });
   }

   return (
      <div>
         {fullLoading && (
               <Loading />
         )}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => {
                        setParceiro({});
                        window.$("#modal-data").modal("show");
                     },
                  },
                  {
                     name: "Exportar CSV",
                     icone: (
                        <i
                           className="fas fa-file-csv"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: handleExport,
                  },
                  {
                     name: "Filtrar",
                     icone: (
                       <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                   }
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card ">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        dark={false}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>

         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Editar"}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onClear}
                     >
                        x
                     </span>
                  </button>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados Pessoais</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>{inputNome}*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="nome"
                                       placeholder="Nome..."
                                       value={parceiro.nome || ""}
                                       helpType="error"
                                       help={errors.nome || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>{inputApelido}*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="apelido"
                                       placeholder="Apelido..."
                                       value={parceiro.apelido || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Email*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="email"
                                       placeholder="Email..."
                                       value={parceiro.email || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>{inputNascimento}*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="data_nascimento"
                                       placeholder="Data de Nascimento..."
                                       value={parceiro.data_nascimento || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>{inputDocumento}*</label>
                                 <Input
                                    type="cpfCnpj"
                                    className="form-control"
                                    id="documento"
                                    value={parceiro.documento || ""}
                                    placeholder="Documento..."
                                    onChange={handleChange}
                                 />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>{inputDocumento2}*</label>
                                 <Input
                                    type="text"
                                    className="form-control"
                                    id="documento2"
                                    value={parceiro.documento2 || ""}
                                    placeholder="Documento2..."
                                    onChange={handleChange}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Tipo Parceiro*</label>
                                 <Select
                                    id="tipo_parceiro"
                                    ref={selectTipoParceiro}
                                    placeholder="Selecione o tipo do parceiro..."
                                    value={{
                                       label: parceiro.tipo_parceiro_nome
                                          ? parceiro.tipo_parceiro_nome
                                          : "Selecione o tipo do parceiro",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             tipo_parceiro: e.value,
                                             tipo_parceiro_nome: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             tipo_parceiro: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             tipo_parceiro: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={[
                                       {
                                          id: 0,
                                          nome: "Jurídica",
                                          label: "Jurídica",
                                          value: 0,
                                       },
                                       {
                                          id: 1,
                                          nome: "Física",
                                          label: "Física",
                                          value: 1,
                                       },
                                    ]}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Celular*</label>
                                 <Input
                                    type="phone"
                                    className="form-control"
                                    id="celular"
                                    value={parceiro.celular}
                                    placeholder="Celular..."
                                    onChange={handleChange}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Nome do Responsável*</label>
                                 <Input
                                    type="text"
                                    className="form-control"
                                    id="responsavel"
                                    value={parceiro.responsavel}
                                    placeholder="Nome contato..."
                                    onChange={handleChange}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Selecione o responsável seu crédito*</label>
                                 <Select
                                    id="responsavel_id"
                                    ref={selectAgentMaster}
                                    placeholder="Selecione o responsável..."
                                    value={{
                                       label: parceiro.responsavel_nome
                                          ? parceiro.responsavel_nome
                                          : "Selecione o responsável",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             responsavel_id: e.value,
                                             responsavel_nome: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             responsavel_id: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             responsavel_id: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={agentesMaster}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Url Amigavel*</label>
                                 <Input
                                    type="text"
                                    className="form-control"
                                    id="urlamigavel"
                                    value={parceiro.urlamigavel}
                                    placeholder="Url Amigavel..."
                                    disabled
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados de endereço</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>CEP*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="cep"
                                       className="form-control"
                                       id="cep"
                                       placeholder="CEP..."
                                       value={parceiro.cep || ""}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Rua*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="logradouro"
                                       placeholder="Rua..."
                                       value={parceiro.logradouro || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Numero*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="numero"
                                       placeholder="Numero..."
                                       value={parceiro.numero || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>

                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Bairro*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="bairro"
                                       placeholder="Bairro..."
                                       value={parceiro.bairro || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Complemento*</label>
                                 <Input
                                    type="text"
                                    className="form-control"
                                    id="complemento"
                                    value={parceiro.complemento || ""}
                                    placeholder="Complemento..."
                                    onChange={handleChange}
                                 />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Estado</label>
                                 <Select
                                    id="estado"
                                    ref={selectEstados}
                                    placeholder="Selecione o estado..."
                                    value={{
                                       label: parceiro.estado_nome
                                          ? parceiro.estado_nome
                                          : "Selecione o estado",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             estado: e.value,
                                             estado_nome: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             estado: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             estado: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={estados}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Cidade</label>
                                 <Select
                                    id="cidade"
                                    ref={selectCidades}
                                    placeholder="Selecione o estado..."
                                    value={{
                                       label: parceiro.cidade_nome
                                          ? parceiro.cidade_nome
                                          : "Selecione o estado",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             cidade: e.value,
                                             cidade_nome: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             cidade: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             cidade: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={cidades}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados Bancarios</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Banco</label>
                                 <Select
                                    id="banco"
                                    ref={selectBancos}
                                    placeholder="Selecione o banco..."
                                    value={{
                                       label: parceiro.banco_nome
                                          ? parceiro.banco_nome
                                          : "Selecione o banco",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             banco: e.value,
                                             banco_nome: e.label
                                          });
                                          setErrors({
                                             ...errors,
                                             banco: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             banco: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={bancos}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Agência*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="agencia"
                                       placeholder="Agência..."
                                       value={parceiro.agencia || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Conta*</label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="conta"
                                       placeholder="Conta..."
                                       value={parceiro.conta || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label>Tipo Conta*</label>
                                 <Select
                                    id="tipo_conta"
                                    ref={selectTipoContas}
                                    placeholder="Selecione o tipo da conta..."
                                    value={{
                                       label: parceiro.tipo_conta_nome
                                          ? parceiro.tipo_conta_nome
                                          : "Selecione o tipo da conta",
                                    }}
                                    onChange={(e) => {
                                       if (e) {
                                          setParceiro({
                                             ...parceiro,
                                             tipo_conta: e.value,
                                             tipo_conta_nome: e.label
                                          });
                                          setErrors({
                                             ...errors,
                                             tipo_conta: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             tipo_conta: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={tipoContas}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Cancelar
                        </button>
                     </div>
                     <div className="col-md-6 text-center">
                        <button
                           type="submit"
                           className="btn btn-success btn-block"
                           onClick={handleSave}
                           disabled={disabledBtnSubmit}
                        >
                           <i className="far fa-save fa-lg iconDownload"></i>
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         {/* Modal de QrCode */}
         <Modal
            id={"modal-qrcode"}
            title={"QRCODE"}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onClear}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">QR CODES</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <h2>FGTS</h2>
                                 <QRCode
                                    id="qrCodeElement-fgts"
                                    value={`https://seucreditonegocios.com.br/fgts/${parceiro.urlamigavel}/${parceiro.parceiro_id}`}
                                    size={290}
                                    level={"H"}
                                    includeMargin={true}
                                 />
                                 <label>{`https://seucreditonegocios.com.br/fgts/${parceiro.urlamigavel}/${parceiro.parceiro_id}`}</label>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <h2>Conta de Luz</h2>
                                 <QRCode
                                    id="qrCodeElement-contadeluz"
                                    value={`https://seucreditonegocios.com.br/emprestimo-conta-de-luz/${parceiro.urlamigavel}/${parceiro.parceiro_id}`}
                                    size={290}
                                    level={"H"}
                                    includeMargin={true}
                                 />
                                 <label>{`https://seucreditonegocios.com.br/emprestimo-conta-de-luz/${parceiro.urlamigavel}/${parceiro.parceiro_id}`}</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Download</h3>
                     </div>
                     <div className="card-body">
                        <div className="row divBtnInfoAgente">
                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 className="btn btn-success btn-block"
                                 data-dismiss="modal"
                                 onClick={() => downloadQrCode('fgts')}
                              >
                                 <i className="fas fa-download" style={{ marginRight: "5px" }}></i>
                                 FGTS
                              </button>
                           </div>
                           <div className="col-md-6 text-center">
                              <button
                                 type="submit"
                                 className="btn btn-success btn-block"
                                 onClick={() => downloadQrCode('contadeluz')}
                              >
                                 <i className="fas fa-download" style={{ marginRight: "5px" }}></i>
                                 Conta de Luz
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />

      {/* Modal Adicionar / Editar */}
      <Modal
        id={"modal-data-comissao"}
        title={tituloDataModal}
        size={"lg"}
        form={
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                arial-label="close"
              >
                <span arial-hidden="true" className="text-white">x</span>
              </button>
              <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Regra</h3>
                  </div>
                  <div className="card-body">
                     <div className="row">
                        <div className="form-group col-md-6">
                           <label className="ml-3">Atributo*</label>
                           <Select
                           id="atributo"
                           className="form-control-select"
                           placeholder="Parceiro..."
                           value={{ label: register.descricao ? register.descricao : "Selecione o Atributo..." }}
                           options={atributos}
                           onChange={(e) => {
                              if (e) {
                                 setRegister({
                                 ...register,
                                 atributo_id: e.value,
                                 descricao: e.label
                                 });
                                 setErrors({ ...errors, atributo_id: null });
                              } else {
                                 setErrors({ ...errors, atributo_id: "Campo Obrigatório" });
                              }
                           }}
                           backspaceRemovesValue
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label className="ml-3">Banco*</label>
                           <Select
                           id="banco"
                           className="form-control-select"
                           placeholder="Banco..."
                           value={{ label: register.banco ? register.banco : "Selecione o banco..." }}
                           options={bancos}
                           onChange={(e) => {
                              if (e) {
                                 setRegister({
                                 ...register,
                                 banco: e.label,
                                 banco_id: e.value,
                                 });
                                 setErrors({ ...errors, banco: null });
                              } else {
                                 setErrors({ ...errors, banco: "Campo Obrigatório" });
                              }
                           }}
                           backspaceRemovesValue
                           />
                        </div>
                     </div>

                     <div className="row">
                        <div className="form-group py-2 col-md-6">
                           <label className="ml-3">Porcentagem*</label>
                           <Input
                           id="porcentagem"
                           className="form-control ml-2"
                           placeholder="porcentagem..."
                           type="percentual"
                           onChange={(e) => setRegister({...register, porcentagem: e.target.value})}
                           value={register.porcentagem || null}
                           help={errors.porcentagem || ""}
                           />
                        </div>
                     </div>

                     <div className="row">
                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           className="btn btn-danger btn-block my-4"
                           data-dismiss="modal"
                           arial-label="close"
                           >
                           Cancelar
                           </button>
                        </div>

                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           onClick={gravarPercentual}
                           disabled={register.atributo_id && register.banco && register.porcentagem && register.regra_id ? false : true}
                           className="btn btn-success btn-block my-4"
                           >
                           Salvar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        }
      />

      {/* Modal de Detalhes */}
      <Modal
        id={"modal-detalhes"}
        title={"Regras - " + tituloModal}
        size={"xl"}
        form={
            <div>
               <button
               type="button"
               className="close"
               data-dismiss="modal"
               arial-label="close"
               >
               <span arial-hidden="true" className="text-white" 
               onClick={onClear}
               >
                  x
               </span>
               </button>
               <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Comissões</h3>
                  </div>
                  <div className="card-body">
                     <MDBDataTableV5
                        data={
                        dataTableDetalhes && dataTableDetalhes.rows
                           ? dataTableDetalhes
                           : []
                        }
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                     <div className="row">
                     <div
                        className="col-md-6 text-center"
                        style={{ margin: "0 auto" }}
                     >
                        <button
                           type="button"
                           onClick={onClear}
                           className="btn btn-danger btn-block"
                        >
                           Fechar
                        </button>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
         }
      />

      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClearFiltro}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Filtrar</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group">
                    <Input
                      id="nome_filtro"
                      className="form-control"
                      placeholder="Nome..."
                      type="text"
                      value={nome}
                      onChange={(e) => setNome(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group ">
                    <Input
                      id="documento_filtro"
                      className="form-control"
                      placeholder="Documento..."
                      type="cpfCnpj"
                      value={documento}
                      onChange={(e) => setDocumento(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group">
                    <Input
                      id="responsavel_filtro"
                      className="form-control"
                      placeholder="Responsavel..."
                      type="text"
                      value={responsavel}
                      onChange={(e) => setResponsavel(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClearFiltro}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />

         <ToastContainer />
      </div>
   );
}

export default Parceiros;
