import { Store } from "../redux/Store";
import instance from "./index";

export const listar = async (documento, responsavel, nome) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = "parceiros/listar";
         const response = await instance.post(
            url, 
            {
               documento, responsavel, nome
            },
            { headers: { token } }
         );

         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const buscarComissoes = async (id) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = `parceiros/buscar?id=${id}`;
         const response = await instance.get(url, { headers: { token } });

         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const buscarAtributos = async () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = `parceiros/buscar-atributos`;
         const response = await instance.get(url, { headers: { token } });

         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const gravarRegraComissao = async ( banco_id, atributo_id, porcentagem, regra_id ) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = `parceiros/gravar-regra-comissao`;
         const response = await instance.post(
            url, 
            {
               banco_id, atributo_id, porcentagem, regra_id
            },
            { headers: { token } }
         );
         if (response.status === 200) {
            resolve(response.data[0]);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const gravar = async (dados) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = "parceiros/gravar";
         const response = await instance.post(
            url,
            { dados },
            { headers: { token } }
         );

         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const desativar = async (parceiro_id) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = "parceiros/desativar";
         const response = await instance.post(
            url,
            { parceiro_id },
            { headers: { token } }
         );
         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};

export const exportarParceiros = async (nome, documento, responsavel) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token;

         const url = "parceiros/exportar";
         const response = await instance.post(
            url, 
            {
               nome, documento, responsavel
            },
            { headers: { token } }
         );

         if (response.status === 200) {
            resolve(response.data);
         } else {
            reject(response);
         }
      } catch (err) {
         reject(err);
      }
   });
};