import { combineReducers } from "redux";

import { LOGOUT, UPDATE_USER } from "./Actions";

const merge = (prevState, nextState) => Object.assign({}, prevState, nextState);

const userInitialStateReducer = {
  key: null,
  name: null,
  token: null,
  first_access: null,
  permissions: [],
  menu:[],
  currency: 'BRL',
  locale: 'pt-BR',
  dateFormat: 'DD / MM / AAAA',
  status_acesso: null,
  status_id: null,
};

const userReducer = (state = userInitialStateReducer, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return merge(state, action.payload);
    case LOGOUT:
      return userInitialStateReducer;

    default:
      return state;
  }
};

export default combineReducers({
  user: userReducer,
});
