import React from "react";
import { Store } from "../../redux/Store";
import styles from "./style.module.css";

function Menu() {
  const { paginas } = Store.getState().user;
  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
      id="sidenav-main"
    >
      <div className="scrollbar-inner">
        {/* Brand */}
        <div className=" sidenav-header d-flex align-items-center">
          <a className="navbar-brand" href="/">
            <img
              src="../assets/img/brand/blue.png"
              className="navbar-brand-img "
              alt="..."
            />
            <i
              className={`${styles.iconeFecharMenu} px-2 fas fa-times sidenav-toggler sidenav-toggler-dark`}
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
            ></i>
          </a>
          <div className="ml-auto">
            <div
              className="sidenav-toggler d-none d-xl-block"
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            {/* Nav items */}
            <ul className="navbar-nav">
              {paginas && paginas.map(
                (x) =>
                  x.nome !== "Meu Perfil" &&
                  x.paginas.length > 0 && (
                    <li key={x.menu_id} className="nav-item">
                      {x.paginas.length > 1 && x.paginas.length > 0 ? (
                        <a
                          className="nav-link"
                          href={`#navbar-${x.nome}`}
                          data-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls={`navbar-${x.nome}`}
                        >
                          <i
                            className={` nav-item ni ${x.icone} text-${
                              x.cor || "default"
                            }`}
                          />
                          <span className="nav-link-text px-1">{x.nome}</span>
                        </a>
                      ) : (
                        <a
                          className={`nav-link ${
                            window.location.pathname === x.rota ? " active" : ""
                          }`}
                          style={{
                            display: x.subitem ? "none" : "block",
                          }}
                          href={x.rota}
                        >
                          <i
                            className={`ni ${x.icone} text-${
                              x.cor || "default"
                            }`}
                          />
                          <span className="nav-link-text px-1">{x.nome}</span>
                        </a>
                      )}
                      {x.paginas && x.paginas.length > 1 && (
                        <div className="collapse" id={`navbar-${x.nome}`}>
                          <ul className="nav nav-sm flex-column">
                            {x.paginas.map(
                              (y, index) =>
                                x.nome !== y.nome && (
                                  <li className="nav-item" key={index}>
                                    <a
                                      className="nav-link"
                                      href={y.subitem === true ? y.rota : ""}
                                    >
                                      <i
                                        className={`fas fa-angle-right text-${
                                          y.cor || "success"
                                        }`}
                                      ></i>
                                      <span className="nav-link-text">
                                        {y.nome}
                                      </span>
                                    </a>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </li>
                  )
              )}
            </ul>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal">Redes Sociais</span>
            </h6>
            {/* Navigation */}
            <ul className="navbar-nav mb-md-3">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.instagram.com/seucreditonegocios/"
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                  <span className="nav-link-text">Instagram</span>
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link"
                  href="https://www.facebook.com/pg/seucreditonegocios"
                  target="_blank"
                >
                  <i className="fab fa-facebook" />
                  <span className="nav-link-text">Facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
