import { Store } from "../redux/Store";
import instance from "./index";

export const getRegrasComission = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "bancos/listar-regras-comissao";
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const gravarRegraComissao = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "bancos/gravar-regra-comissao";
      const response = await instance.post(url, data, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const inativarRegraComissao = async (regracomissao_id) => {
    try {
    const token = Store.getState().user.token

    const url = "bancos/inativar-regra-comissao"
    const response = await instance.post(
        url,
        { id: regracomissao_id },
        { headers: { token } }
    )
        if (response.status === 200) {
            return response.data
        } else {
            return response.data
        }
    } catch (err) {
        return err;
    }
}

export const buscarRegrasComissao = async (banco, atributo, tipo, percent) => {
  return new Promise(async (resolve, reject) => {
      try {
          const url = "bancos/buscar-regras-comissao"
          const response = await instance.post(
              url,
           {banco_id:banco,
            atributo:atributo,
            porcent:percent,
            tipo:tipo
          }
          )
              if (response.status === 200) {
                  resolve(response.data)
              } else {
                  reject(response.data)
              }
          } catch (err) {
              reject(err)
          }
  })
}

export const getTypeRegras = async () => {
  return new Promise(async (resolve, reject) => {
      try {
      const token = Store.getState().user.token

      const url = "bancos/tipos/listar"
      const response = await instance.get(url, { headers: { token } })

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}
