import React, { useState } from "react";

//plugins
import { toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

//services
import { getAnalyctsBank, updateProposalValidate } from "../../services/Commission";

//compomentes
import Modal from "../../components/modal/Modal";

//helpers
import funcoes from "../../utils/helpers/funcoes";

function TableList(props) {
   const [propostas, setPropostas] = useState(null);
   const [bancoId, setBancoId] = useState(null);
   const [produtoId, setProdutoId] = useState(null);
   const { comissaoProdutoId, produto, dados, somatoria, usuario, dataInicio, dataFim } = props;

   async function handleAnalyctsBank(
      banco_id,
      produto_id,
      usuario,
      dataInicio,
      dataFim
   ) {
      try {
         setBancoId(banco_id);
         setProdutoId(produto_id);
         const response = await getAnalyctsBank(
            banco_id,
            produto_id,
            usuario,
            dataInicio,
            dataFim
         );
         setPropostas(response);
         window.$(`#modal-analycts-${comissaoProdutoId}`).modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do agente!");
         window.$(`#modal-analycts-${comissaoProdutoId}`).modal("hide");
      }
   }

   async function handleValidateProposal(proposal, validate) {
      const texto = `A proposta será atualizada para ${validate ? "não validada." : "validada"}`;
      swal({
         title: validate ? "Não validar proposta?" : "Validar proposta?",
         text: texto,
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            const retorno = await updateProposalValidate(proposal, validate);

            if (retorno.id) {
               swal("Proposta atualizada com sucesso.", {
                  icon: "success",
               });

               await handleAnalyctsBank(bancoId, produtoId, usuario, dataInicio, dataFim);
            } else {
               swal("Ocorreu um erro ao atualizar a proposta.", {
                  icon: "error",
               });
            }
         } else {
            swal("A proposta não foi atualizada.", {
               icon: "info",
            });
         }
      });
   }

   const onPageChanged = async () =>
      getAnalyctsBank(bancoId, produtoId, usuario, dataInicio, dataFim);

   function onReset() {
      window.$(`#modal-analycts-${comissaoProdutoId}`).modal("hide");
   }

   const datatable = {
      columns: [
         {
            label: "Banco",
            field: "banco",
         },
         {
            label: "Data Integração",
            field: "data_integracao",
         },
         {
            label: "Agente",
            field: "agente",
         },
         {
            label: "Nº Contrato",
            field: "proposta",
         },
         {
            label: "Valor Empréstimo",
            field: "valor_emprestimo",
         },
         {
            label: "Comissão",
            field: "comissao",
         },
         {
            label: "Validado",
            field: "proposta_validacao",
         },
         {
            label: "Ação",
            field: "action",
         },
      ],
      rows:
         propostas &&
         propostas.dados.map((x) => {
            return {
               banco: funcoes.limitarTexto(x.banco, 10),
               data_integracao: x.data_integracao,
               agente: funcoes.limitarTexto(x.agente, 15),
               proposta: x.proposta,
               valor_emprestimo: 'R$ ' + x.valor_emprestimo,
               comissao: 'R$ ' + x.comissao,
               proposta_validacao: x.proposta_validacao,
               action: (
                  <div className="dropdown">
                     <a
                        className="btn btn-sm btn-icon-only text-light"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <i className="fas fa-ellipsis-v" />
                     </a>
                     <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <button
                           type="button"
                           onClick={() => handleValidateProposal(x.proposta_id, x.validado)}
                           className="dropdown-item"
                        >
                           <i class={x.validado ? "fas fa-ban fa-lg" : "fas fa-check fa-lg"}></i>
                           {x.validado ? "Invalidar" : "Validar"}
                        </button>
                     </div>
                  </div>
               ),
            };
         }),
   };

   return (
      <div>
         <h3 className="mb-2 text-center">{produto && produto.produto && funcoes.camelCase(produto.produto)}</h3>
         <div className="table-responsive">
            <div>
               <table className="table table-striped align-items-center">
                  <thead className="thead-light">
                     {
                        dados && dados.length > 0 &&
                        <tr>
                           <th scope="col">Banco</th>
                           <th scope="col">Produção</th>
                           <th scope="col">Comissão</th>
                           <th scope="col">Ação</th>
                        </tr>
                     }
                  </thead>
                  <tbody className="list">
                     {dados && dados.length > 0 && dados.map((x) => (
                        <tr>
                           <td className="budget">{x.banco}</td>
                           <td className="budget">R$ {x.valor_emprestimo}</td>
                           <td className="budget">R$ {x.comissao}</td>
                           <td className="budget">
                              <i
                                 className="fas fa-eye fa-lg"
                                 title="Ver Extrato"
                                 style={{ cursor: "pointer" }}
                                 onClick={() =>
                                    handleAnalyctsBank(
                                       x.banco_id,
                                       x.produto_id,
                                       usuario,
                                       dataInicio,
                                       dataFim
                                    )
                                 }
                              ></i>
                           </td>
                        </tr>
                     ))}
                     {
                        dados && dados.length > 0 &&
                        <tr>
                           <td className="budget">
                              <strong>Total: </strong>
                           </td>
                           <td className="budget">
                              <strong>R$ {produto.valor_emprestimo}</strong>
                           </td>
                           <td className="budget">
                              <strong>R$ {produto.comissao}</strong>
                           </td>
                           <td className="budget"></td>
                        </tr>
                     }
                     {somatoria && (
                        <tr className="mt-5">
                           <td className="budget">
                              <strong>Total Geral: </strong>
                           </td>
                           <td className="budget">
                              <strong>R$ {somatoria.producao}</strong>
                           </td>
                           <td className="budget">
                              <strong>R$ {somatoria.comissao}</strong>
                           </td>
                           <td className="budget"></td>
                        </tr>
                     )}
                  </tbody>
               </table>
            </div>
         </div>

         {/* Modal de Detalhes */}
         <Modal
            id={`modal-analycts-${comissaoProdutoId}`}
            title={"Analítico Propostas"}
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     {/* <span arial-hidden="true" onClick={onReset}>
                x
              </span> */}
                  </button>
                  <div className="row">
                     <MDBDataTableV5
                        onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
                  <div className="row">
                     <div
                        className="col-md-6 text-center"
                        style={{ margin: "0 auto" }}
                     >
                        <button type="button" className="btn btn-danger btn-block" onClick={onReset}>
                           Fechar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />
      </div>
   );
}

export default TableList;
