import storage from "redux-persist/lib/storage";
import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import Reducer from "./Reducer";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, Reducer);
let Store = createStore(persistedReducer, applyMiddleware(thunk));
let Persistor = persistStore(Store);

export  { Store, Persistor };
