import React, { useState, useEffect, useMemo, useRef } from "react";

//services
import {
   getComissions,
   getComissionAgent,
   getProducts,
   recordComissionAgent,
} from "../services/CommissionAgent";
import { getBanks } from "../services/Price";
import { getAgents } from "../services/Agents";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";

import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import Input from "../components/input/Input";

//plugins
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import "moment/locale/pt-br";

//helpers
import funcoes from "../utils/helpers/funcoes";
import { MDBDataTableV5 } from "mdbreact";

moment.locale("pt-br");

function CommissionAgent() {
   //Dados das tabelas/selects:
   const [data, setData] = useState(null);
   const [datatable, setDataTable] = useState({});
   const [comissoes, setComissoes] = useState(null);

   //Dados do filtro:
   const [name, setName] = useState(null);
   const [cpf, setCpf] = useState(null);
   const [register, setRegister] = useState({
      agente: null,
      produto: null,
      banco: null,
      comissao: "",
   });
   const [errors, setErrors] = useState({});
   const [tituloModal, setTituloModal] = useState(null);
   const [tituloDataModal, setTituloDataModal] = useState(null);
   const [bancos, setBancos] = useState(null);
   const [agents, setAgents] = useState(null);
   const [produtos, setProdutos] = useState(null);

   //Dados do formulário:
   const [formLoading, setFormLoading] = useState(false);
   const selectAgente = useRef();
   const selectBanco = useRef();
   const selectProduto = useRef();

   useEffect(() => {
      getData(null, null);
      getPriceBanks();
      getListAgents();
      getListProducts();

      window.$("#modal-data").on("hidden.bs.modal", () => {
         setErrors({});
         selectAgente.current.select.clearValue();
         selectBanco.current.select.clearValue();
         selectProduto.current.select.clearValue();
         setRegister({
            agente: null,
            produto: null,
            banco: null,
            comissao: "",
            comissao_master: "",
         });
         window.$("#comissao").val(null);
         window.$("#comissao_master").val(null);
      });
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "CPF",
               field: "cpf",
            },
            {
               label: "Nome",
               field: "nome",
            },
            {
               label: "E-mail",
               field: "email",
            },
            {
               label: "Master",
               field: "master",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            data &&
            data.dados.map((x) => {
               return {
                  cpf: x.cpf,
                  nome: x.nome,
                  email: x.email,
                  master: x.master === "false" || !x.master ? "Não" : "Sim",
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              onClick={() => onViewClick(x.agente_id, x.nome)}
                              className="dropdown-item"
                           >
                              <i className="fas fa-eye fa-lg"></i>
                              Visualizar
                           </button>
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [data]);

   const getData = async (name, cpf) => {
      try {
         setData();
         setName(name);
         setCpf(cpf);
         const comissions = await getComissions(name, cpf);         
         setData(comissions);
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   const getPriceBanks = async () => {
      try {
         const response = await getBanks();
         setBancos(response);
      } catch (err) {}
   };

   const getListAgents = async () => {
      try {
         setAgents(await getAgents());
      } catch (err) {}
   };

   const getListProducts = async () => {
      try {
         setProdutos(await getProducts());
      } catch (err) {}
   };

   // const onPageChanged = async () => getData(name, cpf);

   const onViewClick = async (id, nome) => {
      setFormLoading(true);
      try {
         setTituloModal(nome);
         const response = await getComissionAgent(id);
         setComissoes(response);
         window.$("#modal-detalhes").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do agente!");
         window.$("#modal-detalhes").modal("hide");
      } finally {
         setFormLoading(false);
      }
   };

   const onEditClick = (dados) => {
      setTituloDataModal(`Editar Comissões - ${dados.agente}`);
      setErrors({});
      setRegister({
         ...register,
         agente: dados.agente,
         agente_id: dados.agente_id,
         produto: dados.produto,
         produto_id: dados.produto_id,
         banco: dados.banco,
         banco_id: dados.banco_id,
         comissao: dados.comissao,
         comissao_master: dados.comissao_master,
      });

      window.$("#modal-data").modal("show");
      window.$("#modal-data").css("z-index", 9999);
   };

   function handleChange(event) {
      const { id, value } = event.target;
      setRegister({ ...register, [id]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
   }

   function handleBlur(event) {
      let message = "Campo Obrigatório";
      const { id, value } = event.target;

      if (!value) {
         setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
      }
   }

   async function handleSave(event) {
      try {
         event.preventDefault();
         setFormLoading(true);
         const response = await recordComissionAgent(register);
         if (response && response.id) {
            toast.success("Comissão salva com sucesso.");
            await getData(null, null);
            window.$("#modal-data").modal("hide");
         } else {
            toast.error("Ocorreu um erro, ao salvar o usuário.");
         }
      } catch (err) {
         toast.error(err.erro);
      } finally {
         setFormLoading(false);
      }
   }

   function adicionar() {
      setTituloDataModal("Adicionar");
      window.$("#modal-data").modal("show");
   }

   const onFilter = () => {
      window.$("#modal-filter").modal("hide");
      getData(1, name, cpf);
   };

   const onClear = () => {
      window.$("#filtro").val(null);
      window.$("#name").val(null);
      window.$("#cpf").val(null);
      setName(null);
      setCpf(null);
   };

   const onReset = async () => {
      window.$("#modal-detalhes").modal("hide");
      window.$("#modal-filter").modal("hide");
      //onClear();
   };
   const disabledBtnSubmit = useMemo(() => {
      return (
         Object.keys(register).filter((key) => !register[key]).length > 0 ||
         Object.keys(errors).filter((key) => errors[key]).length > 0
      );
   }, [register, errors]);

   const dataTableDetalhes = {
      columns: [
         {
            label: "Banco",
            field: "banco",
         },
         {
            label: "Produto",
            field: "produto",
         },
         {
            label: "Comissão %",
            field: "comissao",
         },
         {
            label: "Comissão Master %",
            field: "comissao_master",
         },
         {
            label: "Ação",
            field: "action",
         },
      ],
      rows:
         comissoes &&
         comissoes.map((x) => {
            return {
               banco: funcoes.limitarTexto(x.banco, 18),
               produto: x.produto,
               comissao: x.comissao,
               comissao_master: x.comissao_master,
               action: (
                  <div className="dropdown">
                     <a
                        className="btn btn-sm btn-icon-only text-light"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <i className="fas fa-ellipsis-v" />
                     </a>
                     <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <button
                           type="button"
                           onClick={() => onEditClick(x)}
                           className="dropdown-item"
                        >
                           <i className="fas fa-edit fa-lg"></i>
                           Editar
                        </button>
                     </div>
                  </div>
               ),
            };
         }),
   };

   return (
      <div>
         {formLoading && (
               <Loading />
         )}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: adicionar,
                  },
                  {
                     name: "Filtrar",
                     icone: (
                        <i
                           className="fas fa-search"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>

         {/* Modal de Filtro */}
         <Modal
            id={"modal-filter"}
            title={"Filtrar"}
            size={"md"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Agente</h3>
                     </div>
                     <div className="card-body">
                        <div className="form-group mb-3">
                           <div className="input-group mx-4">
                              <Input
                                 id="name"
                                 className="form-control"
                                 placeholder="Nome..."
                                 type="text"
                                 value={name}
                                 onChange={(e) =>
                                    setName(e.target.value || null)
                                 }
                              />
                           </div>
                        </div>

                        <div className="form-group mb-3">
                           <div className="input-group mx-4">
                              <Input
                                 id="cpf"
                                 className="form-control"
                                 placeholder="Cpf..."
                                 type="cpf"
                                 value={cpf}
                                 onChange={(e) =>
                                    setCpf(e.target.value || null)
                                 }
                              />
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onClear}
                                 className="btn btn-danger btn-block my-4"
                              >
                                 Limpar
                              </button>
                           </div>

                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onFilter}
                                 className="btn btn-success btn-block my-4"
                              >
                                 Filtrar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />

         {/* Modal Adicionar / Editar */}
         <Modal
            id={"modal-data"}
            title={tituloDataModal}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span arial-hidden="true" className="text-white">
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Comissão</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="form-group col-md-6">
                              <label className="ml-3">Agente*</label>
                              <Select
                                 ref={selectAgente}
                                 id="agente"
                                 className="form-control-select"
                                 placeholder="Agente..."
                                 value={{
                                    label: register.agente
                                       ? register.agente
                                       : "Selecione o agente...",
                                 }}
                                 options={agents}
                                 onChange={(e) => {
                                    if (e) {
                                       setRegister({
                                          ...register,
                                          agente: e.label,
                                          agente_id: e.value,
                                       });
                                       setErrors({ ...errors, agente: null });
                                    } else {
                                       setErrors({
                                          ...errors,
                                          agente: "Campo Obrigatório",
                                       });
                                    }
                                 }}
                                 backspaceRemovesValue
                              />
                           </div>
                           <div className="form-group col-md-6">
                              <label className="ml-3">Banco*</label>
                              <Select
                                 ref={selectBanco}
                                 id="banco"
                                 className="form-control-select"
                                 placeholder="Banco..."
                                 value={{
                                    label: register.banco
                                       ? register.banco
                                       : "Selecione o banco...",
                                 }}
                                 options={bancos}
                                 onChange={(e) => {
                                    if (e) {
                                       setRegister({
                                          ...register,
                                          banco: e.label,
                                          banco_id: e.value,
                                       });
                                       setErrors({ ...errors, banco: null });
                                    } else {
                                       setErrors({
                                          ...errors,
                                          banco: "Campo Obrigatório",
                                       });
                                    }
                                 }}
                                 backspaceRemovesValue
                              />
                           </div>
                        </div>

                        <div className="row">
                           <div className="form-group col-md-6">
                              <label className="ml-3">Produto*</label>
                              <Select
                                 ref={selectProduto}
                                 id="produto"
                                 className="form-control-select"
                                 placeholder="Produto..."
                                 value={{
                                    label: register.produto
                                       ? register.produto
                                       : "Selecione o produto...",
                                 }}
                                 options={produtos}
                                 onChange={(e) => {
                                    if (e) {
                                       setRegister({
                                          ...register,
                                          produto: e.label,
                                          produto_id: e.value,
                                       });
                                       setErrors({ ...errors, produto: null });
                                    } else {
                                       setErrors({
                                          ...errors,
                                          produto: "Campo Obrigatório",
                                       });
                                    }
                                 }}
                                 backspaceRemovesValue
                              />
                           </div>

                           <div className="form-group py-2 col-md-6">
                              <label className="ml-3">Comissão*</label>
                              <Input
                                 id="comissao"
                                 className="form-control ml-2"
                                 placeholder="Comissão..."
                                 type="percentual"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={register.comissao || null}
                                 help={errors.comissao || ""}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="form-group ml-2 col-md-6">
                              <label className="ml-2">Comissão Master</label>
                              <Input
                                 id="comissao_master"
                                 className="form-control"
                                 placeholder="Comissão Master..."
                                 type="percentual"
                                 onChange={handleChange}
                                 value={register.comissao_master || null}
                              />
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 className="btn btn-danger btn-block my-4"
                                 data-dismiss="modal"
                                 arial-label="close"
                              >
                                 Cancelar
                              </button>
                           </div>

                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={handleSave}
                                 disabled={disabledBtnSubmit}
                                 className="btn btn-success btn-block my-4"
                              >
                                 Salvar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />
         {/* Modal de Detalhes */}
         <Modal
            id={"modal-detalhes"}
            title={"Visualizar - " + tituloModal}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Comissões</h3>
                     </div>
                     <div className="card-body">
                        <MDBDataTableV5
                           // onPageChanged={onPageChanged}
                           data={
                              dataTableDetalhes && dataTableDetalhes.rows
                                 ? dataTableDetalhes
                                 : []
                           }
                           searchLabel="Buscar"
                           entriesLabel="Registros por Página"
                           hover
                           striped={true}
                           entriesOptions={[10, 20, 30]}
                           pagingTop
                           searchTop
                           searchBottom={false}
                           responsive={true}
                           infoLabel={["", "a", "de", "Registros"]}
                           entries={10}
                           maxHeight="500px"
                        />
                        <div className="row">
                           <div
                              className="col-md-6 text-center"
                              style={{ margin: "0 auto" }}
                           >
                              <button
                                 type="button"
                                 onClick={onReset}
                                 className="btn btn-danger btn-block"
                              >
                                 Fechar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />

         <ToastContainer />
      </div>
   );
}

export default CommissionAgent;
