import React, { useState, useEffect, useMemo } from "react";
import { getCpfs, updateCpf, salvarCpfRemessa } from "../../../services/RemessaCpf";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import TopMenu from "../../../components/topMenu/TopMenu";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../components/modal/Modal";

//plugins
import swal from "sweetalert";
import {  MDBDataTableV5 } from "mdbreact";

function RemessaCpf() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});

  //Dados do filtro:
  const [cpf, setCpf] = useState(null);
  const [errors, setErrors] = useState({});

  //Dados do formulário:
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    getData(null);
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Data Cadastro",
          field: "criado_em",
          width: 100,
        },
        {
          label: "CPF",
          field: "cpf",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Nome",
          },
        },
        {
          label: "Ativo",
          field: "ativo",
          width: 100,
        },
        {
          label: "Ação",
          field: "action",
          width: 50,
        },
      ],
      rows: 
        data &&
        data.dados.map((x) => {
          return {
            criado_em: x.criado_em,
            cpf: x.cpf,
            ativo: x.ativo === 1 ? <span className="badge badge-success">Ativo</span> : <span className="badge badge-danger">Inativo</span>,
            action: (
              <div>
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => inactivate(x.cpf, x.ativo)}
                  >
                    {
                      x.ativo === 1 ? (
                        <>
                          <i className="fas fa-trash fa-lg"></i> Inativar
                        </>
                      ) : (
                        <>
                          <i className="fas fa-check fa-lg"></i> Ativar
                        </>
                      )
                    }
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const getData = async (cpf) => {
    try {
      setData();
      setCpf(cpf);
      setData(await getCpfs(cpf));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  // const onPageChanged = async () => getData(cpf);

  const onClearModal = () => {
    setErrors({});
    setCpf(null);
    window.$("#cpf").val(null);
  };

  const disabledBtnSubmit = useMemo(() => {
    return cpf == null ||
    Object.keys(errors).filter((key) => errors[key]).length > 0;
  }, [errors]);

  const inactivate = (cpf, ativo) => {
    swal({
      title: `${ativo === 1 ? "Inativar o cpf?" : "Ativar o cpf?"}`,
      text: `Deseja realmente ${ativo === 1 ? `inativar o cpf: ${cpf} ?` : `ativar o cpf: ${cpf} ?`}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await updateCpf(cpf, ativo);
        if (retorno.return) {
          setFormLoading(false);
          swal(`Função ${ativo === 1 ? "inativada" : "ativada"} com sucesso.`, {
            icon: "success",
          });
          await getData(null);
        } else {
          setFormLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFormLoading(false);
        swal(`A função não foi ${ativo === 1 ? "inativada" : "ativada"}.`, {
          icon: "info",
        });
      }
    });
  };

  async function adicionar() {
    setFormLoading(true);
    try {
      onClearModal();
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFormLoading(false);
    }
  }

  function handleChange(event) {
    const { id, value } = event.target;
    setCpf(value);
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  }

  function handleBlur(event) {
    let message = "Campo Obrigatório";
    const { id, value } = event.target;
    if (!value) {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
    }
  }

  async function handleSave(event) {
    try {
      event.preventDefault();
      setFormLoading(true);
      const response = await salvarCpfRemessa(cpf);
      if (response) {
        toast.success("CPF Salvo com sucesso.");
        onClearModal();
        await getData(null);
        window.$("#modal-data").modal("hide");
      } else {
        toast.error("Ocorreu um erro, ao salvar o CPF.");
        window.$("#modal-data").modal("hide");
      }
    } catch (err) {
      toast.error(err.erro);
    } finally {
      setFormLoading(false);
    }
  }

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>,
              onClick: adicionar,
            }
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                id="table"
                // onPageChanged={onPageChanged}
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>

    
      {/* Modal Adicionar/editar */}
      <Modal
        id={"modal-data"}
        title={"Adicionar"}
        size={"md"}
        form={(
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                arial-label="close"
              >
                <span arial-hidden="true" className="text-white">x</span>
              </button>
              <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Remessa</h3>
                  </div>
                  <div className="card-body">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="form-group">
                           <label className="ml-4">CPF*</label>
                           <Input
                              id="cpf"
                              className="form-control ml-4"
                              placeholder="Nome..."
                              type="cpf"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={cpf || null}
                              help={errors.cpf || ""}
                           />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           className="btn btn-danger btn-block my-4"
                           data-dismiss="modal"
                           arial-label="close"
                           >
                           Cancelar
                           </button>
                        </div>

                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           onClick={handleSave}
                           disabled={disabledBtnSubmit}
                           className="btn btn-success btn-block my-4"
                           >
                           Salvar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )
         }
      />
      <ToastContainer />
    </div>
  );
}

export default RemessaCpf;
