import React from "react";
import Lottie from "lottie-react";
import loading from "../../loading.json";
import { ContainerBlockScreen, ContainerLoading } from "./styles";

function Loading() {
  return (
      <ContainerLoading>
        <ContainerBlockScreen>
          <Lottie animationData={loading} loop className="animation " />
        </ContainerBlockScreen>
      </ContainerLoading>
  );
}

export default Loading;
