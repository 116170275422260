import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import moment from "moment";
import Modal from "../components/modal/Modal";
import TopMenu from "../components/topMenu/TopMenu";
import { getProposalStatus } from "../services/Proposal";
import {
  getReanalise,
  getReanalises,
  getReanaliseData,
  gravarReanalise,
  exportReanalises,
  getMotivos,
  getReanalisesIndividual,
  exportReanalisesIndividual,
} from "../services/Reanalise";
import Input from "../components/input/Input";
import Loading from "../components/loading/Loading";
import Select from "react-select";
import { listBanks } from "../services/Api";

//helpers
import funcoes from "../utils/helpers/funcoes";

function Reanalise() {
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [page, setPage] = useState(1);
  const [nome, setNome] = useState(null);
  const [status, setStatus] = useState(null);
  const [labelStatus, setLabelStatus] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [historico, setHistorico] = useState(null);
  const [retorno, setRetorno] = useState({});
  const [simulacao, setSimulacao] = useState(null);
  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState({});

  //checkbox
  const [checkProp, setCheckProp] = useState(null);
  const [checkVenda, setCheckVenda] = useState(null);
  const [checkContato, setCheckContato] = useState(null);
  const [motivoProp, setMotivoProp] = useState(null);
  const [motivoVenda, setMotivoVenda] = useState(null);
  const [vendaBanco, setVendaBanco] = useState(null);
  const [valorVenda, setValorVenda] = useState(null);

  //repescagem
  const [bancos, setBancos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [observation, setObservation] = useState(null);

  const selectStatus = useRef();

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    onClear();
    setObservation(null);
    setCheckContato(null);
    setCheckProp(null);
    setCheckVenda(null);
    setId(0);
  };
  const onCloseFilter = async () => {
    window.$("#modal-filter").modal("hide");
    onClearFilter();
    setObservation(null);
    setResult(null);
  };

  const onClear = async () => {
    setResult(null);
    setRetorno(null);
  };
  const onClearFilter = async () => {
    window.$("#nome").val(null);
    window.$("#cpf_filtro").val(null);
    window.$("#status").val(null);
    selectStatus.current.select.clearValue();
    setNome(null);
    setStatus(null);
    setLabelStatus(null);
    setCpf(null);
  };

  const getStatus = async () => {
    try {
      setList(await getProposalStatus());
    } catch (err) {}
  };

  useEffect(() => {
    getData(null, null, null, 1);
    getStatus();
    getBancos();
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Simulação ID",
          field: "simulacao_id",
        },
        {
          label: "Data da Simulação",
          field: "criado_em",
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Nome",
          field: "nome",
        },
        {
          label: "Nascimento",
          field: "nascimento",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Status Simulação",
          field: "status_simulacao",
        },
        {
          label: "Status Proposta",
          field: "status_proposta",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            simulacao_id: x.simulacao_id,
            criado_em: x.criado_em,
            nome: x.nome,
            status_simulacao: x.status_simulacao,
            produto_descricao: x.produto_descricao,
            cpf: x.cpf,
            nascimento: x.nascimento,
            email: x.email,
            status_proposta: (
              <span className="badge badge-dot mr-4">
                <i
                  className={`bg-${
                    [2, 7, 17, 18].includes(x.proposta_status_id)
                      ? "warning"
                      : [12, 20].includes(x.proposta_status_id)
                      ? "gray"
                      : [19].includes(x.proposta_status_id)
                      ? "danger"
                      : "gray"
                  }`}
                ></i>
                <span className="status">{x.status_proposta}</span>
              </span>
            ),
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      setRetorno({});
                      visualizar(x.proposta_id, x.simulacao_id);
                    }}
                  >
                    <i className="fas fa-eye"></i>
                    Visualizar
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  async function getBancos() {
    const data = await listBanks();
    setBancos(data);
  }

  async function visualizar(id, simulacao_id) {
    setFormLoading(true);
    setId(simulacao_id);
    setMotivos(await getMotivos());
    try {
      const response = await getReanaliseData(id ? id : 0, simulacao_id);
      setSimulacao(id ? null : response);
      setHistorico(response.historico);
      setResult(id ? response : null)
      
      const retorno = await getReanalise(simulacao_id);
      setRetorno(retorno);
      setCheckContato(retorno.contato_sucesso);
      setCheckProp(retorno.proposta_efetuada);
      setCheckVenda(retorno.venda_efetuada);
      setMotivoProp(retorno.motivo_proposta);
      setMotivoVenda(retorno.motivo_venda);
      setValorVenda(retorno.valor_venda_banco && retorno.valor_venda_banco.replace('R$ ', ''));
      setVendaBanco(retorno.venda_banco);
      setObservation(retorno.observacao);
      window.$(`#modal-${id ? 'data' : 'simulacao'}`).modal("show");
      
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
      window.$("#modal-simulacao").modal("hide");
    } finally {
      setFormLoading(false);
    }
  }

  const handleChange = async (event) => {
    const { id, value } = event.target;
    switch (id) {
      case "observacao":
        setObservation(value || "");
        break;
      default:
        break;
    }
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(status, nome, cpf, 1);
  };

  const handleExport = async () => {
    swal({
      title: "Exportar Reanálise?",
      text: "Será exportado todas as reanálise cadastradas no sistema.",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await exportReanalisesIndividual();
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV +=
            `Relatório de Reanálises - ${moment().format("MMMM YYYY")}` +
            "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar as reanalises", {
              icon: "error",
            });
          }

          let nomeArquivo = `reanalise-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFormLoading(false);
          swal("Reanálise exportada com sucesso.", {
            icon: "success",
          });
        } else {
          setFormLoading(false);
          swal("Ocorreu um erro ao exportar as reanálises", {
            icon: "error",
          });
        }
      } else {
        swal("As reanálises não foram exportadas.", {
          icon: "info",
        });
      }
    });
  };

  const onSaveDetails = async (event) => {
    event.preventDefault();
    try {
      setFormLoading(true);
      await gravarReanalise({
        id,
        checkProp,
        checkContato,
        checkVenda,
        observation,
        vendaBanco,
        motivoProp,
        motivoVenda,
        valorVenda,
      });

      toast.success(`Atualizado com sucesso!`);
      window.$("#modal-data").modal("hide");
      window.$("#modal-simulacao").modal("hide");
      await getData(status, nome, cpf, page);
      onReset();
    } catch (err) {
      toast.error("Falha ao atualizar os detalhes da pendência!");
    } finally {
      setFormLoading(false);
    }
  };

  const getData = async (status, nome, cpf, pg = 1) => {
    try {
      setData();
      setStatus(status);
      setNome(nome);
      setCpf(cpf);
      setPage(pg);
      setData(await getReanalisesIndividual(status, nome, cpf, pg));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  const renderCheck = () => {
    return (
      <div className="form-group">
        <label>Sucesso ao entrar em contato?</label>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="checkContatoFalse"
            className="custom-control-input"
            onChange={(e) => {
              setCheckContato(e.target.value === 'false' ? false : true);
            }}
            value={false}
            checked={checkContato === false && true}
          />
          <label className="custom-control-label" htmlFor="checkContatoFalse">
            Não
          </label>
        </div>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="checkContatoTrue"
            className="custom-control-input"
            onChange={(e) => {
              setCheckContato(e.target.value === 'false' ? false : true);
            }}
            checked={checkContato === true && true}
            value={true}
          />
          <label className="custom-control-label" htmlFor="checkContatoTrue">
            Sim
          </label>
        </div>
      </div>
    );
  };

  const renderCheckProp = () => {
    if (checkContato === true) {
      return (
        <div className="col-md-4">
          <div className="form-group">
            <label>Proposta aprovada?</label>
            <div className="custom-control custom-checkbox">
              <input
                id="checkPropostaFalse"
                type="checkbox"
                className="custom-control-input"
                onChange={(e) =>{setCheckProp(e.target.value === 'false' ? false : true);}}
                checked={checkProp === false && true}
                value={false}
              />
              <label className="custom-control-label" htmlFor="checkPropostaFalse">
                Não
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkPropostaTrue"
                type="checkbox"
                className="custom-control-input"
                onChange={(e) =>{setCheckProp(e.target.value === 'true' ? true : false);}}
                checked={checkProp === true && true}
                value={true}
              />
              <label className="custom-control-label" htmlFor="checkPropostaTrue">
                Sim
              </label>
            </div>
          </div>
          {checkProp === false ? (
            <div className="form-group">
              <label>Motivo Proposta*</label>
              <Select
                id="motivoProp"
                type="text"
                placeholder="Selecione uma opção..."
                onChange={(e) => {
                  if (e) {
                   
                    setMotivoProp(e.label);
                    setErrors({ ...errors, motivo: null });
                  } else {
                    setErrors({ ...errors, motivo: "Campo Obrigatório" });
                  }
                }}
                options={motivos ?? []}
                value={{
                  label: motivoProp ?? "Selecione um motivo...",
                }}
                backspaceRemovesValue
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  const renderCheckVenda = () => {
    if (checkProp === true && checkContato === true) {
      return (
        <div className="col-md-4">
          <div className="form-group">
            <label>Venda Efetuada?</label>
            <div className="custom-control custom-checkbox">
              <input
                id="checkVendaFalse"
                type="checkbox"
                className="custom-control-input"
                onChange={(e) =>{setCheckVenda(e.target.value === 'false' ? false : true);}}
                checked={checkVenda === false && true}
                value={false}
              />
              <label className="custom-control-label" htmlFor="checkVendaFalse">
                Não
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkVendaTrue"
                type="checkbox"
                className="custom-control-input"
                onChange={(e) =>{setCheckVenda(e.target.value === 'true' ? true : false);}}
                checked={checkVenda === true && true}
                value={true}
              />
              <label className="custom-control-label" htmlFor="checkVendaTrue">
                Sim
              </label>
            </div>
          </div>
          {checkVenda === false ? (
            <div className="form-group">
              <label>Motivo Venda*</label>
              <Select
                id="motivo_venda"
                type="text"
                placeholder="Selecione uma opção..."
                onChange={(e) => {
                  if (e) {
                    setRetorno({
                      motivo_venda: e.label
                    });
                    setMotivoVenda(e.label);
                    setErrors({ ...errors, motivo_venda: null });
                  } else {
                    setErrors({ ...errors, motivo_venda: "Campo Obrigatório" });
                  }
                }}
                value={{
                  label: retorno && retorno.motivo_venda ? retorno.motivo_venda : "Selecione um motivo...",
                }}
                options={motivos ?? []}
              />
            </div>
          ) : (
            <div className="form-group">
              <label>Banco*</label>
              <Select
                id="bancoVenda"
                type="text"
                placeholder="Selecione uma opção..."
                options={bancos ?? []}
                onChange={(e) => {
                  if (e) {
                    setVendaBanco(e.label);
                    setErrors({ ...errors, banco: null });
                  } else {
                    setErrors({ ...errors, banco: "Campo Obrigatório" });
                  }
                }}
                value={{
                  label: vendaBanco ?? "Selecione o banco...",
                }}
                backspaceRemovesValue
              />
              <div className="form-group">
                <label>Valor*</label>
                <Input
                  id="valorVenda"
                  type="money"
                  onChange={(e) => {
                    if (e) {
                      setValorVenda(e.target.value);
                      setErrors({ ...errors, banco: null });
                    } else {
                      setErrors({ ...errors, banco: "Campo Obrigatório" });
                    }
                  }}
                  value={valorVenda}
                  className="form-control"
                />
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      <Menu />
      <div className="main-content" id="panel">
        <TopMenu />
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Exportar CSV",
              icone: (
                <i className="fas fa-file-csv" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: handleExport,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      <Modal
        id={"modal-data"}
        title={"Reanálise - Visualizar"}
        size={"xl"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados do Cliente</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {result &&
                    result.cliente.blocos.map((x) => {
                      if (
                        x.id !== "numero_beneficio" &&
                        x.id !== "cartao_magnetico"
                      ) {
                        return (
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>{x.nome}</label>
                              <Input
                                id={x.id}
                                type="text"
                                className="form-control"
                                value={x.valor}
                                disabled={true}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados da Proposta</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {result &&
                    result.proposta.blocos.map((x) => {
                      if (
                        x.id !== "valor_imposto" &&
                        x.id !== "comissao_seucredito" &&
                        x.id !== "comissao" &&
                        x.id !== "comissao_master" &&
                        x.id !== "valor_liquido"
                      ) {
                        if (x.id === "data_integracao") {
                          return (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{x.nome}</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="ni ni-calendar-grid-58"></i>
                                    </span>
                                  </div>
                                  <Input
                                    id={x.id}
                                    type="birthday"
                                    className="form-control"
                                    value={x.valor}
                                    help={errors.data_integracao || ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{x.nome}</label>
                                <Input
                                  id={x.id}
                                  type="text"
                                  className="form-control"
                                  value={x.valor}
                                  disabled
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                </div>
              </div>
            </div>
            {result &&
              result.pendencia.map((x) => (
                <div className="card mb-4">
                  <div className="card-header">
                    <h3 className="mb-0">Pendências</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label style={{ color: "red" }}>Status*</label>
                          <Input
                            id={x.id}
                            type="text"
                            className="form-control"
                            value={x.status}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Data da Integração</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="ni ni-calendar-grid-58"></i>
                              </span>
                            </div>
                            <Input
                              id={x.id}
                              type="birthday"
                              className="form-control"
                              value={x.data_integracao}
                              help={errors.data_integracao || ""}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group">
                          <label style={{ color: "red" }}>Descrição*</label>
                          <Input
                            id={x.id}
                            type="text"
                            className="form-control"
                            value={x.descricao}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Repescagem</h3>
                <h6>
                  *Obrigatório o preenchimento de todos os campos e os motivos,
                  se houver.
                </h6>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">{renderCheck()}</div>
                  {renderCheckProp()}
                  {renderCheckVenda()}
                </div>

                <div className="form-group">
                  <h3>Observações</h3>
                  <div className="input-group">
                    <textarea
                      id="observacao"
                      name="observacao"
                      className="form-control"
                      aria-label="With textarea"
                      onChange={handleChange}
                      value={observation || ""}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-2 mt-2">Histórico da Reanálise</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    <table className="table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Atualizado em </th>
                          <th scope="col">Atualizado Por</th>
                          <th scope="col">Observação</th>
                          <th scope="col">Proposta Reprovada</th>
                          <th scope="col">Não Venda</th>
                          <th scope="col">Banco ID</th>
                          <th scope="col">Valor</th>
                        </tr>
                      </thead>
                      {result && result.historico.length > 0 ? (
                        result.historico.map((x,index) => (
                          <tbody className="list" key={index}>
                            <tr>
                              <td className="budget">{x.atualizado_em}</td>
                              <td className="budget">{x.criado_por}</td>
                              <td className="budget">{x.observacao}</td>
                              <td className="budget">{x.motivo_proposta}</td>
                              <td className="budget">{x.motivo_venda}</td>
                              <td className="budget">{x.venda_banco}</td>
                              <td className="budget">{x.valor_venda_banco ? 'R$ ' + funcoes.formataMoeda(x.valor_venda_banco) : ''}</td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody className="list" key={0}>
                          <tr>
                          <td className="budget text-center" colSpan={6}>
                            <span>Nenhum registro encontrado...</span>
                          </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Histórico Proposta</h3>
              </div>
              <div className="card-body">
                <div className="form-group mt-2">
                  <h3>Observação da Proposta</h3>
                  <div className="input-group">
                    {result &&
                      result.anotacao.map((x) => {
                        if (x.anotacao_auditoria !== null) {
                          return (
                            <textarea
                              id="obs"
                              className="form-control"
                              aria-label="With textarea"
                              value={x.anotacao_auditoria}
                              disabled
                            ></textarea>
                          );
                        } else {
                          return (
                            <textarea
                              id="obs"
                              className="form-control"
                              aria-label="With textarea"
                              value={
                                "Sem observações adicionadas a essa proposta.."
                              }
                              disabled
                            ></textarea>
                          );
                        }
                      })}
                  </div>
                </div>
                <div className="table-responsive">
                  <div>
                    <table className="table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Atualizado em</th>
                          <th scope="col">Atualizado Por</th>
                          <th scope="col">Status</th>
                          <th scope="col">Observação</th>
                          <th scope="col">Proposta</th>
                          <th scope="col">Data Integração</th>
                          {result &&
                            result.historico_proposta.length > 0 &&
                            result.historico_proposta[0].colunas.map((x) => {
                              if (
                                x.id !== "valor_imposto" &&
                                x.id !== "comissao_seucredito" &&
                                x.id !== "comissao" &&
                                x.id !== "comissao_master"
                              ) {
                                return <th scope="col" key={x.id}>{x.nome}</th>;
                              }
                            })}
                        </tr>
                      </thead>
                      <tbody className="list">
                        {result &&
                          result.historico_proposta.map((x) => (
                            <tr>
                              <td className="budget">{x.atualizado_em}</td>
                              <td className="budget">{x.atualizado_por}</td>
                              <td className="budget">{x.status}</td>
                              <td className="budget" title={x.observacao}>
                                {x.observacao && x.observacao !== ""
                                  ? x.observacao
                                  : ""}
                              </td>
                              <td className="budget">{x.proposta}</td>
                              <td className="budget">{x.data_integracao}</td>
                              {x.colunas &&
                                x.colunas.map((y) => {
                                  if (
                                    y.id !== "valor_imposto" &&
                                    y.id !== "comissao_seucredito" &&
                                    y.id !== "comissao" &&
                                    y.id !== "comissao_master"
                                  ) {
                                    return (
                                      <td className="budget">{y.valor}</td>
                                    );
                                  }
                                })}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onReset}
                >
                  Fechar
                </button>
                <button
                  type="button"
                  onClick={onSaveDetails}
                  className="btn btn-primary"
                  // disabled={disabledBtn}
                >
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* Modal de simulacoes */}
      <Modal
        id={"modal-simulacao"}
        title={"Reanálise - Visualizar"}
        size={"xl"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados da simulação do Cliente</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {simulacao &&
                    simulacao.simulacao.produto_id != "5" &&
                    simulacao.simulacao.blocos.map((x) => {
                      if (x.id !== "saldo_fgts" && x.id !== "modelo_saque") {
                        return (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{x.nome}</label>
                              <Input
                                id={x.id}
                                type="text"
                                className="form-control"
                                value={x.valor}
                                disabled={true}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}

                  {/* FGTS */}
                  {simulacao &&
                    simulacao.simulacao.produto_id === "5" &&
                    simulacao.simulacao.blocos.map((x) => (
                      <div className="col-md-4 form-group" key={x.id}>
                          <label>{x.nome}</label>
                          <Input
                            id={x.id}
                            type="text"
                            className="form-control"
                            value={x.valor}
                            disabled={true}
                          />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Repescagem</h3>
                <h6>
                  *Obrigatório o preenchimento de todos os campos e os motivos,
                  se houver.
                </h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">{renderCheck()}</div>
                  {renderCheckProp()}
                  {renderCheckVenda()}
                </div>
                <div className="form-group">
                  <h3>Observações</h3>
                  <div className="input-group">
                    <textarea
                      id="observacao"
                      name="observacao"
                      className="form-control"
                      aria-label="With textarea"
                      onChange={handleChange}
                      value={observation || ""}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-2 mt-2">Histórico da Reanálise</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    <table className="table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Atualizado em</th>
                          <th scope="col">Atualizado Por</th>
                          <th scope="col">Observação</th>
                          <th scope="col">Proposta Reprovada</th>
                          <th scope="col">Não Venda</th>
                          <th scope="col">Banco ID</th>
                          <th scope="col">Valor</th>
                        </tr>
                      </thead>
                      {historico && historico.length > 0 ? (
                        historico.map((x,index) => (
                          <tbody className="list" key={index}>
                            <tr>
                              <td className="budget">{x.atualizado_em}</td>
                              <td className="budget">{x.criado_por}</td>
                              <td className="budget">{x.observacao}</td>
                              <td className="budget">{x.motivo_proposta}</td>
                              <td className="budget">{x.motivo_venda}</td>
                              <td className="budget">{x.venda_banco}</td>
                              <td className="budget">{x.valor_venda_banco ? 'R$ ' + funcoes.formataMoeda(x.valor_venda_banco) : ''}</td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody className="list" key={0}>
                          <tr>
                          <td className="budget text-center" colSpan={7}>
                            <span>Nenhum registro encontrado...</span>
                          </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onReset}
                >
                  Fechar
                </button>
                <button
                  type="button"
                  onClick={onSaveDetails}
                  className="btn btn-primary"
                  // disabled={disabledBtn}
                >
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        }
      />

      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onCloseFilter}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados a serem Filtrados</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group mx-4">
                    <Input
                      id="nome"
                      className="form-control"
                      placeholder="Nome..."
                      type="text"
                      value={nome}
                      onChange={(e) => setNome(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group mx-4">
                    <Input
                      id="cpf_filtro"
                      className="form-control"
                      placeholder="CPF..."
                      type="cpfCnpj"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value || null)}
                    />
                  </div>
                </div>

                    <div className="form-group mb-3">
                        <Select
                          id="status"
                          ref={selectStatus}
                          className="select-form-control"
                          placeholder="Status Proposta..."
                          onChange={(e) => {if(e){setStatus(e.value || null); setLabelStatus(e.label || null)}}}
                          options={list}
                          value={{label: labelStatus ?? "Status da Proposta..."}}
                        />
                    </div>


                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClearFilter}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
export default Reanalise;
