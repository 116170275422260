import React, { useState, useEffect, useMemo } from "react";
import {
  getFunctions,
  recordFunction,
  inactivateFunction,
} from "../../../services/Users";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import TopMenu from "../../../components/topMenu/TopMenu";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../components/modal/Modal";

//plugins
import swal from "sweetalert";
import {  MDBDataTableV5 } from "mdbreact";

function AgentFunction(props) {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [page, setPage] = useState(1);
  //Dados do filtro:
  const [nome, setNome] = useState(null);
  const [nomeFuncao, setNomeFuncao] = useState(null);
  const [nomeModal, setNomeModal] = useState(null);
  const [dados, setDados] = useState({});
  const [errors, setErrors] = useState({});

  //Dados do formulário:
  const [formLoading, setFormLoading] = useState(false);
  const [fullLoading, setFullLoading] = useState(false);

  const [idFuncao, setIdFuncao] = useState(null);

  useEffect(() => {
    getData(null, 1);
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Id",
          field: "id",
          width: 100,
        },
        {
          label: "Nome",
          field: "nome",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Nome",
          },
        },
        {
          label: "Ação",
          field: "action",
          width: 50,
        },
      ],
      rows: 
        data &&
        data.dados.map((x) => {
          return {
            nome: x.nome,
            id: x.id,
            action: (
              <div>
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => editar(x)}
                  >
                    <i className="fas fa-user-edit"></i>
                    Editar
                  </button>
  
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => inactivate(x.id, x.nome)}
                  >
                    <i className="fas fa-trash fa-lg"></i>
                    Deletar
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
  };
  const getData = async (nome, pg = 1) => {
    try {
       setData();
      setNome(nome);
      setPage(pg);
      setData(await getFunctions(nome, page));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };
  // const onPageChanged = async (pg) => getData(nome, pg);

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    onClear();
    getData(nome, 1);
  };

  const onClear = () => {
    setNome(null);
    window.$("#funcao").val(null);
  };

  const onClearModal = () => {
    setErrors({});
    setDados({});
    setNome(null);
    window.$("#funcao").val(null);
  };

  const disabledBtnSubmit = useMemo(() => {
    return nomeFuncao == null ||
    Object.keys(errors).filter((key) => errors[key]).length > 0;
  }, [errors]);

  const inactivate = (id, nome) => {
    swal({
      title: "Inativar Função?",
      text: `Deseja inativar a função: ${nome}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await inactivateFunction(id);
        if (retorno.return) {
          setFullLoading(false);
          swal("Função inativado com sucesso.", {
            icon: "success",
          });
          await getData(null, 1);
        } else {
          setFullLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFullLoading(false);
        swal("A função não foi inativado.", {
          icon: "info",
        });
      }
    });
  };

  async function adicionar() {
    onClearModal();
    setFormLoading(true);
    setNomeModal("Adicionar");
    setNomeFuncao(null);
    setIdFuncao(null);
    try {
      setData(await getFunctions());
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFormLoading(false);
    }
  }

  const editar = async (x) => {
    setFormLoading(true);
    setNomeModal("Editar - " + x.nome);
    try {
      setNomeFuncao(x.nome);
      setIdFuncao(x.id);
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFormLoading(false);
    }
  };
  function handleChange(event) {
    const { id, value } = event.target;
    setNomeFuncao(event.target.value);
    setDados({ ...dados, [id]: value, funcao_id: idFuncao });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  }

  function handleBlur(event) {
    let message = "Campo Obrigatório";
    const { id, value } = event.target;
    if (!value) {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
    }
  }

  async function handleSave(event) {
    try {
      event.preventDefault();
      setFormLoading(true);
      const response = await recordFunction(dados);
      if (response) {
        toast.success("Funcão Salva com sucesso.");
        onClearModal();
        await getData(null, 1);
        window.$("#modal-data").modal("hide");
      } else {
        toast.error("Ocorreu um erro, ao salvar função.");
        window.$("#modal-data").modal("hide");
      }
    } catch (err) {
      toast.error(err.erro);
    } finally {
      setFormLoading(false);
    }
  }

  return (
    <div>
      {fullLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>,
              onClick: adicionar,
            }
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                id="table"
                // onPageChanged={onPageChanged}
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"sm"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span arial-hidden="true" className="text-white" onClick={onReset}>
                x
              </span>
            </button>

            <div className="form-group mb-3">
              <div className="input-group input-group-merge input-group-alternative">
                <Input
                  id="funcao"
                  className="form-control"
                  placeholder="Nome da função..."
                  type="text"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center">
                <button
                  type="button"
                  onClick={onClear}
                  className="btn btn-danger btn-block my-4"
                >
                  Limpar
                </button>
              </div>

              <div className="col-md-6 text-center">
                <button
                  type="button"
                  onClick={onFilter}
                  className="btn btn-primary btn-block my-4"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal Adicionar/editar */}
      <Modal
        id={"modal-data"}
        title={nomeModal}
        size={"md"}
        form={
          formLoading ? (
            <div>
              <Loading />
            </div>
          ) : (
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                arial-label="close"
              >
                <span arial-hidden="true">x</span>
              </button>
              <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Função</h3>
                  </div>
                  <div className="card-body">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="form-group">
                           <label className="ml-4">Nome da Função*</label>
                           <Input
                              id="funcao"
                              className="form-control ml-4"
                              placeholder="Nome..."
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={nomeFuncao || dados.nome || null}
                              help={errors.funcao || ""}
                           />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           className="btn btn-danger btn-block my-4"
                           data-dismiss="modal"
                           arial-label="close"
                           >
                           Cancelar
                           </button>
                        </div>

                        <div className="col-md-6 text-center">
                           <button
                           type="button"
                           onClick={handleSave}
                           disabled={disabledBtnSubmit}
                           className="btn btn-success btn-block my-4"
                           >
                           Salvar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          )
        }
      />
      <ToastContainer />
    </div>
  );
}

export default AgentFunction;
