import moment from "moment";
import { Store } from "../../redux/Store"
import instance from "../index";

export const listarMailing = async (datacadastro_mai, codigo_usu, qtdesolicitada_sol, qtdeliberada_sol, codigo_stm, dataatendimento_mai, pagina) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_mailing"
         const response = await instance.post(
            url,
            { datacadastro_mai, codigo_usu, qtdesolicitada_sol, qtdeliberada_sol, codigo_stm, dataatendimento_mai, pagina },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response)
         } else {
            reject(response)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const buscarMailing = async (codigo_mai, codigo_usu) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/buscar_mailing"
         const response = await instance.post(
            url,
            { codigo_mai, codigo_usu },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const listarStatusMailing = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_status_mailing"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const buscarConfiguracaoMailing = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/buscar_configuracao_mailing"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const listarSegmentosCnae = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_segmentos_cnae"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const gravarSolicitacao = (register) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/gravar_solicitacao"
         const response = await instance.post(
            url,
            { dados: register },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const gravarAtendimento = (register) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = "crm_unificado/administrativo/gravar_atendimento"
         const response = await instance.post(
            url,
            { dados: register },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const cancelarMailing = (codigo_mai) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = `crm_unificado/administrativo/cancelar_mailing?codigo_mai=${codigo_mai}`
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const buscarSolicitacaoPrazo = (codigo_mai) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = `crm_unificado/administrativo/buscar_prazo_mailing?codigo_mai=${codigo_mai}`
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const recusarPrazoMailing = (codigo_mpe, codigo_mai, observacaoatendimento_mpe) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = 'crm_unificado/administrativo/recusar_prazo_mailing'
         const response = await instance.post(
            url,
            { codigo_mpe, codigo_mai, observacaoatendimento_mpe },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const aprovarPrazoMailing = (codigo_mpe, codigo_mai, observacaoatendimento_mpe, prazo_mpe) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = 'crm_unificado/administrativo/aprovar_prazo_mailing'
         const response = await instance.post(
            url,
            { codigo_mpe, codigo_mai, observacaoatendimento_mpe, prazo_mpe },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}