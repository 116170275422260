import styled from 'styled-components'

export const ContainerBlockScreen = styled.div`
   position: fixed ;
   background-color: rgba(0,0,0,0.7);
   top: 0;
   left: 0;
   width: 100vw;
   min-height: 100vh;
   height: 100%;
   z-index: 9999;
`

export const ContainerLoading = styled.div`
   flex: 1;
   display: flex;
   justify-content: center ;
   align-items: center ;
   .animation{
      background: transparent;
      margin-bottom: 7rem;
      height: 30rem;
   }
`