import { Store } from "../redux/Store"
import instance from "./index";

export const getClients = async (nome, cpf, pagina, dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "leads/administrativo/listar-clientes";
      const response = await instance.post(
        url,
        {
          nome,
          cpf,
          pagina, 
          dataInicio,
          dataFim
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getClient = async (id) => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
  
        const url = `leads/administrativo/buscar-cliente?&id=${id}`;
        const response = await instance.get(
          url,
          { headers: { token } }
        );
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const exportClients = async (nome, cpf, dataInicio, dataFim) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = "leads/administrativo/exportar-clientes"
        const response = await instance.post(
          url,
          { nome, cpf, dataInicio, dataFim },
          { headers: { token } }
        )
        
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const getLeadsConsorcio = async () => {
   return new Promise(async (resolve, reject) => {
     try { 
       const token = Store.getState().user.token;
 
       const url = "leads/administrativo/listar-leads-consorcio";
       const response = await instance.get(
         url,
         { headers: { token } }
       );
 
       if (response.status === 200) {
         resolve(response.data)
       } else {
         reject(response.data)
       }
     } catch (err) {
       reject(err)
     }
   })
 }
 
export const getPreSimulation = async (id) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = `leads/administrativo/buscar-pre-simulacoes?&id=${id}`;
      const response = await instance.get(
        url,
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getSolicitacoesExclusao = async () => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = 'clientes/administrativo/buscar_solicitacoes_exclusao';
      const response = await instance.get(
        url,
        { headers: { token } }
      );
      const { status, data } = response;
      if (status === 200 && data.length > 0) {
        resolve(data[0].solicitacoes)
      } else {
        reject(data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const finalizarSolicitacoesExclusao = async (id) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = 'clientes/administrativo/finalizar_solicitacoes_exclusao';
      const response = await instance.post(
        url,
        { id },
        { headers: { token } }
      );
      const { status, data } = response;
      if (status === 200 && data.length > 0) {
        resolve(data[0])
      } else {
        reject(data)
      }
    } catch (err) {
      reject(err)
    }
  })
}


export const sendImportLeads = async base64 => {
  return new Promise(async (resolve, reject) => {
      try {
          const token = Store.getState().user.token

          const url = "clientes/importar";
          const response = await instance.post(
              url,
              { arquivo: base64 },
              { headers: { token } }
          )
          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}