import { Store } from "../redux/Store"
import instance from "./index";
import moment from "moment";

export const getAgentsComission = async (nome, cpf, pagina, dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "agentes/administrativo/listar-comissao-agentes"
      const response = await instance.post(
        url,
        {
          nome,
          cpf,
          pagina,
          dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
          dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getAgentComissions = async (id, dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "agentes/administrativo/buscar-comissoes-agente"
      const response = await instance.post(
        url,
        {
          id,
          dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
          dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const exportComissionsAgent = async (dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "agentes/administrativo/exportar-comissoes-agentes"
      const response = await instance.post(
        url,
        {
          dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
          dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getAnalyctsBank = async (banco_id, produto_id, usuario, dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "propostas/administrativo/buscar-contratos-banco"
      const response = await instance.post(
        url,
        {
          banco_id, usuario, produto_id,
          dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
          dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const updateProposalValidate = async (proposta, validacao) => {
  return new Promise(async (resolve, reject) => {

      try {
          const token = Store.getState().user.token
          const url = "propostas/administrativo/atualizar-validacao"
          const response = await instance.post(
              url,
              { proposta, validacao: !validacao },
              { headers: { token } }
          )

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}

export const sendValidationBanks = async (base64, fileName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "comissoes/processar/validacao-bancos"
      const response = await instance.post(
        url,
        { arquivo: base64, nomeArquivo:fileName },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response)
      } else {
        reject(response)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const listarValidationBanks = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token
      const url = 'comissoes/listar/validacao-bancos'
      const response = await instance.get(
        url,
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}


export const getAtributosComission = async () => {
  return new Promise(async (resolve, reject) => {
      try {
      const token = Store.getState().user.token

      const url = "comissoes/atributos/listar"
      const response = await instance.get(url, { headers: { token } })

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}