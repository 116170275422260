import { Store } from "../redux/Store"
import instance from "./index";

//Busca os bancos contidos na tabela price
export const getBanks = async (
    pagina
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        
        const url = "bancos/listar_price"
        const response = await instance.post(
          url,
          { pagina },
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  //Busca as taxas, parcelas dos bancos contidos na price
  export const getDetailsBank = async (
    id, tipoContrato
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        const url = "bancos/buscar-detalhes"
        const response = await instance.post(
          url,
          { id, tipoContrato },
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  //busca os contratos do consignado
  export const getContractsType = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        
        const url = "tipos_contrato/listar"
        const response = await instance.get(
          url,
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  //Responsavel por salvar as configurações do simulador informadas pelo usuário
  export const saveConfigsBank = async (
    data
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        const url = "bancos/salvar-configuracoes-simulador"
        const response = await instance.post(
          url,
          data,
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  //Responsavel por retornar as regras do simulador cadastradas pelo usuário
  export const getRulesBank = async (id, pagina) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = "bancos/listar-regras?id=" + id + "&pagina=" + pagina
        const response = await instance.get(url, { headers: { token } })
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }
  
  //Responsavel por inativar à selecionada pelo usuário
  export const inactivateRule = async (
    id
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        const url = "bancos/inativar-regra"
        const response = await instance.post(
          url,
          { id },
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }