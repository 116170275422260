import React, { useState, useEffect, useMemo } from "react";
import {
  getConfigurationsFgts,
  saveRulesFgts,
  inactivate,
} from "../services/Fgts";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";

import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import Input from "../components/input/Input";

//plugins
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";

//helpers
import funcoes from "../utils/helpers/funcoes";

function Fgts() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [register, setRegister] = useState({});
  const [page, setPage] = useState(1);

  //Dados do formulário:
  const [fullLoading, setFullLoading] = useState(false);
  const [title, setTitle] = useState("Adicionar");
  const [valor, setValor] = useState(null);
  const [aliquota, setAliquota] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getData(1, null, null);
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Data",
          field: "criado_em",
        },
        {
          label: "Valor",
          field: "valor",
        },
        {
          label: "Parcela",
          field: "valor_parcela",
        },
        {
          label: "Alíquota",
          field: "aliquota_formatada",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            criado_em: x.criado_em,
            valor: x.valor,
            valor_parcela: x.valor_parcela,
            aliquota_formatada: x.aliquota_formatada,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    onClick={() => onEditClick(x)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-pencil-alt fa-lg"></i>
                    Alterar
                  </button>
                  <button
                    type="button"
                    onClick={() => inactivateRule(x.fgts_id)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-trash fa-lg"></i>
                    Excluir
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const getData = async (pg = 1, valor, aliquota) => {
    try {
      setPage(pg);
      setValor(valor);
      setAliquota(aliquota);

      setData(await getConfigurationsFgts(valor, aliquota, page));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  // const onPageChanged = async (pg) => getData(pg, valor, aliquota);

  const onEditClick = async (dados) => {
    setFullLoading(true);
    try {
      setTitle("Editar Regra FGTS");
      dados.aliquota = dados.aliquota_formatada;
      setRegister(dados);
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do agente!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFullLoading(false);
    }
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(1, funcoes.formatarMoedaBanco(valor), aliquota);
  };

  const onClear = () => {
    setValor(null);
    setAliquota(null);
    window.$("#filter_valor").val(null);
    window.$("#filter_aliquota").val(null);
  };

  const onReset = async () => {
    onClear();
    window.$("#modal-filter").modal("hide");
  };

  const onClearModal = () => {
    setRegister({});
    setErrors({});
    window.$("#valor").val(null);
    window.$("#valor_parcela").val(null);
    window.$("#aliquota").val(null);
  };

  const onResetModal = async () => {
    onClearModal();
    window.$("#modal-data").modal("hide");
  };

  const disabledBtnSubmit = useMemo(() => {
    return (
      Object.keys(register).filter((key) => register[key]).length < 3 ||
      Object.keys(errors).filter((key) => errors[key]).length > 0
    );
  }, [errors,register]);

  function handleChange(event) {
    const { id, value } = event.target;
    setRegister({ ...register, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  }

  function handleBlur(event) {
    let message = "Campo Obrigatório";
    const { id, value } = event.target;

    if (!value) {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
    }
  }

  async function handleSave(event) {
    try {
      event.preventDefault();
      setFullLoading(true);
      const response = await saveRulesFgts(register);
      if (response && response.id) {
        toast.success("Salvo com sucesso.");
        onClearModal();
        await getData(1, null, null);
        window.$("#modal-data").modal("hide");
      } else {
        toast.error("Ocorreu um erro, ao salvar à regra.");
      }
    } catch (err) {
      toast.error(err.erro);
    } finally {
      setFullLoading(false);
    }
  }

  function adicionar() {
    try {
      onClearModal();
      setFullLoading(true);
      setTitle("Adicionar");
      window.$("#modal-data").modal("show");
    } catch (err) {
      window.$("#modal-data").modal("hide");
    } finally {
      setFullLoading(false);
    }
  }

  const inactivateRule = (id) => {
    swal({
      title: "Inativar usuário?",
      text: "Deseja inativar à regra",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await inactivate(id);
        if (retorno.return) {
          setFullLoading(false);
          swal("Regra inativado com sucesso.", {
            icon: "success",
          });

          await getData(1, null, null);
        } else {
          setFullLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFullLoading(false);
        swal("A regra não foi inativada.", {
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {fullLoading && (
          <Loading />
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>,
              onClick: adicionar,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
            <div>
               <button
               type="button"
               className="close"
               data-dismiss="modal"
               arial-label="close"
               >
               <span arial-hidden="true" className="text-white" onClick={onReset}>
                  x
               </span>
               </button>
               <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Fgts</h3>
                  </div>
                  <div className="card-body">
                     <div className="form-group mb-3 ml-4">
                     <label>Valor</label>
                     <div className="input-group ">
                        <Input
                           id="filter_valor"
                           className="form-control "
                           placeholder="Valor..."
                           type="money"
                           value={valor}
                           onChange={(e) => setValor(e.target.value || null)}
                        />
                     </div>
                     </div>

                     <div className="form-group mb-3  ml-4">
                     <label>Alíquota</label>
                     <div className="input-group ">
                        <Input
                           id="filter_aliquota"
                           className="form-control"
                           placeholder="Aliquota..."
                           type="number"
                           value={aliquota}
                           onChange={(e) => setAliquota(e.target.value || null)}
                        />
                     </div>
                     </div>

                     <div className="row">
                        <div className="col-md-6 text-center">
                           <button
                              type="button"
                              onClick={onClear}
                              className="btn btn-danger btn-block my-4"
                           >
                              Limpar
                           </button>
                        </div>

                        <div className="col-md-6 text-center">
                           <button
                              type="button"
                              onClick={onFilter}
                              className="btn btn-success btn-block my-4"
                           >
                              Filtrar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
      />

      {/* Modal Data */}
      <Modal
        id={"modal-data"}
        title={title}
        size={"sz"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span arial-hidden="true" className="text-white" onClick={onReset}>
                x
              </span>
            </button>
            <div className="card mb-4">
               <div className="card-header">
                  <h3 className="mb-0">Fgts</h3>
               </div>
               <div className="card-body">
                  <div className="form-group mb-3">
                  <label htmlFor="valor" className="ml-4">Valor</label>
                  <div className="input-group ">
                     <Input
                        id="valor"
                        className="form-control ml-4"
                        placeholder="Valor..."
                        type="money"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.valor || null}
                        help={errors.valor || ""}
                     />
                  </div>
                  </div>

                  <div className="form-group mb-3">
                  <label htmlFor="valor_parcela" className="ml-4">Valor Parcela</label>
                  <div className="input-group ">
                     <Input
                        id="valor_parcela"
                        className="form-control ml-4"
                        placeholder="Valor parcela..."
                        type="money"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.valor_parcela || null}
                        help={errors.valor_parcela || ""}
                     />
                  </div>
                  </div>

                  <div className="form-group mb-3">
                  <label htmlFor="aliquota" className="ml-4">Alíquota</label>
                  <div className="input-group ">
                     <Input
                        id="aliquota"
                        className="form-control ml-4"
                        placeholder="Alíquota..."
                        type="money"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.aliquota || null}
                        help={errors.aliquota || ""}
                     />
                  </div>
                  </div>

                  <div className="row">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           onClick={onResetModal}
                           className="btn btn-danger btn-block my-4"
                        >
                           Fechar
                        </button>
                     </div>

                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           onClick={handleSave}
                           disabled={disabledBtnSubmit}
                           className="btn btn-success btn-block my-4"
                        >
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default Fgts;
