import { Store } from "../redux/Store"
import instance from "./index";

export const getProposals = async (nome, cpf, status, pagina, proposta,parceiro, dataInicio, dataFim) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = "propostas/administrativo/listar"
        const response = await instance.post(
          url,
          { nome, cpf, status, pagina, proposta,parceiro, dataInicio, dataFim },
          { headers: { token } }
        )
        
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}

export const getProposalStatus = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = "propostas/administrativo/status/listar"
        const response = await instance.get(url, { headers: { token } })
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}

export const getProposalData = async id => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = `propostas/administrativo/buscar?proposta=${id}`
        const response = await instance.get(url, { headers: { token } })
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}

export const getProposalClientData = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = `propostas/administrativo/buscar-dados-cliente-proposta?proposta=${id}`
      const response = await instance.get(url, { headers: { token } })

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getContract = async (id, proposta, cliente, enviarDocumento) => {
  try {
    const token = Store.getState().user.token

    const url = `propostas/administrativo/buscar-contrato?contrato=${id}&proposta=${proposta}&cliente=${cliente}&enviar=${enviarDocumento}`
    const response = await instance.get(url, { headers: { token } })

    if (response.status === 200) {
      return response.data
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export const getCep = async (cep) => {
  try {
    const token = Store.getState().user.token

    const url = `/ceps/buscar?cep=${cep}`
    const response = await instance.get(url, { headers: { token } })

    if (response.status === 200) {
      return response.data
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export const getStates = async () => {
  try {
    const token = Store.getState().user.token

    const url = '/estados/listar'
    const response = await instance.get(url, { headers: { token } })
    
    if (response.status === 200) {
      return response.data
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export const getCitys = async (estado) => {
  try {
    const token = Store.getState().user.token

    const url = `/cidades/listar?estado=${estado}`
    const response = await instance.get(url, { headers: { token } })
    
    if (response.status === 200) {
      return response.data
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export const updateProposal = async (proposta, campos) => {
    return new Promise(async (resolve, reject) => {

        try {
            const token = Store.getState().user.token

            let aux = campos

            if (campos.data_integracao) {
                aux = {
                ...campos,
                }
            }

            const url = "propostas/administrativo/atualizar"
            const response = await instance.post(
                url,
                { proposta, campos: aux },
                { headers: { token } }
            )

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const atualizarPropostaInterna = async (proposta, campos) => {
  return new Promise(async (resolve, reject) => {

      try {
          const token = Store.getState().user.token
          const url = "propostas/administrativo/atualizar_interno"
          const response = await instance.post(
              url,
              { proposta, campos },
              { headers: { token } }
          )

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}

export const updateProposalAgibank = async (proposta, data) => {
  return new Promise(async (resolve, reject) => {
      try {
          const token = Store.getState().user.token

          const url = "propostas/administrativo/atualizar-proposta-agibank"
          const response = await instance.post(
              url,
              { proposta, data },
              { headers: { token } }
          )

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}

export const getObservations = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = Store.getState().user.token

            const url = "propostas/administrativo/observacoes/listar"
            const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

//Busca os bancos contidos na tabela price
export const getBanks = async (
    pagina
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
        
        const url = "bancos/listar_price"
        const response = await instance.post(
          url,
          { pagina },
          { headers: { token } }
        )
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}

// Recalcular a comissão do agente
export const calcularComissao = async (
  valor,
  valor_parcela,
  taxa,
  parcela,
  regra,
  banco,
  convenio,
  tipo_contrato,
  seguro,
  criado_por,
  produto
) => {
  try {
    const token = Store.getState().user.token
    const url = `propostas/administrativo/${produto == 1 ? 'calcular-comissao' : 'calcular-comissao-auxilio'}`
    const response = await instance.post(
      url,
      { valor, valor_parcela, taxa, parcela, regra, banco, convenio, tipo_contrato, seguro, criado_por, produto },
      { headers: { token } }
    )

    return response.data;
  } catch (err) {
    return err;
  }
}

// Recalcular a comissão do agente
export const recalculateCommissionAgent = async (
  id,
  valor,
  valor_parcela,
  taxa,
  parcela,
  regra,
  banco,
  tipo_contrato,
  criado_por
) => {
  try {
    const token = Store.getState().user.token
    const url = "propostas/recalcular-comissao"
    const response = await instance.post(
      url,
      { id, valor, valor_parcela, taxa, parcela, regra, banco, tipo_contrato, criado_por },
      { headers: { token } }
    )

    return response.data;
  } catch (err) {
    return err;
  }
}

// Recalcular a comissão do agente baseado no produto da proposta
export const recalculateCommissionProductAgent = async (
  id,
  produto,
  dados
) => {
  try {
    const token = Store.getState().user.token
    
    const url = "propostas/recalcular-comissao-produto"
    const response = await instance.post(
      url,
      { id, produto, dados },
      { headers: { token } }
    )

    return response.data;
  } catch (err) {
    return err;
  }
}

//Responsável por fazer upload do documento da proposta na api do banco (agibank ou cetelem)
export const uploadDocumentsProposal = async (
  proposta,
  idResposta,
  banco,
  arquivo,
  tipo
) => {
  try {
    const token = Store.getState().user.token
    
    const url = "propostas/administrativo/upload-documentos-proposta"
    const response = await instance.post(
      url,
      { 
        proposta,
        id_resposta: idResposta,
        banco,
        arquivo: arquivo,
        tipo
      },
      { headers: { token } }
    )

    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (err) {
    throw new Error(err); 
  }
}

//Responsável retornar os documentos enviados para as apis (cetelem ou agibank), que estão salvos no banco de dados
export const getDocumentsUploadProposal = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = `propostas/administrativo/buscar-documentos?proposta=${id}`
      const response = await instance.get(url, { headers: { token } })

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

//Responsável retornar os enviar os dados da proposta e formalizar a mesma (api agibank ou cetelem)
export const formalizationProposal = async (proposta, id, banco) => {
  try {
    const token = Store.getState().user.token

    const url = 'propostas/administrativo/formalizar'
    const response = await instance.post(url, 
      {
        proposta,
        id,
        banco
      },
      { headers: { token } }
      )

    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (err) {
    return {
      return: false,
      message: 'Ocorreu um erro, tente novamente.'
    }
  }
}

//Responsável retornar os enviar os dados da proposta e formalizar a mesma (api agibank ou cetelem)
export const cancelProposal = async (proposta, id, banco) => {
  try {
    const token = Store.getState().user.token

    const url = 'propostas/administrativo/cancelar'
    const response = await instance.post(url, 
      {
        proposta,
        id,
        banco
      },
      { headers: { token } }
      )

    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (err) {
    return {
      return: false,
      message: 'Ocorreu um erro, tente novamente.'
    }
  }
}

export const saveSaqueFgts = async (data) => {
  return new Promise(async (resolve, reject) => {
      try {
          const token = Store.getState().user.token

          const url = "propostas/administrativo/atualizar-saque-proposta"
          const response = await instance.post(
              url,
              data,
              { headers: { token } }
          )

          if (response.status === 200) {
              resolve(response.data)
          } else {
              reject(response.data)
          }
      } catch (err) {
          reject(err)
      }
  })
}

export const exportProposals = async (nome, cpf, status, dataInicio, dataFim) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "propostas/administrativo/exportar-propostas"
      const response = await instance.post(
        url,
        { nome, cpf, status, dataInicio, dataFim },
        { headers: { token } }
      )
      
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const rulesBank = async (id, tipo_contrato, produto) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = 'propostas/administrativo/buscar-regras-banco'
      const response = await instance.post(
        url,
        { id, tipo_contrato, produto },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const buscarClienteNovaProposta = async (cpf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = 'propostas/buscar/cliente-proposta'
      const response = await instance.post(
        url,
        { cpf },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const buscarConveniosProposta = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = 'propostas/buscar/convenios-proposta-adm'
      const response = await instance.get(
        url,
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const buscarOrigemProposta = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = 'propostas/buscar/origem-proposta-adm'
      const response = await instance.get(
        url,
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const buscarParcelasRegra = async (regraId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = 'propostas/buscar/parcelas-regra-adm'
      const response = await instance.post(
        url,
        { regraId },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const gravarProposta = async (dados) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token
      const url = 'propostas/administrativo/gravar'
      const response = await instance.post(
        url,
        { dados },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}