import "react-toastify/dist/ReactToastify.css"

import React, { useEffect, useState, useRef } from "react"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"
import { getChats, getMessages, sendMessage, finalizeChat } from "../services/Api"
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import Loading from "../components/loading/Loading"
import Menu from "../components/sidenav/Menu"
import TopMenu from "../components/topMenu/TopMenu"
import { Store } from "../redux/Store"
import socket from "../Socket"
import profile from "../profile.jpg"
import Input from "../components/input/Input";

function Chats() {
  const user = Store.getState().user
  const [filter, setFilter] = useState(null)
  const [chats, setChats] = useState(null)
  const chatsRef = useRef(chats)
  const [messages, setMessages] = useState(null)
  const messagesRef = useRef(messages)
  const [message, setMessage] = useState("")
  const [selectedChat, setSelectedChat] = useState(null)
  const selectedChatRef = useRef(selectedChat)
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [sockets, setSockets] = useState([])

  const setChatsState = data => {
    chatsRef.current = data
    setChats(data)
  }

  const setMessagesState = data => {
    messagesRef.current = data
    setMessages(data)
  }

  const setSelectedChatState = data => {
    selectedChatRef.current = data
    setSelectedChat(data)
  }

  const getList = async () => {
    try {
      const result = await getChats()
      setChatsState(result)
    } catch (err) {
      toast.error("Falha ao carregar dados!")
    }
  }

  const listen = (arr = []) => {
    unlisten()

    for (let i = 0; i < arr.length; i++) {
      const x = arr[i]

      //Salva para poder desligar depois:
      setSockets(prev => (prev || []).concat(x.id))

      //Ativa listener:
      socket.on(`chat_${x.id}`, data => {
        setChatsState(
          chatsRef.current
            .map(y => {
              if (y.id === data.chat_id) {
                return { ...y, unix: data.unix, msg: data.msg }
              } else {
                return y
              }
            })
            .sort((a, b) => b.unix - a.unix)
        )

        if (selectedChatRef.current === data.chat_id) {
          setMessagesState((messagesRef.current || []).concat(data))
        }
      })
    }
  }

  const unlisten = () => {
    sockets.forEach(x => socket.disconnect(`chat_${x}`))
  }

  const selectChat = async (id, people) => {
    setSelectedChatState(id)
    setSelectedPeople(people)

    try {
      setMessagesState(await getMessages(id))
    } catch (err) {
      toast.error("Falha ao carregar mensagens...")
    }
  }

  const onSubmit = async event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      if (message) {
        try {
          await sendMessage(selectedChat, message.trim())
          setMessage("")
        } catch (err) {
          toast.error(err.erro)
        }
      }
    } else if (event.keyCode === 13 && event.shiftKey) {
      setMessage(message + "\n")
    }
  }

  const onWrite = event => {
    setMessage(event.target.value)
  }

  const finalizar = async () => {
    try {
      await finalizeChat(selectedChatRef.current)
    } catch (err) {
      toast.error(err.erro)
    }
  }

  useEffect(() => {
    getList()

    socket.on("chat_creation", async data => {
      try {
        const result = await getChats()
        setChatsState(result)

        if (!result.map(x => x.id).includes(selectedChatRef.current)) {
          setMessagesState(null)
          setSelectedChatState(null)
          toast.info("Chat foi finalizado...")
        }
      } catch (err) {}
    })

    socket.on("chat_updation", async data => {
      try {
        const result = await getChats()
        setChatsState(result)

        if (!result.map(x => x.id).includes(selectedChatRef.current)) {
          setMessagesState(null)
          setSelectedChatState(null)
          toast.info("Chat foi finalizado...")
        }
      } catch (err) {}
    })

    return () => {
      socket.disconnect("chat_creation")
      socket.disconnect("chat_updation")
      unlisten()
    }
  }, [])

  useEffect(() => {
    if (chats) {
      listen(chats);
    }
  }, [chats])

  return (
    <div>
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header dashboard={null} />
        {/* Page content */}
        <div className="container-fluid mt--7">
          <div className="row flex-row chat">
            <div className="col-lg-5">
              <div className="card bg-secondary">
                <form className="card-header  bg-gradient-default mb-3">
                  <div className="row col-xl-9 input-group-alternative">
                    <Input
                      type="text"
                      id="pesquisar-usuario"
                      className="form-control  ml-2"
                      placeholder="Pesquisar..."
                      value={filter}
                      onChange={e => setFilter(e.target.value || null)}
                    />
                      <span className="input-group-text text-success">
                        <i className="ni ni-zoom-split-in" />
                      </span>
                  </div>
                </form>
                {chats ? (
                  <div className="list-group list-group-chat list-group-flush">
                    {(filter
                      ? chats.filter(x =>
                          x.nome.toUpperCase().includes(filter.toUpperCase())
                        )
                      : chats
                    ).map(x => (
                      <a
                        key={x.id}
                        className="list-group-item"
                        onClick={() =>
                          selectChat(x.id, {
                            nome: x.nome,
                            cpf: x.cpf,
                            celular: x.celular,
                            foto: x.foto,
                            funcao: x.funcao,
                            data: x.data,
                            unix: x.unix,
                          })
                        }
                      >
                        <div className="media">
                          <img
                            alt="Profile avatar"
                            src={x.foto || profile}
                            className="chat-avatar shadow"
                          />
                          <div className="media-body ml-2">
                            <div className="justify-content-between align-items-center">
                              <h6 className="mb-0 text-primary">{`${x.nome} (${x.funcao})`}</h6>
                              <div>
                                <small className="text-muted">
                                  {moment(x.unix, "X").add(3, 'hours').format("DD/MMM HH:mm")}
                                </small>
                              </div>
                            </div>
                            <span className="text-muted text-small col-10 p-0 text-truncate d-block">
                              {x.msg}
                            </span>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="list-group list-group-chat list-group-flush">
                    <Loading />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              {selectedChat ? (
                <div className="card">
                  <div className="card-header bg-default d-inline-block">
                    <div className="row">
                      <div className="col-md-11">
                        <div className="media align-items-center">
                          <img
                            alt="profile avatar people"
                            src={
                              (selectedPeople && selectedPeople.foto) || profile
                            }
                            className="chat-avatar shadow"
                          />
                          <div className="media-body">
                            <h6 className="mb-0 d-block text-white" style={{marginTop:5}}>
                              {
                                (selectedPeople && selectedPeople.cpf) ?
                                  `${selectedPeople.nome} (${selectedPeople.funcao}) | CPF: ${selectedPeople.cpf}`
                                :
                                  `${selectedPeople.nome} (${selectedPeople.funcao})`
                              }
                            </h6>
                            {
                              (selectedPeople && selectedPeople.celular) &&
                                <h6 className="mb-0 d-block text-white" style={{marginTop:5}}>
                                  Celular: {selectedPeople.celular}
                                </h6>
                            }
                            <span className=" text-white text-small">
                              {selectedPeople &&
                                moment(selectedPeople.unix, "X").add(3, 'hours').format(
                                  "DD/MMM HH:mm"
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1 col-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-link text-white text-lg"
                            type="button"
                            data-toggle="dropdown"
                          >
                            <i className="ni ni-settings-gear-65" />
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" onClick={finalizar}>
                              <i className="ni ni-fat-remove" /> Finalizar chat
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {messages ? (
                    <div className="chat-card-body  bg-gradient-secondary">
                      {messages.map(x =>
                        x.autor_id === user.key ? (
                          <div
                            key={x.id}
                            className="row justify-content-end text-right"
                          >
                            <div className="col-auto">
                              <div className="card bg-gradient-primary text-white">
                                <div className="chat-card-body p-2">
                                  <p className="mb-1">
                                    {x.msg}
                                    <br />
                                  </p>
                                  <div>
                                    <small className="opacity-60">
                                      {moment(x.unix, "X").add(3, 'hours').format(
                                        "DD/MMM HH:mm"
                                      ) + " "}
                                    </small>
                                    {x.lido && (
                                      <i className="ni ni-check-bold" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div key={x.id} className="row justify-content-start">
                            <div className="col-auto">
                              <div className="card">
                                <div className="chat-card-body p-2">
                                  <p className="mb-1">{x.msg}</p>
                                  <div>
                                    <small className="opacity-60">
                                      <i className="far fa-clock" />{" "}
                                      {moment(x.unix, "X").add(3, 'hours').format(
                                        "DD/MMM HH:mm"
                                      )}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="chat-card-body">
                      <Loading />
                    </div>
                  )}

                  {messages && (
                    <div className="card-footer d-block  bg-gradient-secondary">
                      <div className="form-group ">
                        <div className="row mb-2">
                          <Input
                            className="form-control ml-4"
                            placeholder="Digite sua mensagem..."
                            type="text"
                            onKeyDown={onSubmit}
                            onChange={onWrite}
                            value={message}
                          />
                            <span className="input-group-text text-success">
                              <i className="ni ni-send" />
                            </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="card">
                  <div className="chat-card-body text-center">
                    <p>Ao selecionar um chat, a conversa aparecerá aqui!</p>
                    <p>...</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Chats
