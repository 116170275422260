import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  getPrices,
  getPriceConventions,
  sendPrice,
  getPriceBanks,
  getPriceRules,
  deletePrice,
  getTypesContractsConsignado,
  saveRulePrice,
  getRule,
} from "../services/Price";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import Input from "../components/input/Input";
import Select from "react-select";

import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";

function Price() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [bancos, setBancos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [regras, setRegras] = useState([]);

  //Dados do filtro:
  const [banco, setBanco] = useState(null);
  const [convenio, setConvenio] = useState(null);
  const [regra, setRegra] = useState(null);
  const [dataBase, setDataBase] = useState(null);
  const [datatable, setDataTable] = useState({});
  //Dados do formulário:
  const [formLoading, setFormLoading] = useState(false);
  const [register, setRegister] = useState({});
  const [errors, setErrors] = useState({});

  const selectBanco = useRef();
  const selectRegra = useRef();
  const selectTipo = useRef();
  const selectConvenio = useRef();
  const selectBancoFiltro = useRef();
  const selectConvenioFiltro = useRef();

  useEffect(() => {
    getData(1, null, null, null, null);
    getBanks();
    getTypesContracts();
    getConventions();
    getRules();
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Banco",
          field: "banco",
        },
        {
          label: "Regra",
          field: "regra_id",
        },
        {
          label: "Convênio",
          field: "abreviatura",
        },
        {
          label: "Tipo Contrato",
          field: "tipo_contrato_consignado",
        },
        {
          label: "Regra",
          field: "nome",
        },
        {
          label: "Taxa",
          field: "juros",
        },
        {
          label: "Prazo",
          field: "prazo_min",
        },
        {
          label: "Comissão",
          field: "comissao",
        },
        {
          label: "Comissão c/ Seguro",
          field: "comissao_com_seguro",
        },
        {
          label: "Comissão Diferido",
          field: "comissao_diferido",
        },
        {
          label: "Fator",
          field: "fator",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            banco: x.banco,
            regra_id: x.regra_id,
            abreviatura: x.abreviatura,
            tipo_contrato_consignado: x.tipo_contrato_consignado,
            nome: x.nome,
            prazo_min: x.prazo_min,
            fator: x.fator,
            comissao: x.comissao,
            juros: x.juros,
            comissao_diferido: x.comissao_diferido,
            comissao_com_seguro: x.comissao_com_seguro,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    onClick={() => onEdit(x.regra_id, x.taxa_id)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-pen"></i>
                    Editar
                  </button>
  
                  <button
                    type="button"
                    onClick={() => onDelete(x.regra_id, x.nome)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-trash fa-lg"></i>
                    Deletar
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const getBanks = async () => {
    try {
      setBancos(await getPriceBanks());
    } catch (err) {}
  };

  const getConventions = async () => {
    try {
      setConvenios(await getPriceConventions());
    } catch (err) {}
  };

  const getRules = async () => {
    try {
      setRegras(await getPriceRules());
    } catch (err) {}
  };

  const getTypesContracts = async () => {
    try {
      setTiposContrato(await getTypesContractsConsignado());
    } catch (err) {
      console.error(err);
    }
  };

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
    onClear();
  };

  const getData = async (pg = 1, bank, convention, rule, date) => {
    try {
      setData();
      setPage(pg);
      setData(await getPrices(page, bank, convention, rule, date));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(1, banco, convenio, regra, dataBase);
  };

  const onClear = () => {
    window.$("#banco").val(null);
    window.$("#convenio").val(null);
    window.$("#regra").val(null);
    selectBanco.current.select.clearValue();
    selectConvenio.current.select.clearValue();
    selectRegra.current.select.clearValue();
    setBanco(null);
    setConvenio(null);
    setRegra(null);
  };

  const onClearFiltro = () => {
    window.$("#banco").val(null);
    window.$("#convenio").val(null);
    window.$("#regra").val(null);
    selectBancoFiltro.current.select.clearValue();
    selectConvenioFiltro.current.select.clearValue();
    selectRegra.current.select.clearValue();
    setBanco(null);
    setConvenio(null);
    setRegra(null);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const sendFile = () => {
    try {
      var file = document.querySelector("#inputArquivo").files[0];
      if (file) {
        setFormLoading(true);
        getBase64(file).then(async (data) => {
          try {
            const result = await sendPrice(data);
            toast.success(`${result.total} price(s) foram inseridas!`);
            window.$("#modal-data").modal("hide");
            onClear();
            window.$("#inputArquivo").val(null);
            setFormLoading(false);
          } catch (err) {
            console.warn(err);
            setFormLoading(false);
            toast.error(err.erro);
          }
        });
      } else {
        toast.info("Selecione algum arquivo!");
      }
    } catch (err) {
      console.warn(err);
      setFormLoading(false);
      toast.error("Falha ao enviar arquivo!");
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    switch (id) {
      default:
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
        setRegister({ ...register, [id]: value });
        break;
    }
  };

  const handleBlur = async (event) => {
    const { id, value } = event.target;
    if (!value || value === "R$ 0,00") {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: "Campo Obrigatório" }));
    }
  };

  const onEdit = async (regraId, taxaId) => {
    try {
      setFormLoading(true);
      const result = await getRule(regraId, taxaId);
      setRegister(result);
      window.$("#modal-adicionar").modal("show");
    } catch (err) {
      toast.error(err);
    } finally {
      setFormLoading(false);
    }
  };

  const onDelete = async (regra, nome) => {
    swal({
      title: "Deletar Regra?!",
      text: `A: ${nome}, será deletada, você tem certeza?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await deletePrice(regra);
        if (retorno.return) {
          setFormLoading(false);
          swal("Regra deletada com sucesso.", {
            icon: "success",
          });

          getData(1, null, null, null, null);
        } else {
          setFormLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFormLoading(false);
        swal("A regra não foi deletada.", {
          icon: "info",
        });
      }
    });
  };

  const onSaveRulePrice = async (event) => {
    event.preventDefault();
    setFormLoading(true);
    try {
      await saveRulePrice(register);
      getData(1, null, null, null, null);
      toast.success("Regra salva com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro tente novamente!");
      setFormLoading(false);
    } finally {
      window.$("#modal-adicionar").modal("hide");
      setFormLoading(false);
      setRegister({});
    }
  };

  const clearModal = () => {
    setRegister({});
    window.$("#banco_id").val("0");
    window.$("#convenio_id").val(null);
    window.$("#tipo_contrato_id").val(null);
    window.$("#regra").val(null);
    window.$("#data_inicio").val(null);
    window.$("#comissao").val(null);
    window.$("#juros").val(null);
    window.$("#prazo_min").val(null);
    window.$("#comissao_com_seguro").val(null);
    window.$("#comissao_diferido").val(null);
    window.$("#fator").val(null);
  };

  const disabledBtnSubmit = useMemo(() => {
    return Object.keys(register).filter((key) => register[key]).length < 14;
  }, [register]);

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Importar Price",
              icone: (
                <i className="fas fa-file-csv" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-data").modal("show"),
            },
            {
              name: "Adicionar",
              icone: <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>,
              onClick: () => window.$("#modal-adicionar").modal("show"),
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
               <div className="card-header">
                  <h3 className="mb-0">Price</h3>
               </div>
               <div className="card-body">
                  <div className="form-group mb-3">
                     <div className="input-group input-group-merge input-group-alternative">
                        <Select
                           id="banco"
                           ref={selectBancoFiltro}
                           className="form-control-select"
                           placeholder="Banco..."
                           defaultValue={banco}
                           options={bancos}
                           onChange={(e) => {
                           if (e) {
                              setBanco(e.value || null);
                           }
                           }}
                           backspaceRemovesValue
                        />
                     </div>
                  </div>
                  <div className="form-group mb-3">
                     <div className="input-group input-group-merge input-group-alternative">
                        <Select
                           id="convenio"
                           ref={selectConvenioFiltro}
                           className="form-control-select"
                           placeholder="Convênios..."
                           onChange={(e) => {
                           if (e) {
                              setConvenio(e.value || null);
                           }
                           }}
                           options={convenios}
                           defaultValue={convenio}
                        />
                     </div>
                  </div>

                  <div className="form-group mb-3">
                     <div className="input-group input-group-merge input-group-alternative">
                        <Select
                           id="nome_regra"
                           ref={selectRegra}
                           className="form-control-select"
                           placeholder="Regra..."
                           onChange={(e) => {
                           if (e) {
                              setRegra(e.value || null);
                           }
                           }}
                           options={regras}
                           defaultValue={regra}
                        />
                     </div>
                  </div>

                  <div className="form-group mb-3">
                     <div className="input-group input-group-merge input-group-alternative">
                        <input
                           id="dataBase"
                           className="form-control"
                           placeholder="Data Base..."
                           type={"date"}
                           value={dataBase == null ? '' : dataBase}
                           onChange={(e) => setDataBase(e.target.value || null)}
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           onClick={onClearFiltro}
                           className="btn btn-danger btn-block my-4"
                        >
                           Limpar
                        </button>
                     </div>

                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           onClick={onFilter}
                           className="btn btn-primary btn-block my-4"
                        >
                           Filtrar
                        </button>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        }
      />
      {/* Modal de Dados */}
      <Modal
        id={"modal-data"}
        title={"Importar Regras Price"}
        form={
          <div>
             <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Arquivo XLSX*</label>
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputArquivo"
                    lang="pt-Br"
                  />
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button
                  type="button"
                  onClick={() => sendFile()}
                  className="btn btn-primary btn-block"
                >
                  Adicionar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* Modal Editar Saque */}
      <Modal
        id={"modal-adicionar"}
        title="Adicionar"
        size="xl"
        form={
          <div>
            <button
              type="button"
              className="close"
              onClick={clearModal}
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
               <div className="card-header">
                  <h3 className="mb-0">Regra Price</h3>
               </div>
               <div className="card-body">
                  <div className="row">
                  <div className="form-group col-md-6">
                     <label className="ml-3">Banco*</label>
                     <Select
                        id="banco_id"
                        ref={selectBanco}
                        className="form-control-select"
                        onChange={(e) => {
                        if (e) {
                           setRegister({
                              ...register,
                              banco: e.label,
                              banco_id: e.value,
                           });
                           setErrors({ ...errors, banco: null });
                        } else {
                           setErrors({ ...errors, banco: "Campo Obrigatório" });
                        }
                        }}
                        onBlur={handleBlur}
                        value={{
                        label: register.banco
                           ? register.banco
                           : "Selecione o banco...",
                        }}
                        options={bancos}
                     />
                  </div>

                  <div className="form-group col-md-6">
                     <label className="ml-3">Convênio*</label>
                     <Select
                        id="convenio_id"
                        ref={selectConvenio}
                        className="form-control-select"
                        onChange={(e) => {
                        if (e) {
                           setRegister({
                              ...register,
                              convenio: e.label,
                              convenio_id: e.value,
                           });
                           setErrors({ ...errors, convenio: null });
                        } else {
                           setErrors({ ...errors, convenio: "Campo Obrigatório" });
                        }
                        }}
                        onBlur={handleBlur}
                        options={convenios}
                        value={{
                        label: register.convenio
                           ? register.convenio
                           : "Selecione um convênio...",
                        }}
                     />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-group col-md-6">
                     <label className="ml-3">Tipo Contrato*</label>
                     <Select
                        id="tipo_contrato_id"
                        ref={selectTipo}
                        className="form-control-select"
                        onChange={(e) => {
                        if (e) {
                           setRegister({
                              ...register,
                              tipo_contrato: e.label,
                              tipo_contrato_id: e.value,
                           });
                           setErrors({ ...errors, convenio: null });
                        } else {
                           setErrors({ ...errors, convenio: "Campo Obrigatório" });
                        }
                        }}
                        onBlur={handleBlur}
                        value={{
                        label: register.tipo_contrato
                           ? register.tipo_contrato
                           : "Selecione um tipo de contrato...",
                        }}
                        options={tiposContrato}
                     />
                  </div>

                  <div className="form-group col-md-6">
                     <label className="ml-3">Regra*</label>
                     <Input
                        id="regra"
                        className="form-control ml-2 "
                        placeholder="Digite o nome da regra..."
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.regra || null}
                        help={errors.regra || ""}
                     />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-group col-md-6">
                     <label className="ml-3">Data Inicio*</label>
                     <Input
                        id="data_inicio"
                        className="form-control ml-2"
                        placeholder="Ex: ##/##/####...."
                        type="birthday"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.data_inicio || null}
                        help={errors.data_inicio || ""}
                     />
                  </div>
                  <div className="form-group col-md-6">
                     <label className="ml-3">Comissão*</label>
                     <Input
                        id="comissao"
                        className="form-control ml-2"
                        placeholder="Digite o valor da comissão..."
                        type="percentual"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.comissao || null}
                        help={errors.comissao || ""}
                     />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-group col-md-6">
                     <label className="ml-3">Taxa*</label>
                     <Input
                        id="juros"
                        className="form-control ml-2"
                        placeholder="Digite o valor da taxa..."
                        type="percentual"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.juros || null}
                        help={errors.juros || ""}
                     />
                  </div>
                  <div className="form-group col-md-6">
                     <label className="ml-3">Parcela*</label>
                     <Input
                        id="prazo_min"
                        className="form-control ml-2"
                        placeholder="Digite a parcela..."
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.prazo_min || null}
                        help={errors.prazo_min || ""}
                     />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-group col-md-4">
                     <label className="ml-3">Comissão com Seguro*</label>
                     <Input
                        id="comissao_com_seguro"
                        className="form-control ml-2"
                        placeholder="Digite o valor da comissão com seguro..."
                        type="percentual"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.comissao_com_seguro || null}
                        help={errors.comissao_com_seguro || ""}
                     />
                  </div>
                  <div className="form-group col-md-4">
                     <label className="ml-3">Comissão Diferido</label>
                     <Input
                        id="comissao_diferido"
                        className="form-control ml-2"
                        placeholder="Digite o valor da comissão diferido..."
                        type="percentual"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.comissao_diferido || null}
                        help={errors.comissao_diferido || ""}
                     />
                  </div>
                  <div className="form-group col-md-4">
                     <label className="ml-3">Fator</label>
                     <Input
                        id="fator"
                        className="form-control ml-2"
                        placeholder="Digite o valor do fator..."
                        type="percentual"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.fator || null}
                        help={errors.fator || ""}
                     />
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6 text-center">
                     <button
                        type="button"
                        className="btn btn-danger btn-block my-4"
                        data-dismiss="modal"
                        arial-label="close"
                        onClick={clearModal}
                     >
                        Cancelar
                     </button>
                  </div>

                  <div className="col-md-6 text-center">
                     <button
                        type="button"
                        onClick={onSaveRulePrice}
                        disabled={disabledBtnSubmit}
                        className="btn btn-success btn-block my-4"
                     >
                        Salvar
                     </button>
                  </div>
                  </div>
               </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default Price;
