import React from "react";
import { useState, useEffect } from "react";
import {getReportDados } from "../../services/Api";

//charts
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';



function RelProposta() {
    const [relatorio, setRelatorios] = useState({});
    const options = {
        colors: ['#058DC7', '#6AF9C4', '#DC143C', '#DDDF00', '#24CBE5', '#64E572',
        '#FF9655', '#FFF263', '#50B432','#FF2400','#5A4FCF','#E32636'],
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height:400,
            backgroundColor: {}
            
        },
        title: {
            backgroundColor: 'transparent',
            text:''
        },
        credits: {
            enabled: false
        },
       
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Propostas',
            colorByPoint: true,
            data: relatorio,
            credits: {
                enabled: false
            }
        }]
    }
    const getRelatorios = async () => {
        try{
             setRelatorios(await getReportDados());
        }catch(err){}
    } 
    useEffect(() => {
        
        getRelatorios();
        
    }, [])

  return(
   <HighchartsReact   highcharts={Highcharts} options={options} />     
    )

}
export default RelProposta;