import React, { useState, useEffect } from "react";
import { logout, getChatsPending, firstAccess } from "../../services/Api";
import { Store } from "../../redux/Store";
// import { useHistory } from "react-router-dom";
import profile from "../../profile.jpg";
import Modal from "../../components/modal/Modal";
import Input from "../../components/input/Input";
import funcoes from "../../utils/helpers/funcoes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPerfil } from "../../services/Users";

function TopMenu() {
   const dadosStore = Store.getState().user;
   // const history = useHistory();
   const [chats, setChats] = useState(null);
   const [user, setUser] = useState(null);
   const [password, setPassword] = useState(null);
   const [newPassword, setNewPassword] = useState(null);
   const [newPasswordConfirmation, setnewPasswordConfirmation] = useState(null);

   const logoutUser = () => {
      logout();
      // history.push("/");
   };

   useEffect(() => {
      window.$("#modal-senha").on("hidden.bs.modal", function () {
         setPassword(null);
         setNewPassword(null);
         setnewPasswordConfirmation(null);

         window.$("#senhaAntiga").val("");
         window.$("#senha").val("");
         window.$("#confirmarSenha").val("");
      });
      getDadosProfile();
   }, []);

   async function getChatsNotification() {
      const response = await getChatsPending();
      setChats(response);
   }

   async function getDadosProfile() {
      const uid = dadosStore.key;
      const response = await getPerfil(uid);
      if (response.imagem) {
         const img = response.imagem;
         setUser({ ...response, img });
      } else {
         setUser(response);
      }
   }

   function handleChat() {
      // history.push("/chats");
   }

   async function onSave(event) {
      event.preventDefault();
      try {
         if (password !== newPassword) {
            if (newPassword === newPasswordConfirmation) {
               const validaSenha = funcoes.validaSenha(newPassword);
               const errorMessage = validaSenha.erro;
               if (!validaSenha.retorno) {
                  toast.error(errorMessage);
               } else {
                  await firstAccess(password, newPassword);
                  toast.success("Senha redefinida com sucesso.");
                  window.$("#modal-senha").modal("hide");
               }
            } else {
               toast.error("As senhas não são iguais.");
            }
         } else {
            toast.error("Sua nova senha deve ser diferente da antiga.");
         }
      } catch (error) {
         toast.error(error);
      }
   }

   const handleChange = (event) => {
      const { id, value } = event.target;
      switch (id) {
         case "senhaAntiga":
            setPassword(value);
            break;
         case "senha":
            setNewPassword(value);
            break;
         case "confirmarSenha":
            setnewPasswordConfirmation(value);
            break;
         default:
            break;
      }
   };

   return (
      <nav className="navbar navbar-top navbar-expand navbar-dark bg-default border-bottom ">
         <div className="container-fluid">
            <div
               className="collapse navbar-collapse"
               id="navbarSupportedContent"
            >
               {/* Navbar links */}
               <ul className="navbar-nav align-items-center  ml-md-auto ">
                  <li className="nav-item d-xl-none">
                     {/* Sidenav toggler */}
                     <div
                        className="pr-3 sidenav-toggler sidenav-toggler-dark"
                        data-action="sidenav-pin"
                        data-target="#sidenav-main"
                     >
                        <div className="sidenav-toggler-inner">
                           <i className="sidenav-toggler-line"></i>
                           <i className="sidenav-toggler-line"></i>
                           <i className="sidenav-toggler-line"></i>
                        </div>
                     </div>
                  </li>
                  <li className="nav-item d-sm-none">
                     <a
                        className="nav-link"
                        href="#"
                        data-action="search-show"
                        data-target="#navbar-search-main"
                     >
                        <i className="ni ni-zoom-split-in" />
                     </a>
                  </li>
                  <li className="nav-item dropdown">
                     <a
                        className="nav-link"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={getChatsNotification}
                     >
                        <i
                           className={
                              chats && chats.total > 0
                                 ? "ni ni-bell-55 bell-alert"
                                 : "far fa-bell"
                           }
                        />
                        <strong className="text-white text-xs ">
                           <sup className="text-white">
                              {chats && chats.total > 0 ? chats.total : ""}
                           </sup>
                        </strong>
                     </a>
                     <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left py-0 overflow-hidden">
                        <div className="px-3 py-3">
                           <h6 className="text-sm text-muted m-0">
                              Você tem{" "}
                              <strong className="text-primary">
                                 {chats && chats.total > 0 ? chats.total : 0}
                              </strong>{" "}
                              notificações não lidas.
                           </h6>
                        </div>
                        <div className="list-group list-group-flush">
                           {chats &&
                              chats.dados &&
                              chats.dados.map((x) => (
                                 <a
                                    onClick={handleChat}
                                    className="list-group-item list-group-item-action"
                                 >
                                    <div className="row align-items-center">
                                       <div className="col-sm-3">
                                          <img
                                             alt="Img placeholder"
                                             src={x.foto ? x.foto : profile}
                                             className="avatar rounded-circle"
                                          />
                                       </div>
                                       <div className="col ml--2">
                                          <div className="d-flex justify-content-between align-items-center">
                                             <div>
                                                <h4 className="mb-0 text-sm">
                                                   {x.nome}
                                                </h4>
                                             </div>
                                             <div className="text-right text-muted">
                                                <small>{x.data}</small>
                                             </div>
                                          </div>
                                          <p className="text-sm mb-0">
                                             {x.msg}
                                          </p>
                                       </div>
                                    </div>
                                 </a>
                              ))}
                        </div>
                        <a
                           onClick={handleChat}
                           className="dropdown-item text-center text-primary font-weight-bold py-3"
                        >
                           Ver Todas
                        </a>
                     </div>
                  </li>
               </ul>
               <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
                  <li className="nav-item dropdown">
                     <a
                        className="nav-link pr-0"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <div className="media align-items-center">
                           {dadosStore &&
                           dadosStore.foto && (
                              <img
                                 alt=""
                                 className="avatar avatar-sm rounded-circle"
                                 src={dadosStore.foto}
                              />
                           ) ? (
                              <img
                                 alt=""
                                 className="avatar avatar-sm rounded-circle"
                                 src={dadosStore.foto}
                              />
                           ) : (
                              <span
                                 style={{ backgroundColor: "rgb(46,182,121)" }}
                                 className="avatar avatar-sm rounded-circle"
                              >
                                 {dadosStore.name.charAt(0).toUpperCase()}
                              </span>
                           )}
                           <div className="media-body  ml-2  d-none d-lg-block">
                              <span className="mb-0 text-sm  font-weight-bold">
                                 {dadosStore.name}
                              </span>
                           </div>
                        </div>
                     </a>
                     <div>
                        <div className="dropdown-menu">
                           <div className="dropdown-header noti-title">
                              <h6 className="text-overflow m-0">Bem-vindo!</h6>
                           </div>
                           <a href="/perfil" className="dropdown-item">
                              <i className="far fa-user-circle text-default" />
                              <span className="h5">Meu Perfil</span>
                           </a>
                           <div className="dropdown-divider" />
                           <a
                              onClick={() =>
                                 window.$("#modal-senha").modal("show")
                              }
                              className="dropdown-item"
                           >
                              <i className="fas fa-lock-open text-default" />
                              <span className="h5">Redefinir Senha</span>
                           </a>
                           <div className="dropdown-divider" />
                           <button
                              type="button"
                              className="dropdown-item "
                              data-toggle="modal"
                              data-target="#logoutModal"
                           >
                              <i className="fas fa-sign-out-alt text-default" />
                              <span className="h5">Logout</span>
                           </button>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
         <div
            className="modal fade mt-5"
            id="logoutModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="logoutModalLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog" role="document">
               <div className="modal-content ">
                  <div className="modal-header mt--1 bg-default">
                     <h5
                        className="modal-title  text-white"
                        id="logoutModalLabel"
                     >
                        LOGOUT
                     </h5>
                     <button
                        type="button"
                        className="close text-white py-0 pt-2 pr-3"
                        data-dismiss="modal"
                        arial-label="close"
                        arial-hidden="true"
                     >
                        x
                     </button>
                  </div>
                  <div className="modal-body ">
                     {dadosStore.name + ", tem certeza que deseja sair?"}
                  </div>
                  <div className="modal-footer">
                     <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                     >
                        Cancelar
                     </button>
                     <button
                        type="button"
                        className="btn btn-primary bg-default border-default"
                        onClick={logoutUser}
                        data-dismiss="modal"
                     >
                        Logout
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <Modal
            id={"modal-senha"}
            title={"Redefinir Senha"}
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span arial-hidden="true" className="text-white">
                        x
                     </span>
                  </button>
                  <div className="row col-xl-12 ">
                     <div className="card bg-transparent py-3  col-xl-6 border-0">
                        {/* Card body */}
                        <div className="card-body">
                           <div className="row col-xl-12">
                              <div className="">
                                 <h2 className="card-title text-uppercase mb-2 text-default">
                                    Política de senha
                                 </h2>
                                 <h5 className="card-title text-uppercase mb-0 text-primary">
                                    A senha deve conter:
                                 </h5>
                                 <span className="h3 pb-3 text-sm">
                                    Não pode ser nenhuma das últimas 10 senhas.
                                    <br />
                                 </span>
                                 <span className="h3  mb-1 text-default">
                                    A senha precisa ter pelo menos 9 caracteres.
                                    <br />
                                 </span>
                                 <span className="h3 mb-1 text-default">
                                    A senha precisa ter pelo menos 1 número,
                                    0-9.
                                    <br />
                                 </span>
                                 <span className="h3  mb-1 text-default">
                                    A senha precisa ter pelo menos 1 letra
                                    minúscula, a-z.
                                    <br />
                                 </span>
                                 <span className="h3 mb-1 text-default">
                                    A senha precisa ter pelo menos 1 letra
                                    maiúscula, A-Z.
                                    <br />
                                 </span>
                                 <span className="h3  mb-1 text-default">
                                    A senha precisa ter pelo menos 1 caracter
                                    especial: ()[]|\`~!@#$%^*_-+=;:'",./?
                                    <br />
                                 </span>
                                 <h5 className="card-title text-uppercase my-2 text-danger">
                                    A senha não pode conter:
                                    <br />
                                 </h5>
                                 <span className="h3 mb-1 text-default">
                                    Placas de carro.
                                    <br />
                                 </span>
                                 <span className="h3 mb-1 text-default">
                                    Sequências repetitivas (ex.: 99999, aaaaa).
                                    <br />
                                 </span>
                                 <span className="h3 mb-1 text-default">
                                    Nome do mês por extenso.
                                    <br />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className=" py-3 bg-transparent col-xl-6 border-0">
                        <h2 className="font-weight-bold">Alterar senha</h2>
                        <div className="input-group input-group-merge mb-2 col-xl-12">
                           <div className="input-group-prepend">
                              <span className="input-group-text">
                                 <i className="ni ni-lock-circle-open  text-primary" />
                              </span>
                           </div>
                           <Input
                              className="form-control "
                              placeholder="Senha Antiga"
                              htmlType="password"
                              id="senhaAntiga"
                              required
                              onChange={handleChange}
                              value={password}
                           />
                        </div>
                        <div className="input-group input-group-merge mb-2 col-xl-12">
                           <div className="input-group-prepend">
                              <span className="input-group-text">
                                 <i className="ni ni-lock-circle-open  text-primary" />
                              </span>
                           </div>
                           <Input
                              className="form-control"
                              placeholder="Nova Senha"
                              id="senha"
                              htmlType="password"
                              required
                              onChange={handleChange}
                              value={newPassword}
                           />
                        </div>
                        <div className="input-group input-group-merge  mb-2 col-xl-12">
                           <div className="input-group-prepend">
                              <span className="input-group-text">
                                 <i className="ni ni-lock-circle-open  text-primary" />
                              </span>
                           </div>
                           <Input
                              className="form-control"
                              placeholder="Confirmar nova Senha"
                              htmlType="password"
                              id="confirmarSenha"
                              required
                              onChange={handleChange}
                              value={newPasswordConfirmation}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12 text-center">
                        <button
                           type="button"
                           onClick={onSave}
                           className="btn btn-primary btn-block my-4"
                        >
                           Alterar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />
         <ToastContainer />
      </nav>
   );
}

export default TopMenu;
