import React from "react";
import { useState, useEffect } from "react";
import { getReportSimulation } from "../../../services/Api";
//charts
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AnualCharts() {
  const [simulacoes, setSimulacoes] = useState([]);

  useEffect(() => {
    getSimulation();
  }, []);

  const options = {
    title: {
      text: "Relatório Anual de Simulações  ",
    },

    subtitle: {
      text: '<a href="http://seucreditonegocios.com.br" target="_blank">seucreditonegocios.com.br</a>',
    },

    yAxis: {
      title: {
        text: "Número de Simulações",
      },
    },
    xAxis: {
      categories: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      crosshair: true,
    },
    chart: {
      style: {
        fontFamily: "arial",
      },
      type: "column",
      spacingRight: 20,
      spacingLeft: 30
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 4,
        borderRadius:5,
      },
    },
    legend: false,
    series: simulacoes,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  const getSimulation = async () => {
    try {
      const data = await getReportSimulation();
      setSimulacoes(data);
    } catch (err) {
      toast.error(err);
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
export default AnualCharts;
