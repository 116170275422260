import { Store } from "../redux/Store"
import instance from "./index";

export const getConfigurationsFgts = async (valor, aliquota, pagina) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "configuracoes/administrativo/listar-configuracoes-fgts"
      const response = await instance.post(
        url,
        {
          valor,
          aliquota,
          pagina,
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const saveRulesFgts = async (data) => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
  
        const url = "configuracoes/administrativo/salvar-regras-fgts";
        const response = await instance.post(
          url,
          data,
          { headers: { token } }
        );
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const inactivate = async id => {
    try {
      const token = Store.getState().user.token
  
      const url = `configuracoes/administrativo/inativar-regra?id=${id}`
      const response = await instance.get(
        url,
        { headers: { token } }
      )
  
      if (response.status === 200) {
        return response.data
      } else {
        return response.data
      }
    } catch (err) {
      return err
    }
  }