import moment from "moment";
import { Store } from "../redux/Store"
import instance from "./index";

export const sendCrm = async (base64, fileName) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm/processar"
         const response = await instance.post(
            url,
            { arquivo: base64, nomeArquivo: fileName },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response)
         } else {
            reject(response)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const listarImportacoesCrm = async (page) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = `crm/listar-importacoes?pagina=${page}`
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const getAgentsMailings = async (nome, cpf, pagina, dataInicio, dataFim) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm/administrativo/listar-agentes-mailing"
         const response = await instance.post(
            url,
            {
               nome,
               cpf,
               pagina,
               dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
               dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
            },
            { headers: { token } }
         )
         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const getMailingsAgent = async (id, dataInicio, dataFim) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm/administrativo/buscar-comissoes-agente"
         const response = await instance.post(
            url,
            {
               id,
               dataInicio: dataInicio ? moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataInicio,
               dataFim: dataFim ? moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD') : dataFim
            },
            { headers: { token } }
         )
         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const getReportsCrm = async () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm/administrativo/buscar-relatorios"
         const response = await instance.get(
            url,
            { headers: { token } }
         )
         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}