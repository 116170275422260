// redux
// import { Store } from '../redux/Store';

// import all languages
import ptBR from '../resources/pt-BR.json';

// errors
import ptBR_error from '../resources/pt-BR_error.json';

const languages = {
  'pt-BR': ptBR,
  'pt-BR_error': ptBR_error,
};

// const language = Store.getState().language;

export const errors = languages['pt-BR_error'];

export default languages['pt-BR'];
