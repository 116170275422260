const register = {
  nome: true,
  nascimento: true,
  cpf: true,
  celular: true,
  email: true,
  master: true,
  sexo_id: true,
  cep: true,
  estado_id: true,
  cidade: true,
  endereco: true,
  numero_endereco: true,
  bairro: true,
  certificacao: true
};

export default JSON.parse(JSON.stringify(register));
