import { Store } from "../redux/Store"
import instance from "./index";

export const getCpfs = async (cpf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "clientes/administrativo/listar-remessa-cpf"
      const response = await instance.post(
        url,
        {
          cpf
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const updateCpf = async (cpf, ativo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "clientes/administrativo/atualizar-cpf-remessa"
      const response = await instance.post(
        url,
        {
          cpf,
          ativo
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const salvarCpfRemessa = async (cpf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "clientes/administrativo/gravar-cpf-remessa"
      const response = await instance.post(
        url,
        {
          cpf
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}