import React from "react";
import { Store } from "../../redux/Store";
import { getReportHeader } from "../../services/Api";
import { getReportsCrm } from "../../services/Crm";
import { useState, useEffect } from "react";

// import ReactGA from "react-ga";
// import AppConfig from "../../App.config";
import { withRouter } from "react-router-dom";

import funcoes from "../../utils/helpers/funcoes";

// ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);

function Header(props) {
  const [dados, setDados] = useState({});
  const { buttons } = props;
  const route = Store.getState().user.permissions.find(
    (x) => x.rota === window.location.pathname
  );

  useEffect(() => {
    getReports();
  }, []);

  const getReports = async () => {
    try {
      if (window.location.pathname === "/") {
        const response = await getReportHeader();
        setDados(response);
      } else if (window.location.pathname === "/relatorios_crm") {
        const response = await getReportsCrm();
        setDados(response);
      }
    } catch (err) {
      console.error(err);
    }
  };


  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // },[]);

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-6 col-7">
              <h6 className="h2 d-inline-block mb-0">{route.nome}</h6>
              <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i></a></li>
                  <li className="breadcrumb-item"><a href={route.rota}>{route.nome}</a></li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 col-5 text-right">
              {buttons &&
                buttons.map((x, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={x.onClick}
                    title={x.name}
                    className="btn btn-sm btn-neutral py-2 border-default bg-default text-white"
                  >
                    {x.name}
                    {x.icone && x.icone}
                  </button>
                ))}
            </div>
          </div>
          <div className="row">
            {/* <div className="col-xl-4 col-md-6">
                <div className="card card-stats">
                   <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Acessos Diários
                        </h5>
                        <span className="h2 font-weight-bold mb-0">
                          {dados.acessosDiarios}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Acessos diários seu crédito.</span>
                    </p>
                  </div> 
                </div>
              </div> */}
            {window.location.pathname === "/" && (
              <>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-gradient-orange  py-3 border-0">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0 text-white">
                            Agentes
                          </h5>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {dados.agentes}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                            <i className="ni ni-user-run" />
                          </div>
                        </div>
                        {/* <div className="col-auto">
                          <button
                            type="button"
                            className="btn btn-sm btn-neutral mr-0"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-h" />
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-gradient-red py-3 border-0">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0 text-white">
                            Em Andamento
                          </h5>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            R${" "}
                            {dados.producao && parseFloat(dados.producao) > 0
                              ? funcoes.formataMoeda(dados.producao)
                              : "0,00"}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-gradient-info border-0 py-3">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0 text-white">
                            Simulações
                          </h5>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {dados.simulacoes}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-default text-white rounded-circle shadow">
                            <i className="ni ni-books" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-gradient-success py-3 border-0">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0 text-white">
                            Propostas
                          </h5>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {dados.propostas}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className="ni ni-briefcase-24" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {window.location.pathname === "/relatorios_crm" && (
              <>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">
                            Total
                          </h5>
                          <span className="h2 font-weight-bold mb-0">
                            {dados.total}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="ni ni-user-run" />
                          </div>
                        </div>
                      </div>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">
                          Total mailings ativos
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">
                            Finalizados
                          </h5>
                          <span className="h2 font-weight-bold mb-0">
                            {dados.finalizados}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                            <i className="ni ni-chat-round" />
                          </div>
                        </div>
                      </div>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">
                          Total de mailings finalizados
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">
                            FGTS
                          </h5>
                          <span className="h2 font-weight-bold mb-0">
                            {dados.fgts}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </div>
                      </div>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">
                          Total de mailings FGTS
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats">
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">
                            Consignado
                          </h5>
                          <span className="h2 font-weight-bold mb-0">
                            {dados.consignado}
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                            <i className="ni ni-briefcase-24" />
                          </div>
                        </div>
                      </div>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">
                          Total de mailings consignado
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
