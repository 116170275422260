const register = {
    banco_id: '',
    tipoContratoAgent: '',
    tipoContratoClient: '',
    taxaAgent: '',
    taxaClient: '',
    parcelaAgent: '',
    parcelaClient: ''
  };
  
  export default JSON.parse(JSON.stringify(register));
  