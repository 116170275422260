import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import moment from "moment";
import Modal from "../../../components/modal/Modal";
import TopMenu from "../../../components/topMenu/TopMenu";
import { getProposalStatus } from "../../../services/Proposal";
import {
  getReanalises,
  getReanaliseData,
  exportReanalises,
  atribuirReanalise,
  getAdms,
} from "../../../services/Reanalise";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import Select from "react-select";

function ReanaliseGeral() {
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [verTodos, setVerTodos] = useState(0);
  const [nome, setNome] = useState(null);
  const [status, setStatus] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [simulacao, setSimulacao] = useState(null);
  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState({});

  //atribuicao
  const [checkArray, setCheckArray] = useState([]);
  const [adms, setAdms] = useState([]);
  const [idAdm, setIdAdm] = useState(null);
  const [NomeAdm, setNomeAdm] = useState(null);
  const [idSimulacao, setIdSimulacao] = useState(null);

  const selectStatus = useRef();

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    onClear();
    setId(0);
  };
  const onCloseFilter = async () => {
    window.$("#modal-filter").modal("hide");
    setResult(null);
  };
  const onCloseAtribuir = () => {
    window.$("#modal-atribuir").modal("hide");
    window.$("#Adms").val(null);
    setIdAdm(null);
    setNomeAdm(null);
  };

  const onClear = async () => {
    setResult(null);
  };
  const onClearFilter = async () => {
    window.$("#nome").val(null);
    window.$("#cpf_filtro").val(null);
    window.$("#status").val(null);
    selectStatus.current.select.clearValue();
    setNome(null);
    setStatus(null);
    setCpf(null);
  };

  const onClearAtribuir = async () => {
    window.$("#Adms").val(null);
    setIdAdm(null);
    setNomeAdm(null);
  };

  const getStatus = async () => {
    try {
      setList(await getProposalStatus());
    } catch (err) {}
  };

  const getAdmsList = async () => {
    try {
      setAdms(await getAdms());
    } catch (err) {}
  };

  useEffect(() => {
    getData(null, null, null, 0);
    getStatus();
    getAdmsList();

    window.$("#modal-atribuir").on("hidden.bs.modal", function () {
      onCloseAtribuir();
    });
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: 
            <input
              className="custom-control-input-check"
              id="btnCheck"
              onChange={handleCheck}
              type="checkbox"
            />
          ,
          field: "check",
        },
        {
          label: "Simulação ID",
          field: "simulacao_id",
        },

        {
          label: "Data da Simulação",
          field: "criado_em",
        },
        {
          label: "Atribuído Para",
          field: "atribui",
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Nome",
          field: "nome",
        },
        {
          label: "Nascimento",
          field: "nascimento",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Status Simulação",
          field: "status_simulacao",
        },
        {
          label: "Status Proposta",
          field: "status_proposta",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            check:
              <input
                className="custom-control-input-check check"
                id={x.simulacao_id}
                onChange={handleCheck}
                type="checkbox"
              />
            ,
            simulacao_id: x.simulacao_id,
            criado_em: x.criado_em,
            nome: x.nome,
            status_simulacao: x.status_simulacao,
            produto_descricao: x.produto_descricao,
            atribui: verTodos === 0 ? "N/A" : x.adm_nome,
            cpf: x.cpf,
            nascimento: x.nascimento,
            email: x.email,
            status_proposta: (
              <span className="badge badge-dot mr-4">
                <i
                  className={`bg-${
                    [2, 7, 17, 18].includes(x.proposta_status_id)
                      ? "warning"
                      : [12, 20].includes(x.proposta_status_id)
                      ? "gray"
                      : [19].includes(x.proposta_status_id)
                      ? "danger"
                      : "gray"
                  }`}
                ></i>
                <span className="status">{x.status_proposta}</span>
              </span>
            ),
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      visualizar(x.proposta_id, x.simulacao_id);
                    }}
                  >
                    <i className="fas fa-eye"></i>
                    Visualizar
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      setIdSimulacao(x.simulacao_id);
                      // setTodos(0);
                      window.$("#modal-atribuir").modal("show");
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    {verTodos === 0 ? "Atribuir" : "Re-Atribuir"}
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  async function visualizar(id, simulacao_id) {
    setFormLoading(true);
    setId(simulacao_id);
    try {
      const response = await getReanaliseData(id ? id : 0, simulacao_id);
      setSimulacao(id ? null : response);
      setResult(id ? response : null);
      window.$(`#modal-${id ? "data" : "simulacao"}`).modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
      window.$("#modal-simulacao").modal("hide");
    } finally {
      setFormLoading(false);
    }
  }

  const atribuir = async (simulacao_id, idAdm) => {
    swal({
      title: `Tem certeza que quer atribuir a(s) reanálise(s)?`,
      text: `A reanálise desse(s) cliente(s) será atribuida a ${NomeAdm}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      let dados = JSON.stringify(checkArray);
      if (response) {
        const retorno = await atribuirReanalise(simulacao_id, idAdm, dados);
        if (retorno) {
          swal("Atribuição realizada com sucesso.", {
            icon: "success",
          });
          window.$("#modal-atribuir").modal("hide");
          setCheckArray([]);
          document.getElementById("btnAtribuir").style.display = "none";
          document.getElementById("btnVerAtribuidos").style.display = "inline-block";
          document.getElementById("btnNaoAtribuidos").style.display = "inline-block";
          getData(null, null, null, 0);
        } else {
          swal("Ocorreu um erro ao atribuir a(s) reanálise(s).", {
            icon: "error",
          });
        }
      } else {
        swal("Cancelado a atribuição dessa reanálise.", {
          icon: "info",
        });
      }
    });
  };

  const handleCheck = (e) => {
    let checked = e.target;
    let check = e.target.checked;
    let pos = null;
    let aux = checkArray;
    let checkbox = document.querySelectorAll(".check");
    let btnCheck = document.querySelector("#btnCheck");
    pos = checkArray.indexOf(parseInt(checked.id));

    btnCheck.addEventListener("change", () => {
      for (var i = 0; i < checkbox.length; i++) {
        if (check) {
          aux.push(parseInt(checkbox[i].id));
          checkbox[i].checked = true;
          document.getElementById("btnAtribuir").style.display = "inline-block";
          document.getElementById("btnVerAtribuidos").style.display = "none";
          document.getElementById("btnNaoAtribuidos").style.display = "none";
        } else {
          aux.splice(parseInt());
          checkbox[i].checked = false;
          document.getElementById("btnAtribuir").style.display = "none";
          document.getElementById("btnVerAtribuidos").style.display = "inline-block";
          document.getElementById("btnNaoAtribuidos").style.display = "inline-block";
        }
      }
    });

    if (pos === 0) {
      aux.shift();
    } else if (pos && pos > 0) {
      aux.splice(pos, 1);
      document.getElementById("btnAtribuir").style.display = "inline-block";
      document.getElementById("btnVerAtribuidos").style.display = "none";
      document.getElementById("btnNaoAtribuidos").style.display = "none";
    } else if (pos && pos == -1) {
      aux.push(parseInt(checked.id));
      document.getElementById("btnAtribuir").style.display = "inline-block";
      document.getElementById("btnVerAtribuidos").style.display = "none";
      document.getElementById("btnNaoAtribuidos").style.display = "none";
    }
    if(checkArray.length < 1){
      document.getElementById("btnAtribuir").style.display = "none";
      document.getElementById("btnVerAtribuidos").style.display = "inline-block";
      document.getElementById("btnNaoAtribuidos").style.display = "inline-block";
    }
    setCheckArray(aux);
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(status, nome, cpf, verTodos);
  };

  const handleExport = async () => {
    swal({
      title: "Exportar Reanálise?",
      text: "Será exportado todas as reanálise cadastradas no sistema.",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await exportReanalises();
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV +=
            `Relatório de Reanálises - ${moment().format("MMMM YYYY")}` +
            "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar as reanalises", {
              icon: "error",
            });
          }

          let nomeArquivo = `reanalise-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFormLoading(false);
          swal("Reanálise exportada com sucesso.", {
            icon: "success",
          });
        } else {
          setFormLoading(false);
          swal("Ocorreu um erro ao exportar as reanálises", {
            icon: "error",
          });
        }
      } else {
        swal("As reanálises não foram exportadas.", {
          icon: "info",
        });
      }
    });
  };

  const disabledBtn = useMemo(() => {
    return NomeAdm === null;
  }, [NomeAdm]);

  const getData = async (status, nome, cpf, verTodos) => {
    try {
      setData();
      setStatus(status);
      setNome(nome);
      setCpf(cpf);
      setVerTodos(verTodos);
      setData(await getReanalises(status, nome, cpf, verTodos));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      <Menu />
      <div className="main-content" id="panel">
        <TopMenu />
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Exportar CSV",
              icone: (
                <i
                  className="fas fa-file-csv"
                  style={{ marginLeft: "5px" }}
                ></i>
              ),
              onClick: handleExport,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        <div className="container-fluid mt--5">
          <div className="card">
            <div className="card-header border-0">
              <>
                <div className="text-center">
                  <button
                    className="btn btn-neutral bg-dark-green text-white"
                    style={{display: "none", margin: "auto"}}
                    id="btnAtribuir"
                    onClick={() => {
                      window.$("#modal-atribuir").modal("show");
                    }}
                  >
                    Atribuir Selecionados
                  </button>
                  <div>
                    
                  </div>
                  <button
                    className="btn btn-neutral" 
                    id="btnVerAtribuidos"
                     style={{ margin: "auto"}}
                    onClick={() => {
                        document.getElementById(
                          "btnAtribuir"
                        ).style.display = "none";
                        setFormLoading(true);
                        getData(null, null, null, 0);
                        setFormLoading(false);
                    }}
                  >
                    Não Atribuídos
                    <i
                      className={
                         "fa fa-eye-slash fa-lg" 
                      }
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </button>
                  <button
                    className="btn btn-neutral" 
                    id="btnNaoAtribuidos"
                    style={{ margin: "auto"}}
                    onClick={() => {
                        document.getElementById(
                          "btnAtribuir"
                        ).style.display
                         = "none";
                        setFormLoading(true);
                        getData(null, null, null, 1);
                        setFormLoading(false);
                    }}
                  >
                    Atribuídos
                    <i
                      className={
                         "fa fa-eye fa-lg" 
                      }
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </button>
                </div>

                <MDBDataTableV5
                  data={datatable && datatable.rows ? datatable : []}
                  searchLabel="Buscar"
                  entriesLabel="Registros por Página"
                  hover
                  striped={true}
                  entriesOptions={[15, 30, 45]}
                  pagingTop
                  searchTop
                  searchBottom={false}
                  responsive={true}
                  infoLabel={["", "a", "de", "Registros"]}
                  entries={15}
                  maxHeight="500px"
                />
              </>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      <Modal
        id={"modal-data"}
        title={"Reanálise - Visualizar"}
        size={"xl"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados do Cliente</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {result &&
                    result.cliente.blocos.map((x) => {
                      if (
                        x.id !== "numero_beneficio" &&
                        x.id !== "cartao_magnetico"
                      ) {
                        return (
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>{x.nome}</label>
                              <Input
                                id={x.id}
                                type="text"
                                className="form-control"
                                value={x.valor}
                                disabled= {true}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados da Proposta</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {result &&
                    result.proposta.blocos.map((x) => {
                      if (
                        x.id !== "valor_imposto" &&
                        x.id !== "comissao_seucredito" &&
                        x.id !== "comissao" &&
                        x.id !== "comissao_master" &&
                        x.id !== "valor_liquido"
                      ) {
                        if (x.id === "data_integracao") {
                          return (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{x.nome}</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="ni ni-calendar-grid-58"></i>
                                    </span>
                                  </div>
                                  <Input
                                    id={x.id}
                                    type="birthday"
                                    className="form-control"
                                    value={x.valor}
                                    help={errors.data_integracao || ""}
                                    disabled= {true}

                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{x.nome}</label>
                                <Input
                                  id={x.id}
                                  type="text"
                                  className="form-control"
                                  value={x.valor}
                                  disabled= {true}

                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onReset}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* Modal de simulacoes */}
      <Modal
        id={"modal-simulacao"}
        title={"Reanálise - Visualizar"}
        size={"xl"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados da simulação do Cliente</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {simulacao &&
                    simulacao.simulacao.produto_id != "5" &&
                    simulacao.simulacao.blocos.map((x) => {
                      if (x.id !== "saldo_fgts" && x.id !== "modelo_saque") {
                        return (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{x.nome}</label>
                              <Input
                                id={x.id}
                                type="text"
                                className="form-control"
                                value={x.valor}
                                disabled= {true}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}

                  {/* FGTS */}
                  {simulacao &&
                    simulacao.simulacao.produto_id === "5" &&
                    simulacao.simulacao.blocos.map((x) => (
                      <div className="form-group col-md-4" key={x.id}>
                          <label>{x.nome}</label>
                          <Input
                            id={x.id}
                            type="text"
                            className="form-control"
                            value={x.valor}
                            disabled= {true}
                          />
                        </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onReset}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        }
      />

      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onCloseFilter}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados a serem Filtrados</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group">
                    <Input
                      id="nome"
                      className="form-control"
                      placeholder="Nome..."
                      type="text"
                      value={nome}
                      onChange={(e) => setNome(e.target.value || null)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group ">
                    <Input
                      id="cpf_filtro"
                      className="form-control"
                      placeholder="CPF..."
                      type="cpfCnpj"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value || null)}
                    />
                  </div>
                </div>

                    <div className="form-group mb-3">
                        <Select
                          id="status"
                          ref={selectStatus}
                          className="select-form-control"
                          placeholder="Status Proposta..."
                          onChange={(e) => { if(e){setStatus(e.value || null)}}}
                          options={list}
                        />
                </div>

                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClearFilter}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <Modal
        id={"modal-atribuir"}
        title={"Atribuir"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onCloseAtribuir}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">A quem deseja atribuir?</h3>
              </div>
              <div className="card-body">
                <div className="col-lg-12 ">
                  <div className="form-group">
                    <Select
                      id="Adms"
                      type="text"
                      className="form-control-select "
                      placeholder="Selecione uma opção..."
                      onChange={(e) => {
                        if (e) {
                          setNomeAdm(e.label);
                          setIdAdm(e.value);
                          setErrors({ ...errors, adm: null });
                        } else {
                          setErrors({
                            ...errors,
                            adm: "Campo Obrigatório",
                          });
                        }
                      }}
                      options={adms ?? []}
                      value={{
                        label: NomeAdm ?? "Selecione um Usuário...",
                      }}
                      backspaceRemovesValue
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClearAtribuir}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={() => {
                        atribuir(idSimulacao, idAdm, checkArray);
                      }}
                      disabled={disabledBtn}
                      className="btn btn-primary btn-block my-4"
                    >
                      Atribuir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
export default ReanaliseGeral;
