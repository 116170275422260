import moment from "moment";
import { Store } from "../../redux/Store"
import instance from "../index";

export const listarSolicitacoes = async (datacadastro_sol, codigo_usu, qtdesolicitada_sol, qtdeliberada_sol, codigo_sts, dataatendimento_sol, pagina) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_solicitacoes"
         const response = await instance.post(
            url,
            { datacadastro_sol, codigo_usu, qtdesolicitada_sol, qtdeliberada_sol, codigo_sts, dataatendimento_sol, pagina },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response)
         } else {
            reject(response)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const buscarSolicitacao = async (codigo_sol, codigo_usu) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/buscar_solicitacao"
         const response = await instance.post(
            url,
            { codigo_sol, codigo_usu },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const listarStatusSolicitacao = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_status_solicitacao"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const buscarConfiguracaoMailing = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/buscar_configuracao_mailing"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const listarSegmentosCnae = () => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/listar_segmentos_cnae"
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const gravarSolicitacao = (register) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token

         const url = "crm_unificado/administrativo/gravar_solicitacao"
         const response = await instance.post(
            url,
            { dados: register },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const gravarAtendimento = (register) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = "crm_unificado/administrativo/gravar_atendimento"
         const response = await instance.post(
            url,
            { dados: register },
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

export const cancelarSolicitacao = (codigo_sol) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = `crm_unificado/administrativo/cancelar?codigo_sol=${codigo_sol}`
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
}

/**
 * [**GET**] Responsável por acionar o back-end e verificar se o documento seja º convênio ou cnpj existe na base.
 * @name verificarDocumentoProspect
 * @function
 * @inner
 * @param {string} path - Rota do Express.
 * @param {callback} callback - Callback do Express.
 */
 export const verificarDocumentoProspect = (codigo_pro, documento_pro) => {
   return new Promise(async (resolve, reject) => {
      try {
         const token = Store.getState().user.token
         const url = `/crm_unificado/verificar-documento?codigo_pro=${codigo_pro}&documento_pro=${documento_pro}`
         const response = await instance.get(
            url,
            { headers: { token } }
         )

         if (response.status === 200) {
            resolve(response.data)
         } else {
            reject(response.data)
         }
      } catch (err) {
         reject(err)
      }
   })
 }