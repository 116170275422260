import React, { useState, useEffect, useRef } from "react";
import {
   listarMailing,
   listarStatusMailing,
   listarSegmentosCnae,
   buscarMailing,
   gravarSolicitacao,
   cancelarMailing,
   buscarSolicitacaoPrazo,
   recusarPrazoMailing,
   aprovarPrazoMailing,
} from "../../services/crm/Mailing";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Menu from "../../components/sidenav/Menu";
import Input from "../../components/input/Input";
import TopMenu from "../../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/modal/Modal";
import Select from "react-select";
import Loading from "../../components/loading/Loading";
import styles from "./style.module.css";
import { MDBDataTableV5 } from "mdbreact";
import funcoes from "../../utils/helpers/funcoes";
import moment from "moment";
import "moment/locale/pt-br";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

moment.locale("pt-br");

function CrmMailing() {
   //Dados do formulário:
   const [fullLoading, setFullLoading] = useState(false);
   const [data, setData] = useState(null);
   const [dataCadastroMai, setDataCadastroMai] = useState(null);
   const [codigoUsuMai, setCodigoUsuMai] = useState(null);
   const [qtdeSolicitadaSol, setQtdeSolicitadaSol] = useState(null);
   const [qtdeLiberadaSol, setQtdeLiberadaSol] = useState(null);
   const [codigoStm, setCodigoStm] = useState(null);
   const [dataAtendimentoMai, setDataAtendimentoMai] = useState(null);
   const [register, setRegister] = useState({});
   const [registerPropesct, setRegisterPropesct] = useState([]);
   const [solicitacoesAnteriores, setSolicitacoesAnteriores] = useState([]);
   const [status, setStatus] = useState([]);
   const [segmentos, setSegmentos] = useState([]);
   const [errors, setErrors] = useState({});
   const [page, setPage] = useState(1);

   const selectStatus = useRef();
   const selectSegmentos = useRef();


   const tipoCliente = [
      {
         label: "Cliente Correntista não FOPA",
         value: "Cliente Correntista não FOPA",
      },
      { label: "Cliente Correntista FOPA", value: "Cliente Correntista FOPA" },
      { label: "Não Correntista", value: "Não Correntista" },
   ];

   useEffect(() => {
      getData(
         dataCadastroMai,
         codigoUsuMai,
         qtdeSolicitadaSol,
         qtdeLiberadaSol,
         codigoStm,
         dataAtendimentoMai,
         page
      );

      window.$("#modal-data").on("hidden.bs.modal", function () {
         setRegister({});
         setErrors({});
         setStatus([]);
      });

      window.$("#modal-solicitacao-prazo").on("hidden.bs.modal", function () {
         setRegister({});
         setErrors({});
         setStatus([]);
         setCodigoUsuMai(null);
      });
   }, []);

   const getData = async (
      dataCadastro,
      codigoUsu,
      qtdeSolicitada,
      qtdeLiberada,
      codigoStmMai,
      dataAtendimento,
      pg = 1
   ) => {
      try {
         setData(null);
         setPage(pg);
         setDataCadastroMai(dataCadastro);
         setCodigoUsuMai(codigoUsu);
         setQtdeSolicitadaSol(qtdeSolicitada);
         setQtdeLiberadaSol(qtdeLiberada);
         setCodigoStm(codigoStmMai);
         setDataAtendimentoMai(dataAtendimento);
         const response = await listarMailing(
            dataCadastroMai,
            codigoUsuMai,
            qtdeSolicitadaSol,
            qtdeLiberadaSol,
            codigoStm,
            dataAtendimentoMai,
            page
         );

         if (response.data.retorno) {
            setData(response.data);
         } else {
            toast.error(
               response.data.mensagem
                  ? response.data.mensagem
                  : "Falha ao carregar dados!"
            );
            setData(null);
         }
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async (pg) => getData(pg);

   const editar = async (codigo_mai, codigo_usu) => {
      try {
         setFullLoading(true);
         const response = await buscarMailing(codigo_mai, codigo_usu);
         const responseStatus = await listarStatusMailing();
         const responseSegmentos = await listarSegmentosCnae();
         if (responseStatus.retorno) {
            setStatus(responseStatus.dados);
         }

         if (responseSegmentos.retorno) {
            setSegmentos(responseSegmentos.dados);
         }

         if (response.retorno) {
            setRegister(response.dados[0]);
            setRegisterPropesct(response.dados[0].registros);
            window.$("#modal-data").modal("show");
         } else {
            toast.warn(response.mensagem);
            window.$("#modal-data").modal("hide");
         }
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
         console.warn(err.message);
         window.$("#modal-data").modal("hide");
      } finally {
         setFullLoading(false);
         window.$("#modal-data").modal("hide");
      }
   };

   const solicitacaoPrazo = async (codigo_mai, codigo_usu) => {
      try {
         setFullLoading(true);
         setCodigoUsuMai(codigo_usu);
         const response = await buscarSolicitacaoPrazo(codigo_mai);
         if (response.retorno && response.dados) {
            const dados = response.dados;
            let dadosRegistros =
               dados[0].registros.length > 0 ? dados[0].registros[0] : null;
            if (dadosRegistros) {
               dadosRegistros.prazo_mpe = dadosRegistros.prazo_mpe
                  ? moment(
                       dadosRegistros.prazo_mpe,
                       "DD/MM/YYYY HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss")
                  : null;
               dadosRegistros.prazo_mpe = new Date(dadosRegistros.prazo_mpe);
               setRegister(dadosRegistros);
            }
            setSolicitacoesAnteriores(dados);
            window.$("#modal-solicitacao-prazo").modal("show");
         } else {
            toast.warn(
               response.mensagem
                  ? response.mensagem
                  : "Não foi encontrada nenhuma solicitação de prazo extra para o mailing."
            );
            window.$("#modal-solicitacao-prazo").modal("hide");
         }
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
         console.warn(err.message);
         window.$("#modal-solicitacao-prazo").modal("hide");
      } finally {
         setFullLoading(false);
         window.$("#modal-solicitacao-prazo").modal("hide");
      }
   };

   const cancelar = async (codigo_mai) => {
      try {
         swal({
            title: "Cancelar o mailing?",
            text: `O mailing de código: ${codigo_mai} será cancelado, tem certeza?`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFullLoading(true);
               const retorno = await cancelarMailing(codigo_mai);
               if (retorno.retorno) {
                  setFullLoading(false);
                  await getData(
                     dataCadastroMai,
                     codigoUsuMai,
                     qtdeSolicitadaSol,
                     qtdeLiberadaSol,
                     codigoStm,
                     dataAtendimentoMai,
                     page
                  );
                  swal(retorno.mensagem, {
                     icon: "success",
                  });
               } else {
                  setFullLoading(false);
                  swal(retorno.mensagem, {
                     icon: "error",
                  });
               }
            } else {
               setFullLoading(false);
               swal("O mailing não foi cancelado.", {
                  icon: "info",
               });
            }
         });
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
      } finally {
         setFullLoading(false);
      }
   };

   const recusarPrazo = async (
      codigo_mpe,
      codigo_mai,
      observacaoatendimento_mpe
   ) => {
      try {
         swal({
            title: "Recusar a solicitação de prazo?",
            text: `A solicitação de prazo do mailing: ${codigo_mai} será recusado, tem certeza?`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFullLoading(true);
               const retorno = await recusarPrazoMailing(
                  codigo_mpe,
                  codigo_mai,
                  observacaoatendimento_mpe
               );
               if (retorno.retorno) {
                  window.$("#modal-solicitacao-prazo").modal("hide");
                  setFullLoading(false);
                  await getData(
                     dataCadastroMai,
                     codigoUsuMai,
                     qtdeSolicitadaSol,
                     qtdeLiberadaSol,
                     codigoStm,
                     dataAtendimentoMai,
                     page
                  );
                  swal(retorno.mensagem, {
                     icon: "success",
                  });
               } else {
                  setFullLoading(false);
                  swal(retorno.mensagem, {
                     icon: "error",
                  });
               }
            } else {
               setFullLoading(false);
               swal("O prazo do mailing não foi recusado.", {
                  icon: "info",
               });
            }
         });
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
      } finally {
         setFullLoading(false);
      }
   };

   const aprovarPrazo = async (
      codigo_mpe,
      codigo_mai,
      observacaoatendimento_mpe,
      prazo_mpe
   ) => {
      try {
         prazo_mpe = prazo_mpe
            ? moment(prazo_mpe).format("YYYY-MM-DD HH:mm:ss")
            : null;
         swal({
            title: "Aprovar a solicitação de prazo?",
            text: `A solicitação de prazo do mailing: ${codigo_mai} será aprovada, tem certeza?`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFullLoading(true);
               const retorno = await aprovarPrazoMailing(
                  codigo_mpe,
                  codigo_mai,
                  observacaoatendimento_mpe,
                  prazo_mpe
               );
               if (retorno.retorno) {
                  window.$("#modal-solicitacao-prazo").modal("hide");
                  setFullLoading(false);
                  await getData(
                     dataCadastroMai,
                     codigoUsuMai,
                     qtdeSolicitadaSol,
                     qtdeLiberadaSol,
                     codigoStm,
                     dataAtendimentoMai,
                     page
                  );
                  swal(retorno.mensagem, {
                     icon: "success",
                  });
               } else {
                  setFullLoading(false);
                  swal(retorno.mensagem, {
                     icon: "error",
                  });
               }
            } else {
               setFullLoading(false);
               swal("O prazo do mailing não foi aprovado.", {
                  icon: "info",
               });
            }
         });
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
      } finally {
         setFullLoading(false);
      }
   };

   function handleChange(e) {
      let { id, value } = e.target;
      if (id.indexOf("_solicitacao") >= 0) {
         id = id.replace("_solicitacao");
      }

      switch (id) {
         default:
            setErrors((prevErrors) => ({
               ...prevErrors,
               [id]: value ? null : "Campo obrigatório",
            }));
            setRegister({ ...register, [id]: value });
            break;
      }
   }

   async function handleSave(event) {
      event.preventDefault();

      try {
         setFullLoading(true);
         const response = await gravarSolicitacao(register);
         if (response.retorno) {
            await getData(
               dataCadastroMai,
               codigoUsuMai,
               qtdeSolicitadaSol,
               qtdeLiberadaSol,
               codigoStm,
               dataAtendimentoMai,
               page
            );
            toast.success(response.mensagem);
            window.$("#modal-data").modal("hide");
         } else {
            toast.warn(response.mensagem);
         }
      } catch (err) {
         setFullLoading(false);
      } finally {
         setFullLoading(false);
      }
   }

   function handleChangeFiltro(e) {
      const { id, value } = e.target;
      let filtro_mai = register.filtro_mai;
      let v = value;

      switch (id) {
         case "documento_pro":
            if (filtro_mai && filtro_mai.length > 0) {
               filtro_mai.find((x) => x.id === id).valor =
                  parseInt(register.codigo_pro) === 3
                     ? funcoes.mascaraCpfCnpj(value)
                     : value;
            }
            setRegister({ ...register, filtro_mai });
            break;
         case "margemmin_pro":
         case "margemmax_pro":
         case "valorparcelamin_pro":
         case "valorparcelamax_pro":
         case "saldodevedormin_pro":
         case "saldodevedormax_pro":
            if (filtro_mai && filtro_mai.length > 0) {
               if (v && v !== null) {
                  v = funcoes.formatarMoedaMilhar(v);
                  filtro_mai.find((x) => x.id === id).valor = v;
               }
            }
            setRegister({ ...register, filtro_mai });
            break;
         case "taxaammin_pro":
         case "taxaammax_pro":
            if (filtro_mai && filtro_mai.length > 0) {
               if (v && v !== null) {
                  v = v.replace(/\D/g, "");
                  if (parseFloat(v) / 100 <= 100) {
                     v = (v / 100).toFixed(2) + "";
                  }
                  filtro_mai.find((x) => x.id === id).valor = v;
               }
            }
            setRegister({ ...register, filtro_mai });
            break;
         default:
            if (filtro_mai && filtro_mai.length > 0) {
               filtro_mai.find((x) => x.id === id).valor = value;
            }
            setRegister({ ...register, filtro_mai });
            break;
      }
   }

   const datatable = {
      columns: [
         {
            label: "Id",
            field: "codigo_mai",
         },
         {
            label: "Data Cadastro",
            field: "datacadastro_mai",
         },
         {
            label: "Solicitante / Sistema",
            field: "codigo_usu",
         },
         {
            label: "Qt. Liberada",
            field: "qtdeliberada_sol",
         },
         {
            label: "Qt. Solicitada",
            field: "qtdesolicitada_sol",
         },
         {
            label: "Status",
            field: "nome_stm",
         },
         {
            label: "Data Atendimento",
            field: "dataatendimento_mai",
         },
         {
            label: "Ação",
            field: "action",
         },
      ],
      rows:
         data &&
         data.dados.map((x) => {
            return {
               codigo_mai: x.codigo_mai,
               datacadastro_mai: x.datacadastro_mai,
               codigo_usu: `${x.codigo_usu} - ${x.nome_sis}`,
               qtdeliberada_sol: x.qtdeliberada_sol,
               qtdesolicitada_sol: x.qtdesolicitada_sol,
               nome_stm: (
                  <span className="badge badge-dot mr-4">
                     <i
                        className={`bg-${funcoes.retornarStatusMailing(
                           x.codigo_stm
                        )}`}
                     ></i>
                     <span className="status">{x.nome_stm}</span>
                  </span>
               ),
               dataatendimento_mai: x.dataatendimento_mai,
               action: (
                  <div className="dropdown">
                     <a
                        className="btn btn-sm btn-icon-only text-light"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <i className="fas fa-ellipsis-v" />
                     </a>
                     <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <button
                           type="button"
                           className="dropdown-item"
                           onClick={() => editar(x.codigo_mai, x.codigo_usu)}
                        >
                           <i className="fas fa-eye"></i>
                           Visualizar
                        </button>

                        {x.codigo_stm === 2 && (
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                 solicitacaoPrazo(x.codigo_mai, x.codigo_usu)
                              }
                           >
                              <i className="fas fa-calendar"></i>
                              Solicitação de Prazo
                           </button>
                        )}

                        {[2].includes(x.codigo_stm) && (
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() => cancelar(x.codigo_mai)}
                           >
                              <i className="fas fa-close"></i>
                              Cancelar
                           </button>
                        )}
                     </div>
                  </div>
               ),
            };
         }),
   };

   const dataTableRegistrosProspect = {
      columns: [
         {
            label: "Id",
            field: "codigo_pro",
         },
         {
            label: "Data Cadastro",
            field: "datacadastro_pro",
         },
         {
            label: "CPF",
            field: "cpf_pro",
         },
         {
            label: "Nome",
            field: "nome_pro",
         },
         {
            label: "Contato",
            field: "fone1_pro",
         },
         {
            label: "Idade",
            field: "idade_pro",
         },
         {
            label: "Cidade - UF",
            field: "cidade_pro",
         },
         {
            label: "Status",
            field: "status_map",
         },
         {
            label: "Atualizado Em",
            field: "dataatualizado_pro",
         },
      ],
      rows:
         registerPropesct &&
         registerPropesct.map((x) => {
            return {
               codigo_pro: x.codigo_pro,
               datacadastro_pro: x.datacadastro_pro,
               cpf_pro: x.cpf_pro,
               nome_pro: x.nome_pro,
               fone1_pro: x.fone1_pro,
               idade_pro: x.idade_pro,
               cidade_pro: `${x.cidade_pro} - ${x.uf_pro}`,
               dataatualizado_pro: x.dataatualizado_pro,
               status_map: (
                  <span className="badge badge-dot mr-4">
                     <i
                        className={`bg-${
                           x.status_map == 0 ? "danger" : "success"
                        }`}
                     ></i>
                     <span className="status">
                        {x.status_map == 0 ? "Não atendito" : "Atendido"}
                     </span>
                  </span>
               ),
               dataatendimento_mai: x.dataatendimento_mai,
            };
         }),
   };

   return (
      <div>
         {fullLoading && <Loading />}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Filtrar",
                     icone: (
                        <i
                           className="fas fa-search"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>

         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Detalhes do Mailing"}
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        // onClick={onClear}
                     >
                        x
                     </span>
                  </button>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados Mailing</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-7">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Descrição*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="descricao_mai"
                                       placeholder="Informe descrição..."
                                       value={register.descricao_mai || ""}
                                       helpType="error"
                                       help={errors.descricao_mai || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-5">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Solicitante / Sistema*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="codigo_usu"
                                       placeholder="Informe o usuário..."
                                       value={
                                          register.codigo_usu +
                                             " / " +
                                             register.nome_sis || ""
                                       }
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Cadastro*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="datacadastro_mai"
                                       placeholder="Informe a data de cadastro..."
                                       value={register.datacadastro_mai || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Produto Mailing*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="nome_pro"
                                       placeholder="Informe o produto..."
                                       value={register.nome_pro || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Status*
                                 </label>
                                 <Select
                                    id="codigo_stm"
                                    ref={selectStatus}
                                    className={`${styles.padding} form-control-select`}
                                    placeholder="Selecione o status da solicitação..."
                                    value={{
                                       label: register.nome_stm
                                          ? register.nome_stm
                                          : "Selecione o status da solicitação...",
                                    }}
                                    onChange={(e) => {
                                       if (e && e.value) {
                                          setRegister({
                                             ...register,
                                             codigo_stm: e.value,
                                             nome_stm: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             codigo_stm: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             codigo_stm: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={status}
                                    backspaceRemovesValue
                                    isDisabled={true}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Solicitada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdesolicitada_sol"
                                       placeholder="Informe o quantidade solicitada..."
                                       value={register.qtdesolicitada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Liberada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdeliberada_sol"
                                       placeholder="Informe o quantidade liberada..."
                                       value={register.qtdeliberada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Solicitação*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacao_sol"
                                       className="form-control"
                                       aria-label="Observação Solicitação"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacao_sol: e.value,
                                          });
                                       }}
                                       value={register.observacao_sol}
                                       readonly="true"
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Atendimento</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="dataatendimento_mai"
                                       placeholder="Informe a data de atendimento..."
                                       value={
                                          register.dataatendimento_mai || ""
                                       }
                                       helpType="error"
                                       help={errors.dataatendimento_mai || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Prazo Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="prazoatendimento_mai"
                                       placeholder="Informe o prazo de atendimento..."
                                       value={
                                          register.dataatendimento_mai || ""
                                       }
                                       helpType="error"
                                       help={errors.dataatendimento_mai || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacaoatendimento_mai"
                                       className="form-control"
                                       aria-label="Observação Atendimento"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacaoatendimento_mai:
                                                e.target.value,
                                          });
                                       }}
                                       value={
                                          register.observacaoatendimento_mai
                                       }
                                       readonly="true"
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Filtros Solicitação</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    CNPJ / Nº Convênio*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="documento_pro"
                                       placeholder="Informe documento..."
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) =>
                                                     x.id === "documento_pro"
                                               ).valor
                                             : ""
                                       }
                                       helpType="error"
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Razão Social / Nome Convênio*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="razaoconvenio_pro"
                                       placeholder="Informe a razão ou nome convênio..."
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) =>
                                                     x.id ===
                                                     "razaoconvenio_pro"
                                               ).valor
                                             : ""
                                       }
                                       helpType="error"
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-4 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    DDD Range*
                                 </label>
                                 <div className="input-group">
                                    <input
                                       id="dddmin_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="DDD Inicial"
                                       placeholder="Ex: 11"
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "dddmin_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                    <div className="input-group-prepend">
                                       <span className="input-group-text border-right-0">
                                          e
                                       </span>
                                    </div>
                                    <input
                                       id="dddmax_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="DDD Final"
                                       placeholder="Ex: 18"
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "dddmax_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-4 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Idade Range*
                                 </label>
                                 <div className="input-group">
                                    <input
                                       id="idademin_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="Idade Inicial"
                                       placeholder="Ex: 11"
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "idademin_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                    <div className="input-group-prepend">
                                       <span className="input-group-text border-right-0">
                                          e
                                       </span>
                                    </div>
                                    <input
                                       id="idademax_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="Idade Final"
                                       placeholder="Ex: 18"
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "idademax_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>

                           {register.codigo_pro != 3 && (
                              <>
                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Margem Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="margemmin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Margem Inicial"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "margemmin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="margemmax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Margem Final"
                                             placeholder="Ex: 180,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "margemmax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Pagas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelapagamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelapagamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelapagamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelapagamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Vencidas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelavencidamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelavencidamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelavencidamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelavencidamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Em Aberto Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelaemabertomin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelaemabertomin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelaemabertomax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelaemabertomax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Total De Parcelas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Valor Parcela Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="valorparcelamin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o valor da parcela"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "valorparcelamin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="valorparcelamax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o valor da parcela"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "valorparcelamax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Saldo Devedor Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="saldodevedormin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o saldo devedor"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "saldodevedormin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="saldodevedormax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o saldo devedor máximo"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id ===
                                                           "saldodevedormax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Taxa Am% Range *
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="taxaammin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe a taxa"
                                             placeholder="Ex: 1.20"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id === "taxaammin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             maxLength={5}
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="taxaammax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe a taxa"
                                             placeholder="Ex: 1.20"
                                             value={
                                                register.filtro_mai &&
                                                register.filtro_mai.length > 0
                                                   ? register.filtro_mai.find(
                                                        (x) =>
                                                           x.id === "taxaammax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             maxLength={5}
                                             onChange={handleChangeFiltro}
                                             disabled={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )}

                           <div className="col-lg-6 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Cidade*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="cidade_pro"
                                       placeholder="Informe a cidade..."
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "cidade_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-2 col-sm-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    UF*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="uf_pro"
                                       placeholder="UF..."
                                       value={
                                          register.filtro_mai &&
                                          register.filtro_mai.length > 0
                                             ? register.filtro_mai.find(
                                                  (x) => x.id === "uf_pro"
                                               ).valor
                                             : ""
                                       }
                                       maxLength={2}
                                       onChange={handleChangeFiltro}
                                       disabled={true}
                                    />
                                 </div>
                              </div>
                           </div>
                           {register.codigo_pro == 3 && (
                              <div className="col-lg-6 col-sm-6">
                                 <div className="form-group">
                                    <label className="form-control-label">
                                       Segmentos Cnae*
                                    </label>
                                    <Select
                                       label="Selecione o tipo do cliente"
                                       id="codigo_sgc"
                                       placeholder="Selecione o tipo do cliente...."
                                       options={segmentos}
                                       onChange={(e) => {
                                          if (e && e.value) {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "codigo_sgc",
                                                   value: e.value,
                                                },
                                             });
                                          } else {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "codigo_sgc",
                                                   value: null,
                                                },
                                             });
                                          }
                                       }}
                                       value={{
                                          label:
                                             register.filtro_mai.find(
                                                (y) => y.id === "codigo_sgc"
                                             ).valor &&
                                             segmentos &&
                                             segmentos.find(
                                                (x) =>
                                                   x.value ===
                                                   register.filtro_mai.find(
                                                      (y) =>
                                                         y.id === "codigo_sgc"
                                                   ).valor
                                             ).label,
                                       }}
                                       className={`${styles.padding} form-control-select`}
                                       isDisabled={true}
                                    />
                                 </div>
                              </div>
                           )}

                           {register.codigo_pro != 3 && (
                              <div className="col-lg-6 col-sm-6">
                                 <div className="form-group">
                                    <label className="form-control-label">
                                       Tipo Cliente*
                                    </label>
                                    <Select
                                       label="Selecione o tipo do cliente"
                                       id="tipocliente_pro"
                                       placeholder="Selecione o tipo do cliente...."
                                       options={tipoCliente}
                                       onChange={(e) => {
                                          if (e && e.value) {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "tipocliente_pro",
                                                   value: e.value,
                                                },
                                             });
                                          } else {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "tipocliente_pro",
                                                   value: null,
                                                },
                                             });
                                          }
                                       }}
                                       value={{
                                          label:
                                             register.filtro_mai &&
                                             register.filtro_mai.length > 0
                                                ? register.filtro_mai.find(
                                                     (x) =>
                                                        x.id ===
                                                        "tipocliente_pro"
                                                  ).valor
                                                : "",
                                       }}
                                       className={`${styles.padding} form-control-select`}
                                       isDisabled={true}
                                    />
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Listagem de Registros Mailing</h3>
                     </div>
                     <div className="card-body">
                        <MDBDataTableV5
                           // onPageChanged={onPageChanged}
                           data={
                              dataTableRegistrosProspect &&
                              dataTableRegistrosProspect.rows
                                 ? dataTableRegistrosProspect
                                 : []
                           }
                           searchLabel="Buscar"
                           entriesLabel="Registros por Página"
                           hover
                           striped={true}
                           entriesOptions={[10, 20, 30]}
                           pagingTop
                           searchTop
                           searchBottom={false}
                           responsive={true}
                           infoLabel={["", "a", "de", "Registros"]}
                           entries={10}
                           maxHeight="500px"
                        />
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente justify-content-center">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Fechar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         {/* Modal Solicitação Prazo */}
         <Modal
            id={"modal-solicitacao-prazo"}
            title={"Detalhes - Solicitação Prazo"}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span arial-hidden="true" className="text-white">
                        x
                     </span>
                  </button>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">
                           Dados da Solicitação - Solicitante: {codigoUsuMai}
                        </h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-5">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data do Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="dataatendimento_mai_solicitacao"
                                       placeholder="Informe descrição..."
                                       value={
                                          register.dataatendimento_mai || ""
                                       }
                                       helpType="error"
                                       help={errors.dataatendimento_mai || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-7">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Prazo Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text px-2">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <DatePicker
                                       id="prazo_mpe"
                                       dateFormat="dd/MM/yyyy HH:mm:ss"
                                       locale="pt"
                                       className="form-control px-2"
                                       placeholderText="Data final..."
                                       showTimeInput
                                       timeInputLabel="Horário:"
                                       selected={register.prazo_mpe}
                                       onChange={(date) => {
                                          setRegister({
                                             ...register,
                                             prazo_mpe: date,
                                          });
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Solicitação*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacao_mpe"
                                       className="form-control"
                                       aria-label="Observação Solicitação"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacao_mpe: e.target.value,
                                          });
                                       }}
                                       value={register.observacao_mpe}
                                       disabled={true}
                                    ></textarea>
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacaoatendimento_mpe"
                                       className="form-control"
                                       aria-label="Observação Solicitação"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacaoatendimento_mpe:
                                                e.target.value,
                                          });
                                       }}
                                       value={
                                          register.observacaoatendimento_mpe
                                       }
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Solicitações Anteriores</h3>
                     </div>
                     <div className="card-body">
                        <div className="table-responsive">
                           <div>
                              <table className="table align-items-center">
                                 <thead className="thead-light">
                                    <tr>
                                       <th scope="col">ID</th>
                                       <th scope="col">Prazo</th>
                                       <th scope="col">Observação</th>
                                       <th scope="col">
                                          Observação Atendimento
                                       </th>
                                       <th scope="col">Status</th>
                                    </tr>
                                 </thead>
                                 <tbody className="list">
                                    {solicitacoesAnteriores &&
                                       solicitacoesAnteriores.map((x) => (
                                          <tr>
                                             <td className="budget">
                                                {x.codigo_mpe}
                                             </td>
                                             <td className="budget">
                                                {x.prazo_mpe}
                                             </td>
                                             <td
                                                className="budget"
                                                title={x.observacao_mpe}
                                             >
                                                {x.observacao_mpe &&
                                                x.observacao_mpe !== ""
                                                   ? funcoes.limitarTexto(
                                                        x.observacao_mpe,
                                                        20
                                                     )
                                                   : ""}
                                             </td>
                                             <td
                                                className="budget"
                                                title={
                                                   x.observacaoatendimento_mpe
                                                }
                                             >
                                                {x.observacaoatendimento_mpe &&
                                                x.observacaoatendimento_mpe !==
                                                   ""
                                                   ? funcoes.limitarTexto(
                                                        x.observacaoatendimento_mpe,
                                                        20
                                                     )
                                                   : ""}
                                             </td>
                                             <td className="budget">
                                                <span className="badge badge-dot mr-4">
                                                   <i
                                                      className={`bg-${
                                                         x.status_mpe === 0
                                                            ? "warning"
                                                            : x.status_mpe === 1
                                                            ? "success"
                                                            : "danger"
                                                      }`}
                                                   ></i>
                                                   <span className="status">
                                                      {x.status_mpe === 0
                                                         ? "AGUARDANDO APROVAÇÃO"
                                                         : x.status_mpe === 1
                                                         ? "APROVADO"
                                                         : "RECUSADO"}
                                                   </span>
                                                </span>
                                             </td>
                                          </tr>
                                       ))}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente justify-content-center">
                     {register && register.prazo_mpe && (
                        <div className="col-md-3 text-center">
                           <button
                              type="button"
                              className="btn btn-warning btn-block"
                              onClick={() =>
                                 recusarPrazo(
                                    register.codigo_mpe,
                                    register.codigo_mai,
                                    register.observacaoatendimento_mpe
                                 )
                              }
                           >
                              Recusar
                           </button>
                        </div>
                     )}
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Fechar
                        </button>
                     </div>
                     {register && register.prazo_mpe && (
                        <div className="col-md-3 text-center">
                           <button
                              type="button"
                              className="btn btn-success btn-block"
                              onClick={() =>
                                 aprovarPrazo(
                                    register.codigo_mpe,
                                    register.codigo_mai,
                                    register.observacaoatendimento_mpe,
                                    register.prazo_mpe
                                 )
                              }
                           >
                              Aprovar
                           </button>
                        </div>
                     )}
                  </div>
               </div>
            }
         />

         <ToastContainer />
      </div>
   );
}

export default CrmMailing;
