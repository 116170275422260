import React from "react";
import { useState, useEffect } from "react";
import { getProjecoes } from "../../services/Api";
//charts
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//helpers
import funcoes from "../../utils/helpers/funcoes"

function RelProjecao({ userId }) {
  const [projecoes, setProjecoes] = useState([]);
  useEffect(() => {
    const buscarProjecoes = async () => {
      try {
        const response = await getProjecoes(userId);
        setProjecoes(response);
      } catch (err) {
        toast.error(err);
      }
    };

    buscarProjecoes();
  }, [userId]);

  const options = {
    chart: {
      style: {
        fontFamily: 'arial',
    },
      type: "column",
    },
    title: {
      text: "Relatório de Projeções",
    },
    subtitle: {
      text: '<a href="http://seucreditonegocios.com.br" target="_blank">seucreditonegocios.com.br</a>',
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      labels: {
        formatter: function () {
          return "R$ " + funcoes.formataMoeda(this.value);
        }
      },
      title: {
        text: "Valor da Projeção",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "R$ {point.y:.1f}",
        },
      },
      column: {
        borderRadius: 6
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>R$ {point.y:.2f}</b> do total<br/>',
    },

    series: [
      {
        name: "Produto",
        colorByPoint: true,
        data: [
          {
            name: "Consignado",
            y: projecoes.projecaoConsignado,
            drilldown: "Consignado",
          },
          {
            name: "Crédito Pessoal",
            y: projecoes.projecaoCp,
            drilldown: "Crédito Pessoal",
          },
          {
            name: "Consignado Cartão",
            y: projecoes.projecaoConsignadoCartao,
            drilldown: "Consignado Cartão",
          },
          {
            name: "Seguros",
            y: projecoes.projecaoSeguros,
            drilldown: "Seguros",
          },
          {
            name: "FGTS",
            y: projecoes.projecaoFgts,
            drilldown: "FGTS",
          },
          {
            name: "Emprestimo Conta de Luz",
            y: projecoes.projecaoContaDeLuz,
            drilldown: "Emprestimo Conta de Luz",
          },
          {
            name: "Aux. Brasil",
            y: projecoes.projecaoAuxBrasil,
            drilldown: "Aux. Brasil",
          },
          {
            name: "Total",
            y: projecoes.projecaoTotal,
            drilldown: "Total",
          },
        ],
      },
    ],
    drilldown: {
      series: [
        {
          name: "Chrome",
          id: "Chrome",
          data: [
            ["v65.0", 0.1],
            ["v64.0", 1.3],
            ["v63.0", 53.02],
            ["v62.0", 1.4],
            ["v61.0", 0.88],
            ["v60.0", 0.56],
            ["v59.0", 0.45],
            ["v58.0", 0.49],
            ["v57.0", 0.32],
            ["v56.0", 0.29],
            ["v55.0", 0.79],
            ["v54.0", 0.18],
            ["v51.0", 0.13],
            ["v49.0", 2.16],
            ["v48.0", 0.13],
            ["v47.0", 0.11],
            ["v43.0", 0.17],
            ["v29.0", 0.26],
          ],
        },
        {
          name: "Firefox",
          id: "Firefox",
          data: [
            ["v58.0", 1.02],
            ["v57.0", 7.36],
            ["v56.0", 0.35],
            ["v55.0", 0.11],
            ["v54.0", 0.1],
            ["v52.0", 0.95],
            ["v51.0", 0.15],
            ["v50.0", 0.1],
            ["v48.0", 0.31],
            ["v47.0", 0.12],
          ],
        },
        {
          name: "Internet Explorer",
          id: "Internet Explorer",
          data: [
            ["v11.0", 6.2],
            ["v10.0", 0.29],
            ["v9.0", 0.27],
            ["v8.0", 0.47],
          ],
        },
        {
          name: "Safari",
          id: "Safari",
          data: [
            ["v11.0", 3.39],
            ["v10.1", 0.96],
            ["v10.0", 0.36],
            ["v9.1", 0.54],
            ["v9.0", 0.13],
            ["v5.1", 0.2],
          ],
        },
        {
          name: "Edge",
          id: "Edge",
          data: [
            ["v16", 2.6],
            ["v15", 0.92],
            ["v14", 0.4],
            ["v13", 0.1],
          ],
        },
        {
          name: "Opera",
          id: "Opera",
          data: [
            ["v50.0", 0.96],
            ["v49.0", 0.82],
            ["v12.1", 0.14],
          ],
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
export default RelProjecao;
