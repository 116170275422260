import React from "react";
import { useState, useEffect } from "react";
import { getMelhoresAgentes } from "../../services/Api";
import { Store } from "../../redux/Store";
import funcoes from "../../utils/helpers/funcoes";

function BestAgents(props) {
  const [dados, setDados] = useState([]);
  const dadosStore = Store.getState().user;
  useEffect(() => {
    getBests();
  }, []);
  
  const getBests = async () => {
    try {
      setDados(await getMelhoresAgentes());
    } catch (err) {}
  };

  return (
    <div className="">
      {dados &&
        dados.map((x, index) => (
          <div key={index}>
            <ul className="list-group list-group-flush list my--3">
              {x.data[0] != 0 && x.data[0] > 0 ? (
                <li className="list-group-item px-2 ">
                  <div className="row align-items-center">
                      <i
                      className={`ml-3 fas fa-star text-${
                            [1].includes(x.nextval)
                          ? "yellow"
                          : [2].includes(x.nextval)
                          ? "info"
                          : [3].includes(x.nextval)
                          ? "success"
                          : "default"
                      }`}
                    ></i>
                    <div className="col-auto">
                      {dadosStore && x.selfie ? (
                        <img
                          alt=""
                          className="avatar avatar-sm rounded-circle"
                          src={x.selfie}
                        />
                      ) : (
                        <span
                          style={{ backgroundColor: "rgb(46,182,121)" }}
                          className="avatar avatar-sm rounded-circle"
                        >
                          {x.name.charAt(0).toUpperCase()}
                        </span>
                      )}
                    </div>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a className={` text-${
                            [1].includes(x.nextval)
                          ? "yellow"
                          : [2].includes(x.nextval)
                          ? "info"
                          : [3].includes(x.nextval)
                          ? "success"
                          : "default"
                      }`}>{x.name}</a>
                        <div className="col-auto">
                        </div>

                      </h4>
                    </div>
                    <div className="col-auto">
                      <small className="text-success font-weight-600">R$ </small>
                      <small className="font-weight-600">{funcoes.formatarMoeda(x.data[0])}</small>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        ))}
    </div>
  );
}
export default BestAgents;
