import { Store } from "../redux/Store";
import instance from "./index";

export const getContas = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token;
  
        const url = "configuracoes/listar-contas";
        const response = await instance.get(url, { headers: { token } });
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  export const inativarValorConta = async (id) => {
    try {
    const token = Store.getState().user.token

    const url = "configuracoes/inativar-conta-de-luz"
    const response = await instance.post(
        url,
        { id: id },
        { headers: { token } }
    )
        if (response.status === 200) {
            return response.data
        } else {
            return response.data
        }
    } catch (err) {
        return err;
    }
}

export const getValores = async (valor) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        const url = 'configuracoes/buscar-outros-valores-conta'
        const response = await instance.post(
          url,
          { valor }
        )
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }
export const buscarValores = async (valor) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        const url = 'configuracoes/buscar-valores-conta'
        const response = await instance.post(
          url,
          { valor }
        )
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }
export const getValor = async (valor, parcela) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        const url = 'configuracoes/buscar-valor'
        const response = await instance.post(
          url,
          { valor,parcela }
        )
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const saveValuesConta = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "configuracoes/gravar-valor"
        const response = await instance.post(
            url,
            data,
            { headers: { token } }
        )

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}