import React, { useState, useEffect, useMemo, useRef } from "react";
import {
   getProposalData,
   getProposalClientData,
   getProposals,
   getProposalStatus,
   getContract,
   getCep,
   getStates,
   getCitys,
   updateProposal,
   updateProposalAgibank,
   getObservations,
   recalculateCommissionAgent,
   recalculateCommissionProductAgent,
   calcularComissao,
   uploadDocumentsProposal,
   getDocumentsUploadProposal,
   formalizationProposal,
   cancelProposal,
   saveSaqueFgts,
   exportProposals,
   rulesBank,
   buscarClienteNovaProposta,
   buscarConveniosProposta,
   buscarOrigemProposta,
   gravarProposta,
   buscarParcelasRegra,
   atualizarPropostaInterna,
} from "../services/Proposal";

import { getContractsType } from "../services/Configurations";

import { listEstados, listByEstado, listPartners } from "../services/Api";
import { getAgents } from "../services/Agents";
import { getBanks } from "../services/Price";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import { Store } from "../redux/Store";
import Input from "../components/input/Input";
import FormErrors from "../components/formErrors/FormErrors";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";

import swal from "sweetalert";
import moment from "moment";
import funcoes from "../utils/helpers/funcoes";
import { MDBDataTableV5 } from "mdbreact";

const ID_WITH_OBS = ["3", "4", "7", "11", "14"];
const ID_DISABLED = [];
// const ID_DISABLED = ["3", "4", "7", "10", "11"];
const ARQUIVOS_PROPOSTA_AGIBANK = [
   {
      id: "RG",
      nome: "RG (Frente & Verso)",
   },
   {
      id: "CNH",
      nome: "CNH (Frente & Verso)",
   },
   {
      id: "COMPROVANTE_RESIDENCIA",
      nome: "Comprovante de Residência",
   },
   {
      id: "BIOMETRIA_FACIAL",
      nome: "Selfie",
   },
];

const ARQUIVOS_PROPOSTA_CETELEM = [
   {
      id: 1,
      nome: "Selfie",
   },
   {
      id: 2,
      nome: "Comprovante de Benéficio",
   },
   {
      id: 3,
      nome: "Comprovante de Endereço",
   },
   {
      id: 4,
      nome: "RG ou CNH (Frente & Verso)",
   },
   {
      id: 5,
      nome: "CCB Assinada",
   },
   {
      id: 6,
      nome: "Gravação de Voz",
   },
];

const optionsAutPagamento = [
   { value: 1, label: "CONTRATO FÍSICO" },
   { value: 2, label: "MOBILE" },
   { value: 3, label: "SMS" },
];

const optionsSetorConvenio = [
   { value: 1, label: "INSS" },
   { value: 2, label: "PRIVADO" },
   { value: 3, label: "PUBLICO" },
];

const optionsCanalVenda = [
   { value: 1, label: "TELEMARKETING" },
   { value: 2, label: "PESSOALMENTE - LOJA" },
   { value: 3, label: "PESSOALMENTE - VISITA" },
];

const optionsRemuneracao = [
   { value: 1, label: "NÃO REMUNERADA" },
   { value: 2, label: "PREMIUM" },
   { value: 3, label: "PREMIUM +" },
   { value: 4, label: "PURA" },
   { value: 5, label: "COMPETITIVA" },
   { value: 6, label: "TABELA CHEIA" },
];

const camposProposta = [
   "cpf_proposta",
   "nome_proposta",
   "nascimento_proposta",
   "renda",
   "celular_proposta",
   "email_proposta",
   "bancoPagamento",
   "numProposta",
   "dataEmissao",
   "autPagamento",
   "setorConvenio",
   "convenio",
   "matricula",
   "canalVenda",
   "origem",
   "tipoContrato",
   "integracaoProposta",
   "valBrutoProposta",
   "valLiquidoProposta",
   "remuneracaoProposta",
   "vencPrimeiraParcela",
   "qtdParcelas",
   "valorParcelas",
   "valorTotalParcelas",
   "endereco",
   "taxaProposta",
];

const camposVariaveis = ["iofProposta"];

const camposEndereco = ["cep", "rua", "numero", "bairro", "cidade", "estado"];

const camposProduto2 = [
   "bancoPortabilidade",
   "numContratoOriginal",
   "saldoDevedorSolicitado",
   "oriundaAgencia",
   "parcelasContratoOriginal",
   "dataContratoOriginal",
   "parcelaBancoPortabilidade",
];

const camposProduto3 = ["parcelasPagasContratoOriginal"];

function Proposal() {
   const [data, setData] = useState(null);
   const [list, setList] = useState([]);
   const [page, setPage] = useState(1);
   const [bancos, setBancos] = useState([]);
   const [regra, setRegra] = useState(null);
   const [regras, setRegras] = useState([]);
   const [name, setName] = useState(null);
   const [cpf, setCpf] = useState(null);
   const [parceiro, setParceiro] = useState(null);
   const [estados, setEstados] = useState(null);
   const [cidades, setCidades] = useState(null);
   const [inputErrors, setInputErrors] = useState({});
   const [valorEmprestimo, setValorEmprestimo] = useState(null);
   const [taxaEmprestimo, setTaxaEmprestimo] = useState(null);
   const [parcelaEmprestimo, setParcelaEmprestimo] = useState(null);
   const [regraEmprestimo, setRegraEmprestimo] = useState(null);
   const [status, setStatus] = useState(null);
   const [proposalFilter, setProposalFilter] = useState(null);
   const [proposal, setProposal] = useState(null);
   const [idResponseProposal, setIdResponseProposal] = useState(null);
   const [bancoUpload, setBancoUpload] = useState(null);
   const [fields, setFields] = useState({});
   const [formLoading, setFormLoading] = useState(false);
   const [viewMode, setViewMode] = useState(false);
   const [inputCliente, setInputCliente] = useState(null);
   const [inputEndereco, setInputEndereco] = useState(null);
   const [inputProposta, setInputProposta] = useState(null);
   const [inputEsteira, setInputEsteira] = useState(null);
   const [inputStatus, setInputStatus] = useState(null);
   const [inputHistorico, setInputHistorico] = useState(null);
   const [inputPendencia, setInputPendencia] = useState(null);
   const [inputDocumentos, setInputDocumentos] = useState(null);
   const [inputContrato, setInputContrato] = useState(null);
   const [inputAudio, setInputAudio] = useState(null);
   const [inputTicagem, setInputTicagem] = useState(null);
   const [inputAnotacao, setInputAnotacao] = useState(null);
   const [tipoDocumento, setTipoDocumento] = useState(null);
   const [tics, setTics] = useState({});
   const [cardsFgts, setCardsFgts] = useState(null);
   const [file, setFile] = useState(null);
   const [files, setFiles] = useState({});
   const [filename, setFilename] = useState(null);
   const [observations, setObservations] = useState([]);
   const [inputObservacao, setInputObservacao] = useState([]);
   const [disableSubmitUpload, setDisableSubmitUpload] = useState(true);
   const [register, setRegister] = useState({});
   const [datatable, setDataTable] = useState({});
   const [errors, setErrors] = useState({});
   const [dataInicio, setDataInicio] = useState(null);
   const [dataFim, setDataFim] = useState(null);
   const [convenios, setConvenios] = useState(null);
   const [origens, setOrigens] = useState(null);
   const [camposNovaProposta, setCamposNovaProposta] = useState({});
   const [agentes, setAgentes] = useState([]);
   const [linkFormalizacao, setLinkFormalizacao] = useState(null);
   const [listParceiros, setListParceiros] = useState([]);

   const animatedComponents = makeAnimated();
   const user = Store.getState().user;
   const selectParc = useRef();
   const selectStatus = useRef();

   const extensoesPermitidas = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
   ];

   useEffect(() => {
      getData(1, null, null, null, null, null, null);
      getStatus();
      getParceiros();
      getObs();
      getPriceBanks();

      window.$("#modal-image").on("hidden.bs.modal", function () {
         window.$("#modal-image").modal("hide");
         window.$("#modal-data").modal("show");
         window
            .$("#modal-data")
            .animate({ scrollTop: window.$("#modal-data").height() });
      });

      window.$("#modal-data").on("hidden.bs.modal", function () {
         window.$("#inputContrato").val(null);
         window.$("#inputAudio").val(null);
         window.$("#valor_liquido_contrato").val(null);
         window.$("#anotacao_auditoria").val(null);

         setInputAnotacao(null);
         setInputContrato(null);
         setInputAudio(null);
         setFields({});
      });

      window.$("#modal-adicionar").on("hidden.bs.modal", function () {
         window.$("#inputContrato").val(null);
         window.$("#inputAudio").val(null);
         window.$("#anotacao_auditoria").val(null);

         setInputAnotacao(null);
         setInputContrato(null);
         setInputAudio(null);
         setCamposNovaProposta({});
      });
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "Id",
               field: "id",
            },
            {
               label: "Produto",
               field: "produto",
            },
            {
               label: "Banco",
               field: "banco",
            },
            {
               label: "Cliente",
               field: "cliente",
            },
            {
               label: "CPF",
               field: "cpf",
            },
            {
               label: "Criado em",
               field: "criado_em",
            },
            {
               label: "Criado Por",
               field: "criado_por",
            },
            {
               label: "Criado Por Master",
               field: "criado_por_master",
            },
            {
               label: "Status",
               field: "status",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            data &&
            data.dados.map((x) => {
               return {
                  produto: x.produto,
                  id: x.id,
                  cpf: x.cpf,
                  criado_em: x.criado_em,
                  criado_por: x.criado_por_cliente
                     ? x.criado_por_cliente
                     : x.criado_por,
                  criado_por_master: x.criado_por ? x.criado_por_master : "N/A",
                  banco: (
                     <div className="media align-items-center">
                        <div
                           className="avatar"
                           style={{ backgroundColor: "transparent" }}
                        >
                           <img alt="Banco" src={x.logo} />
                        </div>
                     </div>
                  ),
                  cliente: x.cliente,
                  status: x.status,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              onClick={() => onEditClick(x.id)}
                              className="dropdown-item"
                           >
                              <i className="fas fa-pencil-alt fa-lg"></i> Editar
                           </button>

                           {x.campos.cetelem && (
                              <button
                                 type="button"
                                 onClick={() =>
                                    uploadDocuments(
                                       x.id,
                                       x.campos.cetelem.id,
                                       "cetelem"
                                    )
                                 }
                                 className="dropdown-item"
                              >
                                 <i className="fas fa-file-upload fa-lg"></i>{" "}
                                 Enviar Documentos
                              </button>
                           )}
                           {x.campos.agibank &&
                              ["WITH_PENDING", "WAITING_DOCUMENTS"].includes(
                                 x.campos.agibank.status
                              ) &&
                              x.status !== "Cancelado" && (
                                 <>
                                    <button
                                       type="button"
                                       onClick={() =>
                                          uploadDocuments(
                                             x.id,
                                             x.campos.agibank.id,
                                             "agibank"
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="fas fa-file-upload fa-lg"></i>{" "}
                                       Enviar Documentos
                                    </button>

                                    <button
                                       type="button"
                                       onClick={() =>
                                          getContractAgibank(
                                             x.campos.agibank.id,
                                             x.id,
                                             x.cliente_id,
                                             false
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="fas fa-file-download fa-lg"></i>{" "}
                                       Ver Contrato
                                    </button>

                                    <button
                                       type="button"
                                       onClick={() =>
                                          getContractAgibank(
                                             x.campos.agibank.id,
                                             x.id,
                                             x.cliente_id,
                                             true
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="fas fa-file-import"></i>{" "}
                                       Enviar p/ assinatura
                                    </button>

                                    <button
                                       type="button"
                                       onClick={() =>
                                          onDataComplements(
                                             x.id,
                                             x.campos.agibank.id
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="far fa-address-card"></i>{" "}
                                       Dados Complementares
                                    </button>

                                    <button
                                       type="button"
                                       onClick={() =>
                                          formalization(
                                             x.id,
                                             x.campos.agibank.id,
                                             "agibank"
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="fas fa-file-alt"></i>{" "}
                                       Formalizar
                                    </button>

                                    <button
                                       type="button"
                                       onClick={() =>
                                          cancel(
                                             x.id,
                                             x.campos.agibank.id,
                                             "agibank"
                                          )
                                       }
                                       className="dropdown-item"
                                    >
                                       <i className="fas fa-trash"></i> Cancelar
                                    </button>
                                 </>
                              )}
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [data]);

   useEffect(() => {
      if (register.endereco_estado_id) {
         getCitysState(register.endereco_estado_id);
      }
   }, [register.endereco_estado_id]);

   useEffect(() => {
      if (register.endereco_cep) {
         const event = {
            target: {
               id: "endereco_cep",
               value: register.endereco_cep,
            },
         };
         handleBlur(event);
      }
   }, [register.endereco_cep]);

   useEffect(() => {
      async function getEstados() {
         const data = await listEstados();
         setEstados(data);
      }
      getEstados();
   }, []);

   useEffect(() => {
      const getConvenios = async () => {
         const data = await buscarConveniosProposta();
         setConvenios(data);
      };
      getConvenios();
   }, []);

   useEffect(() => {
      const getOrigens = async () => {
         const data = await buscarOrigemProposta();
         setOrigens(data);
      };
      getOrigens();
   }, []);

   useEffect(() => {
      async function getCidades() {
         const data = await listByEstado(
            camposNovaProposta.endereco?.estado.id
         );
         setCidades(data);
      }
      if (camposNovaProposta && camposNovaProposta.endereco?.estado.id) {
         getCidades();
      }
   }, [camposNovaProposta.endereco?.estado?.id]);

   const getStatus = async () => {
      try {
         setList(await getProposalStatus());
      } catch (err) {}
   };
   const getParceiros = async () => {
      try {
         setListParceiros(await listPartners());
      } catch (err) {}
   };

   const getCitysState = async (estado) => {
      try {
         setCidades(await getCitys(estado));
      } catch (err) {}
   };

   const getObs = async () => {
      try {
         let response = await getObservations();
         if (response) {
            setObservations(response);
         } else {
            toast.error("Falha ao carregar observações");
         }
      } catch (err) {}
   };

   const getRulesBank = async (
      id,
      tipo_contrato,
      dadosBanco = {},
      produto = 1
   ) => {
      try {
         if (!tipo_contrato) {
            setCamposNovaProposta({
               ...camposNovaProposta,
               regras: "",
               bancoPagamento: "",
            });
            toast.info("Selecione o tipo de contrato.");
            return;
         }

         if (produto && produto.value) produto = produto.value;

         const response = await rulesBank(id, tipo_contrato, produto);

         if (response && response.length > 0) {
            setRegras(response);
         } else {
            setCamposNovaProposta({
               ...camposNovaProposta,
               regras: null,
               bancoPagamento: dadosBanco,
            });
            setRegras([]);
            toast.warning(
               "O banco selecionado não possui coêficientes para essa vigência. Cadastre."
            );
         }
      } catch (err) {
         toast.error("Falha ao carregar as regras");
      }
   };

   const isDisabled =
      inputEsteira && ID_DISABLED.includes(inputEsteira.status.toString());

   const isDisabledByRole = (id) => {
      if (user.role === 1 || user.role === 6 || user.role === 8) {
         return ![
            "status",
            "observacao",
            "n_proposta",
            "data_integracao",
            "valor_emprestimo",
            "parcela",
            "valor_parcela",
            "taxa_juros",
            "numero_beneficio",
            "banco_pagamento",
            "comissao",
            "comissao_master",
            "valor_imposto",
            "comissao_seucredito",
            "comissao_master2",
            "parcelas_saque",
         ].includes(id);
      } else if (user.role === 5) {
         return !["status", "observacao"].includes(id);
      } else {
         return true;
      }
   };

   const getData = async (
      page,
      name,
      cpf,
      status,
      proposal,
      parceiro,
      dataInicio,
      dataFim
   ) => {
      try {
         setData();
         setPage(page);
         setName(name);
         setCpf(cpf);
         setStatus(status);
         setParceiro(parceiro);
         setProposalFilter(proposal);
         setData(
            await getProposals(
               name,
               cpf,
               status,
               page,
               proposal,
               parceiro,
               dataInicio,
               dataFim
            )
         );
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   const getPriceBanks = async () => {
      try {
         setBancos(await getBanks());
      } catch (err) {}
   };

   const onEditClick = async (id) => {
      setViewMode(false);
      setFormLoading(true);
      setProposal(id);

      window.$("#anotacao_auditoria").val("");
      window.$("#observacao").val("");
      window.$("#modal-data").modal("show");
      try {
         let valor_parcela = 0,
            parcela = 0;
         await getObs();
         let data = await getProposalData(id);

         if (parseInt(data.proposta.produto) === 1) {
            await getRulesBank(
               data.cliente.banco_id,
               parseInt(data.proposta.tipo_contrato),
               {},
               parseInt(data.proposta.produto)
            );
         }

         if (data.proposta.link_formalizacao_agibank !== "null") {
            setLinkFormalizacao(data.proposta.link_formalizacao_agibank);
         }

         setFields({
            ...fields,
            status: data.esteira.status,
            numero_beneficio: null,
            data_integracao: null,
            n_proposta: null,
            observacao: null,
         });

         let aux = [];

         if (observations) {
            // observations.map((o) => {
            observations.forEach((o) => {
               data.pendencia.map((d) => {
                  if (
                     d.descricao &&
                     d.descricao.toLowerCase() === o.label.toLowerCase()
                  ) {
                     aux.push({ label: o.label, value: o.value });
                  }
               });
            });
         }

         if (
            parseInt(data.proposta.produto) === 1 ||
            (parseInt(data.proposta.produto) === 7 &&
               parseInt(data.proposta.tipo_contrato) === 1)
         ) {
            data.proposta.blocos.map((x) => {
               if (x.id === "parcelas") {
                  let parcelasArr = [];
                  if (data.cliente.banco_id === 121) {
                     parcelasArr = x.valor.split("x");
                     parcela = parseInt(parcelasArr[0].trim());
                     valor_parcela =
                        "R$ " + parcelasArr[1].replace("R$", "").trim();
                  } else {
                     parcelasArr = x.valor.split("de");
                     parcela = parseInt(parcelasArr[0].replace("x", "").trim());
                     valor_parcela =
                        "R$ " + parcelasArr[1].replace("R$", "").trim();
                  }
               }
            });

            data.proposta.blocos.map((x) => {
               if (x.id === "valor_parcela") x.valor = valor_parcela;
               if (x.id === "parcela") x.valor = parcela;
            });

            data.proposta.blocos.map((x) => {
               if (x.id === "regra_id") setRegra(x.valor);
            });
         }

         setInputCliente(data.cliente);
         setInputEndereco(data.endereco);
         setInputEsteira(data.esteira);
         setInputStatus(data.esteira.status);
         setInputProposta(data.proposta);
         setInputDocumentos(data.documentos);
         setInputHistorico(data.historico);
         setCardsFgts(data.cards);
         setInputPendencia(data.pendencia);
         setInputObservacao(data.pendencia);
         setInputContrato(data.contrato);
         setInputAnotacao(data.anotacao.anotacao_auditoria);

         if (data.proposta.proposta_interna == "true") {
            setFields({
               ...fields,
               comissao: data.proposta.blocos.find((x) => x.id === "comissao")
                  .valor,
               comissao_master: data.proposta.blocos.find(
                  (x) => x.id === "comissao_master"
               ).valor,
               comissao_seucredito: data.proposta.blocos.find(
                  (x) => x.id === "comissao_seucredito"
               ).valor,
               data_integracao: data.proposta.blocos.find(
                  (x) => x.id === "data_integracao"
               ).valor,
               n_proposta: data.proposta.blocos.find(
                  (x) => x.id === "n_proposta"
               ).valor,
               numero_beneficio: data.cliente.blocos.find(
                  (x) => x.id === "numero_beneficio"
               ).valor,
               observacao: aux || null,
               parcela: data.proposta.blocos.find((x) => x.id === "parcela")
                  .valor,
               valor_emprestimo: data.proposta.blocos.find(
                  (x) => x.id === "valor_emprestimo"
               ).valor,
               valor_imposto: data.proposta.blocos.find(
                  (x) => x.id === "valor_imposto"
               ).valor,
               valor_parcela: data.proposta.blocos.find(
                  (x) => x.id === "valor_parcela"
               ).valor,
               status: data.esteira.status,
            });
         } else {
            setFields({
               ...fields,
               valor_liquido_contrato: null,
               observacao: aux || null,
            });
         }

         if (data.proposta) {
            data.proposta.blocos.map((x) => {
               switch (x.id) {
                  case "valor_emprestimo":
                     setValorEmprestimo(x.valor);
                     break;
                  case "taxa_juros":
                     setTaxaEmprestimo(x.valor.replace("%", ""));
                     break;
                  case "parcelas":
                     setParcelaEmprestimo(parseInt(x.valor));
                     break;
                  case "regra_id":
                     setRegraEmprestimo(x.valor);
                     break;
                  default:
                     break;
               }
            });
         }

         if (data.auditoria) {
            let aux = {};
            let ticagem = data.auditoria.ticagem;

            for (let i = 0; i < ticagem.length; i++) {
               const item = ticagem[i];
               aux[item.id] = item.valor;
            }

            setTics(aux);
            setInputTicagem(data.auditoria.ticagem);
            setInputAudio(data.auditoria.audio);
         } else {
            setTics(null);
            setInputTicagem(null);
            setInputAudio(null);
         }

         setFormLoading(false);
      } catch (err) {
         toast.error("Falha ao carregar dados!");
         window.$("#modal-data").modal("hide");
         setProposal(null);
         setFields({});
         setFormLoading(false);
      }
   };

   const onEditSaqueClick = async (card) => {
      setViewMode(false);
      setRegister({
         ...card,
         proposta: proposal,
         criado_por: inputProposta.criado_por,
         saque: card.valor
            ? card.valor.replace("R$ ", "")
            : card.saque.replace("R$ ", ""),
      });
      window.$("#modal-editar-saque").modal("show");
   };

   const onSaveSaque = async (event) => {
      event.preventDefault();
      setFormLoading(true);
      try {
         await saveSaqueFgts(register);
         await onEditClick(proposal);
         toast.success("Saque atualizado com sucesso!");
      } catch (err) {
         toast.error("Ocorreu um erro tente novamente!");
         setFormLoading(false);
      } finally {
         window.$("#modal-editar-saque").modal("hide");
         setFormLoading(false);
         setRegister({});
      }
   };

   const onDataComplements = async (id, propostaId) => {
      setViewMode(false);
      setFormLoading(true);
      setProposal(id);
      try {
         const estados = await getStates();
         const data = await getProposalClientData(id);
         setEstados(estados);
         setRegister({
            ...register,
            nome: data.nome,
            nascimento: moment(data.nascimento, "YYYY-MM-DD").format(
               "DD/MM/YYYY"
            ),
            celular: data.celular,
            email: data.email.toLowerCase(),
            endereco_cep: data.cep
               ? data.cep
               : data.endereco_cep
               ? data.endereco_cep
               : "",
            endereco_logradouro: data.endereco
               ? data.endereco
               : data.endereco_rua
               ? data.endereco_rua
               : "",
            endereco_bairro: data.bairro
               ? data.bairro
               : data.endereco_bairro
               ? data.endereco_bairro
               : "",
            endereco_numero: data.endereco_numero ? data.endereco_numero : "",
            endereco_complemento: data.complemento
               ? data.complemento
               : data.endereco_complemento
               ? data.endereco_complemento
               : "",
            rg: data.rg
               ? data.rg
               : data.registro_geral
               ? data.registro_geral
               : "",
            data_emissao: data.data_emissao
               ? moment(data.data_emissao, "YYYY-MM-DD").format("DD/MM/YYYY")
               : "",
            orgao_emissor: data.orgao_emissor ? data.orgao_emissor : "",
            nome_pai: data.nome_pai ? data.nome_pai : "",
            nome_mae: data.nome_mae ? data.nome_mae : "",
            tipo_endereco: "RESIDENCIAL",
            id: propostaId,
         });
         setFormLoading(false);
         window.$("#modal-dados-complementares").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados!");
         window.$("#modal-dados-complementares").modal("hide");
         setProposal(null);
         setFormLoading(false);
      }
   };

   const onFilter = () => {
      if ((dataInicio && !dataFim) || (!dataInicio && dataFim)) {
         toast.info("Favor preencher as datas corretamente");
      } else {
         window.$("#modal-filter").modal("hide");
         getData(
            1,
            name,
            cpf,
            status,
            proposalFilter,
            parceiro,
            dataInicio,
            dataFim
         );
      }
   };

   const onClear = () => {
      window.$("#name").val(null);
      window.$("#parceiro").val(null);
      window.$("#cpf").val(null);
      window.$("#status").val(null);
      window.$("#proposta").val(null);
      selectParc.current.select.clearValue();
      selectStatus.current.select.clearValue();
      setName(null);
      setCpf(null);
      setParceiro(null);
      setStatus(null);
      setProposalFilter(null);
   };

   const updateFields = async (id, value) => {
      let v = value;
      //Formatar
      if (
         id === "valor_emprestimo" ||
         id === "valor_parcela" ||
         id === "comissao" ||
         id === "comissao_master" ||
         id === "valor_imposto" ||
         id === "comissao_seucredito"
      ) {
         if (v && v !== null) {
            v = v.replace(/\D/g, "");
            v = (v / 100).toFixed(2) + "";
            v = v.replace(".", ",");
            v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
            v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
            v = "R$ " + v;
            setFields({ ...fields, [id]: v });
         }
      } else if (id === "taxa_juros" && v && v !== null) {
         v = v.replace(/\D/g, "");
         if (parseFloat(v) / 100 <= 100) {
            v = (v / 100).toFixed(2) + "%";
            setFields({ ...fields, [id]: v });
         }
      } else if (id === "regras" && parseInt(inputProposta.produto) === 1) {
         try {
            setFormLoading(true);
            let valor = valorEmprestimo.replace("R$ ", "");
            valor = valor.replace(".", "");
            valor = valor.replace(",", ".");

            let valor_parcela = 0;

            if (fields.valor_parcela) {
               valor_parcela = fields.valor_parcela.replace("R$ ", "");
               valor_parcela = valor_parcela.replace(".", "");
               valor_parcela = valor_parcela.replace(",", ".");
            }

            const response = await recalculateCommissionAgent(
               proposal,
               valor,
               valor_parcela,
               fields.taxa_juros
                  ? fields.taxa_juros.replace("%", "")
                  : taxaEmprestimo,
               fields.parcela ? fields.parcela : parcelaEmprestimo,
               value,
               fields.banco_pagamento
                  ? fields.banco_pagamento
                  : inputCliente.banco_id,
               inputProposta.tipo_contrato,
               inputProposta.criado_por
            );

            if (response.result && response.result.length > 0) {
               const valorParcelas = response.result[4].valor;
               const valoresArr = valorParcelas.split("de");
               const parcela = parseInt(valoresArr[0]);
               const valorParcela = "R$" + valoresArr[1].replace("R$ ", "");

               setFields({
                  ...fields,
                  produto: parseInt(inputProposta.produto),
                  comissao: response.result[0].valor,
                  valor_imposto: response.result[1].valor,
                  comissao_seucredito: response.result[2].valor,
                  comissao_master: response.result[3].valor,
                  parcelas: response.result[4].valor,
                  parcela: parcela,
                  valor_parcela: valorParcela,
                  regra_id: value,
                  regras: value,
                  taxa: response.result[6].valor + "%",
                  taxa_juros: response.result[6].valor + "%",
               });
            }
         } catch (err) {
            setFormLoading(false);
            toast.error("Ocorreu um erro, ao recalcular a comissão.");
         } finally {
            setFormLoading(false);
         }
      } else if (
         id === "banco_pagamento" &&
         parseInt(value) !== inputCliente.banco_id &&
         parseInt(inputProposta.produto) === 1
      ) {
         try {
            setFormLoading(true);
            let valor = valorEmprestimo.replace("R$ ", "");
            valor = valor.replace(".", "");
            valor = valor.replace(",", ".");

            let valor_parcela = 0;

            if (fields.valor_parcela) {
               valor_parcela = fields.valor_parcela.replace("R$ ", "");
               valor_parcela = valor_parcela.replace(".", "");
               valor_parcela = valor_parcela.replace(",", ".");
            }

            await getRulesBank(
               parseInt(value),
               parseInt(inputProposta.tipo_contrato),
               {},
               parseInt(inputProposta.produto)
            );

            const response = await recalculateCommissionAgent(
               proposal,
               valor,
               valor_parcela,
               fields.taxa_juros
                  ? fields.taxa_juros.replace("%", "")
                  : taxaEmprestimo,
               fields.parcela ? fields.parcela : parcelaEmprestimo,
               fields.regras ? fields.regras : regraEmprestimo,
               value,
               inputProposta.tipo_contrato,
               inputProposta.criado_por
            );

            if (response.retorno === false) {
               setFields({
                  ...fields,
                  banco_id: parseInt(value),
                  banco_pagamento: parseInt(value),
               });

               //atualizando o banco pagamento
               setInputCliente({
                  ...inputCliente,
                  banco_id: parseInt(value),
               });

               toast.info(response.erro);
            } else {
               if (response.result.length > 5) {
                  setFields({
                     ...fields,
                     banco_id: parseInt(value),
                     banco_pagamento: parseInt(value),
                     produto: parseInt(inputProposta.produto),
                     comissao: response.result[0].valor,
                     valor_imposto: response.result[1].valor,
                     comissao_seucredito: response.result[2].valor,
                     comissao_master: response.result[3].valor,
                     parcelas: response.result[4].valor,
                     regra_id: response.result[5].valor,
                     taxa: response.result[6].valor + "%",
                     taxa_juros: response.result[6].valor + "%",
                  });
               } else {
                  setFields({
                     ...fields,
                     banco_id: parseInt(value),
                     banco_pagamento: parseInt(value),
                     produto: parseInt(inputProposta.produto),
                     comissao: response.result[0].valor,
                     parcelas: response.result[1].valor,
                     regra_id: response.result[2].valor,
                     taxa: response.result[3].valor + "%",
                     taxa_juros: response.result[3].valor + "%",
                  });

                  //atualizando o banco pagamento
                  setInputCliente({
                     ...inputCliente,
                     banco_id: parseInt(value),
                  });
               }
            }
         } catch (err) {
            setFormLoading(false);
            toast.error("Ocorreu um erro, ao recalcular a comissão.");
         } finally {
            setFormLoading(false);
         }
      } else if (
         id === "banco_pagamento" &&
         parseInt(value) !== inputCliente.banco_id &&
         parseInt(inputProposta.produto) !== 1
      ) {
         setFields({
            ...fields,
            banco_pagamento: parseInt(value),
            produto: parseInt(inputProposta.produto),
         });
      } else if (id === "bancoPagamento") {
         await getRulesBank(
            parseInt(value.id),
            parseInt(
               camposNovaProposta.tipoContrato &&
                  camposNovaProposta.tipoContrato.id
            ),
            value,
            camposNovaProposta.produto ? camposNovaProposta.produto : 1
         );
      } else if (id === "data_integracao" && value) {
         if (!moment(value, "DD/MM/YYYY").isValid()) {
            setErrors((prevErrors) => ({
               ...prevErrors,
               [id]: "Data informada é inválida.",
            }));
         } else {
            setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
         }
         setFields({ ...fields, [id]: v });
      } else if (v === null || v === "") {
         v = "";
         setFields({ ...fields, [id]: v });
      } else {
         setFields({ ...fields, [id]: v });
      }
   };

   const onBlur = async (id, value) => {
      if (
         id === "valor_emprestimo" &&
         value !== valorEmprestimo &&
         inputProposta.proposta_interna == "false"
      ) {
         setFormLoading(true);

         try {
            let valor = value.replace("R$ ", "");
            valor = valor.replace(".", "");
            valor = valor.replace(",", ".");

            let valor_parcela = 0;

            if (fields.valor_parcela) {
               valor_parcela = fields.valor_parcela.replace("R$ ", "");
               valor_parcela = valor_parcela.replace(".", "");
               valor_parcela = valor_parcela.replace(",", ".");
            }

            if (parseInt(inputProposta.produto) !== 1) {
               const dados = {
                  valor,
                  valor_parcela,
                  parcela: fields.parcela ? fields.parcela : parcelaEmprestimo,
                  regra: fields.regras ? fields.regras : regraEmprestimo,
                  banco: inputCliente.banco_id,
                  tipo_contrato: inputProposta.tipo_contrato,
                  criado_por: inputProposta.criado_por,
               };

               const response = await recalculateCommissionProductAgent(
                  proposal,
                  parseInt(inputProposta.produto),
                  dados
               );

               if (response.result && response.result.length > 4) {
                  const valorParcela = response.result[4].valor.split(" de ");
                  setFields({
                     ...fields,
                     banco_id: inputCliente.banco_id,
                     banco_pagamento: inputCliente.banco_id,
                     produto: parseInt(inputProposta.produto),
                     comissao: response.result[0].valor,
                     valor_imposto: response.result[1].valor,
                     comissao_seucredito: response.result[2].valor,
                     comissao_master: response.result[3].valor,
                     parcelas: response.result[4].valor,
                     regra_id: response.result[5].valor,
                     valor_parcela: valorParcela[1],
                     parcela: parseInt(valorParcela[0]),
                  });
               } else if (response.result && response.result.length === 4) {
                  const valorParcela = response.result[2].valor.split(" de ");
                  setFields({
                     ...fields,
                     valor_imposto: response.result[0].valor,
                     comissao_seucredito: response.result[1].valor,
                     parcelas: response.result[2].valor,
                     regra_id: response.result[3].valor,
                     valor_parcela: valorParcela[1],
                     parcela: parseInt(valorParcela[0]),
                  });
               } else {
                  toast.error(response.erro);
               }
            } else {
               const response = await recalculateCommissionAgent(
                  proposal,
                  valor,
                  valor_parcela,
                  fields.taxa_juros
                     ? fields.taxa_juros.replace("%", "")
                     : taxaEmprestimo,
                  fields.parcela ? fields.parcela : parcelaEmprestimo,
                  fields.regras ? fields.regras : regraEmprestimo,
                  fields.banco_pagamento,
                  inputProposta.tipo_contrato,
                  inputProposta.criado_por
               );

               if (response) {
                  if (response.result.length > 5) {
                     const valorParcela =
                        response.result[4].valor.split(" de ");
                     setFields({
                        ...fields,
                        banco_id: inputCliente.banco_id,
                        banco_pagamento: inputCliente.banco_id,
                        produto: parseInt(inputProposta.produto),
                        comissao: response.result[0].valor,
                        valor_imposto: response.result[1].valor,
                        comissao_seucredito: response.result[2].valor,
                        comissao_master: response.result[3].valor,
                        parcelas: response.result[4].valor,
                        regra_id: response.result[5].valor,
                        taxa: response.result[6].valor + "%",
                        taxa_juros: response.result[6].valor + "%",
                        valor_parcela: valorParcela[1],
                        parcela: parseInt(valorParcela[0]),
                     });
                  } else {
                     const valorParcela =
                        response.result[2].valor.split(" de ");
                     setFields({
                        ...fields,
                        banco_id: inputCliente.banco_id,
                        banco_pagamento: inputCliente.banco_id,
                        produto: parseInt(inputProposta.produto),
                        valor_imposto: response.result[0].valor,
                        comissao_seucredito: response.result[1].valor,
                        parcelas: response.result[2].valor,
                        regra_id: response.result[3].valor,
                        taxa: response.result[4].valor + "%",
                        taxa_juros: response.result[4].valor + "%",
                        valor_parcela: valorParcela[1],
                        parcela: parseInt(valorParcela[0]),
                     });
                  }
               }
            }
         } catch (err) {
            toast.error("Ocorreu um erro, ao recalcular a comissão.");
         } finally {
            setFormLoading(false);
         }
      }
   };

   const onSave = async () => {
      try {
         let valid = true;
         //Verifica se
         const status = fields["status"] || inputStatus;
         if (ID_WITH_OBS.includes(status.toString())) {
            const observacao = fields["observacao"] || inputObservacao;
            if (observacao === null || observacao === -1) {
               valid = false;
               window.$("#observacao").focus();
               toast.error("Insira uma observação!");
            }
         }

         if (valid) {
            setFormLoading(true);
            await updateProposal(proposal, fields);
            toast.success(`Proposta foi atualizada!`);

            window.$("#modal-data").modal("hide");
            await getData(page, name, cpf, null, null, proposalFilter);
         }
      } catch (err) {
         toast.error("Falha ao atualizar proposta!");
         window.$("#modal-data").modal("hide");
         setProposal(null);
         setFields({});
         setFormLoading(false);
      } finally {
         setFormLoading(false);
      }
   };

   const atualizarProposta = async () => {
      try {
         setFormLoading(true);
         const response = await atualizarPropostaInterna(proposal, fields);
         if (response.proposta_id) {
            window.$("#modal-data").modal("hide");
            toast.success("Proposta atualizada com sucesso.");
            await getData(page, name, cpf, null, null, proposalFilter);
         } else {
            toast.error(
               "Ocorreu um erro ao atualizar a proposta, tente novamente."
            );
         }
      } catch (e) {
         setFormLoading(false);
         toast.error("Falha ao atualizar proposta!");
      } finally {
         setFormLoading(false);
      }
   };

   const onSaveNewProposta = async () => {
      try {
         let comissoes = [];

         if (camposNovaProposta.regras?.id) {
            comissoes = await calcularComissao(
               funcoes.formatarMoedaBanco(
                  camposNovaProposta.valLiquidoProposta
               ),
               funcoes.formatarMoedaBanco(camposNovaProposta.valorParcelas),
               camposNovaProposta.taxaProposta,
               camposNovaProposta.qtdParcelas.value,
               camposNovaProposta.regras.id,
               camposNovaProposta.bancoPagamento.id,
               camposNovaProposta.convenio.value,
               camposNovaProposta.tipoContrato.id,
               camposNovaProposta.seguroProposta ? true : false,
               camposNovaProposta.agenteId.usuario_id,
               camposNovaProposta.produto.value
                  ? camposNovaProposta.produto.value
                  : camposNovaProposta.produto
                  ? camposNovaProposta.produto
                  : 1
            );
         }

         setFormLoading(true);
         let result = await gravarProposta({
            ...camposNovaProposta,
            comissoes:
               comissoes && comissoes.result && comissoes.result.length > 0
                  ? comissoes.result
                  : [],
         });

         if (result && result.proposta_id) {
            window.$("#modal-adicionar").modal("hide");
            toast.success(
               `Proposta ${result.proposta_id} gravada com sucesso.`
            );
            await getData(page, name, cpf, null, null, proposalFilter);
         } else {
            toast.error(
               "Ocorreu um erro ao gravar a proposta, tente novamente."
            );
         }
      } catch (error) {
         console.error(error);
         toast.error("Ocorreu um erro ao gravar a proposta, tente novamente.");
      } finally {
         setFormLoading(false);
      }
   };

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = (error) => reject(error);
      });
   };

   const onSaveFile = async (id, html) => {
      try {
         var file = document.querySelector(`#${html}`).files[0];

         if (file) {
            getBase64(file).then(async (data) => {
               if (id === "contrato") {
                  setInputContrato(data);
               } else if (id === "audio") {
                  setInputAudio(data);
               }

               updateFields(id, data);
            });
         }
      } catch (err) {
         console.warn(err);
         toast.error("Falha ao enviar arquivo!");
      }
   };

   const onResetEditFGTS = async () => {
      setCamposNovaProposta({});
      onClear();
      window.$("#modal-detalhes").modal("hide");
   };

   const onReset = async () => {
      setCamposNovaProposta({});
      onClear();
      window.$("#modal-data").modal("hide");
      window.$("#modal-detalhes").modal("hide");
   };

   const onDeleteFile = async (id, html) => {
      window.$(`#${html}`).val(null);

      if (id === "contrato") {
         setInputContrato(null);
      } else if (id === "audio") {
         setInputAudio(null);
      }

      updateFields(id, null);
   };

   const openFile = (name, file) => {
      setFile(file);
      setFilename(name);
      window.$("#modal-data").modal("hide");
      window.$("#modal-image").modal("show");
   };

   const updateTic = async (id) => {
      setTics({ ...tics, [id]: !tics[id] });
      updateFields("ticagem", { ...tics, [id]: !tics[id] });
   };

   const handleChangeFile = async (event) => {
      let { id } = event.target;
      let file = event.target.files[0];
      let filtro = extensoesPermitidas.filter((val) => {
         return val === file.type;
      });
      const tamanhoMaximo = file.size > 2000000;

      if (!filtro[0] && !tamanhoMaximo) {
         setDisableSubmitUpload(true);
         setInputErrors({
            ...inputErrors,
            [id]: "Extensão do arquivo não suportada! Extensões suportadas: (JPG, JPEG, PNG e PDF).",
         });
         return;
      } else if (filtro[0] && tamanhoMaximo) {
         setDisableSubmitUpload(true);
         setInputErrors({
            ...inputErrors,
            [id]: "Tamanho do arquivo não suportado! Tamanho maximo: 2mb.",
         });
         return;
      } else if (filtro[0] && !tamanhoMaximo) {
         if (inputErrors) {
            setDisableSubmitUpload(false);
            setInputErrors({ ...inputErrors, [id]: "" });
         }

         const base64 = await fileToBase64(file);

         setFiles({
            ...files,
            [id]: {
               data: base64,
               name: file.name,
               type: file.type,
               isFile: true,
            },
         });
      }
   };

   const handleChange = (event) => {
      const { id, value } = event.target;

      switch (id) {
         case "tipo_documento":
            setTipoDocumento(value);
            setInputErrors({ ...inputErrors, [id]: false });
            break;
         default:
            setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
            setRegister({ ...register, [id]: value });
            break;
      }
   };

   const handleChangeForm = (event) => {
      const { id, value } = event.target;
      setRegister({ ...register, [id]: value });
   };

   const handleChangeProposal = async (event) => {
      let { id, value } = event.target;

      switch (id) {
         case "cep":
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: { ...camposNovaProposta?.endereco, [id]: value },
            });
            break;
         case "rua":
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: { ...camposNovaProposta?.endereco, [id]: value },
            });
            break;
         case "bairro":
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: { ...camposNovaProposta?.endereco, [id]: value },
            });
            break;
         case "numero":
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: { ...camposNovaProposta?.endereco, [id]: value },
            });
            break;
         case "complemento":
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: { ...camposNovaProposta?.endereco, [id]: value },
            });
            break;
         case "valorParcelas":
         case "qtdParcelas":
            let auxValParcelas =
               id === "valorParcelas"
                  ? funcoes.formatarMoedaBanco(value)
                  : camposNovaProposta.valorParcelas
                  ? funcoes.formatarMoedaBanco(camposNovaProposta.valorParcelas)
                  : 0;

            let auxQtdParcelas =
               id === "qtdParcelas"
                  ? value.value
                  : camposNovaProposta.qtdParcelas
                  ? camposNovaProposta.qtdParcelas.value
                  : 0;

            setCamposNovaProposta({
               ...camposNovaProposta,
               [id]: value,
               valorTotalParcelas: funcoes.formatarMoeda(
                  parseFloat(auxValParcelas * auxQtdParcelas)
               ),
            });
            break;
         case "regras":
            const result = await buscarParcelasRegra(value.value);
            setCamposNovaProposta({
               ...camposNovaProposta,
               parcelas: result ? result : [],
               [id]: value,
            });
            break;
         default:
            setCamposNovaProposta({ ...camposNovaProposta, [id]: value });
            break;
      }
   };

   const handleBlur = async (event) => {
      const { id, value } = event.target;
      if (id === "endereco_cep") {
         setFormLoading(true);
         const dadosCep = await getCep(value);

         if (dadosCep) {
            const estadoSelected = estados.find(
               (estado) => estado.uf === dadosCep.uf
            );
            setRegister({
               ...register,
               endereco_logradouro: dadosCep.logradouro,
               endereco_bairro: dadosCep.bairro,
               endereco_complemento: dadosCep.complemento,
               endereco_estado: estadoSelected ? estadoSelected.nome : "",
               endereco_estado_id: estadoSelected ? estadoSelected.id : "",
               endereco_cidade: dadosCep.localidade,
            });
            setFormLoading(false);
         }
      }

      if (id === "cep") {
         setFormLoading(true);
         const dadosCep = await getCep(value);

         if (dadosCep) {
            const estadoSelected = estados.find(
               (estado) => estado.uf === dadosCep.uf
            );
            setCamposNovaProposta({
               ...camposNovaProposta,
               endereco: {
                  ...camposNovaProposta.endereco,
                  rua: dadosCep.logradouro,
                  bairro: dadosCep.bairro,
                  complemento: dadosCep.complemento,
                  estado: estadoSelected ? estadoSelected : [],
                  cidade: { value: dadosCep.id, label: dadosCep.localidade },
               },
            });
            setFormLoading(false);
         }
      }

      if (!value || value === "R$ 0,00") {
         setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "Campo Obrigatório",
         }));
      }

      if (id === "cpf_proposta" && value) {
         try {
            setFormLoading(true);
            let result = await buscarClienteNovaProposta(value);

            if (result) {
               result = {
                  nome_proposta: result.nome,
                  cpf_proposta: result.cpf,
                  rg_proposta: result.rg,
                  email_proposta: result.email,
                  nascimento_proposta: result.nascimento,
                  celular_proposta: result.celular,
                  renda: result.renda
                     ? funcoes.formataMoeda(result.renda)
                     : null,
                  endereco:
                     result.endereco && result.endereco.length > 0
                        ? result.endereco[0]
                        : null,
                  id: result.id,
               };
            }
            setCamposNovaProposta({ ...camposNovaProposta, ...result });
            setFormLoading(false);
         } catch (err) {
            setFormLoading(false);
         } finally {
         }
         {
            setFormLoading(false);
         }
      }

      if (camposNovaProposta.tipoContrato?.value === 1) {
         if (
            id === "valLiquidoProposta" ||
            id === "iofProposta" ||
            id === "seguroProposta"
         ) {
            let auxValLiquido = camposNovaProposta.valLiquidoProposta
               ? funcoes.formatarMoedaBanco(
                    camposNovaProposta.valLiquidoProposta
                 )
               : 0;
            let auxValIof = camposNovaProposta.iofProposta
               ? funcoes.formatarMoedaBanco(camposNovaProposta.iofProposta)
               : 0;
            let auxValSeguro = camposNovaProposta.seguroProposta
               ? funcoes.formatarMoedaBanco(camposNovaProposta.seguroProposta)
               : 0;

            setCamposNovaProposta({
               ...camposNovaProposta,
               valBrutoProposta: funcoes.formatarMoeda(
                  parseFloat(auxValLiquido) +
                     parseFloat(auxValIof) +
                     parseFloat(auxValSeguro)
               ),
            });
         }
      }

      if (camposNovaProposta.tipoContrato?.value === 2) {
         switch (id) {
            case "saldoDevedorSolicitado":
               setCamposNovaProposta({
                  ...camposNovaProposta,
                  valBrutoProposta: camposNovaProposta.saldoDevedorSolicitado,
                  valLiquidoProposta: camposNovaProposta.saldoDevedorSolicitado,
               });
               break;

            default:
               break;
         }
      }

      if (
         camposNovaProposta.tipoContrato?.value === 3 ||
         camposNovaProposta.tipoContrato?.value === 4
      ) {
         if (
            id === "valLiquidoProposta" ||
            id === "valBrutoProposta" ||
            id === "iofProposta" ||
            id === "seguroProposta"
         ) {
            let auxValBruto = camposNovaProposta.valBrutoProposta
               ? funcoes.formatarMoedaBanco(camposNovaProposta.valBrutoProposta)
               : 0;
            let auxValLiquido = camposNovaProposta.valLiquidoProposta
               ? funcoes.formatarMoedaBanco(
                    camposNovaProposta.valLiquidoProposta
                 )
               : 0;
            let auxValIof = camposNovaProposta.iofProposta
               ? funcoes.formatarMoedaBanco(camposNovaProposta.iofProposta)
               : 0;
            let auxValSeguro = camposNovaProposta.seguroProposta
               ? funcoes.formatarMoedaBanco(camposNovaProposta.seguroProposta)
               : 0;

            setCamposNovaProposta({
               ...camposNovaProposta,
               quitacaoProposta:
                  parseFloat(auxValBruto) > 0
                     ? funcoes.formatarMoeda(
                          parseFloat(auxValBruto) -
                             (parseFloat(auxValLiquido) +
                                parseFloat(auxValIof) +
                                parseFloat(auxValSeguro))
                       )
                     : funcoes.formatarMoeda(
                          parseFloat(auxValLiquido) +
                             parseFloat(auxValIof) +
                             parseFloat(auxValSeguro)
                       ),
            });
         }
      }
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      if (!tipoDocumento) {
         setInputErrors({
            ...inputErrors,
            tipo_documento: "Campo obrigatório, selecione o tipo do documento.",
         });
      } else {
         setFormLoading(true);
         if (files) {
            try {
               const response = await uploadDocumentsProposal(
                  proposal,
                  idResponseProposal,
                  bancoUpload,
                  files,
                  tipoDocumento
               );
               if (response.return) {
                  LimparCamposUpload();
                  toast.success(response.message);
                  window.$("#modal-upload").modal("hide");
               } else {
                  toast.error(response.erro);
               }
            } catch (err) {
               toast.error("Falha ao enviar o arquivo.");
               LimparCamposUpload();
               window.$("#modal-upload").modal("hide");
            } finally {
               setFormLoading(false);
            }
         }
      }
   };

   const onSubmitEditProposal = async (event) => {
      event.preventDefault();
      setFormLoading(true);
      try {
         const response = await updateProposalAgibank(proposal, register);
         if (response.return) {
            toast.success(response.message);
            setRegister({});
            window.$("#modal-dados-complementares").modal("hide");
            await getData(page, name, cpf, status, proposalFilter);
         } else {
            toast.error(response.erro);
         }
      } catch (err) {
         toast.error("Falha ao atualizar a proposta.");
         setRegister({});
         window.$("#modal-dados-complementares").modal("hide");
      } finally {
         setFormLoading(false);
      }
   };

   const handleExport = async () => {
      swal({
         title: "Exportar propostas?",
         text: "Será exportado as propostas cadastradas no sistema",
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFormLoading(true);
            const retorno = await exportProposals(
               name,
               cpf,
               status,
               dataInicio,
               dataFim
            );
            if (retorno) {
               let dados =
                  typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
               let CSV = "sep=," + "\r\n";
               let row = "";
               //Setar o nome do relatorio na primeira linha
               CSV +=
                  `Relatório de Propostas - ${moment().format("MMMM YYYY")}` +
                  "\r\n";
               //Este loop irá extrair o rótulo do primeiro índice da matriz
               for (let index in dados[0]) {
                  //Agora converta cada valor para string e separado por vírgulas
                  row += index + ",";
               }
               row = row.slice(0, -1);
               //anexar linha de rótulo com quebra de linha
               CSV += row + "\r\n";
               //O primeiro loop é extrair cada linha
               for (let i = 0; i < dados.length; i++) {
                  let row = "";
                  //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
                  for (let index in dados[i]) {
                     row += '"' + dados[i][index] + '",';
                  }
                  row.slice(0, row.length - 1);
                  //adicione uma quebra de linha após cada linha
                  CSV += row + "\r\n";
               }

               if (CSV === "") {
                  swal("Ocorreu um erro ao exportar as propostas", {
                     icon: "error",
                  });
               }

               let nomeArquivo = `propostas-${moment().format("X")}`;
               let uri = "data:text/csv;charset=utf-8," + escape(CSV);
               let link = document.createElement("a");
               link.href = uri;
               link.style = "visibility:hidden";
               link.download = nomeArquivo + ".csv";
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
               setFormLoading(false);
               swal("Propostas exportadas com sucesso.", {
                  icon: "success",
               });
            } else {
               setFormLoading(false);
               swal("Ocorreu um erro ao exportar as propostas", {
                  icon: "error",
               });
            }
         } else {
            swal("As propostas não foram exportadas.", {
               icon: "info",
            });
         }
      });
   };

   const fileToBase64 = (file) => {
      return new Promise((resolve) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
      });
   };

   const LimparCamposUpload = () => {
      document.getElementById("arquivo_proposta").value = null;
      setInputErrors({});
      setFiles({});
      setProposal(null);
      setIdResponseProposal(null);
      setBancoUpload(null);
      setTipoDocumento(null);
   };

   const uploadDocuments = async (proposta, id, bank) => {
      try {
         setFormLoading(true);
         setProposal(proposta);
         setIdResponseProposal(id);
         setBancoUpload(bank);
         const documentos = await getDocumentsUploadProposal(proposta);
         if (documentos && documentos.length > 0) {
            setInputDocumentos(documentos);
         }
         window.$("#modal-upload").modal("show");
      } catch (err) {
         toast.error(err);
      } finally {
         setFormLoading(false);
      }
   };

   const getContractAgibank = async (
      id,
      proposta,
      cliente,
      enviarDocumento
   ) => {
      if (enviarDocumento) {
         swal({
            title: "Enviar contrato para assinatura?",
            text: "O contrato será enviado para o cliente assinar.",
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               if (id) {
                  setFormLoading(true);
                  const retorno = await getContract(
                     id,
                     proposta,
                     cliente,
                     enviarDocumento
                  );

                  if (retorno.erro) {
                     setFormLoading(false);
                     swal(retorno.erro, {
                        icon: "warning",
                     });
                  } else {
                     setFormLoading(false);
                     swal("Documento enviado para o cliente assinar.", {
                        icon: "success",
                     });
                  }
               } else {
                  setFormLoading(false);
                  swal("Proposta não encontrada.", {
                     icon: "error",
                  });
               }
            } else {
               swal("Contrato não foi enviado para assinatura.", {
                  icon: "error",
               });

               setFormLoading(false);
            }
         });
      } else {
         setFormLoading(true);
         const retorno = await getContract(
            id,
            proposta,
            cliente,
            enviarDocumento
         );
         const link = "data:application/pdf;base64, " + retorno.contrato;
         fetch(link)
            .then((res) => res.blob())
            .then((res) => {
               setFormLoading(false);
               window.open(URL.createObjectURL(res), "_blank");
            });
      }
   };

   const formalization = async (proposta, id, banco) => {
      try {
         swal({
            title: "Enviar contrato assinado?",
            text: "O contrato assinado será enviado para aplicação do banco.",
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFormLoading(true);
               const retorno = await formalizationProposal(proposta, id, banco);

               if (retorno.return) {
                  swal(retorno.message, {
                     icon: "success",
                  });

                  setFormLoading(false);
                  await getData(page, name, cpf, status, proposalFilter);
               } else {
                  swal(retorno.message, {
                     icon: "error",
                  });

                  setFormLoading(false);
               }
            } else {
               swal("Ocorreu um ao enviar o contrato, tente novamente.", {
                  icon: "error",
               });

               setFormLoading(false);
            }
         });
         //window.$("#modal-formalizacao").modal("show");
      } catch (err) {
         toast.error(err);
      } finally {
         setFormLoading(false);
      }
   };

   const cancel = async (proposta, id, banco) => {
      try {
         swal({
            title: "Cancelar a proposta?",
            text: `A proposta #${proposta} será cancelada, essa ação não pode ser desfeita.`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFormLoading(true);
               const retorno = await cancelProposal(proposta, id, banco);
               if (retorno.return) {
                  setFormLoading(false);
                  swal(retorno.message, {
                     icon: "success",
                  });

                  await getData(page, name, cpf, status, proposalFilter);
               } else {
                  setFormLoading(false);
                  swal(retorno.message, {
                     icon: "error",
                  });
               }
            } else {
               swal("A proposta não foi cancelada.", {
                  icon: "error",
               });

               setFormLoading(false);
            }
         });
      } catch (err) {
         toast.error(err);
      } finally {
         setFormLoading(false);
      }
   };

   const disabledBtnSubmit = useMemo(() => {
      return Object.keys(errors).filter((key) => errors[key]).length > 0;
   }, [errors]);

   const disableBtnNewProposta = useMemo(() => {
      let auxCampos = camposNovaProposta;
      let auxEndereco =
         camposNovaProposta && camposNovaProposta.endereco
            ? camposNovaProposta.endereco
            : {};
      let validacaoCampos = true;
      let validacaoCamposVariaveis = true;
      let validacaoEndereco = true;
      let validacaoProduto2 = true;
      let validacaoProduto3 = true;

      if (camposNovaProposta.tipoContrato?.value === 2) {
         camposProduto2.map((campo) => {
            if (!Object.keys(auxCampos).includes(campo) || !auxCampos[campo]) {
               validacaoProduto2 = false;
            }
         });
      } else {
         camposVariaveis.map((campo) => {
            if (!Object.keys(auxCampos).includes(campo) || !auxCampos[campo]) {
               validacaoCamposVariaveis = false;
            }
         });
      }

      if (
         camposNovaProposta.tipoContrato?.value === 3 ||
         camposNovaProposta.tipoContrato?.value === 4
      ) {
         camposProduto3.map((campo) => {
            if (!Object.keys(auxCampos).includes(campo) || !auxCampos[campo]) {
               validacaoProduto3 = false;
            }
         });
      }

      camposProposta.map((campo) => {
         if (!Object.keys(auxCampos).includes(campo) || !auxCampos[campo]) {
            validacaoCampos = false;
         }
      });

      camposEndereco.map((campo) => {
         if (!Object.keys(auxEndereco).includes(campo) || !auxEndereco[campo]) {
            validacaoEndereco = false;
         }
      });

      let validacao = !(
         validacaoCampos &&
         validacaoCamposVariaveis &&
         validacaoEndereco &&
         validacaoProduto2 &&
         validacaoProduto3
      );

      return validacao;
   }, [camposNovaProposta]);

   const renderFilter = () => {
      return (
         <>
            <div className="form-group mb-3">
               <div className="input-group mx-3">
                  <Input
                     id="proposta"
                     className="form-control"
                     placeholder="Proposta..."
                     type="text"
                     value={proposalFilter}
                     onChange={(e) => setProposalFilter(e.target.value || null)}
                  />
               </div>
            </div>
            <div className="form-group mb-3">
               <div className="input-group mx-3">
                  <Input
                     id="cpf"
                     className="form-control"
                     placeholder="Cpf..."
                     type="cpf"
                     value={cpf}
                     onChange={(e) => setCpf(e.target.value || null)}
                  />
               </div>
            </div>

            <div className="form-group mb-3">
               <div className="input-group mx-3">
                  <Input
                     id="name"
                     className="form-control"
                     placeholder="Nome..."
                     type="text"
                     value={name}
                     onChange={(e) => setName(e.target.value || null)}
                  />
               </div>
            </div>

            <div className="form-group mx-3">
               <Select
                  id="status"
                  ref={selectStatus}
                  className="select-form-control"
                  placeholder="Status..."
                  onChange={(e) => {
                     if (e) {
                        setStatus(e.value || null);
                     }
                  }}
                  options={list}
                  backspaceRemovesValue
               />
            </div>

            <div className="form-group mx-3">
               <Select
                  id="parceiro_id"
                  ref={selectParc}
                  className="select-form-control"
                  placeholder="Parceiros..."
                  onChange={(e) => {
                     if (e && e.value) {
                        setParceiro(e.value);
                     }
                  }}
                  options={listParceiros}
                  backspaceRemovesValue
               />
            </div>
            <div className="row mx-5">
               <div className="form-group mb-2 ">
                  <div className="input-group ">
                     <div className="input-group-prepend ">
                        <span className="input-group-text px-3">
                           <i className="ni ni-calendar-grid-58"></i>
                        </span>
                     </div>
                     <DatePicker
                        id="dataInicio"
                        dateFormat="P"
                        locale="pt"
                        className="form-control "
                        placeholderText="Data inicial..."
                        selected={dataInicio}
                        onChange={(date) => setDataInicio(date)}
                     />
                  </div>
               </div>

               <div className="form-group mb-2 ">
                  <div className="input-group">
                     <div className="input-group-prepend">
                        <span className="input-group-text px-3">
                           <i className="ni ni-calendar-grid-58"></i>
                        </span>
                     </div>
                     <DatePicker
                        id="dataFim"
                        dateFormat="P"
                        locale="pt"
                        className="form-control "
                        placeholderText="Data final..."
                        selected={dataFim}
                        onChange={(date) => setDataFim(date)}
                     />
                  </div>
               </div>
            </div>
         </>
      );
   };

   const buscarTiposContrato = async () => {
      const result = await getContractsType();
      const agents = await getAgents();
      setAgentes(agents);
      if (result) {
         setCamposNovaProposta({
            ...camposNovaProposta,
            tiposContato: result,
         });
      }
   };

   return (
      <div>
         {formLoading && (
            <div
               style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "100vh",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  display: "flex",
                  position: "fixed",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "999999",
               }}
            >
               <Loading />
            </div>
         )}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Exportar CSV",
                     icone: (
                        <i
                           className="fas fa-file-csv"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: handleExport,
                  },
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => {
                        buscarTiposContrato();
                        window.$("#modal-adicionar").modal("show");
                     },
                  },
                  {
                     name: "Filtrar",
                     icone: (
                        <i
                           className="fas fa-search"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                  },
               ]}
            />

            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>
         {/* Modal de Filtro */}
         <Modal
            id={"modal-filter"}
            title={"Filtrar"}
            size={"md"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Proposta</h3>
                     </div>
                     <div className="card-body">
                        {renderFilter()}
                        <div className="row">
                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onClear}
                                 className="btn btn-danger btn-block my-4"
                              >
                                 Limpar
                              </button>
                           </div>

                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onFilter}
                                 className="btn btn-primary btn-block my-4"
                              >
                                 Filtrar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />
         {/*Modal Adicionar*/}
         <Modal
            id={"modal-adicionar"}
            title={"Adicionar nova Proposta"}
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     {/* <!-- Card header --> */}
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Cliente</h3>
                     </div>
                     {/* <!-- Card body --> */}
                     <div className="card-body">
                        {/* <!-- Form groups used in grid --> */}
                        <div className="row">
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>CPF*</label>
                                 <Input
                                    id="cpf_proposta"
                                    type="cpf"
                                    className="form-control"
                                    placeholder="Digite o cpf..."
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.cpf_proposta ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Nome*</label>
                                 <Input
                                    id="nome_proposta"
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite o nome do cliente..."
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.nome_proposta ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>RG*</label>
                                 <Input
                                    id="rg_proposta"
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite o rg..."
                                    onChange={handleChangeProposal}
                                    value={camposNovaProposta.rg_proposta ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Nascimento*</label>
                                 <Input
                                    id="nascimento_proposta"
                                    type="birthday"
                                    className="form-control"
                                    placeholder="Digite a data de nascimento..."
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.nascimento_proposta ??
                                       ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Renda*</label>
                                 <Input
                                    id="renda"
                                    type="money"
                                    className="form-control"
                                    placeholder="Digite a renda..."
                                    onChange={handleChangeProposal}
                                    value={camposNovaProposta.renda ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>CEP*</label>
                                 <Input
                                    id="cep"
                                    type="cep"
                                    className="form-control"
                                    placeholder="Digite o cep..."
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.endereco?.cep ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-8">
                              <div className="form-group">
                                 <label>Logradouro*</label>
                                 <Input
                                    id="rua"
                                    type="text"
                                    placeholder="Digite o logradouro..."
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.endereco?.rua ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Numero*</label>
                                 <Input
                                    id="numero"
                                    type="text"
                                    className="form-control"
                                    placeholder="Nº"
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.endereco?.numero ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Bairro</label>
                                 <Input
                                    id="bairro"
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite o bairro..."
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.endereco?.bairro ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Complemento</label>
                                 <Input
                                    id="complemento"
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite o complemento..."
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.endereco
                                          ?.complemento ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>UF</label>
                                 <Select
                                    id="estado"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          endereco: {
                                             ...camposNovaProposta?.endereco,
                                             cidade: "",
                                             estado: e,
                                          },
                                       })
                                    }
                                    value={
                                       camposNovaProposta.endereco?.estado ?? []
                                    }
                                    options={estados ?? []}
                                    placeholder="Selecione um estado..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Cidade</label>
                                 <Select
                                    id="cidade"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          endereco: {
                                             ...camposNovaProposta?.endereco,
                                             cidade: e,
                                          },
                                       })
                                    }
                                    value={
                                       camposNovaProposta.endereco?.cidade ?? []
                                    }
                                    options={cidades ?? []}
                                    placeholder="Selecione uma cidade..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Telefone</label>
                                 <Input
                                    id="telefone"
                                    type="phone"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite o telefone"
                                    value={camposNovaProposta.telefone ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Celular</label>
                                 <Input
                                    id="celular_proposta"
                                    type="phone"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite o celular"
                                    value={
                                       camposNovaProposta.celular_proposta ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Email</label>
                                 <Input
                                    id="email_proposta"
                                    type="text"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite o e-mail"
                                    value={
                                       camposNovaProposta.email_proposta ?? ""
                                    }
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card mb-4">
                     {/* <!-- Card header --> */}
                     <div className="card-header">
                        <h3 className="mb-0">Dados da Proposta</h3>
                     </div>
                     {/* <!-- Card body --> */}
                     <div className="card-body">
                        {/* <!-- Form groups used in grid --> */}
                        <div className="row">
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Produto*</label>
                                 <Select
                                    id="produto"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          produto: e,
                                       })
                                    }
                                    value={camposNovaProposta.produto ?? []}
                                    options={[
                                       {
                                          value: 1,
                                          label: "Empréstimo Consignado",
                                       },
                                       { value: 8, label: "Auxílio Brasil" },
                                    ]}
                                    placeholder="Selecione o produto..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Tipo Contrato*</label>
                                 <Select
                                    id="tipoContrato"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          tipoContrato: e,
                                          valBrutoProposta: "",
                                          valLiquidoProposta: "",
                                          seguroProposta: "",
                                          iofProposta: "",
                                          quitacaoProposta: "",
                                       })
                                    }
                                    value={
                                       camposNovaProposta.tipoContrato ?? []
                                    }
                                    options={
                                       camposNovaProposta.tiposContato ?? []
                                    }
                                    placeholder="Selecione o tipo do contrato..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Banco Correspondente*</label>
                                 <Select
                                    id="bancoPagamento"
                                    type="text"
                                    onChange={(e) => {
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          bancoPagamento: e,
                                       });
                                       updateFields(
                                          "bancoPagamento",
                                          e || null
                                       );
                                    }}
                                    value={
                                       camposNovaProposta.bancoPagamento ?? []
                                    }
                                    options={bancos ?? []}
                                    placeholder="Selecione o banco correspondente..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Numero da Proposta*</label>
                                 <Input
                                    id="numProposta"
                                    type="text"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite o número da proposta..."
                                    value={camposNovaProposta.numProposta ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Data da emissão*</label>
                                 <Input
                                    id="dataEmissao"
                                    type="birthday"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite a data de emissão..."
                                    value={camposNovaProposta.dataEmissao ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Autorização de Pagamento*</label>
                                 <Select
                                    id="autPagamento"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          autPagamento: e,
                                       })
                                    }
                                    value={
                                       camposNovaProposta.autPagamento ?? []
                                    }
                                    options={optionsAutPagamento ?? []}
                                    placeholder="Selecione aut. de pagamento..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Setor Convênio*</label>
                                 <Select
                                    id="setorConvenio"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          setorConvenio: e,
                                       })
                                    }
                                    value={
                                       camposNovaProposta.setorConvenio ?? []
                                    }
                                    options={optionsSetorConvenio ?? []}
                                    placeholder="Selecione o setor do convênio..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Convênio*</label>
                                 <Select
                                    id="convenio"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          convenio: e,
                                       })
                                    }
                                    value={camposNovaProposta.convenio ?? []}
                                    options={convenios ?? []}
                                    placeholder="Selecione um convênio..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Matrícula*</label>
                                 <Input
                                    id="matricula"
                                    type="number"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Selecione a matrícula..."
                                    value={camposNovaProposta.matricula ?? ""}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Canal de Venda*</label>
                                 <Select
                                    id="canalVenda"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          canalVenda: e,
                                       })
                                    }
                                    value={camposNovaProposta.canalVenda ?? []}
                                    options={optionsCanalVenda ?? []}
                                    placeholder="Selecione um canal de venda..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Origem*</label>
                                 <Select
                                    id="origem"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          origem: e,
                                       })
                                    }
                                    value={camposNovaProposta.origem ?? []}
                                    options={origens ?? []}
                                    placeholder="Selecione uma origem..."
                                 />
                              </div>
                           </div>
                           {camposNovaProposta.tipoContrato?.value === 2 && (
                              <>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Banco da Portabilidade*</label>
                                       <Select
                                          id="bancoPortabilidade"
                                          type="text"
                                          onChange={(e) =>
                                             setCamposNovaProposta({
                                                ...camposNovaProposta,
                                                bancoPortabilidade: e,
                                             })
                                          }
                                          value={
                                             camposNovaProposta.bancoPortabilidade ??
                                             []
                                          }
                                          options={bancos ?? []}
                                          placeholder="Selecione o banco..."
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Nº do Contrato Original*</label>
                                       <Input
                                          id="numContratoOriginal"
                                          type="number"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          placeholder="Digite o contrato original..."
                                          value={
                                             camposNovaProposta.numContratoOriginal ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Saldo Devedor Solicitado*</label>
                                       <Input
                                          id="saldoDevedorSolicitado"
                                          type="money"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          onBlur={handleBlur}
                                          placeholder="Digite o saldo devedor..."
                                          value={
                                             camposNovaProposta.saldoDevedorSolicitado ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Oriunda Agência:*</label>
                                       <Select
                                          id="oriundaAgencia"
                                          type="text"
                                          onChange={(e) =>
                                             setCamposNovaProposta({
                                                ...camposNovaProposta,
                                                oriundaAgencia: e,
                                             })
                                          }
                                          value={
                                             camposNovaProposta.oriundaAgencia ??
                                             []
                                          }
                                          options={[
                                             { value: 1, label: "SIM" },
                                             { value: 2, label: "NÃO" },
                                          ]}
                                          placeholder="Selecione uma opção..."
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>
                                          Qtd. de Parcelas Contrato Original*
                                       </label>
                                       <Input
                                          id="parcelasContratoOriginal"
                                          type="number"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          value={
                                             camposNovaProposta.parcelasContratoOriginal ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Data Contrato Original*</label>
                                       <Input
                                          id="dataContratoOriginal"
                                          type="birthday"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          value={
                                             camposNovaProposta.dataContratoOriginal ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>
                                          Valor da Parcela no Banco da
                                          Portabilidade*
                                       </label>
                                       <Input
                                          id="parcelaBancoPortabilidade"
                                          type="money"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          value={
                                             camposNovaProposta.parcelaBancoPortabilidade ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                              </>
                           )}
                           {(camposNovaProposta.tipoContrato?.value === 3 ||
                              camposNovaProposta.tipoContrato?.value === 4) && (
                              <>
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>
                                          Parcelas pagas contrato original*
                                       </label>
                                       <Input
                                          id="parcelasPagasContratoOriginal"
                                          type="number"
                                          className="form-control"
                                          onChange={handleChangeProposal}
                                          value={
                                             camposNovaProposta.parcelasPagasContratoOriginal ??
                                             ""
                                          }
                                       />
                                    </div>
                                 </div>
                              </>
                           )}
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Data da Integração</label>
                                 <Input
                                    id="integracaoProposta"
                                    type="birthday"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite a data de integração..."
                                    value={
                                       camposNovaProposta.integracaoProposta ??
                                       ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Valor Bruto</label>
                                 <Input
                                    id="valBrutoProposta"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.valBrutoProposta ?? ""
                                    }
                                    disabled={
                                       camposNovaProposta.tipoContrato
                                          ?.value === 1 ||
                                       camposNovaProposta.tipoContrato
                                          ?.value === 2
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Valor Líquido</label>
                                 <Input
                                    id="valLiquidoProposta"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.valLiquidoProposta ??
                                       ""
                                    }
                                    disabled={
                                       camposNovaProposta.tipoContrato
                                          ?.value === 2
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>IOF</label>
                                 <Input
                                    id="iofProposta"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={camposNovaProposta.iofProposta ?? ""}
                                    disabled={
                                       camposNovaProposta.tipoContrato
                                          ?.value === 2
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Taxa</label>
                                 <Input
                                    id="taxaProposta"
                                    type="percentual"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.taxaProposta ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Seguro</label>
                                 <Input
                                    id="seguroProposta"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.seguroProposta ?? ""
                                    }
                                    disabled={
                                       camposNovaProposta.tipoContrato
                                          ?.value === 2
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Valor Quitação</label>
                                 <Input
                                    id="quitacaoProposta"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.quitacaoProposta ?? ""
                                    }
                                    disabled={true}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Remuneração</label>
                                 <Select
                                    id="remuneracaoProposta"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          remuneracaoProposta: e,
                                       })
                                    }
                                    value={
                                       camposNovaProposta.remuneracaoProposta ??
                                       []
                                    }
                                    options={optionsRemuneracao ?? []}
                                    placeholder="Selecione o tipo de remuneração..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Agentes</label>
                                 <Select
                                    id="agenteId"
                                    type="text"
                                    onChange={(e) =>
                                       setCamposNovaProposta({
                                          ...camposNovaProposta,
                                          agenteId: e,
                                       })
                                    }
                                    value={camposNovaProposta.agenteId ?? []}
                                    options={agentes ?? []}
                                    placeholder="Selecione o agente responsável..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Regras</label>
                                 <Select
                                    id="regras"
                                    type="text"
                                    onChange={(e) =>
                                       handleChangeProposal({
                                          target: { id: "regras", value: e },
                                       })
                                    }
                                    value={camposNovaProposta.regras ?? []}
                                    options={regras ?? []}
                                    placeholder="Selecione a regra..."
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     {/* <!-- Card header --> */}
                     <div className="card-header">
                        <h3 className="mb-0">Parcelas</h3>
                     </div>
                     {/* <!-- Card body --> */}
                     <div className="card-body">
                        {/* <!-- Form groups used in grid --> */}
                        <div className="row">
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Vencimento Primeira Parcela*</label>
                                 <Input
                                    id="vencPrimeiraParcela"
                                    type="birthday"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    placeholder="Digite a data de vencimento..."
                                    value={
                                       camposNovaProposta.vencPrimeiraParcela ??
                                       ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Quantidade de Parcelas*</label>
                                 <Select
                                    id="qtdParcelas"
                                    type="text"
                                    onChange={(e) =>
                                       handleChangeProposal({
                                          target: {
                                             id: "qtdParcelas",
                                             value: e,
                                          },
                                       })
                                    }
                                    value={camposNovaProposta.qtdParcelas ?? []}
                                    options={
                                       camposNovaProposta.parcelas ?? [
                                          { value: 12, label: "12" },
                                          { value: 24, label: "24" },
                                          { value: 36, label: "36" },
                                          { value: 48, label: "48" },
                                          { value: 60, label: "60" },
                                          { value: 72, label: "72" },
                                          { value: 84, label: "84" },
                                       ]
                                    }
                                    placeholder="Selecione uma parcela..."
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Valor Parcelas*</label>
                                 <Input
                                    id="valorParcelas"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    onBlur={handleBlur}
                                    value={
                                       camposNovaProposta.valorParcelas ?? ""
                                    }
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label>Valor Total*</label>
                                 <Input
                                    id="valorTotalParcelas"
                                    type="money"
                                    className="form-control"
                                    onChange={handleChangeProposal}
                                    value={
                                       camposNovaProposta.valorTotalParcelas ??
                                       ""
                                    }
                                    disabled
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  {[5, 6].includes(user.role) &&
                     (inputAudio || (!inputAudio && !isDisabled)) && (
                        <div className="card mb-4">
                           {/* <!-- Card header --> */}
                           <div className="card-header">
                              <h3 className="mb-0">Audio (Ligação)</h3>
                           </div>
                           {/* <!-- Card body --> */}
                           <div className="card-body">
                              {/* <!-- Form groups used in grid --> */}
                              <div className="row">
                                 {[5, 6].includes(user.role) &&
                                    !inputAudio &&
                                    !isDisabled && (
                                       <div
                                          className="col-md-12"
                                          style={{ zIndex: 0 }}
                                       >
                                          <div
                                             className="form-group"
                                             style={{ zIndex: 0 }}
                                          >
                                             <div
                                                className="custom-file"
                                                style={{ zIndex: 0 }}
                                             >
                                                <input
                                                   style={{ zIndex: 0 }}
                                                   type="file"
                                                   className="custom-file-input"
                                                   id="inputAudioNovaProposta"
                                                   lang="pt"
                                                   onChange={() =>
                                                      onSaveFile(
                                                         "audioNovaProposta",
                                                         "inputAudioNovaProposta"
                                                      )
                                                   }
                                                   accept=".mp3"
                                                />
                                                <label
                                                   className="custom-file-label"
                                                   htmlFor="inputAudio"
                                                >
                                                   Selecionar
                                                </label>
                                             </div>
                                          </div>
                                       </div>
                                    )}

                                 {[5, 6].includes(user.role) && inputAudio && (
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <audio
                                             controls
                                             className="embed-responsive-item"
                                          >
                                             <source
                                                src={inputAudio}
                                                type="audio/mpeg"
                                             />
                                             Seu navegador não é compatível com
                                             a função de audio.
                                          </audio>
                                       </div>
                                    </div>
                                 )}

                                 {[5, 6].includes(user.role) &&
                                    inputAudio &&
                                    !isDisabled && (
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <button
                                                type="button"
                                                className="btn btn-danger btn-block"
                                                onClick={() =>
                                                   onDeleteFile(
                                                      "audio",
                                                      "inputAudio"
                                                   )
                                                }
                                             >
                                                Deletar
                                             </button>
                                          </div>
                                       </div>
                                    )}
                              </div>
                           </div>
                        </div>
                     )}

                  {!viewMode && !isDisabled && (
                     <div className="row mt-3">
                        <div className="col-md-12 text-right">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={onReset}
                           >
                              Fechar
                           </button>
                           <button
                              type="button"
                              onClick={onSaveNewProposta}
                              disabled={disableBtnNewProposta}
                              className="btn btn-primary"
                           >
                              Salvar
                           </button>
                        </div>
                     </div>
                  )}
               </div>
            }
         />
         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={
               inputCliente
                  ? inputCliente.blocos.find((x) => x.nome === "Nome").valor
                  : "Editar"
            }
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Cliente</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           {inputCliente &&
                              inputCliente.blocos.map((x, index) => (
                                 <div className="col-md-4" key={index}>
                                    <div className="form-group">
                                       <label>{x.nome}</label>
                                       <Input
                                          id={
                                             x.id ? x.id : x.nome.toLowerCase()
                                          }
                                          type="text"
                                          className="form-control"
                                          value={fields[x.id] || x.valor}
                                          onChange={(e) =>
                                             updateFields(
                                                x.id,
                                                e.target.value || null
                                             )
                                          }
                                          disabled={
                                             isDisabled ||
                                             isDisabledByRole(x.id)
                                          }
                                       />
                                    </div>
                                 </div>
                              ))}
                        </div>
                     </div>
                  </div>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados da Proposta</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           {inputProposta &&
                              inputProposta.blocos.map((x, index) => {
                                 if (x.id === "status") {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <select
                                                onChange={(e) =>
                                                   updateFields(
                                                      x.id,
                                                      e.target.value || null
                                                   )
                                                }
                                                type="text"
                                                className="form-control"
                                                defaultValue={
                                                   inputEsteira.status
                                                }
                                                disabled={
                                                   isDisabled ||
                                                   isDisabledByRole(x.id)
                                                }
                                             >
                                                {inputEsteira &&
                                                   inputEsteira.opcoes.map(
                                                      (y) => (
                                                         <option
                                                            key={y.id}
                                                            value={y.id}
                                                         >
                                                            {y.nome}
                                                         </option>
                                                      )
                                                   )}
                                             </select>
                                          </div>
                                       </div>
                                    );
                                 } else if (x.id === "data_integracao") {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <div className="input-group">
                                                <div className="input-group-prepend">
                                                   <span className="input-group-text">
                                                      <i className="ni ni-calendar-grid-58"></i>
                                                   </span>
                                                </div>
                                                <Input
                                                   onChange={(e) =>
                                                      updateFields(
                                                         x.id,
                                                         e.target.value || null
                                                      )
                                                   }
                                                   id={x.id}
                                                   type="birthday"
                                                   className="form-control"
                                                   value={
                                                      fields[x.id] || x.valor
                                                   }
                                                   help={
                                                      errors.data_integracao ||
                                                      ""
                                                   }
                                                   disabled={
                                                      isDisabled ||
                                                      isDisabledByRole(x.id)
                                                   }
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else if (
                                    x.id === "comissao" &&
                                    inputProposta.proposta_interna == "true"
                                 ) {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <div className="input-group">
                                                <Input
                                                   onChange={(e) =>
                                                      updateFields(
                                                         x.id,
                                                         e.target.value || null
                                                      )
                                                   }
                                                   id={x.id}
                                                   type="money"
                                                   className="form-control"
                                                   value={
                                                      fields[x.id] || x.valor
                                                   }
                                                   help={errors.comissao || ""}
                                                   disabled={
                                                      isDisabled ||
                                                      isDisabledByRole(x.id)
                                                   }
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else if (
                                    x.id === "comissao_master" &&
                                    inputProposta.proposta_interna == "true"
                                 ) {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <div className="input-group">
                                                <Input
                                                   onChange={(e) =>
                                                      updateFields(
                                                         x.id,
                                                         e.target.value || null
                                                      )
                                                   }
                                                   id={x.id}
                                                   type="money"
                                                   className="form-control"
                                                   value={
                                                      fields[x.id] || x.valor
                                                   }
                                                   help={
                                                      errors.comissao_master ||
                                                      ""
                                                   }
                                                   disabled={
                                                      isDisabled ||
                                                      isDisabledByRole(x.id)
                                                   }
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else if (
                                    x.id === "valor_imposto" &&
                                    inputProposta.proposta_interna == "true"
                                 ) {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <div className="input-group">
                                                <Input
                                                   onChange={(e) =>
                                                      updateFields(
                                                         x.id,
                                                         e.target.value || null
                                                      )
                                                   }
                                                   id={x.id}
                                                   type="money"
                                                   className="form-control"
                                                   value={
                                                      fields[x.id] || x.valor
                                                   }
                                                   help={
                                                      errors.valor_imposto || ""
                                                   }
                                                   disabled={
                                                      isDisabled ||
                                                      isDisabledByRole(x.id)
                                                   }
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else if (
                                    x.id === "comissao_seucredito" &&
                                    inputProposta.proposta_interna == "true"
                                 ) {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <div className="input-group">
                                                <Input
                                                   onChange={(e) =>
                                                      updateFields(
                                                         x.id,
                                                         e.target.value || null
                                                      )
                                                   }
                                                   id={x.id}
                                                   type="money"
                                                   className="form-control"
                                                   value={
                                                      fields[x.id] || x.valor
                                                   }
                                                   help={
                                                      errors.comissao_seucredito ||
                                                      ""
                                                   }
                                                   disabled={
                                                      isDisabled ||
                                                      isDisabledByRole(x.id)
                                                   }
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else {
                                    return (
                                       <div className="col-md-4" key={index}>
                                          <div className="form-group">
                                             <label>{x.nome}</label>
                                             <Input
                                                onChange={(e) =>
                                                   updateFields(
                                                      x.id,
                                                      e.target.value || null
                                                   )
                                                }
                                                onBlur={(e) =>
                                                   onBlur(
                                                      x.id,
                                                      e.target.value || null
                                                   )
                                                }
                                                id={x.id}
                                                type="text"
                                                className="form-control"
                                                value={fields[x.id] || x.valor}
                                                disabled={
                                                   isDisabled ||
                                                   isDisabledByRole(x.id)
                                                }
                                             />
                                          </div>
                                       </div>
                                    );
                                 }
                              })}
                           {inputProposta &&
                              parseInt(inputProposta.produto) === 1 && (
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Regras</label>
                                       <select
                                          onChange={(e) =>
                                             updateFields(
                                                "regras",
                                                e.target.value || null
                                             )
                                          }
                                          type="text"
                                          className="form-control"
                                          defaultValue={
                                             regra
                                                ? regra
                                                : fields.regras
                                                ? fields.regras
                                                : null
                                          }
                                          id="regras"
                                       >
                                          {regras &&
                                             regras.map((x) => (
                                                <option value={x.id} key={x.id}>
                                                   {x.nome}
                                                </option>
                                             ))}
                                       </select>
                                    </div>
                                 </div>
                              )}
                           {inputProposta &&
                              [1, 5].includes(
                                 parseInt(inputProposta.produto)
                              ) && (
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Banco Pagamento</label>
                                       <select
                                          onChange={(e) =>
                                             updateFields(
                                                "banco_pagamento",
                                                e.target.value || null
                                             )
                                          }
                                          type="text"
                                          className="form-control"
                                          defaultValue={
                                             inputCliente
                                                ? inputCliente.banco_id
                                                : null
                                          }
                                          disabled={
                                             inputProposta
                                                ? (inputProposta.produto &&
                                                     parseInt(
                                                        inputProposta.produto
                                                     ) === 1) ||
                                                  parseInt(
                                                     inputProposta.produto
                                                  ) === 5
                                                   ? false
                                                   : true
                                                : true
                                          }
                                          id="banco_pagamento"
                                       >
                                          {bancos &&
                                             bancos.map((x) => (
                                                <option key={x.id} value={x.id}>
                                                   {x.nome}
                                                </option>
                                             ))}
                                       </select>
                                    </div>
                                 </div>
                              )}

                           {linkFormalizacao && (
                              <div className="col-md-4">
                                 <div className="form-group">
                                    <a
                                       href={linkFormalizacao}
                                       target="_blank"
                                       className="btn btn-sm btn-info btn-icon"
                                    >
                                       <span className="btn-inner--text">
                                          Formalização Online
                                       </span>
                                       <span className="btn-inner--icon">
                                          <i className="ni ni-world"></i>
                                       </span>
                                    </a>
                                 </div>
                              </div>
                           )}

                           {inputProposta &&
                              [5].includes(parseInt(inputProposta.produto)) && (
                                 <div className="col-md-4">
                                    <div className="form-group">
                                       <label>Valor Líquido</label>
                                       <Input
                                          onChange={(e) =>
                                             updateFields(
                                                "valor_liquido_contrato",
                                                e.target.value || null
                                             )
                                          }
                                          id={"valor_liquido_contrato"}
                                          type="money"
                                          className="form-control"
                                          value={
                                             fields.valor_liquido_contrato
                                                ? fields.valor_liquido_contrato
                                                : inputProposta.valor_liquido_contrato
                                                ? funcoes.formataMoeda(
                                                     inputProposta.valor_liquido_contrato
                                                  )
                                                : null
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {(fields["status"] || inputStatus) &&
                              ID_WITH_OBS.includes(
                                 (fields["status"] || inputStatus).toString()
                              ) && (
                                 <div className="col-md-8">
                                    <div className="form-group">
                                       <label style={{ color: "red" }}>
                                          Pendência*
                                       </label>
                                       <Select
                                          closeMenuOnSelect={false}
                                          components={animatedComponents}
                                          onChange={(e) => {
                                             let auxPendencia = [];
                                             //e.map
                                             e.forEach((p) => {
                                                if (p.value) {
                                                   auxPendencia.push(p.value);
                                                }
                                             });
                                             updateFields(
                                                "observacao",
                                                auxPendencia || null
                                             );
                                          }}
                                          defaultValue={fields["observacao"]}
                                          placeholder="Selecione uma observação..."
                                          isMulti
                                          options={observations}
                                          disabled={
                                             isDisabled ||
                                             isDisabledByRole("observacao")
                                          }
                                          id="observacao"
                                       />
                                    </div>
                                 </div>
                              )}
                        </div>
                     </div>
                  </div>
                  {inputEndereco && (
                     <div className="card mb-4">
                        <div className="card-header">
                           <h3 className="mb-2">Dados de Endereço</h3>
                        </div>
                        <div className="card-body">
                           <div className="row">
                              {inputEndereco &&
                                 inputEndereco.map((x) => (
                                    <div
                                       className="col-md-4 form-group"
                                       key={x.id}
                                    >
                                       <label>{x.nome}</label>
                                       <Input
                                          id={x.id}
                                          type="text"
                                          className="form-control"
                                          value={fields[x.id] || x.valor}
                                          onChange={(e) =>
                                             updateFields(
                                                x.id,
                                                e.target.value || null
                                             )
                                          }
                                          disabled={
                                             isDisabled ||
                                             isDisabledByRole(x.id)
                                          }
                                       />
                                    </div>
                                 ))}
                           </div>
                        </div>
                     </div>
                  )}

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Contrato</h3>
                     </div>
                     <div className="card-body">
                        {inputDocumentos && inputDocumentos.length > 0 && (
                           <h3 className="mb-2">Documentos</h3>
                        )}

                        <div className="row">
                           {inputDocumentos &&
                              inputDocumentos.map((x) => (
                                 <div
                                    className="col-md-4 form-group"
                                    key={x.id}
                                 >
                                    <a
                                       href={x.valor}
                                       className="btn btn-default btn-block"
                                       target="_blank"
                                    >
                                       {x.nome}
                                    </a>
                                 </div>
                              ))}
                        </div>
                        {(inputContrato || (!inputContrato && !isDisabled)) && (
                           <h3 className="mb-2">Contrato</h3>
                        )}
                        <div className="row" style={{ zIndex: 0 }}>
                           {!inputContrato && !isDisabled && (
                              <div className="col-md-12" style={{ zIndex: 0 }}>
                                 <div
                                    className="form-group"
                                    style={{ zIndex: 0 }}
                                 >
                                    <div
                                       className="custom-file"
                                       style={{ zIndex: 0 }}
                                    >
                                       <input
                                          style={{ zIndex: 0 }}
                                          type="file"
                                          className="custom-file-input"
                                          id="inputContrato"
                                          lang="pt"
                                          onChange={() =>
                                             onSaveFile(
                                                "contrato",
                                                "inputContrato"
                                             )
                                          }
                                          accept="application/pdf"
                                       />
                                       <label
                                          className="custom-file-label"
                                          htmlFor="inputContrato"
                                       >
                                          Selecionar
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           )}
                           {inputContrato && (
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <a
                                       href={inputContrato}
                                       target="_blank"
                                       className="btn btn-default btn-block"
                                    >
                                       Contrato
                                    </a>
                                 </div>
                              </div>
                           )}

                           {inputContrato && !isDisabled && (
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <button
                                       type="button"
                                       className="btn btn-danger btn-block"
                                       onClick={() =>
                                          onDeleteFile(
                                             "contrato",
                                             "inputContrato"
                                          )
                                       }
                                    >
                                       Deletar
                                    </button>
                                 </div>
                              </div>
                           )}
                        </div>

                        {[5, 6].includes(user.role) &&
                           (inputAudio || (!inputAudio && !isDisabled)) && (
                              <h3 className="mb-2">Audio (Ligação)</h3>
                           )}
                        <div className="row">
                           {[5, 6].includes(user.role) &&
                              !inputAudio &&
                              !isDisabled && (
                                 <div
                                    className="col-md-12"
                                    style={{ zIndex: 0 }}
                                 >
                                    <div
                                       className="form-group"
                                       style={{ zIndex: 0 }}
                                    >
                                       <div
                                          className="custom-file"
                                          style={{ zIndex: 0 }}
                                       >
                                          <input
                                             style={{ zIndex: 0 }}
                                             type="file"
                                             className="custom-file-input"
                                             id="inputAudio"
                                             lang="pt"
                                             onChange={() =>
                                                onSaveFile(
                                                   "audio",
                                                   "inputAudio"
                                                )
                                             }
                                             accept=".mp3"
                                          />
                                          <label
                                             className="custom-file-label"
                                             htmlFor="inputAudio"
                                          >
                                             Selecionar
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              )}

                           {[5, 6].includes(user.role) && inputAudio && (
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <audio
                                       controls
                                       className="embed-responsive-item"
                                    >
                                       <source
                                          src={inputAudio}
                                          type="audio/mpeg"
                                       />
                                       Seu navegador não é compatível com a
                                       função de audio.
                                    </audio>
                                 </div>
                              </div>
                           )}

                           {[5, 6].includes(user.role) &&
                              inputAudio &&
                              !isDisabled && (
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <button
                                          type="button"
                                          className="btn btn-danger btn-block"
                                          onClick={() =>
                                             onDeleteFile("audio", "inputAudio")
                                          }
                                       >
                                          Deletar
                                       </button>
                                    </div>
                                 </div>
                              )}
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Auditoria</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           {inputTicagem &&
                              inputTicagem.map((x, index) => (
                                 <div key={index} className="col-md-4">
                                    <div className="form-group">
                                       <div className="custom-control custom-checkbox">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id={x.id}
                                             checked={tics[x.id]}
                                             onChange={() => updateTic(x.id)}
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor={x.id}
                                          >
                                             {x.nome}
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              ))}
                           <div className="col-md-12">
                              <div className="form-group">
                                 <h3 className="mb-2">Observações</h3>
                                 <textarea
                                    id="anotacao_auditoria"
                                    className="form-control"
                                    aria-label="With textarea"
                                    rows="4"
                                    onChange={(e) => {
                                       updateFields(
                                          "anotacao_auditoria",
                                          e.target.value || null
                                       );
                                       setInputAnotacao(e.target.value);
                                    }}
                                    value={inputAnotacao || ""}
                                 ></textarea>
                              </div>
                           </div>
                        </div>

                        {inputPendencia && inputPendencia.length > 0 && (
                           <>
                              <h3 className="mb-2">Retorno Pendências Banco</h3>
                              <div className="table-responsive">
                                 <div>
                                    <table className="table table-striped align-items-center">
                                       <thead className="thead-light">
                                          <tr>
                                             <th scope="col">Data</th>
                                             <th scope="col">Titulo</th>
                                             <th scope="col">Descrição</th>
                                             <th scope="col">Status</th>
                                          </tr>
                                       </thead>
                                       <tbody className="list">
                                          {inputPendencia.map((x) => (
                                             <tr>
                                                <td className="budget">
                                                   {x.data_integracao}
                                                </td>
                                                <td className="budget">
                                                   {x.titulo && x.titulo !== ""
                                                      ? x.titulo
                                                      : ""}
                                                </td>
                                                <td
                                                   className="budget"
                                                   title={x.descricao}
                                                >
                                                   {x.descricao &&
                                                   x.descricao !== ""
                                                      ? funcoes.limitarTexto(
                                                           x.descricao,
                                                           100
                                                        )
                                                      : ""}
                                                </td>
                                                <td
                                                   className="budget"
                                                   title={x.status}
                                                >
                                                   <span className="badge badge-warning">
                                                      {x.status}
                                                   </span>
                                                </td>
                                             </tr>
                                          ))}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </>
                        )}
                     </div>
                  </div>

                  {cardsFgts && (
                     <>
                        <div className="card mb-4">
                           <div className="card-header">
                              <h3 className="mb-0">Cards selecionados FGTS</h3>
                           </div>
                           <div className="card-body">
                              <div className="table-responsive">
                                 <div>
                                    <table className="table align-items-center">
                                       <thead className="thead-light">
                                          <tr>
                                             <th scope="col">Parcela</th>
                                             <th scope="col">Data</th>
                                             <th scope="col">Saque</th>
                                             <th scope="col">Ação</th>
                                          </tr>
                                       </thead>
                                       <tbody className="list">
                                          {cardsFgts.map((x, index) => (
                                             <tr key={index} index={x.id}>
                                                <td className="budget">
                                                   {x.id}
                                                </td>
                                                <td className="budget">
                                                   {x.data ? x.data : " - "}
                                                </td>
                                                <td className="budget">
                                                   {x.valor ? x.valor : x.saque}
                                                </td>
                                                <td className="text-left">
                                                   <div className="dropdown">
                                                      <a
                                                         className="btn btn-sm btn-icon-only text-light"
                                                         href="#"
                                                         role="button"
                                                         data-toggle="dropdown"
                                                         aria-haspopup="true"
                                                         aria-expanded="false"
                                                      >
                                                         <i className="fas fa-ellipsis-v" />
                                                      </a>
                                                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                         <button
                                                            type="button"
                                                            onClick={() =>
                                                               onEditSaqueClick(
                                                                  x
                                                               )
                                                            }
                                                            className="dropdown-item"
                                                            title="Editar"
                                                         >
                                                            <i className="fas fa-edit"></i>{" "}
                                                            Editar
                                                         </button>
                                                      </div>
                                                   </div>
                                                </td>
                                             </tr>
                                          ))}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  )}

                  {inputHistorico && (
                     <>
                        <div className="card mb-4">
                           <div className="card-header">
                              <h3 className="mb-0">Histórico</h3>
                           </div>
                           <div className="card-body">
                              <div className="table-responsive">
                                 <div>
                                    <table className="table align-items-center">
                                       <thead className="thead-light">
                                          <tr>
                                             <th scope="col">Atualizado em</th>
                                             <th scope="col">Atualizado Por</th>
                                             <th scope="col">Status</th>
                                             <th scope="col">Observação</th>
                                             <th scope="col">Proposta</th>
                                             <th scope="col">
                                                Data Integração
                                             </th>
                                             {inputHistorico.length > 0 &&
                                                inputHistorico[0].colunas.map(
                                                   (x, index) => (
                                                      <th
                                                         key={index}
                                                         scope="col"
                                                      >
                                                         {x.nome}
                                                      </th>
                                                   )
                                                )}
                                             <th scope="col">Documentos</th>
                                          </tr>
                                       </thead>
                                       <tbody className="list">
                                          {inputHistorico.map((x, index) => (
                                             <tr key={index}>
                                                <td className="budget">
                                                   {x.atualizado_em}
                                                </td>
                                                <td className="budget">
                                                   {x.atualizado_por}
                                                </td>
                                                <td className="budget">
                                                   {x.status}
                                                </td>
                                                <td
                                                   className="budget"
                                                   title={x.observacao}
                                                >
                                                   {x.observacao &&
                                                   x.observacao !== ""
                                                      ? funcoes.limitarTexto(
                                                           x.observacao,
                                                           20
                                                        )
                                                      : ""}
                                                </td>
                                                <td className="budget">
                                                   {x.proposta}
                                                </td>
                                                <td className="budget">
                                                   {x.data_integracao}
                                                </td>
                                                {x.colunas &&
                                                   x.colunas.map(
                                                      (y, indexY) => (
                                                         <td
                                                            key={indexY}
                                                            className="budget"
                                                         >
                                                            {y.valor}
                                                         </td>
                                                      )
                                                   )}
                                                <td className="budget">
                                                   <div className="avatar-group">
                                                      {x.documentos &&
                                                         x.documentos.map(
                                                            (y) => (
                                                               <a
                                                                  type="button"
                                                                  className="avatar avatar-sm rounded-circle"
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title={y.nome}
                                                                  data-original-title={
                                                                     y.nome
                                                                  }
                                                                  key={y.id}
                                                                  onClick={() =>
                                                                     openFile(
                                                                        y.nome,
                                                                        y.valor
                                                                     )
                                                                  }
                                                               >
                                                                  <img
                                                                     alt={y.id}
                                                                     id={`image-${x.id}-${y.id}`}
                                                                     style={{
                                                                        width: 30,
                                                                        height: 30,
                                                                     }}
                                                                     src={
                                                                        y.valor
                                                                     }
                                                                     onError={() => {
                                                                        window
                                                                           .$(
                                                                              `#image-${x.id}-${y.id}`
                                                                           )
                                                                           .attr(
                                                                              "src",
                                                                              "https://storage.googleapis.com/seu-credito/PDF.png"
                                                                           );
                                                                     }}
                                                                  />
                                                               </a>
                                                            )
                                                         )}
                                                   </div>
                                                </td>
                                             </tr>
                                          ))}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  )}

                  {!viewMode && !isDisabled && (
                     <div className="row mt-3">
                        <div className="col-md-12 text-right">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={onReset}
                           >
                              Fechar
                           </button>
                           <button
                              type="button"
                              onClick={
                                 inputProposta &&
                                 inputProposta.proposta_interna == "true"
                                    ? atualizarProposta
                                    : onSave
                              }
                              disabled={disabledBtnSubmit}
                              className="btn btn-primary"
                           >
                              Salvar
                           </button>
                        </div>
                     </div>
                  )}
               </div>
            }
         />

         {/* Modal de Dados Complementares */}
         <Modal
            id={"modal-dados-complementares"}
            title="Editar Proposta"
            size="lg"
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <h3 className="mb-2">Dados Complementares Proposta</h3>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Nome</label>
                           <Input
                              type="text"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.nome || ""}
                              id="nome"
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>E-mail</label>
                           <Input
                              type="text"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.email || ""}
                              id="email"
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Data Nascimento</label>
                           <Input
                              type="birthday"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.nascimento || ""}
                              id="nascimento"
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Celular</label>
                           <Input
                              type="text"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.celular || ""}
                              id="celular"
                           />
                        </div>
                     </div>

                     <div className="col-md-4">
                        <div className="form-group">
                           <label>RG*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="rg"
                              onChange={handleChangeForm}
                              value={register.rg || null}
                              placeholder="RG..."
                           />
                        </div>
                     </div>

                     <div className="col-md-4">
                        <div className="form-group">
                           <label>Orgão Expedidor*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="orgao_emissor"
                              onChange={handleChangeForm}
                              value={register.orgao_emissor || null}
                              placeholder="Orgão emissor..."
                           />
                        </div>
                     </div>

                     <div className="col-md-4">
                        <div className="form-group">
                           <label>Data Expedição*</label>
                           <Input
                              type="birthday"
                              className="form-control"
                              id="data_emissao"
                              onChange={handleChangeForm}
                              value={register.data_emissao || null}
                              placeholder="Data Expedição..."
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Nome Pai*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="nome_pai"
                              onChange={handleChangeForm}
                              value={register.nome_pai || null}
                              placeholder="Nome Pai..."
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Nome Mae*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="nome_mae"
                              onChange={handleChangeForm}
                              value={register.nome_mae || null}
                              placeholder="Nome Mae..."
                           />
                        </div>
                     </div>
                  </div>

                  <h3 className="mb-2">Dados de Endereço</h3>
                  <div className="row">
                     <div className="col-md-4">
                        <div className="form-group">
                           <label>CEP*</label>
                           <Input
                              type="cep"
                              className="form-control"
                              id="endereco_cep"
                              onChange={handleChangeForm}
                              onBlur={handleBlur}
                              value={register.endereco_cep || null}
                              placeholder="CEP..."
                           />
                        </div>
                     </div>

                     <div className="col-md-8">
                        <div className="form-group">
                           <label>Logradouro*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="endereco_logradouro"
                              onChange={handleChangeForm}
                              value={register.endereco_logradouro || null}
                              placeholder="Logradouro..."
                           />
                        </div>
                     </div>

                     <div className="col-md-3">
                        <div className="form-group">
                           <label>Tipo Endereço*</label>
                           <select
                              id="tipo_endereco"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.tipo_endereco || "RESIDENCIAL"}
                           >
                              <option value="RESIDENCIAL">Residencial</option>
                              <option value="COMERCIAL">Comercial</option>
                              <option value="CORRESPONDENCIA">
                                 Correspondência
                              </option>
                           </select>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Bairro*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="endereco_bairro"
                              onChange={handleChangeForm}
                              value={register.endereco_bairro || null}
                              placeholder="Bairro..."
                           />
                        </div>
                     </div>

                     <div className="col-md-3">
                        <div className="form-group">
                           <label>Número*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="endereco_numero"
                              onChange={handleChangeForm}
                              value={register.endereco_numero || null}
                              placeholder="Número..."
                           />
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="form-group">
                           <label>Complemento*</label>
                           <Input
                              type="text"
                              className="form-control"
                              id="endereco_complemento"
                              onChange={handleChangeForm}
                              value={register.endereco_complemento || null}
                              placeholder="Complemento..."
                           />
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Estado*</label>
                           <select
                              id="endereco_estado"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.endereco_estado || ""}
                           >
                              <option value="">Estado...</option>
                              {estados &&
                                 estados.map((x) => (
                                    <option key={x.id} value={x.nome}>
                                       {x.nome}
                                    </option>
                                 ))}
                           </select>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="form-group">
                           <label>Cidade*</label>
                           <select
                              id="endereco_cidade"
                              className="form-control"
                              onChange={handleChangeForm}
                              value={register.endereco_cidade || ""}
                           >
                              <option value="">Selecione o estado...</option>
                              {cidades !== null &&
                                 cidades.map((x) => (
                                    <option value={x.nome}>{x.nome}</option>
                                 ))}
                           </select>
                        </div>
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Cancelar
                        </button>
                     </div>
                     <div className="col-md-6 text-center">
                        <button
                           type="submit"
                           className="btn btn-success btn-block"
                           onClick={onSubmitEditProposal}
                        >
                           <i className="far fa-save fa-lg iconDownload"></i>
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         {/* Modal de Uploads */}
         <Modal
            id={"modal-upload"}
            size={"lg"}
            title={
               bancoUpload
                  ? "Carregar Arquivos - " + bancoUpload.toUpperCase()
                  : "Carregar Arquivos"
            }
            onSubmit={handleSubmit}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <label htmlFor="tipo_documento">Tipo Documento</label>
                  <div className="form-group mb-3">
                     <div className="input-group input-group-merge input-group-alternative">
                        <select
                           id="tipo_documento"
                           className="form-control"
                           onChange={handleChange}
                           //onChange={(e) => setTipoDocumento(e.target.value || null)}
                        >
                           <option value="">Selecione o tipo</option>
                           {bancoUpload === "agibank" &&
                              ARQUIVOS_PROPOSTA_AGIBANK.map((x) => (
                                 <option value={x.id}>{x.nome}</option>
                              ))}

                           {bancoUpload === "cetelem" &&
                              ARQUIVOS_PROPOSTA_CETELEM.map((x) => (
                                 <option value={x.id}>{x.nome}</option>
                              ))}
                        </select>
                     </div>
                     {inputErrors.tipo_documento ? (
                        <FormErrors message={inputErrors.tipo_documento} />
                     ) : null}
                  </div>

                  <label htmlFor="arquivo_proposta">Arquivo</label>
                  <div className="input-group mb-3">
                     <input
                        type="file"
                        className={
                           inputErrors.arquivo_proposta
                              ? "form-control border-error"
                              : "form-control"
                        }
                        accept="image/x-png,application/pdf,image/jpeg"
                        id="arquivo_proposta"
                        name="arquivo_proposta"
                        data-unique="true"
                        onChange={handleChangeFile}
                     />
                     <div className="input-group-append">
                        <button
                           className="btn btn-outline-primary"
                           type="button"
                           id="button-addon2"
                        >
                           <i className="fas fa-file-upload fa-lg"></i>
                        </button>
                     </div>
                     {inputErrors.arquivo_proposta ? (
                        <FormErrors message={inputErrors.arquivo_proposta} />
                     ) : null}
                  </div>

                  {inputDocumentos && (
                     <h3 className="mb-2">Documentos Enviados</h3>
                  )}

                  <div className="row mt-3">
                     {inputDocumentos &&
                        inputDocumentos.map((x) => (
                           <div className="col-md-3 form-grop" key={x.id}>
                              <a
                                 href={x.valor}
                                 className="btn btn-default btn-block"
                                 target="_blank"
                              >
                                 {x.nome}
                              </a>
                           </div>
                        ))}
                  </div>

                  <div className="row mt-3">
                     <div className="col-md-12 text-center">
                        <button
                           type="button"
                           className="btn btn-danger"
                           data-dismiss="modal"
                           onClick={LimparCamposUpload}
                        >
                           Cancelar
                        </button>
                        <button
                           type="submit"
                           className="btn btn-success"
                           disabled={disableSubmitUpload}
                        >
                           <i className="far fa-save fa-lg iconDownload"></i>
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         <Modal
            id={"modal-image"}
            size=""
            title={
               inputCliente
                  ? `${
                       inputCliente.blocos.find((x) => x.nome === "Nome").valor
                    } • ${filename}`
                  : null
            }
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <img
                     alt=""
                     style={{ width: "100%", height: "70vh" }}
                     src={file}
                     scale="tofit"
                  />
               </div>
            }
         />

         {/* Modal Editar Saque */}
         <Modal
            id={"modal-editar-saque"}
            title="Editar Saque - Fgts"
            size="sm"
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onResetEditFGTS}
                     >
                        x
                     </span>
                  </button>

                  <div className="row">
                     <div className="form-group col-md-12">
                        <label>Saque*</label>
                        <Input
                           id="saque"
                           className="form-control"
                           placeholder="saque..."
                           type="money"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={register.saque || null}
                           help={errors.saque || ""}
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block my-4"
                           data-dismiss="modal"
                           arial-label="close"
                        >
                           Cancelar
                        </button>
                     </div>

                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           onClick={onSaveSaque}
                           disabled={disabledBtnSubmit}
                           className="btn btn-success btn-block my-4"
                        >
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         <ToastContainer />
      </div>
   );
}

export default Proposal;
