import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../components/footer/Footer";
import { gravarDadosTermos } from "../../services/Agents";
import { buscarDocumentosAdm } from "../../services/Api";
import Input from "../../components/input/Input";
import styles from "./style.module.css";
// import { useHistory } from "react-router-dom";

//Redux Actions
import { Store } from "../../redux/Store";
import { UPDATE_USER } from "../../redux/Actions";

function TermsValidation() {
   const [nome, setNome] = useState(null);
   const [rg, setRg] = useState(null);
   const [cpf, setCpf] = useState(null);
   const [check, setCheck] = useState([]);
   const [termos, setTermos] = useState(null);
   //  const history = useHistory();

   useEffect(() => {
      const getData = async () => {
         try {
            const response = await buscarDocumentosAdm();
            setTermos(response && response.length > 0 ? response : null);
         } catch (err) {
            toast.error("Falha ao carregar dados!");
         }
      };

      getData();
   }, []);

   const handleNext = () => {
      const user = Store.getState().user;

      Store.dispatch({
         type: UPDATE_USER,
         payload: {
            ...user,
            status_id: 2,
            first_access: true,
         },
      });

      // history.push("/");
      toast.success("Os aceites foram salvos com sucesso.");
   };

   const handleCheck = (e) => {
      let checked = e.target;
      let pos = null;
      let aux = check;

      pos = check.indexOf(parseInt(checked.id));

      if (pos === 0) {
         aux.shift();
      } else if (pos && pos > 0) {
         aux.splice(pos, 1);
      } else if (pos && pos === -1) {
         aux.push(parseInt(checked.id));
      }

      setCheck(aux);
   };

   const handleSave = async () => {
      if (
         check &&
         termos &&
         check.length === termos.length &&
         nome &&
         cpf &&
         rg
      ) {
         const termos = check;

         try {
            await gravarDadosTermos({ nome, cpf, rg, termos });
            handleNext();
         } catch (error) {
            toast.error("Ocorreu um erro ao salvar os aceites dos termos.");
         }
      } else {
         toast.info(
            "Favor preencher todos os campos e selecione os termos antes de prosseguir."
         );
         return;
      }
   };

   return (
      <div>
         {/* Main content */}
         <div className="main-content" id="panel">
            <div className="card">
               <div className="card-header mt--4 py-4 bg-default">
                  <h3 className="mb-0 text-xl text-white">
                     Termos e Validações
                  </h3>
               </div>
               {/* Card body */}
               <div className="card-body">
                  <form>
                     <div
                        className={`${styles.formCentral} form-col ml-4 mt-4`}
                     >
                        <div className="col-md-6 mb-3 ">
                           <div className="form-group">
                              <label
                                 className="form-control-label"
                                 htmlFor="validationServer01"
                              >
                                 Nome Completo
                              </label>
                              <Input
                                 type="text"
                                 className="form-control"
                                 id="validationServer01"
                                 placeholder="Nome Completo.."
                                 value={nome}
                                 onChange={(e) =>
                                    setNome(e.target.value || null)
                                 }
                                 required
                              />
                           </div>
                        </div>
                        <div className="col-md-6 mb-3">
                           <div className="form-group">
                              <label
                                 className="form-control-label"
                                 htmlFor="validationServerUsername"
                              >
                                 RG
                              </label>
                              <Input
                                 type="rg"
                                 className="form-control"
                                 id="rg"
                                 value={rg}
                                 onChange={(e) => setRg(e.target.value || null)}
                                 placeholder="00.000.000-0"
                                 aria-describedby="inputGroupPrepend3"
                                 required
                              />
                           </div>
                        </div>
                        <div className="col-md-6 mb-3">
                           <div className="form-group ">
                              <label
                                 className="form-control-label"
                                 htmlFor="validationServer02"
                              >
                                 CPF
                              </label>
                              <Input
                                 type="cpf"
                                 className="form-control"
                                 id="cpf"
                                 value={cpf}
                                 onChange={(e) =>
                                    setCpf(e.target.value || null)
                                 }
                                 placeholder="000.000.000-00"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row ">
                        <div className="col--10  mx-6 text-center">
                           <h3 className="text-xl text-center mt-4">
                              Declaração
                           </h3>
                           <p className="my-6  text-justify">
                              {nome}, portador da cédula de identidade R.G. n.º{" "}
                              {rg}, inscrito no C.P.F. (M.F.) sob o n.º {cpf},
                              DECLARO, para todos os fins de direito e a quem
                              possa interessar, haver conhecido os precisos
                              termos do REGULAMENTO INTERNO, do MANUAL DE
                              CONDUTA E ÉTICA, do COMUNICADO INTERNO -
                              Recebimento de valores e do COMUNICADO INTERNO -
                              Prática de atos criminosos e irregulares da
                              empregadora SHM PROCESSAMENTO DE DADOS LTDA.,
                              pessoa jurídica de direito privado, inscrita no
                              C.N.P.J. (M.F.) sob o n.º 29.826.853/0001-69, com
                              sede administrativa localizada à rua Jacob Blumer
                              n.º 188, Residencial, nesta cidade e comarca de
                              Presidente Prudente/SP, CEP 19015-160, anuindo
                              expressamente com os seus teores, obrigando-me a
                              cumpri-los fielmente, estando ciente de que
                              poderão ser livremente acessados no site
                              www.seucreditonegocios.com.br.
                           </p>
                           <b className="mt-4 mx-4 text-center">
                              Pela veracidade, firmo a presente para que surta
                              os efeitos legais.
                           </b>
                        </div>
                     </div>
                     <hr />
                     <div className="mx-4">
                        {termos
                           ? termos.map((p) => {
                                return (
                                   <div
                                      className="custom-control custom-checkbox"
                                      style={{ marginBottom: "8px" }}
                                   >
                                      <input
                                         type="checkbox"
                                         className="custom-control-input"
                                         id={p.documento_id}
                                         onChange={handleCheck}
                                      />
                                      <label
                                         className="custom-control-label"
                                         for={p.documento_id}
                                      >
                                         Declaro que baixei o arquivo e estou de
                                         acordo com o
                                         <a
                                            href={`${p.arquivo}`}
                                            target="_blank"
                                            className={`item ${styles.linkDocument}`}
                                         >
                                            {" "}
                                            {p.nome}.
                                         </a>
                                      </label>
                                   </div>
                                );
                             })
                           : "Carregando..."}
                     </div>
                     <div className="text-center">
                        <button
                           className="btn btn-primary mt-6 px-6"
                           onClick={handleSave}
                           type="button"
                        >
                           Enviar
                        </button>
                     </div>
                  </form>
               </div>
            </div>

            {/* Footer */}
            <Footer />
         </div>

         <ToastContainer />
      </div>
   );
}
export default TermsValidation;
