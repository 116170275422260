module.exports = {
    somenteNumeros: function(str) {
        return str.replace(/[^0-9]+/g, '');
    },
    mascaraTelefone: function(str){
        str = str.replace(/\D/g, "");             //Remostre tudo o que não é dígito
        str = str.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        str = str.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return str;
    },
    mascaraCpfCnpj: function(str){
        //Remove tudo o que não é dígito
        str = str.replace(/\D/g, "")

        if (str.length <= 11) { //CPF

            //Coloca um ponto entre o terceiro e o quarto dígitos
            str = str.replace(/(\d{3})(\d)/, "$1.$2")

            //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            str = str.replace(/(\d{3})(\d)/, "$1.$2")

            //Coloca um hífen entre o terceiro e o quarto dígitos
            str = str.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        } else { //CNPJ

            //Coloca ponto entre o segundo e o terceiro dígitos
            str = str.replace(/^(\d{2})(\d)/, "$1.$2");

            //Coloca ponto entre o quinto e o sexto dígitos
            str = str.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

            //Coloca uma barra entre o oitastro e o nono dígitos
            str = str.replace(/\.(\d{3})(\d)/, ".$1/$2");

            //Coloca um hífen depois do bloco de quatro dígitos
            str = str.replace(/(\d{4})(\d)/, "$1-$2");

        }
        return str;
    },
    limitarTexto: function(texto, length){
        return texto.substr(0, length) + '...';
    },
    formatarMoeda: function(valor) {
        valor = valor.toFixed(2) + '';
        valor = parseInt(valor.replace(/[\D]+/g,''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ",$1");
        
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }
        
        return valor;
    },
    formataMoeda: function(valor) {
        valor = valor.toString();
        if(valor.indexOf(".") < 0) {
            valor = valor + ".00";
        }
        let v = valor.replace(/\D/g, '');
        v = (v / 100).toFixed(2) + '';
        v = v.replace('.', ',');
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
        v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
        valor = v;
        
        return valor;
    },
    formatarMoedaMilhar: function (v){
        let negativo = '';
        if(v){
            negativo = v.indexOf('-') >= 0 ? '-' : '';
            v = v.replace(/\D/g, '');
            v = (v / 100).toFixed(2) + '';
            v = v.replace('.', ',');
            v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
            v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
        }
        
        return negativo === '-' ? negativo + v : v;
    },
    formatarMoedaBanco: function(valor) {
        valor = valor.replace('.','');
        valor = valor.replace(',','.');
        
        return valor;
    },
    formatarCEP: function(str){
        var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    
        if(re.test(str)){
            return str.replace(re,"$1$2-$3");
        }
        
        return str;
    },
    filtroOpcoesData: function(str){
        const moment = require('moment');

        let retorno = [];

        switch(str){
            case 'hoje': 
            retorno = [moment(), moment()];
            break;
            case 'ontem': 
            retorno = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
            break;
            case '7dias': 
            retorno = [moment().subtract(6, 'days'), moment()];
            break;
            case 'mes': 
            retorno = [moment().subtract(29, 'days'), moment()];
            break;
            case 'ultimo-mes': 
            retorno = [moment().startOf('month'), moment().endOf('month')];
            break;
            case 'proximo-mes': 
            retorno = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
            break;
            case 'ano': 
            retorno = [moment().startOf('year'), moment().endOf('year')];
            break;
            default :
            retorno = [moment(), moment()];
            break;
        }
        
        return retorno;
    },
    validarCpf: function(cpf) {
        let Soma;
        let Resto;
        Soma = 0;
        cpf = this.somenteNumeros(cpf);
      if (cpf === "00000000000") return false;
    
      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(cpf.substring(9, 10)) ) return false;
    
      Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(cpf.substring(10, 11) ) ) return false;
        return true;
    },
    camelCase: function(valor){
        var loweredText = valor.toLowerCase();
        var words = loweredText.split(" ");
        for (var a = 0; a < words.length; a++) {
            var w = words[a];

            if(w){
                var firstLetter = w[0];
                w = firstLetter.toUpperCase() + w.slice(1);
                words[a] = w;
            }
        }
        return words.join(" ");
    },
    somarComissoesAgente: function (comissoes){
        let somaComissao = 0;
        let somaProducao = 0;
        
        if(comissoes.consignado && comissoes.consignado.produto){
            somaProducao += parseFloat(this.formatarMoedaBanco(comissoes.consignado.produto.valor_emprestimo));
            somaComissao += parseFloat(this.formatarMoedaBanco(comissoes.consignado.produto.comissao));
        }

        if(comissoes.creditoPessoal && comissoes.creditoPessoal.produto){
            somaProducao += parseFloat(this.formatarMoedaBanco(comissoes.creditoPessoal.produto.valor_emprestimo));
            somaComissao += parseFloat(this.formatarMoedaBanco(comissoes.creditoPessoal.produto.comissao));
        }

        if(comissoes.cartao && comissoes.cartao.produto){
            somaProducao += parseFloat(this.formatarMoedaBanco(comissoes.cartao.produto.valor_emprestimo));
            somaComissao += parseFloat(this.formatarMoedaBanco(comissoes.cartao.produto.comissao));
        }

        if(comissoes.fgts && comissoes.fgts.produto){
            somaProducao += parseFloat(this.formatarMoedaBanco(comissoes.fgts.produto.valor_emprestimo));
            somaComissao += parseFloat(this.formatarMoedaBanco(comissoes.fgts.produto.comissao));
        }

        if(comissoes.conta_luz && comissoes.conta_luz.produto){
            somaProducao += parseFloat(this.formatarMoedaBanco(comissoes.conta_luz.produto.valor_emprestimo));
            somaComissao += parseFloat(this.formatarMoedaBanco(comissoes.conta_luz.produto.comissao));
        }
        
        return { producao: this.formataMoeda(somaProducao.toFixed(2)), comissao: this.formataMoeda(somaComissao.toFixed(2)) }
    }, validaSenha: function (senha){
        let retorno = { retorno: false, erro: "Senha inválida." };
        let letrasMaiusculas = /[A-Z]/;
        let letrasMinusculas = /[a-z]/; 
        let numeros = /[0-9]/;
        let caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
        let caracteresRepetidos = /^(.)\1{5}/;
        if(senha.length > 12){
            return { retorno: false, erro: "Senha muito grande, diminua a quantidade de caracteres max: 12." };
        }
        if(senha.length < 8){
            return { retorno: false, erro: "Senha muito curta, aumente a quantidade de caracteres min: 9." };
        }
        let auxMaiuscula = 0;
        let auxMinuscula = 0;
        let auxNumero = 0;
        let auxEspecial = 0;
        let auxRepetidos = caracteresRepetidos.test(senha) ? 1 : 0;
        
        for(let i = 0; i < senha.length; i++){
            if(letrasMaiusculas.test(senha[i])) auxMaiuscula++;
            else if(letrasMinusculas.test(senha[i])) auxMinuscula++;
            else if(numeros.test(senha[i])) auxNumero++;
            else if(caracteresEspeciais.test(senha[i]))auxEspecial++;
        }
        if (auxMaiuscula > 0){
            if (auxMinuscula > 0){
                if (auxNumero > 0){
                    if (auxEspecial) {
                        if(auxRepetidos > 0){
                            retorno = { retorno: false, erro: "Muitos caracteres repetidos." };
                        }else{
                            retorno = { retorno: true, erro: "" };
                        }
                    }else{
                        retorno = { retorno: false, erro: `A senha precisa ter pelo menos 1 caracter especial: ()[]{}|\`~!@#$%^&*_-+=;:'",<>./?` };
                    }
                }else{
                    retorno = { retorno: false, erro: "A senha precisa ter pelo menos 1 número, 0-9." };
                }
            }else{
                retorno = { retorno: false, erro: "A senha precisa ter pelo menos 1 letra minúscula, a-z." };
            }
        }else{
            retorno = { retorno: false, erro: "A senha precisa ter pelo menos 1 letra maiúscula, A-Z." };
        }

        return retorno;
    }, 
    retornarStatusSolicitacao: function(codigo_sts){
        let classe = null;
        if(codigo_sts){
            switch(parseInt(codigo_sts)){
                case 1:
                case 6:
                     classe = 'warning'; break;
                case 2: classe = 'success'; break;
                case 3: classe = 'danger'; break;
                case 4: classe = 'info'; break;
                case 5: classe = 'danger'; break;
                default: classe = 'danger'; break;
            }
        }

        return classe;
    },
    retornarStatusMailing: function(codigo_stm){
        let classe = null;
        if(codigo_stm){
            switch(parseInt(codigo_stm)){
                case 3: classe = 'warning'; break;
                case 5: classe = 'success'; break;
                case 4: classe = 'danger'; break;
                case 2: classe = 'info'; break;
                case 1: classe = 'danger'; break;
                default: classe = 'danger'; break;
            }
        }

        return classe;
    }

};