import React from "react";
import { useState, useEffect } from "react";
import {  getReportProposals } from "../../../services/Api";
//charts
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';


function AnualPropCharts() {
    const [propostas, setPropostas] = useState([]);
    const options = {
        title: {
          text: 'Relatório Anual de Propostas '
            
        },
  
      subtitle: {
        text: '<a href="http://seucreditonegocios.com.br" target="_blank">seucreditonegocios.com.br</a>',
        style: {
          marginBottom: '4rem'
        }
      },
  
      yAxis: {
          title: {
              text: 'Número de Propostas'
          }
      },
      xAxis: {
        categories: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ], crosshair: true},
      chart:{
        style: {
            fontFamily: 'arial',
        },
          type:'column',
          spacingRight: 20,
          spacingLeft: 30
      },
      plotOptions: {
        column: {
          pointWidth: 8,
          borderRadius: 5
        },
      },
      credits: {
        enabled: false
    },
      legend:false,
      series: propostas,
  
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      layout: 'horizontal',
                      align: 'right',
                      verticalAlign: 'bottom'
                  }
              }
          }]
      }
      }
    const getSimulation = async () => {
        try{
            setPropostas(await getReportProposals());
        }catch(err){}
    } 
    useEffect(() => {
        
        getSimulation();
        
    }, [])

  return(
    <HighchartsReact   highcharts={Highcharts} options={options} />
  )

}
export default AnualPropCharts;