import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// resources
import registerDefault from "../../resources/data/register/configuration";

//Compomentes utilizados
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Menu from "../../components/sidenav/Menu";
import Table from "../../components/table/Table";
import TopMenu from "../../components/topMenu/TopMenu";
import Modal from "../../components/modal/Modal";
import FormErrors from "../../components/formErrors/FormErrors";
import Loading from "../../components/loading/Loading";
import schema from "./Validation";
//Apis utilizadas
import {
   getBanks,
   getDetailsBank,
   getContractsType,
   saveConfigsBank,
   getRulesBank,
   inactivateRule,
} from "../../services/Configurations";
import { MDBDataTableV5 } from "mdbreact";

function Configuration() {
   //Dados das tabelas/selects:
   const [data, setData] = useState(null);
   const [datatable, setDataTable] = useState({});
   const [dataRegras, setDataRegras] = useState(null);
   const [page, setPage] = useState(1);
   const [pageDetalhes, setPageDetalhes] = useState(1);

   //Dados do filtro:
   const [name, setName] = useState(null);

   //States
   const [banco, setBanco] = useState(null);
   const [nomeBanco, setNomeBanco] = useState(null);
   const [tiposContrato, setTiposContrato] = useState(null);
   const [bancoDetalhesAgent, setbancoDetalhesAgent] = useState(null);
   const [bancoDetalhesClient, setbancoDetalhesClient] = useState(null);
   const [dadosForm, setDadosForm] = useState(registerDefault);
   const [formLoading, setFormLoading] = useState(true);

   useEffect(() => {
      getData(1, null, null, null);
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "Id",
               field: "banco_id",
            },
            {
               label: "Banco",
               field: "nome",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            data &&
            data.dados.map((x) => {
               return {
                  banco_id: x.banco_id,
                  nome: x.nome,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              onClick={() => onViewClick(x.banco_id, x.nome)}
                              className="dropdown-item"
                           >
                              <i className="fas fa-eye"></i> Visualizar
                           </button>
                           <button
                              type="button"
                              onClick={() => onEditClick(x.banco_id, x.nome)}
                              className="dropdown-item"
                           >
                              <i className="fas fa-pencil-alt"></i> Editar
                           </button>
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [data]);

   const getData = async (pg = 1, name) => {
      try {
         setData(null);
         setPage(pg);
         setName(name);
         setData(await getBanks(page));
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async (pg) => getData(pg, name);
   // const onModalChanged = async (pg) => onViewClick(banco, nomeBanco, pg);

   const onEditClick = async (id, nome) => {
      setFormLoading(true);
      setBanco(id);
      setNomeBanco(nome);
      setDadosForm({ ...dadosForm, banco_id: id });
      window.$("#modal-data").modal("show");
      try {
         const data = await getContractsType();
         setTiposContrato(data);
         setFormLoading(false);
      } catch (err) {
         toast.error("Falha ao carregar dados do agente!");
         window.$("#modal-data").modal("hide");
         setBanco(null);
         setFormLoading(true);
      }
   };

   const onViewClick = async (id, nome, pg = 1) => {
      setFormLoading(true);
      setNomeBanco(nome);
      setBanco(id);
      setPageDetalhes(pg);
      try {
         const data = await getRulesBank(id, pageDetalhes);
         //Seta campos:
         setDataRegras(data);
         setFormLoading(false);
         window.$("#modal-detalhes").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do agente!");
         window.$("#modal-detalhes").modal("hide");
         setBanco(null);
         setFormLoading(true);
      }
   };

   const onDeleteRule = async (id) => {
      setFormLoading(true);
      try {
         const response = await inactivateRule(id);

         if (response) {
            const data = await getRulesBank(banco, pageDetalhes);
            //Seta campos:
            setDataRegras(data);
            toast.success("Regra inativada com sucesso.");
         }
      } catch (err) {
         toast.error("Falha ao inativar a regra, tente novamente.");
         console.error(err);
      } finally {
         setFormLoading(false);
      }
   };

   const onReject = async () => {
      window.$("#modal-data").modal("hide");
      window.$("#modal-detalhes").modal("hide");
      formik.resetForm({});
   };

   const formik = useFormik({
      initialValues: registerDefault,
      validationSchema: schema,
      onSubmit: async (values) => {
         values.banco_id = banco;

         try {
            await saveConfigsBank(values);

            toast.success("Regras salvas com sucesso!");
            window.$("#modal-data").modal("hide");
            formik.resetForm({});
         } catch (err) {
            toast.error(
               "Ocorreu um erro ao salvar as regras, tente novamente!"
            );
         } finally {
            setFormLoading(false);
         }
      },
   });

   useMemo(async () => {
      let tipoContratoAgente = formik.values.tipoContratoAgent;
      let tipoContratoCliente = formik.values.tipoContratoClient;

      if (tipoContratoAgente) {
         const data = await getDetailsBank(
            dadosForm.banco_id,
            tipoContratoAgente
         );
         setbancoDetalhesAgent(data);
      }

      if (tipoContratoCliente) {
         const data = await getDetailsBank(
            dadosForm.banco_id,
            tipoContratoCliente
         );
         setbancoDetalhesClient(data);
      }
   }, [
      formik.values.tipoContratoAgent,
      formik.values.tipoContratoClient,
      dadosForm,
   ]); //dadosForm

   return (
      <div>
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               /*buttons={[
            {
              name: "Filtrar",
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}*/
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>
         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Editar - " + nomeBanco}
            size={"lg"}
            onSubmit={formik.handleSubmit}
            form={
               formLoading ? (
                  <div>
                     <Loading />
                  </div>
               ) : (
                  <div>
                     <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        arial-label="close"
                     >
                        <span arial-hidden="true" onClick={onReject}>
                           x
                        </span>
                     </button>
                     <div className="card mb-4">
                        <div className="card-header">
                           <h3 className="mb-0">Configurações</h3>
                        </div>
                        <div className="card-body">
                           <h3 className="mb-2">Agente</h3>
                           <div className="row">
                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label htmlFor="tipoContratoAgent">
                                       Tipo Contrato*
                                    </label>
                                    <select
                                       className={
                                          formik.touched.tipoContratoAgent &&
                                          formik.errors.tipoContratoAgent
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="tipoContratoAgent"
                                       name="tipoContratoAgent"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.tipoContratoAgent}
                                    >
                                       <option value="" selected>
                                          Selecione o contrato
                                       </option>
                                       {tiposContrato &&
                                          tiposContrato.map((tipo, index) => (
                                             <option
                                                key={index}
                                                value={tipo.id}
                                             >
                                                {" "}
                                                {tipo.nome}{" "}
                                             </option>
                                          ))}
                                    </select>
                                    {formik.touched.tipoContratoAgent &&
                                    formik.errors.tipoContratoAgent ? (
                                       <FormErrors
                                          message={
                                             formik.errors.tipoContratoAgent
                                          }
                                       />
                                    ) : null}
                                 </div>
                              </div>
                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label htmlFor="taxaAgent">Taxa*</label>
                                    <select
                                       type="text"
                                       className={
                                          formik.touched.taxaAgent &&
                                          formik.errors.taxaAgent
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="taxaAgent"
                                       name="taxaAgent"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.taxaAgent}
                                    >
                                       <option value="" selected>
                                          Selecione a taxa
                                       </option>
                                       {bancoDetalhesAgent &&
                                          bancoDetalhesAgent.taxas.map(
                                             (item, index) => (
                                                <option
                                                   key={index}
                                                   value={item.taxas}
                                                >
                                                   {" "}
                                                   {item.taxas}{" "}
                                                </option>
                                             )
                                          )}
                                    </select>
                                    {formik.touched.taxaAgent &&
                                    formik.errors.taxaAgent ? (
                                       <FormErrors
                                          message={formik.errors.taxaAgent}
                                       />
                                    ) : null}
                                 </div>
                              </div>

                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label htmlFor="parcelaAgent">Parcela*</label>
                                    <select
                                       type="text"
                                       className={
                                          formik.touched.parcelaAgent &&
                                          formik.errors.parcelaAgent
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="parcelaAgent"
                                       name="parcelaAgent"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.parcelaAgent}
                                    >
                                       <option value="" selected>
                                          Selecione a parcela
                                       </option>
                                       {bancoDetalhesAgent &&
                                          bancoDetalhesAgent.parcelas.map(
                                             (item, index) => (
                                                <option
                                                   key={index}
                                                   value={item.parcelas}
                                                   selected={
                                                      index === 0 ? true : false
                                                   }
                                                >
                                                   {" "}
                                                   {item.parcelas}{" "}
                                                </option>
                                             )
                                          )}
                                    </select>
                                    {formik.touched.parcelaAgent &&
                                    formik.errors.parcelaAgent ? (
                                       <FormErrors
                                          message={formik.errors.parcelaAgent}
                                       />
                                    ) : null}
                                 </div>
                              </div>
                           </div>

                           <h3 className="mb-2">Cliente</h3>
                           <div className="row">
                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label htmlFor="tipoContratoClient">
                                       Tipo Contrato*
                                    </label>
                                    <select
                                       type="text"
                                       className={
                                          formik.touched.tipoContratoClient &&
                                          formik.errors.tipoContratoClient
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="tipoContratoClient"
                                       name="tipoContratoClient"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.tipoContratoClient}
                                    >
                                       <option value="" selected>
                                          Selecione o contrato
                                       </option>
                                       {tiposContrato &&
                                          tiposContrato.map((tipo, index) => (
                                             <option
                                                key={index}
                                                value={tipo.id}
                                             >
                                                {" "}
                                                {tipo.nome}{" "}
                                             </option>
                                          ))}
                                    </select>
                                    {formik.touched.tipoContratoClient &&
                                    formik.errors.tipoContratoClient ? (
                                       <FormErrors
                                          message={
                                             formik.errors.tipoContratoClient
                                          }
                                       />
                                    ) : null}
                                 </div>
                              </div>

                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label htmlFor="taxaClient">Taxa*</label>
                                    <select
                                       type="text"
                                       className={
                                          formik.touched.taxaClient &&
                                          formik.errors.taxaClient
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="taxaClient"
                                       name="taxaClient"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.taxaClient}
                                    >
                                       <option value="">
                                          Selecione a taxa
                                       </option>
                                       {bancoDetalhesClient &&
                                          bancoDetalhesClient.taxas.map(
                                             (item, index) => (
                                                <option
                                                   key={index}
                                                   value={item.taxas}
                                                >
                                                   {" "}
                                                   {item.taxas}{" "}
                                                </option>
                                             )
                                          )}
                                    </select>
                                    {formik.touched.taxaClient &&
                                    formik.errors.taxaClient ? (
                                       <FormErrors
                                          message={formik.errors.taxaClient}
                                       />
                                    ) : null}
                                 </div>
                              </div>

                              <div className="col-md-4">
                                 <div className="form-group">
                                    <label name="parcelaClient">Parcela*</label>
                                    <select
                                       type="text"
                                       className={
                                          formik.touched.parcelaClient &&
                                          formik.errors.parcelaClient
                                             ? "form-control border-error"
                                             : "form-control"
                                       }
                                       id="parcelaClient"
                                       name="parcelaClient"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.parcelaClient}
                                    >
                                       <option value="">
                                          Selecione a parcela
                                       </option>
                                       {bancoDetalhesClient &&
                                          bancoDetalhesClient.parcelas.map(
                                             (item, index) => (
                                                <option
                                                   key={index}
                                                   value={item.parcelas}
                                                >
                                                   {" "}
                                                   {item.parcelas}{" "}
                                                </option>
                                             )
                                          )}
                                    </select>
                                    {formik.touched.parcelaClient &&
                                    formik.errors.parcelaClient ? (
                                       <FormErrors
                                          message={formik.errors.parcelaClient}
                                       />
                                    ) : null}
                                 </div>
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-md-6 text-center">
                                 <button
                                    type="button"
                                    onClick={onReject}
                                    className="btn btn-danger btn-block"
                                 >
                                    Cancelar
                                 </button>
                              </div>
                              <div className="col-md-6 text-center">
                                 <button
                                    type="submit"
                                    className="btn btn-success btn-block"
                                 >
                                    Salvar
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            }
         />

         <Modal
            id={"modal-detalhes"}
            title={"Configurações - " + nomeBanco}
            size={"lg"}
            form={
               formLoading ? (
                  <div>
                     <Loading />
                  </div>
               ) : (
                  <div>
                     <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        arial-label="close"
                     >
                        <span arial-hidden="true" className="text-white">
                           x
                        </span>
                     </button>
                     <div className="row">
                        <div className="col-md-12">
                           <Table
                              name="Regras"
                              // onPageChanged={onModalChanged}
                              columns={[
                                 {
                                    title: "Banco",
                                    id: "banco",
                                 },
                                 {
                                    title: "Contrato",
                                    id: "tipo_contrato",
                                 },
                                 {
                                    title: "Pessoa",
                                    id: "tipo_pessoa",
                                 },
                                 {
                                    title: "Taxa",
                                    id: "taxa",
                                 },
                                 {
                                    title: "Parcela",
                                    id: "parcela",
                                 },
                                 {
                                    title: "Ação",
                                    id: "action",
                                    data: (item, index) => (
                                       <td key={index} className="text-left">
                                          <div className="dropdown">
                                             <a
                                                className="btn btn-sm btn-icon-only text-light"
                                                href="#"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                             >
                                                <i className="fas fa-ellipsis-v" />
                                             </a>
                                             <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <button
                                                   type="button"
                                                   onClick={() =>
                                                      onDeleteRule(
                                                         item.regrasimulador_id
                                                      )
                                                   }
                                                   className="dropdown-item"
                                                >
                                                   <i className="fas fa-trash"></i>{" "}
                                                   Excluir
                                                </button>
                                             </div>
                                          </div>
                                       </td>
                                    ),
                                 },
                              ]}
                              data={dataRegras}
                              page={pageDetalhes}
                           />
                        </div>
                     </div>

                     <div className="row">
                        <div
                           className="col-md-6 text-center"
                           style={{ margin: "0 auto" }}
                        >
                           <button
                              type="button"
                              onClick={onReject}
                              className="btn btn-danger btn-block"
                           >
                              Fechar
                           </button>
                        </div>
                     </div>
                  </div>
               )
            }
         />
         <ToastContainer />
      </div>
   );
}

export default Configuration;
