import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"

import Login from "./screens/Login"
import Home from "./screens/Home"
import FirstAccess from "./screens/FirstAccess"
import RecoveryAccess from "./screens/RecoveryAccess"
import Agents from "./screens/Agents/Agents"
import Price from "./screens/Price"
import Usuarios from "./screens/Usuarios"
import Commission from "./screens/Commission"
import CommissionAgent from "./screens/CommissionAgent"
import Crm from "./screens/Crm"
import CrmSolicitacao from "./screens/Crm/Solicitacao"
import CrmMailing from "./screens/Crm/Mailing"
import Proposal from "./screens/Proposal"
import Simulations from "./screens/Simulations"
import Clients from "./screens/Clients"
import Chats from "./screens/Chats"
import Configurations from "./screens/Configurations/Configurations"
import Permissoes from "./screens/Configurations/Permissoes/Permissoes"
import AgentsIntentions from "./screens/Configurations/AgentsIntentions/AgentsIntentions"
import RegrasComissao from "./screens/Configurations/RegrasComissao/RegrasComissao"
import Fgts from "./screens/Fgts"
import AgentFunction from "./screens/Configurations/Funcoes/AgentFunction"
import RemessaCpf from "./screens/Configurations/RemessaCpf/RemessaCpf"
import Profile from "./screens/Profile"
import ValidationBanks from './screens/ValidationBanks';
import Reports from './screens/Relatorios/Reports';
import Contas from './screens/Configurations/Contas/Contas';
import TermsValidation from './screens/TermsValidation/TermsValidation';
import Treinamentos from './screens/Treinamentos';
import Consorcio from './screens/Consorcio';
import Parceiros from './screens/Parceiros';
import Reanalise from './screens/Reanalise';
import ExclusaoDados from './screens/ExclusaoDados';
import ReanaliseGeral from "./screens/Configurations/ReanaliseGeral/ReanaliseGeral";

const components = {
   "/": Home,
   "/login": Login,
   "/agentes": Agents,
   "/clientes": Clients,
   "/price": Price,
   "/comissao": Commission,
   "/comissoes_agentes": CommissionAgent,
   "/crm": Crm,
   "/crm_solicitacao": CrmSolicitacao,
   "/crm_mailing": CrmMailing,
   "/propostas": Proposal,
   "/simulacoes": Simulations,
   "/chats": Chats,
   "/configuracoes": Configurations,
   "/usuarios": Usuarios,
   "/permissoes": Permissoes,
   "/fgts": Fgts,
   "/funcoes": AgentFunction,
   "/remessa_cpf": RemessaCpf,
   "/perfil": Profile,
   "/agentes_intencoes": AgentsIntentions,
   "/regras_comissao": RegrasComissao,
   "/validacao_bancos": ValidationBanks,
   "/relatorios": Reports,
   "/contas": Contas,
   "/termos_validacao": TermsValidation,
   "/treinamentos": Treinamentos,
   "/consorcio": Consorcio,
   "/parceiros": Parceiros,
   "/reanalise": Reanalise,
   "/exclusao_dados": ExclusaoDados,
   "/reanalise_geral":ReanaliseGeral
}

class App extends React.PureComponent {
   constructor(props) {
      super(props)
   }

   render() {
      const { user } = this.props;
      
      if (user.key && user.first_access || user.status_id === 2) {
         return <FirstAccess />
      } else if(user.key && user.status_id === 10){
         return <TermsValidation />
      } else if(user.key && user.status_id === 12){
         return <RecoveryAccess />
      } else if (user.key && !user.first_access) {
         return (
            <div>
               <div className="main-content">
                  <Router>
                     {user.permissions.map((x, i) => (
                        <Route
                           key={i}
                           path={x.rota}
                           component={components[x.rota] ? components[x.rota] : x.rota}
                           exact={x.rota === "/"}
                        />
                     ))}
                  </Router>
               </div>
            </div>
         )
      } else {
         return <Login />
      }
   }
}


const mapStateToProps = state => ({
   user: state.user,
})


export default connect(mapStateToProps, {})(App)
