import { Store } from "../redux/Store"
import instance from "./index";

export const getComissions = async (nome, cpf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = "agentes/administrativo/listar-comissoes-individuais"
      const response = await instance.post(
        url,
        {
          nome,
          cpf
        },
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getProducts = async () => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = 'agentes/administrativo/buscar-produtos';
      const response = await instance.get(
        url,
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const recordComissionAgent = async (data) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/salvar-comissao-agente";
      const response = await instance.post(
        url,
        data,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getComissionAgent = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token

      const url = `agentes/administrativo/buscar-comissao-individual?id=${id}`
      const response = await instance.get(
        url,
        { headers: { token } }
      )

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}