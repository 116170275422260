import React from "react";
import Loading from "../loading/Loading";

function Table(props) {
  const { columns, data, page, onPageChanged, name = "Tabela" } = props;
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          {/* Card header */}
          <div className="card-header border-0">
            <h3 className="mb-0">{name}</h3>
          </div>
          {/* Light table */}
          <div className="table-responsive">
            <table className="table align-items-center table-flush table-striped">
              <thead className="thead-light">
                <tr>
                  {columns &&
                    columns.map((x, i) => (
                      <th key={i} scope="col">
                        {x.title}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="list">
                {data ? (
                  data.dados && data.dados.length === 0 ? (
                    <tr key={0}>
                      <td colSpan={columns ? columns.length : 1}>
                        <p style={{ textAlign: "center" }}>
                          Nenhum dado encontrado.
                        </p>
                      </td>
                    </tr>
                  ) : (
                    data.dados.map((x, ix) => {
                      return (
                        <tr key={ix}>
                          {columns.map((y, iy) => {
                            if (y.data) {
                              return y.data(x, ix + "_" + iy);
                            } else {
                              return (
                                <td key={ix + "_" + iy} className="budget">
                                  {x[y.id]}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })
                  )
                ) : (
                  <tr key={0}>
                    <td colSpan={columns ? columns.length : 1}>
                      <Loading />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Card footer */}
          <div className="card-footer py-4">
            <nav aria-label="...">
              {data && page === 1 && (
                <ul className="pagination justify-content-end mb-0">
                  <li className="page-item active">
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => onPageChanged(1)}
                    >
                      1
                    </button>
                  </li>

                  {data && data.total >= 2 && (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => onPageChanged(2)}
                      >
                        2
                      </button>
                    </li>
                  )}

                  {data && data.total >= 3 && (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => onPageChanged(3)}
                      >
                        3
                      </button>
                    </li>
                  )}

                  <li
                    className={`page-item ${
                      data && data.total >= 2 ? "" : "disabled"
                    }`}
                  >
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => onPageChanged(page + 1)}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </button>
                  </li>
                </ul>
              )}

              {data && page !== 1 && page !== data.total && (
                <ul className="pagination justify-content-end mb-0">
                  <li className={`page-item`}>
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => onPageChanged(page - 1)}
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      type="button"
                      onClick={() => onPageChanged(page - 1)}
                      className="page-link"
                      href="#"
                    >
                      {page - 1}
                    </button>
                  </li>
                  <li className="page-item active">
                    <button
                      type="button"
                      onClick={() => onPageChanged(page)}
                      className="page-link"
                      href="#"
                    >
                      {page}
                    </button>
                  </li>
                  {data && page + 1 <= data.total && (
                    <li className="page-item">
                      <button
                        type="button"
                        onClick={() => onPageChanged(page + 1)}
                        className="page-link"
                        href="#"
                      >
                        {page + 1}
                      </button>
                    </li>
                  )}
                  <li className={`page-item`}>
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => onPageChanged(page + 1)}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </button>
                  </li>
                </ul>
              )}

              {data && page !== 1 && page === data.total && (
                <ul className="pagination justify-content-end mb-0">
                  <li className={`page-item`}>
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => onPageChanged(page - 1)}
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </button>
                  </li>
                  {page - 2 > 0 && (
                    <li className="page-item">
                      <button
                        type="button"
                        onClick={() => onPageChanged(page - 2)}
                        className="page-link"
                        href="#"
                      >
                        {page - 2}
                      </button>
                    </li>
                  )}
                  <li className="page-item">
                    <button
                      type="button"
                      onClick={() => onPageChanged(page - 1)}
                      className="page-link"
                      href="#"
                    >
                      {page - 1}
                    </button>
                  </li>
                  <li className="page-item active">
                    <button
                      type="button"
                      onClick={() => onPageChanged(page)}
                      className="page-link"
                      href="#"
                    >
                      {page}
                    </button>
                  </li>
                </ul>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
