import React, { useState, useEffect } from "react";
import { getLeadsConsorcio } from "../services/Clients";
import Input from "../components/input/Input";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";

//plugins
import swal from "sweetalert";
import moment from "moment";
import { MDBDataTableV5 } from "mdbreact";

function Consorcio() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});

  //Dados do filtro:
  const [nome, setNome] = useState(null);
  const [cpf, setCpf] = useState(null);

  //Dados do formulário:
  const [fullLoading, setFullLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Data",
          field: "criado_em",
        },
        {
          label: "Tipo de Bem",
          field: "tipo_bem",
        },
        {
          label: "Cliente",
          field: "nome",
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "ddd",
          field: "ddd",
        },
        {
          label: "Celular",
          field: "celular",
        },
        {
          label: "Cidade",
          field: "cidade",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            criado_em: moment(x.criado_em).format('DD/MM/YYYY HH:mm:SS'), 
            tipo_bem: x.tipo_bem, 
            nome: x.nome,
            cpf: x.cpf,
            ddd: x.ddd,
            celular: x.celular,
            cidade: x.cidade
          };
        }),
    });
  }, [data])

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
    onClear();
  };

  const getData = async () => {
    try {
      setData(await getLeadsConsorcio());
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  const onClear = () => {
    setNome(null);
    setCpf(null);
    window.$("#nome").val(null);
    window.$("#cpf").val(null);
  };

  const handleExport = async () => {
    swal({
      title: "Exportar Leads?",
      text: "Será exportado todos os leads!",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = data.dados;
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV += `Leads Clientes - ${moment().format("MMMM YYYY")}` + "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar os leads", {
              icon: "error",
            });
          }

          let nomeArquivo = `leads-consorcio-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFullLoading(false);
          swal("leads exportados com sucesso.", {
            icon: "success",
          });
        } else {
          setFullLoading(false);
          swal("Ocorreu um erro ao exportar os leads", {
            icon: "error",
          });
        }
      } else {
        swal("Os leads não foram exportados.", {
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {fullLoading && (
          <Loading />
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Exportar CSV",
              icone: (
                <i className="fas fa-file-csv" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: handleExport,
            }
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Consorcio;
