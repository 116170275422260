import React, { useState, useEffect } from "react";
import {
  getClients,
  getClient,
  exportClients,
  getPreSimulation,
  sendImportLeads,
} from "../services/Clients";
import Input from "../components/input/Input";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import DatePicker from "react-datepicker";

//helpers
import funcoes from "../utils/helpers/funcoes";

//plugins
import swal from "sweetalert";
import moment from "moment";
import { MDBDataTableV5 } from "mdbreact";

function Clients() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [page, setPage] = useState(1);

  //Dados do filtro:
  const [nome, setNome] = useState(null);
  const [nomeModal, setNomeModal] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [client, setClient] = useState(null);
  const [preSimular, setPreSimular] = useState(null);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  //Dados do formulário:
  const [fullLoading, setFullLoading] = useState(false);

  useEffect(() => {
    getData(null, null, null, null, null, 1);
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "Data",
          field: "criado_em",
        },
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Cliente",
          field: "nome",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Celular",
          field: "celular",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            criado_em: x.criado_em,
            cpf: x.cpf,
            nome: x.nome,
            email: x.email,
            celular: x.celular,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => details(x.id, x.nome)}
                  >
                    <i className="fas fa-eye"></i>
                    Detalhes
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const onReset = async () => {
    window.$("#modal-data").modal("hide");
    window.$("#modal-detalhes").modal("hide");
    onClear();
  };

  const getData = async (nome, cpf, pg = 1, dataInicio, dataFim) => {
    try {
      setData();
      setNome(nome);
      setCpf(cpf);
      setPage(pg);
      setData(await getClients(nome, cpf, page, dataInicio, dataFim));
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  // const onPageChanged = async (pg) => getData(nome, cpf, pg);

  const onFilter = () => {
    if ((dataInicio && !dataFim) || (!dataInicio && dataFim)) {
      toast.info("Favor preencher as datas corretamente");
    } else {
      window.$("#modal-filter").modal("hide");
      getData(nome, cpf, 1, dataInicio, dataFim);
    }
  };

  const onClear = () => {
    setNome(null);
    setCpf(null);
    setDataInicio(null);
    setDataFim(null);
    setCpf(null);
    window.$("#nome").val(null);
    window.$("#cpf").val(null);
    window.$("#dataInicio").val(null);
    window.$("#dataFim").val(null);
  };

  async function details(id, cliente) {
    setFullLoading(true);
    setNomeModal("Detalhes Cliente - " + cliente);
    try {
      const response = await getClient(id);
      //const result = await getPreSimulation(id);
      setClient(response);
      //setPreSimular(result);
      window.$("#modal-data").modal("show");
    } catch (err) {
      toast.error("Falha ao carregar dados do administrador!");
      window.$("#modal-data").modal("hide");
    } finally {
      setFullLoading(false);
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const sendFile = () => {
    try {
      var file = document.querySelector("#inputArquivo").files[0];
      if (file) {
        setFullLoading(true);
        getBase64(file).then(async (data) => {
          try {
            await sendImportLeads(data);
            toast.success(`Leads foram inseridas!`);
            window.$("#modal-import").modal("hide");
            onClear();
            window.$("#inputArquivo").val(null);
            setFullLoading(false);
            getData(null, null, null, null, null, 1);
          } catch (err) {
            console.warn(err);
            setFullLoading(false);
            toast.error(err.erro);
          }
        });
      } else {
        toast.info("Selecione algum arquivo!");
      }
    } catch (err) {
      console.warn(err);
      setFullLoading(false);
      toast.error("Falha ao enviar arquivo!");
    } finally {
      setFullLoading(false);
    }
  };

  const handleExport = async () => {
    swal({
      title: "Exportar clientes?",
      text: "Será exportado todos os clientes!",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await exportClients(nome, cpf, dataInicio, dataFim);
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          CSV += `Leads Clientes - ${moment().format("MMMM YYYY")}` + "\r\n";
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar os clientes", {
              icon: "error",
            });
          }

          let nomeArquivo = `leads-clientes-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFullLoading(false);
          swal("clientes exportados com sucesso.", {
            icon: "success",
          });
        } else {
          setFullLoading(false);
          swal("Ocorreu um erro ao exportar os clientes", {
            icon: "error",
          });
        }
      } else {
        swal("Os clientes não foram exportados.", {
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {fullLoading && <Loading />}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Importar Leads",
              icone: (
                <i className="fas fa-users" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-import").modal("show"),
            },
            {
              name: "Exportar CSV",
              icone: (
                <i
                  className="fas fa-file-csv"
                  style={{ marginLeft: "5px" }}
                ></i>
              ),
              onClick: handleExport,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>

      {/* IMPORTAR LEADS MODAL */}
      <Modal
        id={"modal-import"}
        title={"Importar Leads Clientes"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="align-items-center text-right mb-4">
              <button
                className="btn btn-sm btn-neutral py-2 border-default bg-default text-white"
                onClick={() =>
                  window.open(
                    "https://storage.googleapis.com/seu-credito/Templates%20de%20Planilhas/modelo_importacao_leads_clientes.xlsx"
                  )
                }
              >
                Modelo importação
                <i
                  className="fas fa-download"
                  style={{ marginLeft: "5px" }}
                ></i>
              </button>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Arquivo XLSX*</label>
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputArquivo"
                    lang="pt-Br"
                  />
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button
                  type="button"
                  onClick={() => sendFile()}
                  className="btn btn-primary btn-block"
                >
                  Adicionar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Cliente</h3>
              </div>
              <div className="card-body">
                <div className="form-group mb-3">
                  <div className="input-group mx-4 ">
                    <Input
                      id="nome"
                      className="form-control"
                      placeholder="Nome..."
                      type="text"
                      value={nome}
                      onChange={(e) => {
                        setNome(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group mx-4">
                    <Input
                      id="cpf"
                      className="form-control"
                      placeholder="cpf..."
                      type="cpf"
                      value={cpf}
                      onChange={(e) => {
                        setCpf(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row mx-5">
                  <div className="form-group mb-2">
                    <div className="input-group">
                      <div className="input-group-prepend ">
                        <span className="input-group-text px-3">
                          <i className="ni ni-calendar-grid-58"></i>
                        </span>
                      </div>
                      <DatePicker
                        id="dataInicio"
                        dateFormat="P"
                        locale="pt"
                        className="form-control "
                        placeholderText="Data inicial..."
                        selected={dataInicio}
                        onChange={(date) => setDataInicio(date)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <span className="input-group-text px-3">
                          <i className="ni ni-calendar-grid-58"></i>
                        </span>
                      </div>
                      <DatePicker
                        id="dataFim"
                        dateFormat="P"
                        locale="pt"
                        className="form-control "
                        placeholderText="Data final..."
                        selected={dataFim}
                        onChange={(date) => setDataFim(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClear}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal Adicionar / Editar */}
      <Modal
        id={"modal-data"}
        title={nomeModal}
        size={"xl"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onClear}
              >
                x
              </span>
            </button>

            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Dados do Cliente</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {client &&
                    client.cliente.blocos.map((x,index) => (
                      <div className="col-md-6 form-group" key={index}>
                          <label>{x.nome}</label>
                          <Input
                            id={x.id}
                            type="text"
                            className="form-control"
                            value={x.valor}
                            disabled={true}
                          />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {client && client.telefones.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-header">
                    <h3 className="mb-0">Telefone(s)</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div>
                        <table className="table align-items-center">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Número</th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            {client.telefones.map((x) => (
                              <tr key={0}>
                                <td className="budget">{x.id}</td>
                                <td className="budget">{x.numero}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {client && client.enderecos.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-header">
                    <h3 className="mb-0">Endereços</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div>
                        <table className="table align-items-center">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">CEP</th>
                              <th scope="col">Logradouro</th>
                              <th scope="col">Número</th>
                              <th scope="col">Bairro</th>
                              <th scope="col">Complemento</th>
                              <th scope="col">Cidade - UF</th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            {client.enderecos.map((x) => (
                              <tr key={0}>
                                <td className="budget">{x.cep}</td>
                                <td className="budget">{x.logradouro}</td>
                                <td className="budget">{x.numero}</td>
                                <td className="budget">{x.bairro}</td>
                                <td className="budget">
                                  {x.complemento
                                    ? funcoes.limitarTexto(x.complemento)
                                    : "N/A"}
                                </td>
                                <td className="budget">
                                  {x.cidade + " - " + x.uf}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {client && client.contasBancarias.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-header">
                    <h3 className="mb-0">Contas Bancárias</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div>
                        <table className="table align-items-center">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Banco</th>
                              <th scope="col">Agência</th>
                              <th scope="col">Conta</th>
                              <th scope="col">Digito</th>
                              <th scope="col">Tipo Conta</th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            {client.contasBancarias.map((x) => (
                              <tr key={0}>
                                <td className="budget">{x.banco}</td>
                                <td className="budget">{x.agencia}</td>
                                <td className="budget">{x.conta}</td>
                                <td className="budget">{x.digito}</td>
                                <td className="budget">{x.tipo_conta}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {client && client.intencoes.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-header">
                    <h3 className="mb-0">Intenção de empréstimo</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div>
                        <table className="table align-items-center">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Nome</th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            {client.intencoes.map((x) => (
                              <tr key={0}>
                                <td className="budget">{x.intencao_id}</td>
                                <td className="budget">{x.intencao}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {preSimular && preSimular.criado_por != null && (
              <div className="card mb-4">
                <div className="card-header">
                  <h3 className="mb-0">Simulações Não finalizadas</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div>
                      <table className="table align-items-center">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Última Atualização</th>
                            <th scope="col">Nome</th>
                            <th scope="col">CPF</th>
                            <th scope="col">Nascimento</th>
                            <th scope="col">Celular</th>
                            <th scope="col">Email</th>
                            <th scope="col">Intenção empréstimo</th>
                            <th scope="col">Step</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {preSimular &&
                            preSimular.criado_por.blocos.map((x) => (
                              <td className="budget" key={x.id}>{x.valor}</td>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-block my-4"
                  data-dismiss="modal"
                  arial-label="close"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default Clients;
