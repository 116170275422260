import React, { useState, useEffect } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import Input from "../components/input/Input";
import TopMenu from "../components/topMenu/TopMenu";
import { Store } from "../redux/Store";
import funcoes from "../utils/helpers/funcoes";

import { getPerfil, recordUser } from "../services/Users";
import { firstAccess } from "../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../components/cropper/style.module.css";
import Loading from "../components/loading/Loading";

function Profile() {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  //senha
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirmation, setnewPasswordConfirmation] = useState(null);

  useEffect(() => {
    getDadosProfile();
  }, []);

  const dadosStore = Store.getState().user;

  function handleChange(event) {
    const { id, value } = event.target;
    switch (id) {
      case "senhaAntiga":
        setPassword(value);
        break;
      case "senha":
        setNewPassword(value);
        break;
      case "confirmarSenha":
        setnewPasswordConfirmation(value);
        break;
      default:
        break;
    }
    setUser({ ...user, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  }

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleChangeImage = async (event) => {
    let img = event.target.files[0];
    const base64 = await fileToBase64(img);
    setUser({
      ...user,
      imagem: { data: base64, name: img.name, type: img.type, isFile: true },
    });
  };

  function handleBlur(event) {
    let message = "Campo Obrigatório";
    const { id, value } = event.target;

    if (!value) {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
    }
  }

  async function handleSave(event) {
    event.preventDefault();
    try {
      setFormLoading(true);
      if (password !== newPassword) {
        if (newPassword === newPasswordConfirmation) {
          const validaSenha = funcoes.validaSenha(newPassword);
          const errorMessage = validaSenha.erro;
          if (!validaSenha.retorno) {
            toast.error(errorMessage);
          } else {
            await firstAccess(password, newPassword);
            // toast.success("Senha redefinida com sucesso.");
          }
        } else {
          toast.error("As senhas não são iguais.");
        }
      } else {
        toast.error("Sua nova senha deve ser diferente da antiga.");
      }
      const response = await recordUser(user);
      if (response) {
        toast.success("Perfil atualizado com sucesso.");
      } else {
        toast.error("Ocorreu um erro ao editar seu perfil.");
      }
    } catch (err) {
      toast.error(err.erro);
    } finally {
      setFormLoading(false);
    }
  }

  async function getDadosProfile() {
    const uid = dadosStore.key;
    const response = await getPerfil(uid);
    if (response.imagem) {
      const img = response.imagem;
      setUser({ ...response, img });
    } else {
      setUser(response);
    }
  }

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header />
        {/* Page content */}

        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col-xl-4 order-xl-2">
              <div className="card card-profile  bg-gradient-success ">
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-profile-image">
                      <a>
                        {user.imagem ? (
                          <img
                            src={
                              user.imagem && user.imagem.data
                                ? user.imagem.data
                                : user.img
                            }
                            id="imgPerfil"
                            className={`${styles.imgPerfil} mb-2`}
                          />
                        ) : (
                          <div className="row justify-content-center ">
                            <span
                              style={{
                                backgroundColor: "#fff",
                                color: "rgb(46,182,121)",
                                width: "100px",
                                height: "100px",
                                fontSize: "40px",
                                marginTop: "5px",
                              }}
                              className="avatar avatar-sm-16 rounded-circle"
                            >
                              {dadosStore.name.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-header  bg-gradient-success text-center border-0  pb-md-4"></div>
                <div className="card-body pt-0">
                  <div className="text-center">
                    <h5 className="h3 mt-2">
                      {user.nome}
                      <span className="font-weight-light"></span>
                    </h5>
                    <div className="h5 font-weight-500">
                      <i className="ni location_pin mr-2"></i>
                      {dadosStore.name}
                    </div>
                    <div className="h5 font-weight-500">
                      <i className="ni education_hat mr-2 "></i>
                      {user.cpf}
                    </div>
                    <div className="h5 font-weight-500">
                      <i className="ni education_hat mr-2 text-black"></i>
                      {user.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card bg-transparent py-3 border-0">
                {/* Card body */}
                <div className="card-body">
                      <h2 className="card-title text-uppercase mb-2 text-default">
                        Política de senha
                      </h2>
                      <h5 className="card-title text-uppercase mb-0 text-primary">
                        A senha deve conter:
                      </h5>
                      <span className="h3 pb-3 text-sm">
                        A senha precisa ter pelo menos 9 caracteres.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        A senha precisa ter pelo menos 1 número, 0-9.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        A senha precisa ter pelo menos 1 letra minúscula, a-z.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        A senha precisa ter pelo menos 1 letra maiúscula, A-Z.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        A senha precisa ter pelo menos 1 caracter especial:
                        ()[]|\`~!@#$%^*_-+=;:'",./?
                        <br />
                      </span>
                      <h5 className="card-title text-uppercase my-2 text-danger">
                        A senha não pode conter:
                        <br />
                      </h5>
                      <span className="h3 pb-3 text-sm">
                        Não pode ser nenhuma das últimas 10 senhas.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        Placas de carro.
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        Sequências repetitivas (ex.: 99999, aaaaa).
                        <br />
                      </span>
                      <span className="h3 pb-3 text-sm">
                        Nome do mês por extenso.
                        <br />
                      </span>
                    </div>
                  </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card">
                <div className="card-header bg-default">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0 text-white">Editar Perfil </h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form>
                    <h6 className="heading-small text-muted mb-4">
                      Informações do usuário
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome Completo*
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="nome"
                              placeholder="Nome..."
                              value={user.nome || null}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              help={errors.nome || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Email*</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="email"
                              value={user.email || null}
                              placeholder="E-mail..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              help={errors.email || ""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              CPF
                            </label>
                            <Input
                              type="cpf"
                              className="form-control"
                              id="cpf"
                              value={user.cpf || null}
                              placeholder="CPF..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                              help={errors.cpf || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Login*</label>
                            <Input
                              id="login"
                              className="form-control"
                              placeholder="Login..."
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={user.login || null}
                              help={errors.login || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <label className="form-control-label">
                            Imagem do Perfil
                          </label>
                          <div className="form-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="imagem"
                                lang="pt"
                                accept=".jpg"
                                onChange={handleChangeImage}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="inputImage"
                              >
                                Selecionar
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="heading-small text-muted mb-4 pt-4">
                        Atualizar Senha
                      </h6>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Senha Antiga*
                            </label>
                            <div className="input-group input-group-merge input-group-alternative mb-2 col-xl-12">
                              <Input
                                className="form-control "
                                placeholder="Senha Antiga"
                                htmlType="password"
                                id="senhaAntiga"
                                required
                                onChange={handleChange}
                                value={password}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Nova Senha*
                            </label>
                            <div className="input-group input-group-merge input-group-alternative mb-2 col-xl-12">
                              <Input
                                className="form-control"
                                placeholder="Nova Senha"
                                id="senha"
                                htmlType="password"
                                required
                                onChange={handleChange}
                                value={newPassword}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Confirmar Nova Senha*
                            </label>
                            <div className="input-group input-group-merge input-group-alternative mb-2 col-xl-12">
                              <Input
                                className="form-control"
                                placeholder="Confirmar nova Senha"
                                htmlType="password"
                                id="confirmarSenha"
                                required
                                onChange={handleChange}
                                value={newPasswordConfirmation}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-mr-lg-6 text-center">
                      <button
                        type="button"
                        className="btn btn-success btn-block my-4 "
                        onClick={handleSave}
                      >
                        Salvar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Profile;
