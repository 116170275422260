import React, { useState, useEffect } from "react";
import {
   listarValidationBanks,
   sendValidationBanks,
} from "../services/Commission";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import { MDBDataTableV5 } from "mdbreact";

function ValidationBanks() {
   //Dados do formulário:
   const [formLoading, setFormLoading] = useState(false);
   const [data, setData] = useState(null);
   const [datatable, setDataTable] = useState({});

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "ID",
               field: "id",
            },
            {
               label: "Proposta",
               field: "proposta",
            },
            {
               label: "Presente no sistema ?",
               field: "presente_sistema",
            },
            {
               label: "Possibilidades",
               field: "possibilidades",
            },
            {
               label: "Criado em",
               field: "criado_em",
            },
         ],
         rows:
            data &&
            data.dados.map((x) => {
               return {
                  id: x.validacao_id,
                  proposta: x.proposta,
                  presente_sistema: x.presente_sistema ? "Sim" : "Não",
                  possibilidades:
                     x.possibilidades && x.possibilidades === x.proposta
                        ? "-"
                        : x.possibilidades,
                  criado_em: x.criado_em,
               };
            }),
      });
   }, [data]);

   const getData = async () => {
      try {
         setData(null);
         const response = await listarValidationBanks();
         setData(response);
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async () => getData();

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = (error) => reject(error);
      });
   };

   const sendFile = () => {
      try {
         var file = document.querySelector("#inputArquivo").files[0];
         if (file) {
            setFormLoading(true);
            getBase64(file).then(async (data) => {
               try {
                  const result = await sendValidationBanks(data, file.name);
                  if (result.data.pendencia) {
                     toast.info("Favor preencher a planilha corretamente.");
                  } else {
                     toast.success("Arquivo enviado com sucesso.");
                  }
                  window.$("#modal-data").modal("hide");
                  window.$("#inputArquivo").val(null);
                  setFormLoading(false);
               } catch (err) {
                  console.warn(err);
                  setFormLoading(false);
                  toast.error(err.erro);
               }
            });
         } else {
            toast.info("Selecione algum arquivo!");
         }
      } catch (err) {
         console.warn(err);
         setFormLoading(false);
         toast.error("Falha ao enviar arquivo!");
      }
   };

   return (
      <div>
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Download Planilha",
                     icone: (
                        <i
                           className="fas fa-download"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () =>
                        window.open(
                           "https://storage.googleapis.com/seu-credito/Templates%20de%20Planilhas/modelo_importacao_validacao_bancos.xlsx"
                        ),
                  },
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-data").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               <Footer />
            </div>
         </div>

         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Adicionar"}
            form={
               formLoading ? (
                  <div>
                     <Loading />
                  </div>
               ) : (
                  <div>
                     <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        arial-label="close"
                     >
                        <span arial-hidden="true" className="text-white">
                           x
                        </span>
                     </button>
                     <div className="row">
                        <div className="col-md-12">
                           <div className="form-group">
                              <label>Arquivo XLSX*</label>
                              <input
                                 type="file"
                                 className="custom-file-input"
                                 id="inputArquivo"
                                 lang="pt-Br"
                              />
                           </div>
                        </div>

                        <div className="col-md-12 text-center">
                           <button
                              type="button"
                              onClick={() => sendFile()}
                              className="btn btn-primary btn-block"
                           >
                              Adicionar
                           </button>
                        </div>
                     </div>
                  </div>
               )
            }
         />
         <ToastContainer />
      </div>
   );
}

export default ValidationBanks;
