import React, { useState, useEffect } from "react";
import { finalizarSolicitacoesExclusao, getSolicitacoesExclusao } from "../services/Clients";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../components/loading/Loading";

//plugins
import swal from "sweetalert";
import moment from "moment";
import { MDBDataTableV5 } from "mdbreact";

function Clients() {
   //Dados das tabelas/selects:
   const [datatable, setDataTable] = useState({});

   //Dados do filtro:
   const [solicitacoes, setSolicitacoes] = useState(null);

   //Dados do formulário:
   const [fullLoading, setFullLoading] = useState(false);

   async function buscarSolicitacoes(){
      try {
         setFullLoading(true);
         const result = await getSolicitacoesExclusao();
         if(result){
            setSolicitacoes(result);
         }else{
            throw Error();
         }
      } catch (error) {
         toast.error('Erro ao buscar solicitacões, tente novamente.')
      }finally{
         setFullLoading(false);
      }
   }

   useEffect(() => {
      buscarSolicitacoes();
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "ID",
               field: "solicitacao_id",
            },
            {
               label: "Nome",
               field: "nome",
            },
            {
               label: "CPF",
               field: "cpf",
            },
            {
               label: "E-mail",
               field: "email",
            },
            {
               label: "Celular",
               field: "celular",
            },
            {
               label: "Criado A",
               field: "criado_a",
            },
            {
               label: "Status",
               field: "status_solicitacao",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            solicitacoes &&
            solicitacoes.map((x) => {
               return {
                  solicitacao_id: x.solicitacao_id,
                  nome: x.nome,
                  cpf: x.cpf,
                  email: x.email,
                  celular: x.celular,
                  criado_a: moment(x.criado_em).fromNow(),
                  status_solicitacao: x.status_solicitacao,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={()=>finalizarSolicitacao(x.solicitacao_id)}
                           >
                              <i className="fas fa-check"></i>
                              Finalizar
                           </button>
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [solicitacoes]);

   async function finalizarSolicitacao(id){
      swal({
         title: "Finalizar solicitação?",
         text: "Essa solicitação de exclusão será finalizada!",
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFullLoading(true);
            const retorno = await finalizarSolicitacoesExclusao(id);
            if (retorno && retorno.solicitacao_id) {
               await buscarSolicitacoes();
               setFullLoading(false);
               swal("Solicitação finalizada com sucesso.", {
                  icon: "success",
               });
            } else {
               setFullLoading(false);
               swal("Ocorreu um erro ao finalizar a solicitação", {
                  icon: "error",
               });
            }
         } else {
            swal("A solicitação não foi finalizada.", {
               icon: "info",
            });
         }
      });
   }

   return (
      <div>
         {fullLoading && (
               <Loading />
         )}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>
         <ToastContainer />
      </div>
   );
}

export default Clients;
