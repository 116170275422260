import React from "react";
import moment from "moment";

function Footer(props) {
  return (
    <footer className="footer pt-0">
      <div className="row align-items-center justify-content-lg-between bg-transparent">
        <div className="col-lg-6">
          <div className="copyright text-center  text-lg-left  text-muted">
            © {moment().format('YYYY')}
            <a
              href="https://seucreditonegocios.com.br/"
              className="font-weight-bold ml-1"
              target="_blank"
            >
              SeuCrédito
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
