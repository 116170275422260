import { Store } from "../redux/Store"
import instance from "./index";

export const getSimulations = async (status, nome, cpf,parceiro, dataInicio, dataFim, pagina) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "leads/administrativo/listar-simulacoes";
      const response = await instance.post(
        url,
        {
          status,
          nome,
          cpf,
          parceiro,
          dataInicio, 
          dataFim,
          pagina
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getSimulation = async (id) => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
  
        const url = `leads/administrativo/buscar-simulacao?&id=${id}`;
        const response = await instance.get(
          url,
          { headers: { token } }
        );
  
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const exportSimulations = async (nome, cpf, status, dataInicio, dataFim) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = Store.getState().user.token
  
        const url = "leads/administrativo/exportar-simulacoes"
        const response = await instance.post(
          url,
          { nome, cpf, status, dataInicio, dataFim },
          { headers: { token } }
        )
        
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const getStatusSimulation = async () => {
      try { 
        const token = Store.getState().user.token;
  
        const url = `simulacoes/status/listar`;
        const response = await instance.get(
          url,
          { headers: { token } }
        );
  
        if (response.status === 200) {
          return response.data.map(item => ({ ...item, value: item.id, label: item.nome }));
        } else {
          return [];
        }
      } catch (err) {
        return [];
      }
  }