import React, { useState, useEffect, useRef, useMemo } from "react";
import { getAgents } from "../../../src/services/Agents";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Menu from "../../components/sidenav/Menu";
import TopMenu from "../../components/topMenu/TopMenu";
import { toast } from "react-toastify";

import Loading from "../../components/loading/Loading";

//Relatorios
import AnualGeralCharts from "../../components/charts/geralCharts/geralCharts";
import RelProjecao from "../../components/charts/RelProjecao";
import RelProducaoProduto from "../../components/charts/RelProducaoProduto";
import BankCharts from "../../components/charts/geralCharts/banksCharts";

//plugins
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import moment from "moment";
import "moment/locale/pt-br";
import Select from "react-select";

//style
import styles from "./style.module.css";
import InputDateRange from "../../components/inputDataRange/inputDataRange";

registerLocale("pt", pt);
moment.locale("pt-br");

function Reports() {
  //Dados das tabelas/selects:
  const [agentes, setAgentes] = useState(null);
  const [agente, setAgente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const selectAgent = useRef();

  //Dados do formulário:
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setFormLoading(true);
      const response = await getAgents();
      setAgentes([ ...response]);
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    } finally {
      setFormLoading(false);
    }
  };

  const handleClear = () => {
    window.$("#agents").val(null);
    window.$("#database").val(null);
    selectAgent.current.select.clearValue();
    document.getElementById("searchAgent").style.visibility = "hidden";
    setPeriodo(null);
    setAgente(null);
  };

  const handleBuscar = () => {
    document.getElementById("searchAgent").style.visibility = "visible";
  };

  const handleDate = (event) => {
    const { value } = event.target;
    setPeriodo(value);
  };

  const disabledBtnSubmit = useMemo(() => {
    return agente == null;
  }, [agente]);

  return (
    <div>
      {formLoading && (
        <div className={styles.containerLoading}>
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header dashboard={null} />
        <div className={` ${styles.container} fluid mt--7`}>
          <div className={`${styles.card} card bg-white `}>
            <div className="card-header  border-0  bg-white ">
              <h3 className={` ${styles.title} mb-1 text-xl p-3.5`}>
                Relatórios Individuais Agentes
              </h3>
              <div className="row">
                <div className="col-md-7 ml-3">
                  <h3 className={`ml-3 mt-2 `}>Selecione um agente:</h3>
                  <Select
                    ref={selectAgent}
                    id="agents"
                    className={`${styles.padding} form-control-select `}
                    placeholder="Selecione o Agente..."
                    options={agentes}
                    onChange={(e) => {
                      if (e) {
                        setAgente(e.value || null);
                      }
                    }}
                    backspaceRemovesValue
                  />
                </div>
                <div className="col-md-4 mt-3">
                <h3 className={`ml-3`}>Periodo:</h3>
                <div className="ml-4">
                  <InputDateRange
                    id="dataBase"
                    placeholder="Data Base..."
                    type={"date"}
                    value={periodo || null}
                    onChange={handleDate}
                  />
                </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <button
                  type="button"
                  className="btn btn-danger btn-block col-md-3 my-4 "
                  onClick={handleClear}
                >
                  Limpar
                </button>
                <button
                  type="button"
                  className="btn btn-success  btn-block col-md-3 my-4 "
                  onClick={handleBuscar}
                  disabled={disabledBtnSubmit}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div id="searchAgent" className={`${styles.sectionSearch}`}>
            <div className="card">
              <div className="card-header bg-default">
                <h6 className="surtitle">INDIVIDUAL</h6>
                <h5 className="h3 mb-0 text-white">Produção total</h5>
              </div>
              <div className="card-body ">
                <AnualGeralCharts userId={agente} />
              </div>
            </div>
            <div className="card">
              <div className="card-header bg-default">
                <h6 className="surtitle">INDIVIDUAL</h6>
                <h5 className="h3 mb-0 text-white">Produção por bancos</h5>
              </div>
              <div className="card-body">
                <div className="chart">
                  <BankCharts userId={agente} periodo={periodo} />
                </div>
              </div>
            </div>
            <div className="row col-xl-12 ">
              <div className=" col-sm-6">
                <div className="card">
                  <div className="card-header bg-default">
                    <h6 className="surtitle">INDIVIDUAL</h6>
                    <h5 className="h3 mb-0 text-white">Produção por Produto</h5>
                  </div>
                  <div className="card-body ">
                    <RelProducaoProduto userId={agente} periodo={periodo} />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header bg-default">
                    <h6 className="surtitle">INDIVIDUAL</h6>
                    <h5 className="h3 mb-0 text-white">Projeção</h5>
                  </div>
                  <div className="card-body">
                    <RelProjecao userId={agente} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Reports;
