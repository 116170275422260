import React from "react";

function Modal(props) {
  const { id, form, title, size, full = false, onSubmit = false } = props;
  
  return (
    <div
      className={"modal fade "}
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog${
          full ? "-full" : ""
        } modal-dialog-centered modal-${size}`}
        role="document"
      >
        <div className={`modal-content${full ? "-full" : ""}`}>
          <div className="modal-header mt--1 bg-default">
          {title && (
              <h5 className="modal-title text-white">
                {title}
              </h5>
            )}
            
          </div>
          <div className="modal-body p-0">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-4">
                {onSubmit && (
                  <form onSubmit={onSubmit} role="form">{form}</form>
                )}

                {!onSubmit && (
                  <form role="form">{form}</form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
