import { Store } from "../redux/Store"
import instance from "./index";
import { UPDATE_USER } from "../redux/Actions"


export const getUser = async id => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
        const url = `usuarios/administrativo/buscar?id=${id}`;
        const response = await instance.get(
          url,
          { headers: { token } }
        );
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}
export const getPerfil = async uid => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
        const url = `usuarios/administrativo/buscar-perfil?uid=${uid}`;
        const response = await instance.get(
          url,
          { headers: { token } }
        );
        if (response.status === 200) {
          const data = response.data;
          Store.dispatch({
            type: UPDATE_USER,
            payload: {
              foto: data.imagem,
            },
          })
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}
export const getFunctions = async (nome, pagina) => {
    return new Promise(async (resolve, reject) => {
      try { 
        const token = Store.getState().user.token;
        const url = `usuarios/administrativo/listar-funcoes`;
        const response = await instance.post(
          url,
          {

            nome,
            pagina
          },
          { headers: { token } }
        );
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
}



export const getUsers = async (status, nome, cpf, pagina) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "usuarios/administrativo/listar";
      const response = await instance.post(
        url,
        {
          status,
          nome,
          cpf,
          pagina
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getFunctionsUsers = async () => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;
      const url = `usuarios/administrativo/buscar-funcao`;
      const response = await instance.get(
        url,
        { headers: { token } }
      ); 
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}
export const getFunction = async id => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;
      const url = `usuarios/administrativo/buscar-funcao?id=${id}`;
      const response = await instance.get(
        url,
        { headers: { token } }
      ); 
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getPermissions = async() => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;
      const url = 'usuarios/administrativo/buscar-permissoes';
      const response = await instance.get(
        url,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getPermissionForFunction = async(funcao_id) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;
      const url = 'usuarios/administrativo/buscar-permissoes-por-funcao';
      const response = await instance.post(
        url,
        {funcao_id:funcao_id},
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const gravarPermissoes = async (funcao_escolhida, data) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "usuarios/administrativo/salvar-permissoes";
      const response = await instance.post(
        url,
        { funcao: funcao_escolhida,
          permissoes: data
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const recordUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "usuarios/administrativo/salvar";
      const response = await instance.post(
        url,
        data,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const recordFunction = async (data) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = `usuarios/administrativo/gravar-funcao`;
      const response = await instance.post(
        url,
        data,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const sendPasswordAdmin = async id => {
  try {
    const token = Store.getState().user.token

    const url = "usuarios/administrativo/enviar-senha"
    const response = await instance.post(
      url,
      { id },
      { headers: { token } }
    )

    if (response.status === 200) {
      return response.data
    } else {
      return response.data
    }
  } catch (err) {
    return err
  }
}

export const inactivateUser = async id => {
  try {
    const token = Store.getState().user.token

    const url = `usuarios/administrativo/inativar?id=${id}`
    const response = await instance.get(
      url,
      { headers: { token } }
    )

    if (response.status === 200) {
      return response.data
    } else {
      return response.data
    }
  } catch (err) {
    return err
  }
}

export const inactivateFunction = async id => {
  try {
    const token = Store.getState().user.token

    const url = `usuarios/administrativo/inativar-funcao?funcao_id=${id}`
    const response = await instance.get(
      url,
      { headers: { token } }
    )
    if (response.status === 200) {
      return response.data
    } else {
      return response.data
    }
  } catch (err) {
    return err
  }
}

export const unlockUser = async cpf => {
  try {
    const token = Store.getState().user.token

    const url = 'usuarios/desbloquear-usuario'
    const response = await instance.post(
      url,
      { cpf },
      { headers: { token } }
    )
    if (response.status === 200) {
      return response.data
    } else {
      return response.data
    }
  } catch (err) {
    return err
  }
}

export const unlockAdministrator = async id => {
  try {
    const token = Store.getState().user.token

    const url = 'administradores/desbloquear-administrador'
    const response = await instance.post(
      url,
      { id },
      { headers: { token } }
    )
    if (response.status === 200) {
      return response.data
    } else {
      return response.data
    }
  } catch (err) {
    return err
  }
}


