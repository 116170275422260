import { Store } from "../redux/Store";
import { UPDATE_USER, LOGOUT } from "../redux/Actions";
import instance from "./index";

import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const login = async (username, password) => {
  return new Promise(async (resolve, reject) => {
    const url = "administradores/login";
    const response = await instance.post(url, {
      login: username,
      senha: password,
      
    });
    if (response.status === 200) {
      if (response.data && response.data.type) {
        // return response;
      } else {
        const data = response.data;
        Store.dispatch({
          type: UPDATE_USER,
          payload: {
            key: data.uid,
            name: data.nome,
            foto: data.foto,
            token: data.token,
            first_access: data.primeiro_acesso,
            permissions: data.permissoes,
            paginas: data.paginas,
            role: data.funcao,
            status_id: data.status_id,
          },
        });
      }
      resolve(response.data);
    } else {
      reject(response.data);
    }
  });
};
export const updatePassword = async (username, cpf, email) => {
  return new Promise(async (resolve, reject) => {
    const url = "administradores/recuperar-senha-adm";
    const response = await instance.post(url, {
      login: username,
      cpf: cpf,
      email: email,  
    });
    if (response.status === 200) {
      if (response.data && response.data.type) {
        return response;
      } 
      resolve(response.data);
    } else {
      reject(response.data);
    }
  });
};


export const firstAccess = async (password, newPassword) => {
  return new Promise(async (resolve, reject) => {
    const token = Store.getState().user.token;

    const url = "administradores/primeiro-acesso";
    const response = await instance.post(
      url,
      {
        senha_atual: password,
        nova_senha: newPassword,
      },
      { headers: { token } }
    );

    if (response.status === 200) {
      Store.dispatch({
        type: UPDATE_USER,
        payload: {
          first_access: false,
          status_id: 3
        },
      });

      resolve();
    } else {
      reject(response.data);
    }
  });
};

export const logout = async () => {
  Store.dispatch({
    type: LOGOUT,
  });
};

export const getReportsHome = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/relatorios";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data[0]);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportSimulation = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-simulacoes";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportSimulationDay = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-diario-simulacoes";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportProposals = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-propostas";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportDados = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-status-propostas";
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportHeader = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-cabecalho";
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportPropostas = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/relatorio-propostas";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data[0]);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getAgents = async (nome, cpf, status,parceiro, pagina) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/listar";
      const response = await instance.post(
        url,
        {
          nome,
          cpf,
          status,
          parceiro,
          pagina,
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getAgentsStatus = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/status/listar";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getAgentData = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/buscar?id=" + id;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const approveAgent = async (id, register) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/aprovar";
      const response = await instance.post(
        url,
        {
          agente: id,
          master: register.master,
          master_id: register.master_id,
          observacao_auditoria: register.observacao_auditoria,
          observacao_operacional: register.observacao_operacional,
          mostrar_comissao: register.mostrar_comissao,
          agente_queroquero: register.agente_queroquero,
          exibe_crm: register.exibe_crm,
          exibe_confirme: register.exibe_confirme
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const disableAgent = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "agentes/administrativo/desativar";
      const response = await instance.post(
        url,
        { agente: id },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const updateStatusAgent = async (id, ticagem, register) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "agentes/administrativo/atualizar_status";
      const response = await instance.post(
        url,
        {
          agente: id,
          ticagem,
          register,
        },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getRulesFees = async (
  banco,
  convenio,
  regra,
  data_inicio,
  data_fim,
  pagina
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "regras/taxas/listar";
      const response = await instance.post(
        url,
        { banco, convenio, regra, data_inicio, data_fim, pagina },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getChats = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "chats/administrativo/listar";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getMessages = async (chat) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/chats/administrativo/conversas/listar";
      const response = await instance.post(
        url,
        { chat },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const sendMessage = async (chat, mensagem) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/chats/conversas/enviar";
      const response = await instance.post(
        url,
        { chat, mensagem },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const finalizeChat = async (chat) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/chats/administrativo/finalizar";
      const response = await instance.post(
        url,
        { chat },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const updateDocuments = async (document, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/agentes/atualizar_documentos";
      const response = await instance.post(
        url,
        { document, id },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const sendPasswordAgent = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/enviar-senha";
      const response = await instance.post(
        url,
        { agente: id },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const sendAgent = async (dados, ticagem) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/cadastrar";
      const response = await instance.post(
        url,
        { dados, ticagem },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getAgentsMaster = async () => {
  try {
    const token = Store.getState().user.token;
    const url = "agentes_master/listar";
    const response = await instance.get(url, { headers: { token } });
    return response.data.map(item => ({ id: item.usuario_id, nome: item.nome, value: item.usuario_id, label: item.nome }));
  } catch (err) {
    const message = _.get(err, "reponse.data.erro", err.message);
    toast.error(message);
  }
};

/**
 * Função para carregar uma lista de estados da api
 */
export async function listEstados() {
  try {
    const url = "/estados/listar";
    const dados = await instance.get(url);
    return dados.data.map((item) => ({
      ...item,
      value: item.id,
      label: `${item.nome} - ${item.uf}`,
    }));
  } catch (err) {
    const message = _.get(err, "reponse.data.erro", err.message);
    toast.error(message);
  }
  return [];
}

/**
 * Função para carregar uma lista de parceiros da api
 */
 export async function listPartners() {
  try {
    const token = Store.getState().user.token;
    const url = "/parceiros/listar-todos";
    const dados = await instance.get(url, { headers: { token } });
    return dados.data && dados.data.map((item) => ({
      ...item,
      value: item.parceiro_id,
      label: `${item.nome}`,
    }));
  } catch (err) {
    const message = _.get(err, "reponse.data.erro", err.message);
    toast.error(message);
  }
  return [];
}

/**
 * Função para listar os bancos
 */
export async function listBanks() {
  try {
    const url = "/bancos/listar";
    const dados = await instance.get(url);
    return dados.data.map((item) => ({
      ...item,
      value: item.id,
      label: item.nome,
    }));
  } catch (err) {
    const message = _.get(err, "response.data.erro", err.message);
    toast.error(message);
  }
  return [];
}

/**
 * Função para listar os tipos de contas
 */
export async function listTypeOfAccounts() {
  try {
    const url = "/bancos/tipos-contas/listar";
    const dados = await instance.get(url);
    return dados.data.map((item) => ({
      ...item,
      value: item.id,
      label: item.nome,
    }));
  } catch (err) {
    const message = _.get(err, "response.data.erro", err.message);
    toast.error(message);
  }
  return [];
}

/**
 * Função para carregar uma lista de cidades da api pelo estado
 * @param {string|number} estado
 */
export async function listByEstado(estado) {
  try {
    if (estado) {
      const url = `/cidades/listar?estado=${estado}`;
      const dados = await instance.get(url);
      return dados.data.map((item) => ({
        ...item,
        value: item.id,
        label: item.nome,
      }));
    }
  } catch (err) {
    const message = _.get(err, "reponse.data.erro", err.message);
    toast.error(message);
  }
  return [];
}

export async function getAddressByCep(cep) {
  try {
    if (cep && cep.length === 9) {
      const url = `/ceps/buscar?cep=${cep}`;
      const data = await instance.get(url);
      return data;
    }
  } catch (err) {
    const message = _.get(err, "response.data.erro", err.message);
    toast.error(message);
  }

  return null;
}

/**
 * Função para carregar uma lista de cidades da api pelo estado
 * @param {string} cpf
 */
export const validateCpfAgent = async (id, cpf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/validar-cpf";
      const response = await instance.post(
        url,
        { id, cpf },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getChatsPending = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "chats/administrativo/pendentes";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const hiddenComissionsAgent = async (id, mostrarComissao) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/exibir-comissoes";
      const response = await instance.post(
        url,
        { agente: id, exibir: mostrarComissao },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const exportAgents = async (nome, cpf, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "agentes/administrativo/exportar";
      const response = await instance.post(
        url,
        { nome, cpf, status },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportProdGeral = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-produtividade-geral";
      const response = await instance.post(
        url,
        { userId },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getReportProdBank = async (userId, periodo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-produtivade-banco";
      const response = await instance.post(
        url,
        { userId, periodo },
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getMelhoresAgentes = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/administrativo/relatorio-melhores-agentes";
      const response = await instance.get(
        url,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const getProjecoes = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "dashboard/relatorios/projecoes";
      const response = await instance.post(
        url,
        { userId },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getProducaoProduto = async (userId, periodo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "dashboard/relatorios/producao-por-produto";
      const response = await instance.post(
        url,
        { userId, periodo },
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const atualizarDocumentosAdm = async (ativo, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/documentacao/atualizar_documento";
      const response = await instance.post(
        url,
        { ativo, data },
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const buscarDocumentosAdm = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;

      const url = "/documentacao/buscar_documentos";
      const response = await instance.get(url, { headers: { token } });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};
