import React, { useState, useEffect } from "react";
import { atualizarDocumentosAdm, buscarDocumentosAdm } from "../services/Api";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import swal from "sweetalert";

function Treinamentos() {
   //Dados do formulário:
   const [formLoading, setFormLoading] = useState(false);
   const [data, setData] = useState(null);
   const [datatable, setDataTable] = useState({});

   let nome = "";

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "ID",
               field: "id",
            },
            {
               label: "Documento",
               field: "documento",
            },
            {
               label: "Criado Em",
               field: "criado_em",
            },
            {
               label: "Criado Por",
               field: "criado_por",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            data &&
            data.length > 0 &&
            data.map((x) => {
               return {
                  id: x.documento_id,
                  documento: x.nome,
                  criado_em: moment(x.criadoem).format("DD/MM/YYYY HH:mm:ss"),
                  criado_por: x.criadopor,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              onClick={() => {
                                 window.open(x.arquivo);
                              }}
                              className="dropdown-item"
                           >
                              <i className="far fa-eye"></i>
                              Visualizar
                           </button>
                           <button
                              type="button"
                              onClick={() =>
                                 removeFile(0, x.documento_id, x.arquivo)
                              }
                              className="dropdown-item"
                           >
                              <i className="far fa-trash-alt"></i>
                              Remover
                           </button>
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [data]);

   const getData = async () => {
      try {
         const response = await buscarDocumentosAdm();
         setData(response && response.length > 0 ? response : null);
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async () => getData();

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = (error) => reject(error);
      });
   };

   const sendFile = (ativo) => {
      try {
         setFormLoading(true);
         var file = document.querySelector("#inputArquivo").files[0];
         let auxType = file && file.type.split("/")[1];
         let validationType =
            auxType == "pdf" ||
            auxType == "jpg" ||
            auxType == "jpeg" ||
            auxType == "png";

         if (file && file.size > 2000000) {
            toast.info("Tamanho maximo do arquivo: 2MB");
            return;
         }

         if (auxType && !validationType) {
            toast.info("Formato do arquivo não suportado");
            return;
         }
         if (!nome) {
            toast.info("Informe o nome do arquivo!");
            return;
         }
         if (file) {
            getBase64(file).then(async (data) => {
               const response = await atualizarDocumentosAdm(ativo, {
                  nome: nome,
                  nomeArquivo: file.name,
                  arquivo: data,
               });
               if (response) {
                  setFormLoading(false);
                  toast.success("Arquivo enviado com sucesso!");
                  window.$("#modal-data").modal("hide");
                  window.$("#inputArquivo").val(null);
                  await getData();
               } else {
                  toast.error("Falha ao enviar arquivo!");
                  window.$("#modal-data").modal("hide");
                  window.$("#inputArquivo").val(null);
               }
            });
         } else {
            toast.info("Selecione algum arquivo!");
         }
      } catch (err) {
         console.warn(err);
         setFormLoading(false);
         toast.error("Falha ao enviar arquivo!");
      } finally {
         setFormLoading(false);
      }
   };

   const removeFile = async (ativo, id, arquivo) => {
      swal({
         title: "Excluir arquivo?",
         text: `Deseja realmente excluir esse arquivo ? Essa ação não poderá ser desfeita !`,
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            try {
               setFormLoading(true);
               const retorno = await atualizarDocumentosAdm(ativo, {
                  id: id,
                  arquivo: arquivo,
               });
               if (retorno) {
                  swal("Arquivo excluido com sucesso.", {
                     icon: "success",
                  });
                  await getData();
               }
            } catch (err) {
               swal("Ocorreu um ao excluir, tente novamente.", {
                  icon: "error",
               });
            } finally {
               setFormLoading(false);
            }
         } else {
            swal("A exclusão foi cancelada.", {
               icon: "info",
            });
         }
      });
   };

   return (
      <div>
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-data").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               <Footer />
            </div>
         </div>

         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Adicionar"}
            form={
               formLoading ? (
                  <div>
                     <Loading />
                  </div>
               ) : (
                  <div>
                     <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        arial-label="close"
                     >
                        <span
                           arial-hidden="true"
                           className="text-white"
                        >
                           x
                        </span>
                     </button>
                     <div className="card mb-4">
                        <div className="card-header">
                           <h3 className="mb-0">Treinamento</h3>
                        </div>
                        <div className="card-body">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">
                                       Nome do arquivo
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="exampleFormControlInput1"
                                       onChange={(e) => {
                                          nome = e.target.value;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <label>Arquivo</label>
                                    <input
                                       type="file"
                                       className="custom-file-input"
                                       id="inputArquivo"
                                       lang="pt-Br"
                                    />
                                 </div>
                              </div>

                              <div className="col-md-12 text-center">
                                 <button
                                    type="button"
                                    onClick={() => sendFile(1)}
                                    className="btn btn-primary btn-block"
                                 >
                                    Adicionar
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            }
         />
         <ToastContainer />
      </div>
   );
}

export default Treinamentos;
