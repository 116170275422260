import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from './style.module.css';

//api
import { getAgents, getIntentions, getIntentionsByAgent, saveIntentionsAgent } from "../../../services/Agents";

//Compomentes utilizados
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import TopMenu from "../../../components/topMenu/TopMenu";
import Loading from "../../../components/loading/Loading";
import Select from "react-select";

function AgentsIntentions() {

  const [agents, setAgents] = useState(null);
  const [agenteEscolhido, setAgenteEscolhido] = useState(null);
  const [intentions, setIntentions] = useState(null); 
  const [intentionAgent, setIntentionAgent] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    async function buscarFuncoes() {
      try {
        setShowLoading(true);
        
        const responseAgents = await getAgents();
        const responseIntencoes = await getIntentions();
        setAgents(responseAgents);
        setIntentions(responseIntencoes);
      } catch (error) {
        toast.error('Erro ao carregar os agentes e as intenções.');
      } finally {
        setShowLoading(false);
      }
    }
    buscarFuncoes();
  }, [])

  useEffect(() => {
    if(agenteEscolhido){
     buscarIntencoes();
    }else{
      document.getElementsByClassName('btn-success')[0].disabled = true;
    }
  }, [agenteEscolhido]);

  async function buscarIntencoes() {
    try {
      setShowLoading(true);

      const response = await getIntentionsByAgent(agenteEscolhido);
      let checks = document.getElementsByClassName('custom-control-input');
      for(let i = 0; i < checks.length; i++){
        checks[i].checked = false;
        checks[i].disabled = false;
      }
      document.getElementsByClassName('btn-success')[0].disabled = false;

      let auxArray = [];

        response.forEach(e => {
          auxArray = [...auxArray, e.id];
          document.getElementById(e.id).checked = true;
        });

    //   response.map((e)=>{
    //     auxArray = [...auxArray, e.id];
    //     document.getElementById(e.id).checked = true;
    // })
    
    setIntentionAgent(auxArray);
    } catch (error) {
      toast.error('Erro ao carregar as intenções de empréstimo do agente escolhido');
    } finally {
      setShowLoading(false);
    }
  }

  const handleChoice = (e) =>{
      if(agents){
        const escolha = e.label;
        agents.map((x)=>{
          if(escolha === x.nome){
            setAgenteEscolhido(x.id)
          }
        })
      }
    else{
      setAgenteEscolhido(null);
      let checks = document.getElementsByClassName('custom-control-input');
        document.getElementsByClassName('btn-success')[0].disabled = true;
        for(let i = 0; i < checks.length; i++){
          checks[i].checked = false;
          checks[i].disabled = true;
        }
    }
  }

  const handleCheck = (e) =>{
    let check = e.target;
    let pos = null;

    pos = intentionAgent.map(function(e) { return e; }).indexOf(parseInt(check.id));

    if(pos === 0){
      intentionAgent.shift();
    }else if(pos && pos > 0){
      intentionAgent.splice(pos,1);
    }else if(pos && pos === -1){
      setIntentionAgent([...intentionAgent, parseInt(check.id)]);
    }
  }

  const handleSave = async() =>{
    const intencoesJson = JSON.stringify(intentionAgent);
    try {
      setShowLoading(true);
      await saveIntentionsAgent(agenteEscolhido, intencoesJson);
      toast.success('Intenções salvas com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao salvar as intenções.');
    } finally {
      setShowLoading(false);
    }
  }

  return (
    <div>
      {
        showLoading &&
          <div className={styles.containerLoading}>
            <Loading/>
          </div>
      }
      <Menu />
      <div className="main-content" id="panel">
        <TopMenu />
        <Header
          dashboard={null}
        />
        <div className="container-fluid mt--6">
          <div className={`${styles.card} card`}>
            <div className="card-header bg-default border-0">
              <h3 className="mb-0 text-white">Permissão de Agentes x Produtos</h3>
            </div>
            <div className={styles.padding}>
              <Select 
              className={`${styles.padding} mt-4 form-control-select`} 
              onChange={handleChoice}
              options={agents}
              placeholder='Selecione um Agente...'
              />
              <div className={styles.teste}>
                {
                  agents && intentions ?
                    intentions.map((x)=>{
                      return(
                        <div className={`${styles.divCheckBox} custom-control custom-checkbox`} key={x.id} style={{marginBottom:'8px'}}>
                          <input 
                            type="checkbox" 
                            className="custom-control-input" 
                            id={x.id}
                            name={x.nome}
                            onChange={handleCheck}
                            disabled
                          >
                          </input>
                          {/* <label className="custom-control-label" for={x.id}>{x.nome}</label> */}
                          <label className="custom-control-label" htmlFor={x.id}>{x.nome}</label>
                        </div>
                      )
                    })
                  :
                    <p>Carregando....</p> 
                }
              </div>
            </div>
            <div className="row justify-content-md-center">
              <button type="button" className="btn btn-success col-sm-6" onClick={handleSave}>Salvar</button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AgentsIntentions;
