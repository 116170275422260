import { Store } from "../redux/Store"
import instance from "./index";

export const getReanalises = async (status, nome, cpf, verTodos) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "reanalise/listar";
      const response = await instance.post(
        url,
        {
          status,
          nome,
          cpf,
          verTodos
        },
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}
export const getReanalisesIndividual = async (status, nome, cpf, pagina) => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "reanalise/listar-individual";
      const response = await instance.post(
        url,
        {
          status,
          nome,
          cpf,
          pagina
        },
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const getAdms = async () => {
  return new Promise(async (resolve, reject) => {
    try { 
      const token = Store.getState().user.token;

      const url = "reanalise/listar-adms";
      const response = await instance.get(
        url,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}



export const getReanaliseData = async (id, simulacao_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `reanalise/administrativo/buscar?proposta=${id}&simulacao=${simulacao_id}`;
      const response = await instance.get(url, { headers: { token } })
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    } catch (err) {
      reject(err)
    }
  })
}


export const gravarReanalise = async (dados) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "reanalise/administrativo/salvar-reanalise";
      const response = await instance.post(
        url,
        dados,
        { headers: { token } }
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      } 
    } catch (err) {
      reject(err);
    }
  });
};


export const getReanalise = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = `reanalise/administrativo/buscar-reanalise?id=${id}`;
      const response = await instance.get(url, { headers: { token } });
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getMotivos = async () => {
  try {
    const token = Store.getState().user.token

    const url = `/reanalise/listar`
    const response = await instance.get(url, { headers: { token } })
    
    if (response.status === 200) {
      return response.data
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export const exportReanalises = async() => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "reanalise/administrativo/exportar";
      const response = await instance.get(
        url,
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) { 
      reject(err);
    }
  });
};

export const exportReanalisesIndividual = async() => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Store.getState().user.token;
      const url = "reanalise/administrativo/exportar-individual";
      const response = await instance.get(
        url,
        { headers: { token } }
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) { 
      reject(err);
    }
  });
};


export const atribuirReanalise = async (simulacao_id, idAdm, dados) => {
    
    try { 
      const token = Store.getState().user.token;
      const url = "reanalise/atribuir-reanalise";
      const response = await instance.post(
        url,
        { simulacao_id, adm_id: idAdm, dados },
        { headers: { token } }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (err) {
      return err;
    }
}