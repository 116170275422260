import React, { useEffect, useMemo, useState } from "react";

//components
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import TopMenu from "../../../components/topMenu/TopMenu";
import Menu from "../../../components/sidenav/Menu";

//tabela
import { MDBDataTableV5 } from "mdbreact";
import Loading from "../../../components/loading/Loading";
import {
  buscarValores,
  getContas,
  getValor,
  getValores,
  inativarValorConta,
  saveValuesConta,
} from "../../../services/Contas";
import swal from "sweetalert";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/input/Input";
import { toast } from "react-toastify";

function Contas() {
  const [formLoading, setFormLoading] = useState(false);

  //Dados formulario
  const [contas, setContas] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [values, setValues] = useState([]);
  const [register, setRegister] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getListContas();

    window.$("#modal-data").on("hidden.bs.modal", () => {
      onReset();
    });
  }, []);

  const getListContas = async () => {
    try {
      setContas(await getContas());
    } catch (err) {}
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    switch (id) {
      default:
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
        setRegister({ ...register, [id]: value });
        break;
    }
  };

  const handleBlur = async (event) => {
    const { id, value } = event.target;
    if (!value || value === "R$ 0,00") {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: "Campo Obrigatório" }));
    }
  };

  //limpar modal
  const onReset = () => {
    setRegister({});
    setErrors({});
    window.$("#valor").val(null);
    window.$("#parcela").val(null);
    window.$("#valor_parcela").val(null);
    window.$("#modal-adicionar").modal("hide");
  };


  //abrir modal de valores
  const onVisualizar = async (value) => {
    try {
      setValues(await getValores(value));
      window.$("#modal-parcelas").modal("show");
    } catch (error) {}
  };
  
  //filtrar tabela
  const onFilter = async () => {
    if(searchValue !== null && searchValue !== '0,00'){
       window.$("#modal-filter").modal("hide");
        setContas( await buscarValores(searchValue));
     }else {
      window.$("#modal-filter").modal("hide");
      setContas( await getContas());
     }
  }

  //Abre modal adicionar para atualizar os dados
  const onEdit = async (valor,parcela) => {
    try {
      setFormLoading(true);
      const result = await getValor(valor,parcela);
      setRegister(result);
      window.$("#modal-adicionar").modal("show");
    } catch (err) {
    //   toast.error(err);
    } finally {
      setFormLoading(false);
    }
  };

  //deletando valor
  const onDelete = async (id) => {
    swal({
      title: "Deletar Valor?!",
      text: `O valor ${id}, será deletado, você tem certeza?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await inativarValorConta(id);
        if (retorno) {
          setFormLoading(false);
          swal("Valor foi deletado com sucesso.", {
            icon: "success",
          });
          await getListContas();
        } else {
          setFormLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFormLoading(false);
        swal("Valor não foi deletado.", {
          icon: "info",
        });
      }
    });
  };

  //Salvar dados
  const onSave = async (event) => {
    event.preventDefault();
    setFormLoading(true);
    try {
      await saveValuesConta(register);
      toast.success("Valor salvo com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro tente novamente!");
      setFormLoading(false);
    } finally {
      window.$("#modal-adicionar").modal("hide");
      onReset();
      setFormLoading(false);
      setRegister({});
    }
  };

  //desabilita botao 
  const disabledBtnSubmit = useMemo(() => {
    return (
      Object.keys(register).filter((key) => register[key]).length < 3 ||
      Object.keys(errors).filter((key) => errors[key]).length > 0
    );
  }, [errors]);

  //dados tabela
  const datatable = {
    columns: [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "Valor total",
        field: "valor",
      },
      {
        label: "Parcela Mínima",
        field: "parcela",
      },
      {
        label: "Ação",
        field: "action",
      },
    ],
    rows:
      contas &&
      contas.map((x) => {
        return {
          id: x.id,
          valor: x.label,
          parcela: x.parcela + 'x',  
          action: (
            <div className="dropdown">
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                <button
                  type="button"
                  onClick={() => onVisualizar(x.value)}
                  className="dropdown-item"
                >
                  <i className="fas fa-eye"></i>
                  Visualizar Parcelas
                </button>
                <button
                  type="button"
                  onClick={() => onEdit(x.value, x.parcela)}
                  className="dropdown-item"
                >
                  <i className="fas fa-edit"></i>
                  Editar
                </button>
                <button
                  type="button"
                  onClick={() => onDelete(x.id)}
                  className="dropdown-item"
                >
                  <i className="fas fa-trash fa-lg"></i>
                  Deletar
                </button>
              </div>
            </div>
          ),
        };
      }),
  };

  //Dados tabela Valores
  const valuesTable = {
    columns: [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "Valor total",
        field: "valor",
      },
      {
        label: "Parcelas",
        field: "parcela",
      },
      {
        label: "Valor da Parcela",
        field: "valor_parcela",
      },
      {
        label: "Ação",
        field: "action",
      },
    ],
    rows:
      values &&
      values.map((x) => {
        return {
          id: x.id,
          valor: x.label,
          parcela: x.parcela + " x",
          valor_parcela: x.vlrparcela,
          action: (
            <div className="dropdown">
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                <button
                  type="button"
                  onClick={() => onEdit(x.valor,x.parcela)}
                  className="dropdown-item"
                >
                  <i className="fas fa-edit"></i>
                  Editar
                </button>
                <button
                  type="button"
                  onClick={() => onDelete(x.id)}
                  className="dropdown-item"
                >
                  <i className="fas fa-trash fa-lg"></i>
                  Deletar
                </button>
              </div>
            </div>
          ),
        };
      }),
  };

  return (
    <div>
      {formLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>,
              onClick: () => window.$("#modal-adicionar").modal("show"),
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable}
                searchLabel="Buscar"
                entriesLabel={false}
                hover
                striped={true}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* modal Parcelas */}
      <Modal
        id={"modal-parcelas"}
        title={"Valores Parcelas"}
        size={"lg"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
              onClick={onReset}
            >
              <span arial-hidden="true">x</span>
            </button>
            <div className="card">
              <div className="card-header border-0">
                <MDBDataTableV5
                  data={valuesTable}
                  searchLabel="Buscar"
                  entriesLabel={false}
                  hover
                  striped={true}
                  pagingTop
                  searchTop
                  searchBottom={false}
                  responsive={true}
                  infoLabel={["", "a", "de", "Registros"]}
                  entries={10}
                  maxHeight="150px"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                <button
                  type="button"
                    onClick={onReset}
                  className="btn btn-danger btn-block my-4"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        }
      />
      {/*Modal Adicionar/editar */}
      <Modal
        id={"modal-adicionar"}
        title={"Adicionar ou Alterar Valores"}
        size={"sm"}
        form={
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                arial-label="close"
                onClick={onReset}
              >
                <span arial-hidden="true" >x</span>
              </button>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="ml-4">Valor Total*</label>
                    <Input
                      id="valor"
                      className="form-control ml-4"
                      placeholder="Valor..."
                      type="money"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.valor || null}
                      //   help={errors.funcao || ""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="ml-4">Quantidade de Parcelas*</label>
                    <Input
                      id="parcela"
                      className="form-control ml-4"
                      placeholder="Ex.:8.."
                      type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={register.parcela || null}
                      //   help={errors.funcao || ""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="ml-4">Valor por Parcelas*</label>
                    <Input
                      id="valor_parcela"
                      className="form-control ml-4"
                      type="money"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      value={ register.valor_parcela || null}
                      //   help={errors.funcao || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    className="btn btn-danger btn-block my-4"
                    data-dismiss="modal"
                    arial-label="close"
                    onClick={onReset}
                  >
                    Cancelar
                  </button>
                </div>

                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    onClick={onSave}
                    disabled={disabledBtnSubmit}
                    className="btn btn-success btn-block my-4"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
        }
      />
      {/*Modal Filtrar */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"sm"}
        form={
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                arial-label="close"
                onClick={onReset}
              >
                <span arial-hidden="true" >x</span>
              </button>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="ml-4">Valor Total</label>
                    <Input
                      id="valor"
                      className="form-control mx-4"
                      placeholder="Valor..."
                      type="money"
                      onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      //   help={errors.funcao || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    className="btn btn-danger btn-block my-4"
                    data-dismiss="modal"
                    arial-label="close"
                    onClick={onReset}
                  >
                    Cancelar
                  </button>
                </div>

                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    onClick={onFilter}
                    // disabled={disabledBtnSubmit}
                    className="btn btn-success btn-block my-4"
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
        }
      />
    </div>
  );
}
export default Contas;
