import React, { useState, useEffect } from "react";
import {
  getAgentsComission,
  getAgentComissions,
  exportComissionsAgent,
} from "../services/Commission";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";

import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";
import Input from "../components/input/Input";

//plugins
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import swal from "sweetalert";
import moment from "moment";
import "moment/locale/pt-br";

//helpers
import funcoes from "../utils/helpers/funcoes";
import { MDBDataTableV5 } from "mdbreact";
import TableList from "../components/table/TableList";

registerLocale("pt", pt);
moment.locale("pt-br");

function Commission() {
  //Dados das tabelas/selects:
  const [data, setData] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [comissoes, setComissoes] = useState({});
  const [page, setPage] = useState(1);

  //Dados do filtro:
  const [name, setName] = useState(null);
  const [nomeDetalhe, setNomeDetalhe] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [user, setUser] = useState(null);
  const [filtroData, setFiltroData] = useState(null);

  //Dados do formulário:
  const [formLoading, setFormLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  useEffect(() => {
    getData(1, null, null);
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "CPF",
          field: "cpf",
        },
        {
          label: "Nome",
          field: "nome",
        },
        {
          label: "Banco",
          field: "banco",
        },
        {
          label: "Agência",
          field: "agencia",
        },
        {
          label: "Conta",
          field: "conta",
        },
        {
          label: "Tipo Conta",
          field: "tipo_conta",
        },
        {
          label: "Pix",
          field: "pix",
        },
        {
          label: "Master",
          field: "master",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        data &&
        data.dados.map((x) => {
          return {
            cpf: x.cpf,
            banco: x.banco,
            nome: x.nome,
            agencia: x.agencia,
            conta: x.conta,
            tipo_conta: x.tipo_conta,
            pix: x.pix,
            master: x.master,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    onClick={() => onViewClick(x.usuario_id, x.nome)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-eye fa-lg"></i>
                    Detalhes
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [data]);

  const getData = async (pg = 1, name, cpf) => {
    try {
      setData();
      setPage(pg);
      setName(name);
      setCpf(cpf);
      const comissions = await getAgentsComission(
        name,
        cpf,
        page,
        dataInicio,
        dataFim
      );
      setData(comissions);
    } catch (err) {
      toast.error("Falha ao carregar dados!");
    }
  };

  // const onPageChanged = async (pg) => getData(pg, name, cpf);

  const onViewClick = async (id, nome) => {
    setNomeDetalhe(nome);
    setUser(id);
    try {
      const retornoComissoes = await getAgentComissions(
        id,
        dataInicio,
        dataFim
      );
      if (
        (retornoComissoes.consignado && retornoComissoes.consignado.dados) ||
        (retornoComissoes.creditoPessoal &&
          retornoComissoes.creditoPessoal.dados) ||
        (retornoComissoes.cartao && retornoComissoes.cartao.dados) ||
        (retornoComissoes.fgts && retornoComissoes.fgts.dados) ||
        (retornoComissoes.conta_luz && retornoComissoes.conta_luz.dados)
      ) {
        setComissoes(retornoComissoes);
        window.$("#modal-detalhes").modal("show");
      } else {
        toast.info("Nenhuma comissão encontrada para o agente.");
        window.$("#modal-detalhes").modal("hide");
      }
    } catch (err) {
      toast.error("Falha ao carregar dados do agente!");
      window.$("#modal-detalhes").modal("hide");
    }
  };

  const handlePrint = () => {
    let conteudo = document.getElementById("print").innerHTML;
    let docPrint = document.body.innerHTML;
    document.body.innerHTML = conteudo;
    window.print();
    document.body.innerHTML = docPrint;
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    getData(1, name, cpf);
  };

  const onClear = () => {
    window.$("#filtro").val(null);
    window.$("#dataInicio").val(null);
    window.$("#dataFim").val(null);
    window.$("#name").val(null);
    window.$("#cpf").val(null);
    setName(null);
    setNomeDetalhe(null);
    setCpf(null);
    setFiltroData(null);
    setDataInicio(null);
    setDataFim(null);
  };

  const onReset = async () => {
    window.$("#modal-detalhes").modal("hide");
    window.$("#modal-filter").modal("hide");
    //onClear();
  };

  const handleChangeFilter = async (event) => {
    const { value } = event.target;
    const datas = funcoes.filtroOpcoesData(value);
    setDataInicio(datas[0]._d);
    setDataFim(datas[1]._d);
  };

  const handleCsvExports = async () => {
    swal({
      title: "Exportar comissões?",
      text: "Será exportado as comissões dos agentes.",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFormLoading(true);
        const retorno = await exportComissionsAgent(dataInicio, dataFim);
        if (retorno) {
          let dados =
            typeof retorno !== "object" ? JSON.parse(retorno) : retorno;
          let CSV = "sep=," + "\r\n";
          let row = "";
          //Setar o nome do relatorio na primeira linha
          if (dataInicio && dataFim) {
            CSV +=
              `Comissões Agentes entre ${dataInicio} à ${dataFim}` + "\r\n";
          } else {
            CSV += `Comissões Agentes ${moment().format("MMMM YYYY")}` + "\r\n";
          }
          //Este loop irá extrair o rótulo do primeiro índice da matriz
          for (let index in dados[0]) {
            //Agora converta cada valor para string e separado por vírgulas
            row += index + ",";
          }
          row = row.slice(0, -1);
          //anexar linha de rótulo com quebra de linha
          CSV += row + "\r\n";
          //O primeiro loop é extrair cada linha
          for (let i = 0; i < dados.length; i++) {
            let row = "";
            //O 2º ciclo irá extrair cada coluna e convertê-la em string separada por vírgulas
            for (let index in dados[i]) {
              row += '"' + dados[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //adicione uma quebra de linha após cada linha
            CSV += row + "\r\n";
          }

          if (CSV === "") {
            swal("Ocorreu um erro ao exportar as comissôes dos agentes.", {
              icon: "error",
            });
          }

          let nomeArquivo = `Comissoes-Agentes-${moment().format("X")}`;
          let uri = "data:text/csv;charset=utf-8," + escape(CSV);
          let link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = nomeArquivo + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setFormLoading(false);
          swal("Comissão exportada com sucesso.", {
            icon: "success",
          });
        } else {
          setFormLoading(false);
          swal("Ocorreu um erro ao exportar as comissôes dos agentes.", {
            icon: "error",
          });
        }
      } else {
        swal("A comissão não foi exportada.", {
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {formLoading && (
          <Loading />
      )}
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Exportar CSV",
              icone: (
                <i className="fas fa-file-csv" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: handleCsvExports,
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                // onPageChanged={onPageChanged}
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel="Registros por Página"
                hover
                striped={true}
                entriesOptions={[10, 20, 30]}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"sm"}
        form={
            <div>
               <button
               type="button"
               className="close"
               data-dismiss="modal"
               arial-label="close"
               >
               <span arial-hidden="true" className="text-white" onClick={onReset}>
                  x
               </span>
               </button>
               <div className="card mb-4">
                  <div className="card-header">
                     <h3 className="mb-0">Agente</h3>
                  </div>
                  <div className="card-body">
                     <div className="form-group mx-2 mb-3">
                        <div className="input-group">
                           <select
                           id="filtro"
                           className="form-control"
                           placeholder="Filtro..."
                           onChange={handleChangeFilter}
                           value={filtroData}
                           >
                           <option value="">Filtro por período...</option>
                           <option value="hoje">Hoje</option>
                           <option value="ontem">Ontem</option>
                           <option value="7dias">Últimos 7 Dias</option>
                           <option value="mes">Este Mês</option>
                           <option value="ultimo-mes">Último Mês</option>
                           <option value="proximo-mes">Próximo Mês</option>
                           <option value="ano">Este Ano</option>
                           </select>
                        </div>
                     </div>
                     <div className="row mx-2">
                     <div className="form-group mb-3 ml-3">
                        <div className="input-group">
                           <div className="input-group-prepend ">
                           <span className="input-group-text px-2">
                              <i className="ni ni-calendar-grid-58"></i>
                           </span>
                           </div>
                           <DatePicker
                           id="dataInicio"
                           dateFormat="P"
                           locale="pt"
                           className="form-control px-2"
                           placeholderText="Data inicial..."
                           selected={dataInicio}
                           onChange={(date) => setDataInicio(date)}
                           />
                        </div>
                     </div>

                     <div className="form-group mb-3 ml-3">
                        <div className="input-group ">
                           <div className="input-group-prepend">
                           <span className="input-group-text px-2">
                              <i className="ni ni-calendar-grid-58"></i>
                           </span>
                           </div>
                           <DatePicker
                           id="dataFim"
                           dateFormat="P"
                           locale="pt"
                           className="form-control px-2"
                           placeholderText="Data final..."
                           selected={dataFim}
                           onChange={(date) => setDataFim(date)}
                           />
                        </div>
                     </div>
                     </div>
                     <div className="form-group mb-3 ">
                     {/* <div className="input-group "> */}
                     <Input
                        id="name"
                        className="form-control mx-2"
                        placeholder="Nome..."
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value || null)}
                     />
                     {/* </div> */}
                     </div>

                     <div className="form-group mb-3">
                     <Input
                        id="cpf"
                        className="form-control mx-2"
                        placeholder="Cpf..."
                        type="cpf"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value || null)}
                     />
                     </div>

                     <div className="row">
                        <div className="col-md-6 text-center">
                           <button
                              type="button"
                              onClick={onClear}
                              className="btn btn-danger btn-block my-4"
                           >
                              Limpar
                           </button>
                        </div>

                        <div className="col-md-6 text-center">
                           <button
                              type="button"
                              onClick={onFilter}
                              className="btn btn-success btn-block my-4"
                           >
                              Filtrar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        }
      />

      {/* Modal de Detalhes */}
      <Modal
        id={"modal-detalhes"}
        title={"Comissões - " + nomeDetalhe}
        size={"lg"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span arial-hidden="true" className="text-white" onClick={onReset}>
                x
              </span>
            </button>
            <a href="" type="button" onClick={handlePrint}>
              <i className="fas fa-print"></i>
            </a>
            {comissoes &&
              comissoes.consignado &&
              comissoes.consignado.dados && (
                <TableList
                  comissaoProdutoId={comissoes.consignado.produto.produto_id}
                  produto={comissoes.consignado.produto}
                  dados={comissoes.consignado.dados}
                  usuario={user}
                  dataInicio={dataInicio}
                  dataFim={dataFim}
                />
              )}
            {comissoes &&
              comissoes.creditoPessoal &&
              comissoes.creditoPessoal.dados && (
                <TableList
                  comissaoProdutoId={
                    comissoes.creditoPessoal.produto.produto_id
                  }
                  produto={comissoes.creditoPessoal.produto}
                  dados={comissoes.creditoPessoal.dados}
                  usuario={user}
                  dataInicio={dataInicio}
                />
              )}
            {comissoes && comissoes.cartao && comissoes.cartao.dados && (
              <TableList
                comissaoProdutoId={comissoes.cartao.produto.produto_id}
                produto={comissoes.cartao.produto}
                dados={comissoes.cartao.dados}
                usuario={user}
                dataInicio={dataInicio}
                dataFim={dataFim}
              />
            )}
            {comissoes && comissoes.fgts && comissoes.fgts.dados && (
              <TableList
                comissaoProdutoId={comissoes.fgts.produto.produto_id}
                produto={
                  comissoes.fgts && comissoes.fgts.produto
                    ? comissoes.fgts.produto
                    : ""
                }
                dados={
                  comissoes.fgts && comissoes.fgts.dados
                    ? comissoes.fgts.dados
                    : []
                }
                usuario={user}
                dataInicio={dataInicio}
                dataFim={dataFim}
              />
            )}

            {comissoes && comissoes.conta_luz && (
              <TableList
                comissaoProdutoId={
                  comissoes.conta_luz && comissoes.conta_luz.produto
                    ? comissoes.conta_luz.produto.produto_id
                    : 7
                }
                produto={
                  comissoes.conta_luz && comissoes.conta_luz.produto
                    ? comissoes.conta_luz.produto
                    : ""
                }
                dados={
                  comissoes.conta_luz && comissoes.conta_luz.dados
                    ? comissoes.conta_luz.dados
                    : []
                }
                somatoria={funcoes.somarComissoesAgente(comissoes)}
                usuario={user}
                dataInicio={dataInicio}
                dataFim={dataFim}
              />
            )}
            <div className="row">
              <div
                className="col-md-6 text-center"
                style={{ margin: "0 auto" }}
              >
                <button
                  type="button"
                  onClick={onReset}
                  className="btn btn-danger btn-block"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/*Impressao*/}
      <div id="print" style={{ display: "none", marginTop: "4rem" }}>
        <img
          src="../../assets/img/brand/blue.png"
          className="img-print"
          alt="..."
          id="logo"
          width="200px"
        />
        {comissoes && comissoes.consignado && comissoes.consignado.dados && (
          <TableList
            produto={comissoes.consignado.produto}
            dados={comissoes.consignado.dados}
            usuario={user}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        )}
        {comissoes &&
          comissoes.creditoPessoal &&
          comissoes.creditoPessoal.dados && (
            <TableList
              produto={comissoes.creditoPessoal.produto}
              dados={comissoes.creditoPessoal.dados}
              usuario={user}
              dataInicio={dataInicio}
            />
          )}
        {comissoes && comissoes.cartao && comissoes.cartao.dados && (
          <TableList
            produto={comissoes.cartao.produto}
            dados={comissoes.cartao.dados}
            usuario={user}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        )}
        {comissoes && comissoes.fgts && (
          <TableList
            produto={
              comissoes.fgts && comissoes.fgts.produto
                ? comissoes.fgts.produto
                : ""
            }
            dados={
              comissoes.fgts && comissoes.fgts.dados ? comissoes.fgts.dados : []
            }
            usuario={user}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        )}

        {comissoes && comissoes.conta_luz && (
          <TableList
            produto={
              comissoes.conta_luz && comissoes.conta_luz.produto
                ? comissoes.conta_luz.produto
                : ""
            }
            dados={
              comissoes.conta_luz && comissoes.conta_luz.dados
                ? comissoes.conta_luz.dados
                : []
            }
            somatoria={funcoes.somarComissoesAgente(comissoes)}
            usuario={user}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Commission;
