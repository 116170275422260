import React, { useState, useEffect, useRef } from "react";
import {
   listarSolicitacoes,
   listarStatusSolicitacao,
   buscarConfiguracaoMailing,
   listarSegmentosCnae,
   buscarSolicitacao,
   gravarSolicitacao,
   gravarAtendimento,
   cancelarSolicitacao,
   verificarDocumentoProspect,
} from "../../services/crm/Solicitacao";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Menu from "../../components/sidenav/Menu";
import Input from "../../components/input/Input";
import TopMenu from "../../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/modal/Modal";
import Select from "react-select";
import Loading from "../../components/loading/Loading";
import styles from "./style.module.css";
import { MDBDataTableV5 } from "mdbreact";
import funcoes from "../../utils/helpers/funcoes";
import moment from "moment";
import "moment/locale/pt-br";
import swal from "sweetalert";

moment.locale("pt-br");

function CrmSolicitacao() {
   //Dados do formulário:
   const [fullLoading, setFullLoading] = useState(false);
   const [data, setData] = useState(null);
   const [dataCadastroSol, setDataCadastroSol] = useState(null);
   const [codigoUsuSol, setCodigoUsuSol] = useState(null);
   const [qtdeSolicitadaSol, setQtdeSolicitadaSol] = useState(null);
   const [qtdeLiberadaSol, setQtdeLiberadaSol] = useState(null);
   const [codigoSts, setCodigoSts] = useState(null);
   const [dataAtendimentoSol, setDataAtendimentoSol] = useState(null);
   const [register, setRegister] = useState({});
   const [status, setStatus] = useState([]);
   const [segmentos, setSegmentos] = useState([]);
   const [errors, setErrors] = useState({});
   const [page, setPage] = useState(1);

   const selectStatus = useRef();
   const selectSegmentos = useRef();

   const tipoCliente = [
      {
         label: "Cliente Correntista não FOPA",
         value: "Cliente Correntista não FOPA",
      },
      { label: "Cliente Correntista FOPA", value: "Cliente Correntista FOPA" },
      { label: "Não Correntista", value: "Não Correntista" },
   ];

   useEffect(() => {
      getData(
         dataCadastroSol,
         codigoUsuSol,
         qtdeSolicitadaSol,
         qtdeLiberadaSol,
         codigoSts,
         dataAtendimentoSol,
         page
      );

      window.$("#modal-data").on("hidden.bs.modal", function () {
         setRegister({});
         setErrors({});
         setStatus([]);
      });

      window.$("#modal-atendimento").on("hidden.bs.modal", function () {
         setRegister({});
         setErrors({});
         setStatus([]);
      });
   }, []);

   const getData = async (
      dataCadastro,
      codigoUsu,
      qtdeSolicitada,
      qtdeLiberada,
      codigoStsSol,
      dataAtendimento,
      pg = 1
   ) => {
      try {
         setData(null);
         setPage(pg);
         setDataCadastroSol(dataCadastro);
         setCodigoUsuSol(codigoUsu);
         setQtdeSolicitadaSol(qtdeSolicitada);
         setQtdeLiberadaSol(qtdeLiberada);
         setCodigoSts(codigoStsSol);
         setDataAtendimentoSol(dataAtendimento);
         const response = await listarSolicitacoes(
            dataCadastroSol,
            codigoUsuSol,
            qtdeSolicitadaSol,
            qtdeLiberadaSol,
            codigoSts,
            dataAtendimentoSol,
            page
         );

         if (response.data.retorno) {
            setData(response.data);
         } else {
            toast.error(
               response.data.mensagem
                  ? response.data.mensagem
                  : "Falha ao carregar dados!"
            );
            setData(null);
         }
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async (pg) => getData(pg);

   const editar = async (codigo_sol, codigo_usu) => {
      try {
         setFullLoading(true);
         const response = await buscarSolicitacao(codigo_sol, codigo_usu);
         const responseStatus = await listarStatusSolicitacao();
         const responseSegmentos = await listarSegmentosCnae();
         if (responseStatus.retorno) {
            let arrStatus = [];
            responseStatus.dados.map((x) => {
               if (parseInt(x.value) !== 2) {
                  arrStatus.push({ ...x });
               }
            });

            setStatus(arrStatus);
         }

         if (responseSegmentos.retorno) {
            setSegmentos(responseSegmentos.dados);
         }

         if (response.retorno) {
            let filtros = response.dados[0].filtro_sol;
            filtros.map((x) => {
               if (
                  x.id === "margemmin_pro" ||
                  x.id === "margemmax_pro" ||
                  x.id === "valorparcelamin_pro" ||
                  x.id === "valorparcelamax_pro" ||
                  x.id === "saldodevedormin_pro" ||
                  (x.id === "saldodevedormax_pro" && x.valor)
               ) {
                  x.valor = funcoes.formatarMoedaMilhar(x.valor);
               }
            });
            setErrors({
               documento_pro:
                  response.dados[0].documentoexiste_sol === false
                     ? `O ${
                          response.dados[0].codigo_pro == 3
                             ? "CNPJ"
                             : "Convênio"
                       } não foi encontrado na base.`
                     : null,
            });

            let dados = response.dados[0];
            if (dados.codigo_sts == 6) {
               dados.codigo_sts = 4;
               dados.nome_sts = "EM ATENDIMENTO";
            }

            filtros.find((x) => x.id === "cidade_pro").valor =
               exibirCidades(filtros);

            setRegister({ ...dados, filtro_sol: filtros });
            window.$("#modal-data").modal("show");
         } else {
            toast.warn(response.mensagem);
            window.$("#modal-data").modal("hide");
         }
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
         console.warn(err.message);
         window.$("#modal-data").modal("hide");
      } finally {
         setFullLoading(false);
         window.$("#modal-data").modal("hide");
      }
   };

   const cancelar = async (codigo_sol) => {
      try {
         swal({
            title: "Cancelar a solicitação?",
            text: `A solicitação: ${codigo_sol} será cancelada, tem certeza?`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
         }).then(async (response) => {
            if (response) {
               setFullLoading(true);
               const retorno = await cancelarSolicitacao(codigo_sol);
               if (retorno.retorno) {
                  setFullLoading(false);
                  await getData(
                     dataCadastroSol,
                     codigoUsuSol,
                     qtdeSolicitadaSol,
                     qtdeLiberadaSol,
                     codigoSts,
                     dataAtendimentoSol,
                     page
                  );
                  swal(retorno.mensagem, {
                     icon: "success",
                  });
               } else {
                  setFullLoading(false);
                  swal(retorno.mensagem, {
                     icon: "error",
                  });
               }
            } else {
               setFullLoading(false);
               swal("A solicitação não foi cancelada.", {
                  icon: "info",
               });
            }
         });
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
      } finally {
         setFullLoading(false);
      }
   };

   const atender = async (codigo_sol, codigo_usu) => {
      try {
         setFullLoading(true);
         const response = await buscarSolicitacao(codigo_sol, codigo_usu);
         const responseStatus = await listarStatusSolicitacao();
         const responseConfig = await buscarConfiguracaoMailing();
         let prazo = 15;

         if (responseStatus.retorno) {
            let arrStatus = [];
            responseStatus.dados.map((x) => {
               if (parseInt(x.value) !== 2) {
                  arrStatus.push({ ...x });
               }
            });

            setStatus(arrStatus);
         }

         if (responseConfig.retorno) {
            prazo = responseConfig.dados[0].prazomax_mcf;
         }

         if (response.retorno) {
            setRegister({
               ...response.dados[0],
               dataatendimento_sol: moment().format("DD/MM/YYYY HH:mm:ss"),
               prazoatendimento_sol: moment()
                  .add(prazo, "days")
                  .format("DD/MM/YYYY HH:mm:ss"),
            });
            window.$("#modal-atendimento").modal("show");
         } else {
            toast.warn(response.mensagem);
            window.$("#modal-atendimento").modal("hide");
         }
      } catch (err) {
         setFullLoading(false);
         toast.error(err.message);
         console.warn(err.message);
         window.$("#modal-atendimento").modal("hide");
      } finally {
         setFullLoading(false);
         window.$("#modal-atendimento").modal("hide");
      }
   };

   function handleChange(e) {
      let { id, value } = e.target;
      if (id.indexOf("_atendimento") >= 0) {
         id = id.replace("_atendimento", "");
      }

      switch (id) {
         default:
            setErrors((prevErrors) => ({
               ...prevErrors,
               [id]: value ? null : "Campo obrigatório",
            }));
            setRegister({ ...register, [id]: value });
            break;
      }
   }

   async function handleSave(event) {
      event.preventDefault();

      try {
         setFullLoading(true);
         const response = await gravarSolicitacao(register);
         if (response.retorno) {
            await getData(
               dataCadastroSol,
               codigoUsuSol,
               qtdeSolicitadaSol,
               qtdeLiberadaSol,
               codigoSts,
               dataAtendimentoSol,
               page
            );
            toast.success(response.mensagem);
            window.$("#modal-data").modal("hide");
         } else {
            toast.warn(response.mensagem);
         }
      } catch (err) {
         setFullLoading(false);
      } finally {
         setFullLoading(false);
      }
   }

   async function handleSaveAtendimento(event) {
      event.preventDefault();

      try {
         setFullLoading(true);
         const response = await gravarAtendimento(register);

         if (response.retorno) {
            await getData(
               dataCadastroSol,
               codigoUsuSol,
               qtdeSolicitadaSol,
               qtdeLiberadaSol,
               codigoSts,
               dataAtendimentoSol,
               page
            );
            toast.success("Atendimento gravado com sucesso.");
            window.$("#modal-atendimento").modal("hide");
         } else {
            toast.warn(response.mensagem);
         }
      } catch (err) {
         setFullLoading(false);
      } finally {
         setFullLoading(false);
      }
   }

   function handleChangeFiltro(e) {
      const { id, value } = e.target;
      let filtro_sol = register.filtro_sol;
      let v = value;

      switch (id) {
         case "documento_pro":
            if (filtro_sol && filtro_sol.length > 0) {
               filtro_sol.find((x) => x.id === id).valor =
                  parseInt(register.codigo_pro) === 3
                     ? funcoes.mascaraCpfCnpj(value)
                     : value;
            }
            setRegister({ ...register, filtro_sol });
            setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
            break;
         case "margemmin_pro":
         case "margemmax_pro":
         case "valorparcelamin_pro":
         case "valorparcelamax_pro":
         case "saldodevedormin_pro":
         case "saldodevedormax_pro":
            if (filtro_sol && filtro_sol.length > 0) {
               if (v && v !== null) {
                  v = funcoes.formatarMoedaMilhar(v);
                  filtro_sol.find((x) => x.id === id).valor = v;
               }
            }
            setRegister({ ...register, filtro_sol });
            break;
         case "taxaammin_pro":
         case "taxaammax_pro":
            if (filtro_sol && filtro_sol.length > 0) {
               if (v && v !== null) {
                  v = v.replace(/\D/g, "");
                  if (parseFloat(v) / 100 <= 100) {
                     v = (v / 100).toFixed(2) + "";
                  }
                  filtro_sol.find((x) => x.id === id).valor = v;
               }
            }
            setRegister({ ...register, filtro_sol });
            break;
         default:
            if (filtro_sol && filtro_sol.length > 0) {
               filtro_sol.find((x) => x.id === id).valor = value;
            }
            setRegister({ ...register, filtro_sol });
            break;
      }
   }

   async function handleBlurFiltro(e) {
      const { id, value } = e.target;

      switch (id) {
         case "documento_pro":
            if (value) {
               try {
                  setFullLoading(true);
                  const response = await verificarDocumentoProspect(
                     register.codigo_pro,
                     value
                  );
                  if (response.retorno && response.dados) {
                     setRegister({ ...register, documentoexiste_sol: true });
                  } else {
                     setRegister({ ...register, documentoexiste_sol: false });
                     setErrors({
                        documento_pro: `O ${
                           register.codigo_pro == 3 ? "CNPJ" : "Convênio"
                        } não foi encontrado na base.`,
                     });
                  }
               } catch (err) {
                  toast.error(err.message);
                  setFullLoading(false);
               } finally {
                  setFullLoading(false);
               }
            }
            break;
      }
   }

   function exibirCidades(filtros) {
      const retornoCidade1 =
         filtros && filtros.length > 0
            ? filtros.find((x) => x.id === "cidade_pro").valor
               ? filtros.find((x) => x.id === "cidade_pro").valor + ";"
               : ""
            : "";
      const retornoCidade2 =
         filtros && filtros.length > 0
            ? filtros.find((x) => x.id === "cidade1_pro").valor
               ? filtros.find((x) => x.id === "cidade1_pro").valor + ";"
               : ""
            : "";
      const retornoCidade3 =
         filtros && filtros.length > 0
            ? filtros.find((x) => x.id === "cidade2_pro").valor
               ? filtros.find((x) => x.id === "cidade2_pro").valor
               : ""
            : "";
      return retornoCidade1 + retornoCidade2 + retornoCidade3;
   }

   const datatable = {
      columns: [
         {
            label: "Id",
            field: "codigo_sol",
         },
         {
            label: "Data Cadastro",
            field: "datacadastro_sol",
         },
         {
            label: "Solicitante / Sistema",
            field: "codigo_usu",
         },
         {
            label: "Qt. Liberada",
            field: "qtdeliberada_sol",
         },
         {
            label: "Qt. Solicitada",
            field: "qtdesolicitada_sol",
         },
         {
            label: "Status",
            field: "nome_sts",
         },
         {
            label: "Data Atendimento",
            field: "dataatendimento_sol",
         },
         {
            label: "Ação",
            field: "action",
         },
      ],
      rows:
         data &&
         data.dados.map((x) => {
            return {
               codigo_sol: x.codigo_sol,
               datacadastro_sol: x.datacadastro_sol,
               codigo_usu: `${x.codigo_usu} - ${x.nome_sis}`,
               qtdeliberada_sol: x.qtdeliberada_sol,
               qtdesolicitada_sol: x.qtdesolicitada_sol,
               nome_sts: (
                  <span className="badge badge-dot mr-4">
                     <i
                        className={`bg-${funcoes.retornarStatusSolicitacao(
                           x.codigo_sts
                        )}`}
                     ></i>
                     <span className="status">{x.nome_sts}</span>
                  </span>
               ),
               dataatendimento_sol: x.dataatendimento_sol,
               action: (
                  <div className="dropdown">
                     <a
                        className="btn btn-sm btn-icon-only text-light"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <i className="fas fa-ellipsis-v" />
                     </a>
                     <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <button
                           type="button"
                           className="dropdown-item"
                           onClick={() => editar(x.codigo_sol, x.codigo_usu)}
                        >
                           <i className="fas fa-eye"></i>
                           Visualizar
                        </button>

                        {x.codigo_sts === 1 && (
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                 atender(x.codigo_sol, x.codigo_usu)
                              }
                           >
                              <i className="fas fa-calendar"></i>
                              Realizar Atendimento
                           </button>
                        )}

                        {[1, 4, 6].includes(x.codigo_sts) && (
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() => cancelar(x.codigo_sol)}
                           >
                              <i className="fas fa-close"></i>
                              Cancelar
                           </button>
                        )}
                     </div>
                  </div>
               ),
            };
         }),
   };

   return (
      <div>
         {fullLoading && <Loading />}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Filtrar",
                     icone: (
                        <i
                           className="fas fa-search"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>

         {/* Modal de Dados */}
         <Modal
            id={"modal-data"}
            title={"Solicitação Mailing"}
            size={"xl"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        // onClick={onClear}
                     >
                        x
                     </span>
                  </button>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados Mailing</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-7">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Descrição*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="descricao_sol"
                                       placeholder="Informe descrição..."
                                       value={register.descricao_sol || ""}
                                       helpType="error"
                                       help={errors.descricao_sol || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-5">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Solicitante / Sistema*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="codigo_usu"
                                       placeholder="Informe o usuário..."
                                       value={
                                          register.codigo_usu +
                                             " / " +
                                             register.nome_sis || ""
                                       }
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Cadastro*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="datacadastro_sol"
                                       placeholder="Informe a data de cadastro..."
                                       value={register.datacadastro_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Produto Mailing*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="nome_pro"
                                       placeholder="Informe o produto..."
                                       value={register.nome_pro || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Status*
                                 </label>
                                 <Select
                                    id="codigo_sts"
                                    ref={selectStatus}
                                    className={`${styles.padding} form-control-select`}
                                    placeholder="Selecione o status da solicitação..."
                                    value={{
                                       label: register.nome_sts
                                          ? register.nome_sts
                                          : "Selecione o status da solicitação...",
                                    }}
                                    onChange={(e) => {
                                       if (e && e.value) {
                                          setRegister({
                                             ...register,
                                             codigo_sts: e.value,
                                             nome_sts: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             codigo_sts: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             codigo_sts: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={status}
                                    isDisabled={true}
                                    backspaceRemovesValue
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Solicitada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdesolicitada_sol"
                                       placeholder="Informe o quantidade solicitada..."
                                       value={register.qtdesolicitada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Liberada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdeliberada_sol"
                                       placeholder="Informe o quantidade liberada..."
                                       value={register.qtdeliberada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Solicitação*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacao_sol"
                                       className="form-control"
                                       aria-label="Observação Solicitação"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacao_sol: e.value,
                                          });
                                       }}
                                       value={register.observacao_sol}
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Atendimento</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="dataatendimento_sol"
                                       placeholder="Informe a data de atendimento..."
                                       value={
                                          register.dataatendimento_sol || ""
                                       }
                                       helpType="error"
                                       help={errors.dataatendimento_sol || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Prazo Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="prazoatendimento_sol"
                                       placeholder="Informe o prazo de atendimento..."
                                       value={
                                          register.prazoatendimento_sol || ""
                                       }
                                       helpType="error"
                                       help={errors.prazoatendimento_sol || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacaoatendimento_sol"
                                       className="form-control"
                                       aria-label="Observação Atendimento"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacaoatendimento_sol:
                                                e.target.value,
                                          });
                                       }}
                                       value={
                                          register.observacaoatendimento_sol
                                       }
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Filtros Solicitação</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    CNPJ / Nº Convênio*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="documento_pro"
                                       placeholder="Informe documento..."
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) =>
                                                     x.id === "documento_pro"
                                               ).valor
                                             : ""
                                       }
                                       helpType="error"
                                       help={errors.documento_pro}
                                       onChange={handleChangeFiltro}
                                       onBlur={handleBlurFiltro}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-5">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Razão Social / Nome Convênio*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="razaoconvenio_pro"
                                       placeholder="Informe a razão ou nome convênio..."
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) =>
                                                     x.id ===
                                                     "razaoconvenio_pro"
                                               ).valor
                                             : ""
                                       }
                                       helpType="error"
                                       onChange={handleChangeFiltro}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-3">
                              <div className="form-group">
                                 <label className="form-control-label">Processar a solicitação no cp ?</label>
                                 <div className="custom-control custom-checkbox">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="processarcp_sol"
                                       onChange={(e) => {
                                          setRegister({ ...register, [e.target.id]: !register.processarcp_sol })
                                       }}
                                       checked={register.processarcp_sol}
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="processarcp_sol"
                                    >
                                       Sim
                                    </label>
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-4 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    DDD Range*
                                 </label>
                                 <div className="input-group">
                                    <input
                                       id="dddmin_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="DDD Inicial"
                                       placeholder="Ex: 11"
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "dddmin_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                    />
                                    <div className="input-group-prepend">
                                       <span className="input-group-text border-right-0">
                                          e
                                       </span>
                                    </div>
                                    <input
                                       id="dddmax_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="DDD Final"
                                       placeholder="Ex: 18"
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "dddmax_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-4 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Idade Range*
                                 </label>
                                 <div className="input-group">
                                    <input
                                       id="idademin_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="Idade Inicial"
                                       placeholder="Ex: 11"
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "idademin_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                    />
                                    <div className="input-group-prepend">
                                       <span className="input-group-text border-right-0">
                                          e
                                       </span>
                                    </div>
                                    <input
                                       id="idademax_pro"
                                       type="number"
                                       className="form-control"
                                       aria-label="Idade Final"
                                       placeholder="Ex: 18"
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "idademax_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                    />
                                 </div>
                              </div>
                           </div>
                           {register.codigo_pro != 3 && (
                              <>
                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Margem Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="margemmin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Margem Inicial"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "margemmin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="margemmax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Margem Final"
                                             placeholder="Ex: 180,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "margemmax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Pagas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelapagamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelapagamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelapagamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelapagamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Vencidas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelavencidamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelavencidamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelavencidamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelavencidamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Parcelas Em Aberto Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelaemabertomin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelaemabertomin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelaemabertomax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelaemabertomax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Qtd. Total De Parcelas Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="qtdeparcelamin_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd. Inicial"
                                             placeholder="Ex: 11"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelamin_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="qtdeparcelamax_pro"
                                             type="number"
                                             className="form-control"
                                             aria-label="Qtd Final"
                                             placeholder="Ex: 18"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "qtdeparcelamax_pro"
                                                     ).valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Valor Parcela Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="valorparcelamin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o valor da parcela"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "valorparcelamin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="valorparcelamax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o valor da parcela"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "valorparcelamax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Saldo Devedor Range*
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="saldodevedormin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o saldo devedor"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "saldodevedormin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="saldodevedormax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe o saldo devedor máximo"
                                             placeholder="Ex: 110,00"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "saldodevedormax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-lg-4 col-sm-6">
                                    <div className="form-group">
                                       <label className="form-control-label">
                                          Taxa Am% Range *
                                       </label>
                                       <div className="input-group">
                                          <input
                                             id="taxaammin_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe a taxa"
                                             placeholder="Ex: 1.20"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "taxaammin_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             maxLength={5}
                                             onChange={handleChangeFiltro}
                                          />
                                          <div className="input-group-prepend">
                                             <span className="input-group-text border-right-0">
                                                e
                                             </span>
                                          </div>
                                          <input
                                             id="taxaammax_pro"
                                             type="tel"
                                             className="form-control"
                                             aria-label="Informe a taxa"
                                             placeholder="Ex: 1.20"
                                             value={
                                                register.filtro_sol &&
                                                register.filtro_sol.length > 0
                                                   ? register.filtro_sol.find(
                                                        (x) =>
                                                           x.id ===
                                                           "taxaammax_pro"
                                                     )?.valor
                                                   : ""
                                             }
                                             maxLength={5}
                                             onChange={handleChangeFiltro}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )}

                           <div className="col-lg-2 col-sm-2">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    UF*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="uf_pro"
                                       placeholder="UF..."
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "uf_pro"
                                               ).valor
                                             : ""
                                       }
                                       maxLength={2}
                                       onChange={handleChangeFiltro}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-lg-6 col-sm-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Cidade*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="cidade_pro"
                                       placeholder="Nome ou parte do nome separado por ';' em caso de múltiplas cidades, máx: 3 cidades"
                                       value={
                                          register.filtro_sol &&
                                          register.filtro_sol.length > 0
                                             ? register.filtro_sol.find(
                                                  (x) => x.id === "cidade_pro"
                                               ).valor
                                             : ""
                                       }
                                       onChange={handleChangeFiltro}
                                    />
                                 </div>
                              </div>
                           </div>
                           {register.codigo_pro == 3 && (
                              <div className="col-lg-6 col-sm-6">
                                 <div className="form-group">
                                    <label className="form-control-label">
                                       Segmentos Cnae*
                                    </label>
                                    <Select
                                       label="Selecione o tipo do cliente"
                                       id="codigo_sgc"
                                       placeholder="Selecione o tipo do cliente...."
                                       options={segmentos}
                                       onChange={(e) => {
                                          if (e && e.value) {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "codigo_sgc",
                                                   value: e.value,
                                                },
                                             });
                                          } else {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "codigo_sgc",
                                                   value: null,
                                                },
                                             });
                                          }
                                       }}
                                       value={{
                                          label:
                                             register.filtro_sol.find(
                                                (y) => y.id === "codigo_sgc"
                                             ).valor &&
                                             segmentos &&
                                             segmentos.find(
                                                (x) =>
                                                   x.value ===
                                                   register.filtro_sol.find(
                                                      (y) =>
                                                         y.id === "codigo_sgc"
                                                   ).valor
                                             ).label,
                                       }}
                                       className={`${styles.padding} form-control-select`}
                                       isClearable={true}
                                    />
                                 </div>
                              </div>
                           )}

                           {register.codigo_pro != 3 && (
                              <div className="col-lg-6 col-sm-6">
                                 <div className="form-group">
                                    <label className="form-control-label">
                                       Tipo Cliente*
                                    </label>
                                    <Select
                                       label="Selecione o tipo do cliente"
                                       id="tipocliente_pro"
                                       placeholder="Selecione o tipo do cliente...."
                                       options={tipoCliente}
                                       onChange={(e) => {
                                          if (e && e.value) {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "tipocliente_pro",
                                                   value: e.value,
                                                },
                                             });
                                          } else {
                                             handleChangeFiltro({
                                                target: {
                                                   id: "tipocliente_pro",
                                                   value: null,
                                                },
                                             });
                                          }
                                       }}
                                       value={{
                                          label:
                                             register.filtro_sol &&
                                             register.filtro_sol.length > 0
                                                ? register.filtro_sol.find(
                                                     (x) =>
                                                        x.id ===
                                                        "tipocliente_pro"
                                                  ).valor
                                                : "",
                                       }}
                                       className={`${styles.padding} form-control-select`}
                                       isClearable={true}
                                    />
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente justify-content-center">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Cancelar
                        </button>
                     </div>
                     {register && parseInt(register.codigo_sts) === 4 && (
                        <div className="col-md-6 text-center">
                           <button
                              type="submit"
                              className="btn btn-success btn-block"
                              onClick={handleSave}
                              // disabled={disabledBtnSubmit}
                           >
                              <i className="far fa-save fa-lg iconDownload"></i>
                              Salvar
                           </button>
                        </div>
                     )}
                  </div>
               </div>
            }
         />

         {/* Modal de Atendimento */}
         <Modal
            id={"modal-atendimento"}
            title={"Atendimento Solicitação"}
            size={"lg"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span arial-hidden="true" className="text-white">
                        x
                     </span>
                  </button>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados Solicitação</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-7">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Descrição*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="descricao_sol_atendimento"
                                       placeholder="Informe descrição..."
                                       value={register.descricao_sol || ""}
                                       helpType="error"
                                       help={errors.descricao_sol || ""}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-5">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Solicitante / Sistema*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="codigo_usu_atendimento"
                                       placeholder="Informe o usuário..."
                                       value={
                                          register.codigo_usu +
                                             " / " +
                                             register.nome_sis || ""
                                       }
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Cadastro*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="datacadastro_sol_atendimento"
                                       placeholder="Informe a data de cadastro..."
                                       value={register.datacadastro_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Produto Mailing*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="text"
                                       className="form-control"
                                       id="nome_pro_atendimento"
                                       placeholder="Informe o produto..."
                                       value={register.nome_pro || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Status*
                                 </label>
                                 <Select
                                    id="codigo_sts_atendimento"
                                    ref={selectStatus}
                                    className={`${styles.padding} form-control-select`}
                                    placeholder="Selecione o status da solicitação..."
                                    value={{
                                       label: register.nome_sts
                                          ? register.nome_sts
                                          : "Selecione o status da solicitação...",
                                    }}
                                    onChange={(e) => {
                                       if (e && e.value) {
                                          setRegister({
                                             ...register,
                                             codigo_sts: e.value,
                                             nome_sts: e.label,
                                          });
                                          setErrors({
                                             ...errors,
                                             codigo_sts: null,
                                          });
                                       } else {
                                          setErrors({
                                             ...errors,
                                             codigo_sts: "Campo Obrigatório",
                                          });
                                       }
                                    }}
                                    options={status}
                                    backspaceRemovesValue
                                    isDisabled={true}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Solicitada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdesolicitada_sol_atendimento"
                                       placeholder="Informe o quantidade solicitada..."
                                       value={register.qtdesolicitada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Quantidade Liberada*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <Input
                                       type="number"
                                       className="form-control"
                                       id="qtdeliberada_sol_atendimento"
                                       placeholder="Informe o quantidade liberada..."
                                       value={register.qtdeliberada_sol || ""}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Solicitação*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacao_sol_atendimento"
                                       className="form-control"
                                       aria-label="Observação Solicitação"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacao_sol: e.target.value,
                                          });
                                       }}
                                       value={register.observacao_sol}
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Dados do Atendimento</h3>
                     </div>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Data Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="dataatendimento_sol_atendimento"
                                       placeholder="Informe a data de atendimento..."
                                       value={
                                          register.dataatendimento_sol || ""
                                       }
                                       helpType="error"
                                       help={errors.dataatendimento_sol || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Prazo Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <div className="input-group-prepend">
                                       <span className="input-group-text">
                                          <i className="ni ni-calendar-grid-58"></i>
                                       </span>
                                    </div>
                                    <Input
                                       type="birthday"
                                       className="form-control"
                                       id="prazoatendimento_sol_atendimento"
                                       placeholder="Informe o prazo de atendimento..."
                                       value={
                                          register.prazoatendimento_sol || ""
                                       }
                                       helpType="error"
                                       help={errors.prazoatendimento_sol || ""}
                                       onChange={handleChange}
                                       readonly="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="form-control-label">
                                    Observação Atendimento*
                                 </label>
                                 <div className="input-group input-group-merge">
                                    <textarea
                                       id="observacaoatendimento_sol_atendimento"
                                       className="form-control"
                                       aria-label="Observação Atendimento"
                                       rows="4"
                                       onChange={(e) => {
                                          setRegister({
                                             ...register,
                                             observacaoatendimento_sol:
                                                e.target.value,
                                          });
                                       }}
                                       value={
                                          register.observacaoatendimento_sol
                                       }
                                    ></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row divBtnInfoAgente">
                     <div className="col-md-6 text-center">
                        <button
                           type="button"
                           className="btn btn-danger btn-block"
                           data-dismiss="modal"
                        >
                           Cancelar
                        </button>
                     </div>
                     <div className="col-md-6 text-center">
                        <button
                           type="submit"
                           className="btn btn-success btn-block"
                           onClick={handleSaveAtendimento}
                           // disabled={disabledBtnSubmit}
                        >
                           <i className="far fa-save fa-lg iconDownload"></i>
                           Salvar
                        </button>
                     </div>
                  </div>
               </div>
            }
         />

         <ToastContainer />
      </div>
   );
}

export default CrmSolicitacao;
