import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./style.module.css";

//api
import {
  getFunctionsUsers,
  getPermissionForFunction,
  getPermissions,
  gravarPermissoes,
} from "../../../services/Users";

//Compomentes utilizados
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import TopMenu from "../../../components/topMenu/TopMenu";
import Loading from "../../../components/loading/Loading";
import Select from "react-select";

function Permissoes() {
  const [funcoes, setFuncoes] = useState(null);
  const [funcaoEscolhida, setFuncaoEscolhida] = useState(null);
  const [todasPermissoes, setTodasPermissoes] = useState(null);
  const [permissaoFuncao, setPermissaoFuncao] = useState([]);
  const [showLoading, setShowLoading] = useState(false);


  useEffect(() => {
    async function buscarFuncoes() {
      try {
        setShowLoading(true);

        const responseFunction = await getFunctionsUsers();
        const responsePermission = await getPermissions();

        setTodasPermissoes(responsePermission);
        setFuncoes(responseFunction);
      } catch (error) {
        toast.error("Erro ao carregar as funções/permissões.");
      } finally {
        setShowLoading(false);
      }
    }
    buscarFuncoes();
  }, []);

  useEffect(() => {
    if (funcaoEscolhida) {
      buscarPermissoes();
    } else {
      document.getElementsByClassName("btn-success")[0].disabled = true;
    }
  }, [funcaoEscolhida]);
  async function buscarPermissoes() {
    try {
      setShowLoading(true);

      let response = await getPermissionForFunction(funcaoEscolhida);
      let checks = document.getElementsByClassName("custom-control-input");
      for (let i = 0; i < checks.length; i++) {
        checks[i].checked = false;
        checks[i].disabled = false;
      }
      document.getElementsByClassName("btn-success")[0].disabled = false;

      let auxArray = [];

      response.map((e) => {
        auxArray = [...auxArray, e.permissao_id];
        document.getElementById(e.permissao_id).checked = true;
      });
      // response.forEach((e) => {
      //   auxArray = [...auxArray, e.permissao_id];
      //   document.getElementById(e.permissao_id).checked = true;
      // });
      setPermissaoFuncao(auxArray);
    } catch (error) {
      toast.error("Erro ao carregar as permissões da função escolhida.");
    } finally {
      setShowLoading(false);
    }
  }

  const handleChoice = (e) => {
      if (funcoes) {
        const escolha = e.label;
        funcoes.map((x) => {
          if (escolha === x.label) {
            setFuncaoEscolhida(x.id);
          }
        });

    } else {
      setFuncaoEscolhida(null);
      let checks = document.getElementsByClassName("custom-control-input");
      document.getElementsByClassName("btn-success")[0].disabled = true;
      for (let i = 0; i < checks.length; i++) {
        checks[i].checked = false;
        checks[i].disabled = true;
      }
    }
  };

  const handleCheck = (e) => {
    let check = e.target;
    let pos = null;

    pos = permissaoFuncao
      .map(function (e) {
        return e;
      })
      .indexOf(parseInt(check.id));

    if (pos === 0) {
      permissaoFuncao.shift();
    } else if (pos && pos > 0) {
      permissaoFuncao.splice(pos, 1);
    } else if (pos && pos === -1) {
      setPermissaoFuncao([...permissaoFuncao, parseInt(check.id)]);
    }
  };

  const handleSave = async () => {
    const permissoesInJson = JSON.stringify(permissaoFuncao);

    try {
      setShowLoading(true);
      await gravarPermissoes(funcaoEscolhida, permissoesInJson);
      toast.success("Permissões salvas com sucesso!");
    } catch (error) {
      toast.error("Ocorreu um erro ao salvar as permissões.");
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <div>
      {showLoading && (
        <div className={styles.containerLoading}>
          <Loading />
        </div>
      )}
      <Menu />
      <div className="main-content" id="panel">
        <TopMenu />
        <Header dashboard={null} />
        <div className="container-fluid mt--6">
          <div className={`${styles.card} card`}>
            <div className="card-header bg-default border-0">
              <h3 className="mb-0 text-white">Permissão de usuários</h3>
            </div>
            <div className={styles.padding}>
              <div className="form-group col-md-12 mt-4">
                <Select
                  className={`${styles.padding} form-control-select`}
                  onChange={handleChoice}
                  options={funcoes}
                  placeholder='Selecione um usuário...'
                />
              </div>
              <div className={styles.teste}>
                {funcoes && todasPermissoes ? (
                  todasPermissoes.map((p) => {
                    return (
                      <div
                        className={`${styles.divCheckBox} custom-control custom-checkbox`}
                        key={p.permissao_id}
                        style={{ marginBottom: "8px" }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input "
                          id={p.permissao_id}
                          name={p.nome}
                          onChange={handleCheck}
                          disabled
                        ></input>
                        <label
                          className="custom-control-label"
                          for={p.permissao_id}
                        >
                          {p.nome}
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <p>Carregando....</p>
                )}
              </div>
            </div>
            <div className="row justify-content-md-center">
              <button
                type="button"
                className="btn btn-success col-sm-6"
                onClick={handleSave}
              >
                Salvar
              </button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Permissoes;
