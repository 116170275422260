import { Store } from "../redux/Store"
import instance from "./index";
import moment from "moment";

export const getPrices = async (pagina, banco, convenio, regra, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = Store.getState().user.token
            const url = "tabelas_price/listar"
            const response = await instance.post(
                url,
                {
                banco: banco || null,
                convenio: convenio || null,
                regra: regra || null,
                data: data ? moment(data, "YYYY-MM-DD").format("DD/MM/YYYY") : null,
                pagina,
                },
                { headers: { token } }
            )

                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            } catch (err) {
                reject(err)
            }
    })
}

export const sendPrice = async base64 => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = Store.getState().user.token

            const url = "tabelas_price/processar"
            const response = await instance.post(
                url,
                { arquivo: base64 },
                { headers: { token } }
            )

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getPriceBanks = async () => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/bancos/listar-todos"
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getBanks = async () => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/bancos/listar"
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getPriceConventions = async () => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/convenios/listar-todos"
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getTypesContractsConsignado = async () => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/tipo-contrato/listar"
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getPriceRules = async () => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/regras/listar"
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const deletePrice = async (regra) => {
    try {
    const token = Store.getState().user.token

    const url = "tabelas_price/deletar-regra"
    const response = await instance.post(
        url,
        { id: regra },
        { headers: { token } }
    )

        if (response.status === 200) {
            return response.data
        } else {
            return response.data
        }
    } catch (err) {
        return err;
    }
}

export const saveRulePrice = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = "tabelas_price/gravar"
        const response = await instance.post(
            url,
            data,
            { headers: { token } }
        )

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}

export const getRule = async (regra, taxa) => {
    return new Promise(async (resolve, reject) => {
        try {
        const token = Store.getState().user.token

        const url = `tabelas_price/regras/buscar-regra?regra=${regra}&taxa=${taxa}`
        const response = await instance.get(url, { headers: { token } })

            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        } catch (err) {
            reject(err)
        }
    })
}