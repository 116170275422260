import React, { useState, useEffect, useMemo, useRef } from "react";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Menu from "../../../components/sidenav/Menu";
import TopMenu from "../../../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../components/modal/Modal";
import Loading from "../../../components/loading/Loading";
import Input from "../../../components/input/Input";

import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";
import {
  inativarRegraComissao,
  getRegrasComission,
  gravarRegraComissao,
  buscarRegrasComissao,
  getTypeRegras,
} from "../../../services/Regras";
import { getBanks } from "../../../services/Price";
import Select from "react-select";
import { getAtributosComission } from "../../../services/Commission";
import { getProducts } from "../../../services/CommissionAgent";

function RegrasComissao() {
  //Dados das tabelas/selects:
  const [fullLoading, setFullLoading] = useState(false);

  //Dados do filtro:
  const [bancos, setBancos] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [atributos, setAtributos] = useState([]);
  const [produtos, setProdutos] = useState([]);

  //Dados do formulário:
  const [regraComissao, setRegraComissao] = useState(null);
  const [banco, setBanco] = useState(null);
  const [atributo, setAtributo] = useState(null);
  const [porcent, setPorcent] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [produto, setProduto] = useState(null);
  const [datatable, setDataTable] = useState({});
  const [register, setRegister] = useState({});
  const [errors, setErrors] = useState({});

  const selectBanco = useRef();
  const selectTipo = useRef();
  const selectAtributo = useRef();
  const selectProduto = useRef();

  useEffect(() => {
    getRegras();
  }, []);

  useEffect(() => {
    setDataTable({
      columns: [
        {
          label: "ID",
          field: "regracomissao_id",
        },
        {
          label: "Banco ID",
          field: "banco_id",
        },
        {
          label: "Banco",
          field: "banco",
        },
        {
          label: "Porcentagem",
          field: "porcentagem",
        },
        {
          label: "Atributo",
          field: "atributo_descricao",
        },
        {
          label: "Tipo",
          field: "tipo_descricao",
        },
        {
          label: "Criado em",
          field: "criado_em",
        },
        {
          label: "Ação",
          field: "action",
        },
      ],
      rows:
        regraComissao &&
        regraComissao.map((x) => {
          return {
            banco: x.banco,
            porcentagem: x.porcentagem + "%",
            banco_id: x.banco_id,
            regracomissao_id: x.regracomissao_id,
            atributo_descricao: x.descricao,
            tipo_descricao: x.tipo_descricao,
            criado_em: x.criado_em,
            action: (
              <div className="dropdown">
                <a
                  className="btn btn-sm btn-icon-only text-light"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <button
                    type="button"
                    onClick={() => onDelete(x.regracomissao_id)}
                    className="dropdown-item"
                  >
                    <i className="fas fa-trash fa-lg"></i>
                    Deletar
                  </button>
                </div>
              </div>
            ),
          };
        }),
    });
  }, [regraComissao]);

  const onReset = async () => {
    window.$("#modal-adicionar").modal("hide");
    onClear();
  };

  const getRegras = async () => {
    try {
      setRegraComissao(await getRegrasComission());
      setBancos(await getBanks());
      setTipos(await getTypeRegras());
      setAtributos(await getAtributosComission());
      setProdutos(await getProducts());
    } catch (err) {}
  };

  const filterRegras = async (bank, atributo, type, percent) => {
    try {
      if (bank != null || atributo != null || type != null || percent != null) {
        setRegraComissao(
          await buscarRegrasComissao(bank, atributo, type, percent)
        );
      } else {
        setRegraComissao(await getRegrasComission());
      }
    } catch (err) {
      toast.error("Falha ao carregar os dados!");
    }
  };

  const onFilter = () => {
    window.$("#modal-filter").modal("hide");
    filterRegras(banco, atributo, tipo, porcent);
  };

  const onClear = () => {
    window.$("#tipo").val(null);
    window.$("#banco").val(null);
    window.$("#banco_id").val(null);
    window.$("#atributo").val(null);
    window.$("#produto").val(null);
    window.$("#porcentagem").val(null);
    selectBanco.current.select.clearValue();
    selectProduto.current.select.clearValue();
    selectAtributo.current.select.clearValue();
    selectTipo.current.select.clearValue();
    setBanco(null);
    setProduto(null);
    setTipo(null);
    setAtributo(null);
    setPorcent(null);
  };

  const onClearFilter = async () => {
    window.$("#tipofilter").val(null);
    window.$("#bancofilter").val(null);
    window.$("#atributofilter").val(null);
    window.$("#porcentagemfilter").val(null);
    setBanco(null);
    setTipo(null);
    setAtributo(null);
    setPorcent(null);
  };

  // const handleChange = (event) => {
  //   const { id, value } = event.target;

  //   switch (id) {
  //     case "percent":
  //       setPorcent(value);
  //       break;
  //     default:
  //       setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  //       setRegister({ ...register, [id]: value });
  //       break;
  //   }
  // };

  async function handleSave(event) {
    try {
      event.preventDefault();
      setFullLoading(true);
      const response = await gravarRegraComissao(register);
      if (response) {
        toast.success("Salvo com sucesso.");
        onClear();
        window.$("#modal-adicionar").modal("hide");
        await getRegras();
      } else {
        toast.error("Ocorreu um erro, ao salvar à regra.");
      }
    } catch (err) {
      toast.error(err.erro);
    } finally {
      setFullLoading(false);
    }
  }

  const onDelete = async (regracomissao_id) => {
    swal({
      title: "Deletar Regra?!",
      text: `A regra ${regracomissao_id}, será deletada, você tem certeza?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (response) => {
      if (response) {
        setFullLoading(true);
        const retorno = await inativarRegraComissao(regracomissao_id);
        if (retorno) {
          setFullLoading(false);
          swal("Regra deletada com sucesso.", {
            icon: "success",
          });
          await getRegras();
        } else {
          setFullLoading(false);
          swal(retorno.message, {
            icon: "error",
          });
        }
      } else {
        setFullLoading(false);
        swal("A regra não foi deletada.", {
          icon: "info",
        });
      }
    });
  };

  const disabledBtnSubmit = useMemo(() => {
    return (
      // Object.keys(register).filter((key) => register[key]).length !== 4 ||
      Object.keys(errors).filter((key) => errors[key]).length > 0
    );
  }, [errors]);

  return (
    <div>
      {fullLoading && (
          <Loading />
     )} 
      {/* Sidenav */}
      <Menu />
      {/* Main content */}
      <div className="main-content" id="panel">
        {/* Topnav */}
        <TopMenu />
        {/* Header */}
        <Header
          dashboard={null}
          buttons={[
            {
              name: "Adicionar",
              icone: (
                <i className="fas fa-plus" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-adicionar").modal("show"),
            },
            {
              name: "Filtrar",
              icone: (
                <i className="fas fa-search" style={{ marginLeft: "5px" }}></i>
              ),
              onClick: () => window.$("#modal-filter").modal("show"),
            },
          ]}
        />
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="card">
            <div className="card-header border-0">
              <MDBDataTableV5
                data={datatable && datatable.rows ? datatable : []}
                searchLabel="Buscar"
                entriesLabel={false}
                hover
                striped={true}
                pagingTop
                searchTop
                searchBottom={false}
                responsive={true}
                infoLabel={["", "a", "de", "Registros"]}
                entries={10}
                maxHeight="500px"
              />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Modal de Filtro */}
      <Modal
        id={"modal-filter"}
        title={"Filtrar"}
        size={"md"}
        form={
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              arial-label="close"
            >
              <span
                arial-hidden="true"
                className="text-white"
                onClick={onReset}
              >
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Regras</h3>
              </div>
              <div className="card-body">
                <div className="col-md-12 mb-2">
                  <Input
                    id="porcentagemfilter"
                    className="form-control"
                    placeholder="Porcentagem..."
                    type="percentual"
                    value={porcent}
                    onChange={(e) => {
                      setPorcent(e.target.value);
                    }}
                    help={errors.porcentagem || ""}
                  />
                </div>
                <div className="form-group  my-3">
                  <Select
                    id="bancofilter"
                    ref={selectBanco}
                    className="select-form-control"
                    placeholder="Bancos..."
                    onChange={(e) => setBanco(e.label || null)}
                    options={bancos}
                    value={{
                      label: banco ? banco : "Selecione o Banco...",
                    }}
                  />
                </div>
                <div className="form-group col-mb-3">
                  <Select
                    id="atributofilter"
                    className="select-form-control"
                    placeholder="Atributo..."
                    type="text"
                    onChange={(e) => setAtributo(e.label || null)}
                    options={atributos}
                    value={{
                      label: atributo ? atributo : "Selecione o Atributo...",
                    }}
                  />
                </div>
                <Select
                  id="tipofilter"
                  className="select-form-control"
                  placeholder="Tipo..."
                  type="text"
                  onChange={(e) => setTipo(e.label || null)}
                  options={tipos}
                  value={{
                    label: tipo ? tipo : "Selecione o Tipo...",
                  }}
                />

                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onClearFilter}
                      className="btn btn-danger btn-block my-4"
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={onFilter}
                      className="btn btn-primary btn-block my-4"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal Adicionar */}
      <Modal
        id={"modal-adicionar"}
        title="Adicionar"
        size="xl"
        form={
          <div>
            <button
              type="button"
              className="close"
               onClick={onReset}
              data-dismiss="modal"
              arial-label="close"
            >
              <span arial-hidden="true" className="text-white">
                x
              </span>
            </button>
            <div className="card mb-4">
              <div className="card-header">
                <h3 className="mb-0">Regra</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Banco</label>

                    <Select
                      id="banco"
                      ref={selectBanco}
                      className="select-form-control"
                      onChange={(e) => {
                        if (e) {
                          setRegister({ ...register, banco_id: e.value });
                          setBanco(e.label || null);
                        }
                      }}
                      options={bancos}
                      value={{
                        label: banco ? banco : "Selecione um banco...",
                      }}
                      backspaceRemovesValue
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Tipo*</label>
                    <Select
                      id="tipo"
                      ref={selectTipo}
                      className="select-form-control"
                      type="text"
                      onChange={(e) => {
                        if (e) {
                          setRegister({ ...register, tipo: e.value || null });
                          setTipo(e.label || null);
                        }
                      }}
                      options={tipos}
                      value={{ label: tipo ?? "Selecione o tipo..." }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Atributo*</label>
                    <Select
                      id="atributo"
                      ref={selectAtributo}
                      className="select-form-control"
                      placeholder="Atributo..."
                      type="text"
                      onChange={(e) => {
                        if (e) {
                          setRegister({
                            ...register,
                            atributo: e.value || null,
                          });
                          setAtributo(e.label || null);
                        }
                      }}
                      options={atributos}
                      value={{ label: atributo ?? "Selecione o atributo..." }}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Produto*</label>
                    <Select
                      id="produto"
                      ref={selectProduto}
                      className="select-form-control"
                      type="text"
                      onChange={(e) => {
                        if (e) {
                          setRegister({
                            ...register,
                            produto: e.value || null,
                          });
                          setProduto(e.label || null);
                        }
                      }}
                      options={produtos}
                      value={{ label: produto ?? "Selecione o produto..." }}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Porcentagem*</label>
                    <Input
                      type="percentual"
                      className="form-control"
                      id="porcent"
                      value={ porcent ?? 0.00}
                      onChange={(e) => {
                        if(e){
                          setRegister({...register, porcent : e.target.value});
                          setPorcent(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-block my-4"
                      data-dismiss="modal"
                      arial-label="close"
                      onClick={onClear}
                    >
                      Cancelar
                    </button>
                  </div>

                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      onClick={handleSave}
                      disabled={disabledBtnSubmit}
                      className="btn btn-success btn-block my-4"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default RegrasComissao;
