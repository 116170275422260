import React, { useState, useEffect, useMemo } from "react";
import {
   getUser,
   getUsers,
   getFunctionsUsers,
   recordUser,
   sendPasswordAdmin,
   inactivateUser,
   unlockAdministrator,
} from "../services/Users";
import Input from "../components/input/Input";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/sidenav/Menu";
import Select from "react-select";
import TopMenu from "../components/topMenu/TopMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/modal/Modal";
import Loading from "../components/loading/Loading";

//plugins
import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";

function Usuarios() {
   //Dados das tabelas/selects:
   const [data, setData] = useState(null);
   const [datatable, setDataTable] = useState({});
   const [page, setPage] = useState(1);

   //Dados do filtro:
   const [nome, setNome] = useState(null);
   const [nomeModal, setNomeModal] = useState(null);
   const [status, setStatus] = useState(null);
   const [cpf, setCpf] = useState(null);
   const [funcoes, setFuncoes] = useState([]);
   const [user, setUser] = useState({});
   const [errors, setErrors] = useState({});
   //Dados do formulário:
   const [formLoading, setFormLoading] = useState(false);
   const [fullLoading, setFullLoading] = useState(false);

   useEffect(() => {
      getData(null, null, null, 1);
      window.$("#modal-data").on("hidden.bs.modal", () => {
         onClearModal();
      });
   }, []);

   useEffect(() => {
      setDataTable({
         columns: [
            {
               label: "Id",
               field: "id",
            },
            {
               label: "CPF",
               field: "cpf",
            },
            {
               label: "Nome",
               field: "nome",
            },
            {
               label: "Status",
               field: "status",
            },
            {
               label: "Ação",
               field: "action",
            },
         ],
         rows:
            data &&
            data.dados.map((x) => {
               return {
                  id: x.id,
                  cpf: x.cpf,
                  nome: x.nome,
                  status: x.status,
                  action: (
                     <div className="dropdown">
                        <a
                           className="btn btn-sm btn-icon-only text-light"
                           href="#"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           <i className="fas fa-ellipsis-v" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() => editar(x.id, x.nome)}
                           >
                              <i className="fas fa-user-edit"></i>
                              Editar
                           </button>

                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() => generateNewPassword(x.id, x.nome)}
                           >
                              <i className="fas fa-key"></i>
                              Enviar Senha
                           </button>

                           {[8].includes(x.status_id) && (
                              <button
                                 type="button"
                                 onClick={() => handleUnlockAdministrator(x)}
                                 className="dropdown-item"
                              >
                                 <div className="divDropdown">
                                    <i className="fas fa-unlock-alt"></i>
                                    Desbloquear
                                 </div>
                              </button>
                           )}

                           <button
                              type="button"
                              className="dropdown-item"
                              onClick={() => inactivate(x.id, x.nome)}
                           >
                              <i className="fas fa-trash fa-lg"></i>
                              Deletar
                           </button>
                        </div>
                     </div>
                  ),
               };
            }),
      });
   }, [data]);

   const onReset = async () => {
      window.$("#modal-data").modal("hide");
      window.$("#modal-detalhes").modal("hide");
   };
   const getData = async (status, nome, cpf, pg = 1) => {
      try {
         setStatus(status);
         setNome(nome);
         setCpf(cpf);
         setPage(pg);
         setData(await getUsers(status, nome, cpf, page));
      } catch (err) {
         toast.error("Falha ao carregar dados!");
      }
   };

   // const onPageChanged = async (pg) => getData(status, nome, cpf, pg);

   const onFilter = () => {
      window.$("#modal-filter").modal("hide");
      getData(status, nome, cpf, 1);
   };

   const onClear = () => {
      setStatus(null);
      setNome(null);
      setCpf(null);
      window.$("#nome").val(null);
      window.$("#cpf").val(null);
   };

   const onClearModal = () => {
      setUser({});
      setErrors({});
      window.$("#funcao_id").val("");
      window.$("#nome").val(null);
      window.$("#cpf").val(null);
      window.$("#login").val(null);
   };

   const disabledBtnSubmit = useMemo(() => {
      return (
         Object.keys(user).filter((key) => user[key]).length < 5 ||
         Object.keys(errors).filter((key) => errors[key]).length > 0
      );
   }, [errors, user]);

   const generateNewPassword = (id, nome) => {
      swal({
         title: "Enviar senha?",
         text: `Será enviado uma nova senha para o e-mail do usuário: ${nome}`,
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFullLoading(true);
            const retorno = await sendPasswordAdmin(id);
            if (retorno.return) {
               setFullLoading(false);
               swal("Senha enviada com sucesso.", {
                  icon: "success",
               });
            } else {
               setFullLoading(false);
               swal(retorno.message, {
                  icon: "error",
               });
            }
         } else {
            setFullLoading(false);
            swal("A senha não foi enviada para o e-mail do usuário.", {
               icon: "info",
            });
         }
      });
   };

   const inactivate = (id, nome) => {
      swal({
         title: "Inativar usuário?",
         text: `Deseja inativar o usuário: ${nome}`,
         icon: "warning",
         buttons: true,
         dangerMode: false,
      }).then(async (response) => {
         if (response) {
            setFullLoading(true);
            const retorno = await inactivateUser(id);
            if (retorno.return) {
               setFullLoading(false);
               swal("Usuário inativado com sucesso.", {
                  icon: "success",
               });

               await getData(null, null, null, 1);
            } else {
               setFullLoading(false);
               swal(retorno.message, {
                  icon: "error",
               });
            }
         } else {
            setFullLoading(false);
            swal("O usuário não foi inativado.", {
               icon: "info",
            });
         }
      });
   };

   async function adicionar() {
      onClearModal();
      setFormLoading(true);
      setNomeModal("Adicionar");
      try {
         setFuncoes(await getFunctionsUsers());
         window.$("#modal-data").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do administrador!");
         window.$("#modal-data").modal("hide");
      } finally {
         setFormLoading(false);
      }
   }

   async function editar(id, nomeUsuario) {
      setFormLoading(true);
      setNomeModal("Editar - " + nomeUsuario);
      try {
         const response = await getUser(id);
         setUser(response);
         setFuncoes(await getFunctionsUsers());
         window.$("#modal-data").modal("show");
      } catch (err) {
         toast.error("Falha ao carregar dados do administrador!");
         window.$("#modal-data").modal("hide");
      } finally {
         setFormLoading(false);
      }
   }

   function handleChange(event) {
      const { id, value } = event.target;
      setUser({ ...user, [id]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
   }

   function handleBlur(event) {
      let message = "Campo Obrigatório";
      const { id, value } = event.target;

      if (!value) {
         setErrors((prevErrors) => ({ ...prevErrors, [id]: message }));
      }
   }

   async function handleSave(event) {
      try {
         event.preventDefault();
         setFormLoading(true);
         const response = await recordUser(user);
         if (response && response.id) {
            toast.success("Usuário salvo com sucesso.");
            onClearModal();
            await getData(null, null, null, 1);
            window.$("#modal-data").modal("hide");
         } else {
            toast.error("Ocorreu um erro, ao salvar o usuário.");
         }
      } catch (err) {
         toast.error(err.erro);
      } finally {
         setFormLoading(false);
      }
   }

   const handleUnlockAdministrator = async (user) => {
      try {
         setFullLoading(true);
         await sendPasswordAdmin(user.id);
         const result = await unlockAdministrator(user.id);
         if (result && result.return === true) {
            toast.success(result.message);
         } else {
            toast.error(
               result.message ||
                  "Ocorreu um erro ao desbloquear o usuario, tente novamente."
            );
         }
      } catch (error) {
         toast.error(
            "Ocorreu um erro ao desbloquear o usuario, tente novamente."
         );
      } finally {
         setFullLoading(false);
      }
   };

   return (
      <div>
         {fullLoading && (
               <Loading />
         )}
         {/* Sidenav */}
         <Menu />
         {/* Main content */}
         <div className="main-content" id="panel">
            {/* Topnav */}
            <TopMenu />
            {/* Header */}
            <Header
               dashboard={null}
               buttons={[
                  {
                     name: "Adicionar",
                     icone: (
                        <i
                           className="fas fa-plus"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: adicionar,
                  },
                  {
                     name: "Filtrar",
                     icone: (
                        <i
                           className="fas fa-search"
                           style={{ marginLeft: "5px" }}
                        ></i>
                     ),
                     onClick: () => window.$("#modal-filter").modal("show"),
                  },
               ]}
            />
            {/* Page content */}
            <div className="container-fluid mt--6">
               <div className="card">
                  <div className="card-header border-0">
                     <MDBDataTableV5
                        // onPageChanged={onPageChanged}
                        data={datatable && datatable.rows ? datatable : []}
                        searchLabel="Buscar"
                        entriesLabel="Registros por Página"
                        hover
                        striped={true}
                        entriesOptions={[10, 20, 30]}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        responsive={true}
                        infoLabel={["", "a", "de", "Registros"]}
                        entries={10}
                        maxHeight="500px"
                     />
                  </div>
               </div>
               {/* Footer */}
               <Footer />
            </div>
         </div>
         {/* Modal de Filtro */}
         <Modal
            id={"modal-filter"}
            title={"Filtrar"}
            size={"md"}
            form={
               <div>
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     arial-label="close"
                  >
                     <span
                        arial-hidden="true"
                        className="text-white"
                        onClick={onReset}
                     >
                        x
                     </span>
                  </button>
                  <div className="card mb-4">
                     <div className="card-header">
                        <h3 className="mb-0">Usuario</h3>
                     </div>
                     <div className="card-body">
                        <div className="form-group mb-3">
                           <div className="input-group mx-4">
                              <Input
                                 id="nome"
                                 className="form-control"
                                 placeholder="Nome..."
                                 type="text"
                                 value={nome}
                                 onChange={(e) => {
                                    setNome(e.target.value);
                                 }}
                              />
                           </div>
                        </div>

                        <div className="form-group mb-3">
                           <div className="input-group mx-4">
                              <Input
                                 id="cpf"
                                 className="form-control"
                                 placeholder="cpf..."
                                 type="cpf"
                                 value={cpf}
                                 onChange={(e) => {
                                    setCpf(e.target.value);
                                 }}
                              />
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onClear}
                                 className="btn btn-danger btn-block my-4"
                              >
                                 Limpar
                              </button>
                           </div>

                           <div className="col-md-6 text-center">
                              <button
                                 type="button"
                                 onClick={onFilter}
                                 className="btn btn-primary btn-block my-4"
                              >
                                 Filtrar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         />
         {/* Modal Adicionar / Editar */}
         <Modal
            id={"modal-data"}
            title={nomeModal}
            size={"lg"}
            form={
               formLoading ? (
                  <div>
                     <Loading />
                  </div>
               ) : (
                  <div>
                     <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        arial-label="close"
                     >
                        <span
                           arial-hidden="true"
                           className="text-white"
                           onClick={onClear}
                        >
                           x
                        </span>
                     </button>
                     <div className="card mb-4">
                        <div className="card-header">
                           <h3 className="mb-0">Usuario</h3>
                        </div>
                        <div className="card-body">
                           <div className="row">
                              <div className="form-group col-md-6">
                                 <label>Função*</label>
                                 <Select
                                    id="funcao_id"
                                    className="select-form-control"
                                    placeholder="Função..."
                                    onChange={(e) => {if(e){ setUser({...user, funcao_id : e.value, funcao: e.label}); }}}
                                    onBlur={handleBlur}
                                    help={errors.funcao_id || ""}
                                    options={funcoes}
                                    value={{label: user.funcao ?? user.funcao ?? "Selecione uma função.."}}
                                 />
                              </div>

                              <div className="form-group col-md-6">
                                 <label>CPF*</label>
                                 <Input
                                    id="cpf"
                                    className="form-control"
                                    placeholder="cpf..."
                                    type="cpf"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={user.cpf || null}
                                    help={errors.cpf || ""}
                                 />
                              </div>
                           </div>

                           <div className="row">
                              <div className="form-group col-md-6">
                                 <label>Nome Completo*</label>
                                 <Input
                                    id="nome"
                                    className="form-control"
                                    placeholder="Nome..."
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={user.nome || null}
                                    help={errors.nome || ""}
                                 />
                              </div>

                              <div className="form-group col-md-6">
                                 <label>E-mail*</label>
                                 <Input
                                    id="email"
                                    className="form-control"
                                    placeholder="E-mail..."
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={user.email || null}
                                    help={errors.email || ""}
                                 />
                              </div>
                           </div>

                           <div className="row">
                              <div className="form-group col-md-6">
                                 <label>Login*</label>
                                 <Input
                                    id="login"
                                    className="form-control"
                                    placeholder="Login..."
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={user.login || null}
                                    help={errors.login || ""}
                                 />
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-md-6 text-center">
                                 <button
                                    type="button"
                                    className="btn btn-danger btn-block my-4"
                                    data-dismiss="modal"
                                    arial-label="close"
                                 >
                                    Cancelar
                                 </button>
                              </div>

                              <div className="col-md-6 text-center">
                                 <button
                                    type="button"
                                    onClick={handleSave}
                                    disabled={disabledBtnSubmit}
                                    className="btn btn-success btn-block my-4"
                                 >
                                    Salvar
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            }
         />
         <ToastContainer />
      </div>
   );
}

export default Usuarios;
